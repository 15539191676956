import React, { useRef, useState } from 'react'
import { useEffect } from 'react'

// styles
import { WrapDropdown } from './index.styles'

// component
import BoxListDropdownItem, { listDropdownData } from './BoxListDropdownItem'
import ArrowDownBlack from '../../../../assets/images/components/ArrowDownBlack'

// hooks
import { useOnClickOutside } from '../../../../hook/useClickOutside.hook'

const DropdownSelectTypeFilterNum = ({ value, onChangeValue }) => {

    const [selected, setSelected] = useState(value || "")

    const dropdownRef = useRef()

    const [isShowDropdown, setIsShowDropdown] = useState(false)

    const _handleClose = () => {
        setIsShowDropdown(!isShowDropdown)
    }

    useOnClickOutside(dropdownRef, () => setIsShowDropdown(false))

    useEffect(() => {
        setSelected(value)
    }, [value])

    useEffect(() => {
        onChangeValue && onChangeValue(selected)
    }, [selected])

    return (
        <WrapDropdown ref={dropdownRef}>
            <div className="title-dropdown" onClick={_handleClose}>
                <div>{listDropdownData[selected]?.text || "Chọn"}</div>
                <ArrowDownBlack />
            </div>
            {
                isShowDropdown &&
                <BoxListDropdownItem 
                    closePopup={() => setIsShowDropdown(false)}
                    selected={selected}
                    setSelected={setSelected}
                />
            }
        </WrapDropdown>
    )
}

export default DropdownSelectTypeFilterNum
