
import moment from "moment";
import { dataCategory } from '../data/dataChain'

export const checkMail = (email) => {
  var filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!filter.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const convertDateTime = (time, format) => {
  return moment(time).format(format);
};

export const converTypeOfPaper = (data) => {
  let fullcardType = ''
  let shortcardType = ''
  switch (data) {
    case 'CCCD':
      {
        fullcardType = 'Căn cước công dân'
        shortcardType = 'căn cước công dân'
      }
      break;
    case 'CMND':
      {
        fullcardType = 'Chứng minh nhân dân'
        shortcardType = 'chứng minh nhân dân'
      }
      break;
    case 'PASSPORT':
      {
        fullcardType = 'Passport'
        shortcardType = 'passport'
      }
      break;
    default:
      break;
  }
  return { fullcardType, shortcardType }
};

export const renderNameCategory = (type) => {
  if (type) {
    const data = dataCategory.filter((item) => item.key === type);
    if(data[0])
      return data[0].value;
    else 
      return "-"
  } else return "-";
};

export const buildMoney = value => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

