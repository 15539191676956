export const GET_LIST_SHOPS_BY_CHAIN = 'GET_LIST_SHOPS_BY_CHAIN'
export const GET_LIST_SHOPS_BY_CHAIN_ERR = 'GET_LIST_SHOPS_BY_CHAIN_ERR'

export const ADD_SHOP_TO_CHAIN_START = "ADD_SHOP_TO_CHAIN_START"
export const ADD_SHOP_TO_CHAIN_FINISH = "ADD_SHOP_TO_CHAIN_FINISH"
export const ADD_SHOP_TO_CHAIN = "ADD_SHOP_TO_CHAIN"
export const ADD_SHOP_TO_CHAIN_ERR = "ADD_SHOP_TO_CHAIN_ERR"

export const EDIT_SHOP_TO_CHAIN_START = "EDIT_SHOP_TO_CHAIN_START"
export const EDIT_SHOP_TO_CHAIN_FINISH = "EDIT_SHOP_TO_CHAIN_FINISH"
export const EDIT_SHOP_FROM_CHAIN = "EDIT_SHOP_FROM_CHAIN"
export const EDIT_SHOP_FROM_CHAIN_ERR = "EDIT_SHOP_FROM_CHAIN_ERR"

export const REMOVE_SHOP_TO_CHAIN_START = "REMOVE_SHOP_TO_CHAIN_START"
export const REMOVE_SHOP_TO_CHAIN_FINISH = "REMOVE_SHOP_TO_CHAIN_FINISH"
export const REMOVE_SHOP_FROM_CHAIN = "REMOVE_SHOP_FROM_CHAIN"
export const REMOVE_SHOP_FROM_CHAIN_ERR = "REMOVE_SHOP_FROM_CHAIN_ERR"