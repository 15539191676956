import React, { useRef, useCallback, useState } from 'react'

// style
import { WrapperFillReason, BtnSend } from './index.style'

const FillReasonReject = ({ onClick }) => {

    const [value, setValue] = useState('')
    const inputRef = useRef()

    const _handleSend = useCallback(
        () => {
            onClick(value.trim().replace(/\s+/g, " "))
        },
        [onClick, value],
    )

    const _handleOnChange = useCallback(
        (e) => {
            const { value } = e.target;
            const val = value.trimStart().replace(/\s+/g, " ")
            setValue(val)
        },
        [setValue],
    )

    return (
        <WrapperFillReason>
            {/* <div className="bg-fixed"></div> */}
            <div className="title">Nhập lý do từ chối</div>
            <div className="wrapper-reason">
                <input 
                    type="text" 
                    placeholder="Nhập lý do từ chối"
                    ref={inputRef}
                    value={value}
                    maxLength={200}
                    onChange={_handleOnChange}
                />
                <BtnSend 
                    onClick={_handleSend}
                    isDisabled={!value.trim().replace(/\s+/g, " ")}
                >Gửi</BtnSend>
            </div>
        </WrapperFillReason>
    )
}

export default FillReasonReject
