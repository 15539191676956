import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// style
import { HeaderNoteWrapper, NodeItem } from './index.style';

// constant
import { INFO, WARNING, DANGER } from '../../../../assets/constants/noteTypes';

const HeaderNote = ({lstClassification}) => {
    const { t } = useTranslation('common')

    // const { lstClassification } = useSelector(state => ({
    //     lstClassification: state.userCensorship.detailsUser.notes.lstClassification,
    // }))

    // const NoteA = dataListNote.filter((item) => item.Priority === "A");
    // const NoteB = dataListNote.filter((item) => item.Priority === "B");
    // const NoteC = dataListNote.filter((item) => item.Priority === "C");
    return (
      <HeaderNoteWrapper>
        <p className="title">{t('header.note')}</p>
        <div className="listNode">
          <NodeItem color="#fa8c8c">
            <p>{lstClassification[DANGER]}</p>
            <span />
          </NodeItem>
          <NodeItem color="#FAD58C">
            <p>{lstClassification[WARNING]}</p>
            <span />
          </NodeItem>
          <NodeItem color="#93BFF2">
            <p>{lstClassification[INFO]}</p>
            <span />
          </NodeItem>
        </div>
      </HeaderNoteWrapper>
    );
  };
  

export default HeaderNote
