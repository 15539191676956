import { combineReducers } from "redux";

// reducers
import authReducer from "./authenticates";
import userCensorship from './userCensorship'
import compareEKYCs from './compareEKYCs'
import listInfoUsers from './listInfoUsers'
import notifies from './notifies'
import chains from './chains'
import transactions from './transactions'

// constant
import { AUTH_LOGOUT } from "./authenticates/types";
import { CLEAR_STORE } from "./types";

const app = combineReducers({
  authReducer,
  userCensorship,
  compareEKYCs,
  listInfoUsers,
  notifies,
  chains,
  transactions
});

const rootReducer = (state, action) => {
  if ([AUTH_LOGOUT, CLEAR_STORE].includes(action.type)) state = undefined;
  return app(state, action);
};

export default rootReducer;
