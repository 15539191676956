import styled from 'styled-components'

const WrapperVideo = styled.div`
    border-radius: 3px;
    background: #eaeff4cc;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    /* &:hover > .overlay-control {
        display: flex;
    } */

    .overlay-control {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(image.png);
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        /* display: none; */

        :hover {
            cursor: pointer;
        }
    }
`;

export {
    WrapperVideo
}