import React, { useCallback, useMemo, useState, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
//host
import { apiAvatarImageMini } from '../../../../../assets/constants/host'
//constants
import * as censorship_status from '../../../../../assets/constants/faceCensorShipStatus'
//component
import AvatarVerifyDefault from '../../../../../assets/images/components/AvatarVerifyDefault'
import IcCheckGreen from '../../../../../assets/images/components/Ic_CheckGreen'
import IcTimeGrey from '../../../../../assets/images/components/Ic_TimeGrey'

// styles
import { StyleRow } from './index.styles'
import IcRejectRed from '../../../../../assets/images/components/IcRejectRed'
import IcNotRegister from '../../../../../assets/images/components/IcNotRegister'
import IcCheckGrey from '../../../../../assets/images/components/IcCheckGrey'

// const dataMock = {
//     EmailStatus: "ACCEPT",
// }

const Row = ({ data, selected, setSelected, enableClick = true, onSelect, ...props }) => {
    const { t } = useTranslation('common');
    const [imageError, setImageError] = useState(false)
    const [avatar, setAvatar] = useState('');

    const _handleClick = useCallback(
        () => {
            if (!enableClick) return;
            setSelected(data)
            onSelect(data)
        },
        [setSelected, data, enableClick],
    )

    const isSelected = useMemo(() => enableClick ? selected.ID === data.ID : false, [enableClick, selected, data])

    useEffect(() => {
        const { ID } = data;
        if(!ID) {
            setAvatar('')
            return;
        }
        setAvatar(apiAvatarImageMini(ID))
    }, [data]);


    return (
        <StyleRow onClick={_handleClick} enableClick={enableClick} {...props}>
            {
                enableClick &&
                <div className={isSelected ? "dot dot-selected" : "dot"} />
            }
            <div className={isSelected ? "content-data content-data-selected" : "content-data"}>
                <div className='avatar-user-container '>
                    {
                        imageError || !avatar ?
                            <AvatarVerifyDefault className='avatar-user' />
                            :
                            <img
                                className={data?.IsLock === false ? 'avatar-user' : 'avatar-user block'}
                                src={avatar}
                                alt='avatar-user'
                                onError={() => setImageError(true)}
                            />
                    }

                </div>
                <div className='info-user'>
                    <div className='fullname-user' data-toggle='tooltip' title={data?.FullName ? data.FullName : ""}>
                        {data?.FullName ? data.FullName : "-"}
                    </div>
                    <div className='wrapper-username'>
                        <span data-toggle='tooltip' title={data?.Username ? data.Username : ""}>
                            {data?.Username ? `@${data.Username}` : "-"}
                        </span>
                            - &nbsp;{data?.PhoneNumber ? data.PhoneNumber : "-"}
                    </div>
                </div>
                <div className='status-container'>
                    <div className={data?.IsVerifiedMail === true ? "email-status accept" : "email-status reject"}>
                        {data?.IsVerifiedMail === true ?
                            <Fragment>
                                <IcCheckGreen />
                                <span>{t('table.status.email_status')}</span>
                            </Fragment>
                            :
                            <Fragment>
                                <IcCheckGrey />
                                <span>{t('table.status.email_status')}</span>
                            </Fragment>
                        }
                    </div>
                    <div className={data.AvatarStatus === censorship_status.ACCEPT ? "avatar-status accept" : "avatar-status reject"}>
                        {data?.AvatarStatus && data.AvatarStatus === censorship_status.ACCEPT ?
                            <Fragment>
                                <IcCheckGreen />
                                <span>{t('table.status.avatar_status')}</span>
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    data.AvatarStatus === censorship_status.REJECT ?
                                        (
                                            <Fragment>
                                                <IcRejectRed />
                                                <span>{t('table.status.avatar_status')}</span>
                                            </Fragment>
                                        )
                                        :
                                        (
                                            <Fragment>
                                                <IcTimeGrey />
                                                <span>{t('table.status.avatar_status')}</span>
                                            </Fragment>
                                        )
                                }
                            </Fragment>
                        }
                    </div>
                    <div className={data.EKYCStatus === censorship_status.ACCEPT ? "ekyc-status accept" : "ekyc-status reject"}>
                        {data?.EKYCStatus && data.EKYCStatus === censorship_status.ACCEPT ?
                            <Fragment>
                                <IcCheckGreen />
                                <span>{t('table.status.ekyc_status')}</span>
                            </Fragment>
                            :
                            <Fragment>
                                {
                                    data.EKYCStatus === censorship_status.REJECT ?
                                        <Fragment>
                                            <IcRejectRed />
                                            <span>{t('table.status.ekyc_status')}</span>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {
                                                data.EKYCStatus === censorship_status.NOTREGISTER ?
                                                    <Fragment>
                                                        <IcNotRegister />
                                                        <span>{t('table.status.ekyc_status')}</span>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <IcTimeGrey />
                                                        <span>{t('table.status.ekyc_status')}</span>
                                                    </Fragment>
                                            }
                                        </Fragment>
                                }
                            </Fragment>
                        }
                    </div>
                </div>

            </div>
        </StyleRow>
    )
}

export default React.memo(Row)
