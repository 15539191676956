import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'

// styles
import * as style from './index.styles'
import { BgWrap } from '../../../../Control/Popup/index.styles'

//component
import Popup from '../../../Popup'
import ContentPopupAddChains from './ContenPopuptAddChains'
import ModalControl from '../../../ModalControl'

const PopupAddChains = ({ btnRef, handleClose }) => {

    const timeRef = useRef(null)

    const [pos, setPos] = useState({})

    useLayoutEffect(() => {
        const { current } = btnRef;
        if (!current) return;
        const posOfBtn = current.getBoundingClientRect();
        
        setPos({ 
            top: posOfBtn.top + posOfBtn.height + 16,
            left: posOfBtn.left + 20
        })
    }, [])

    const handleChangePos = () => {
        
        const { current } = btnRef;
        if (!current) return;
        const posOfBtn = current.getBoundingClientRect();
        setPos({
            top: posOfBtn.top + posOfBtn.height + 16,
            left: posOfBtn.left + 20
        })
    }

    useEffect(() => {
        window.addEventListener("scroll", handleChangePos)
        return () => {
            window.removeEventListener("scroll", handleChangePos)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleChangePos)
        return () => {
            window.removeEventListener("resize", handleChangePos)
        }
    }, [])

    // useEffect(() => {
    //     const html = document.querySelector("html")
    //     if (!html) return;
    //     html.style.overflowX = "hidden"
    //     return () => {
    //         html.style.overflowX = "auto"
    //     }
    // }, [])

    return (
        <ModalControl>
            <BgWrap>
                <style.Bound pos={pos}>
                    <Popup
                        title="thêm chuỗi mới"
                        handleClose={handleClose}
                    >
                        <ContentPopupAddChains handleClose={handleClose} />
                    </Popup>
                </style.Bound>
            </BgWrap>
        </ModalControl>
    )
}

export default PopupAddChains
