import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import * as styles from './index.style'
//tools
import { convertDateTime } from '../../../../assets/helper/tools';
import { apiAvatarImage } from '../../../../assets/constants/host';

const SimpleInfos = ({ infos, paddingLeft }) => {

    const { t } = useTranslation('common')
    const [data, setData] = useState(infos)

    useEffect(() => {
        setData(infos)
    }, [infos])

    return (
        <styles.Bound paddingLeft={paddingLeft}>
            <div className="infomation-left">
                <img className="avatar-user" src={data?.ID?apiAvatarImage(data.ID):""} alt="avatar-user" />
                <div className="infomation-avatar">
                    <p className="title-info">{t('user_detail.date_register')}</p>
                    <p className="content-info">
                        {data.CreateTime ?
                            convertDateTime(data.CreateTime, "DD/MM/YYYY")
                            : "-"
                        }
                        <span>
                            {data.CreateTime ?
                                convertDateTime(data.CreateTime, "HH:mm")
                                : "-"
                            }
                        </span>
                    </p>
                </div>
                <div className="infomation-avatar">
                    <p className="title-info">{t('user_detail.last_active')}</p>
                    <p className="content-info">
                        {data.UpdateTime ?
                            convertDateTime(data.UpdateTime, "DD/MM/YYYY")
                            : "-"
                        }
                        <span>
                            {data.UpdateTime ?
                                convertDateTime(data.UpdateTime, "HH:mm")
                                : "-"
                            }
                        </span>
                    </p>
                </div>
            </div>
            <div className="infomation-right">
                <div className="name-container">
                    <p className="name-user">{data ?.FullName ? data.FullName : "-"}</p>
                    <p className="phone-user">{data ?. PhoneNumber ? data.PhoneNumber : "-"}</p>
                </div>
                <div className="gender-container">
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.wee_id')}</p>
                        <p className="content-info">
                            {data ?. WeeID ? data.WeeID : "-"}
                        </p>
                    </div>
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.birthday')}</p>
                        <p className="content-info">
                            {data ?. Birthday ? convertDateTime(data.Birthday, "DD/MM/YYYY") : "-"}
                        </p>
                    </div>
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.gender')}</p>
                        <p className="content-info">
                            {data ?. Gender && data.Gender === 0
                                ? t('user_detail.gender_default')
                                : data.Gender === 1
                                    ? t('user_detail.gender_male')
                                    : data.Gender === 2
                                        ? t('user_detail.gender_female')
                                        : data.Gender === 3
                                            ? t('user_detail.gender_other')
                                            : "-"
                            }
                        </p>
                    </div>
                </div>
            </div>
        </styles.Bound>
    )
}

export default SimpleInfos
