import React, { useState, useEffect } from 'react'

const useDidMount = (callback, listDependencies) => {
    const [isFirstRender, setIsFirstRender] = useState(true)
    useEffect(() => {
        if(!isFirstRender) return;
        const isCall = callback()
        if(!isCall) return;
        setIsFirstRender(false)
    }, [isFirstRender, ...listDependencies])
}

export default useDidMount
