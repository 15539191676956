import styled from "styled-components";

export const Bound = styled.div`
    width: 100%;
    height: 100%;
    font-family: SVN-Gilroy;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px 0 30px;
    box-sizing: border-box;

    .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 170%;
        text-transform: uppercase;
    }
    .block-edit {
        display: flex;
        justify-content: flex-end;
        .button-edit {
          width: 30px;
          height: 30px;
          border-radius: 12.5px;
          position: relative;
          overflow: hidden;
          transition: 0.5s all ease-in-out;
          .icon-edit {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            z-index: 1;
          }
          .tooltiptext {
            visibility: hidden;
            z-index: 0;
            width: 84px;
            height: 28px;
            background-color: rgba(18, 121, 218, 0.2);
            border-radius: 12.5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            top: 1px;
            left: 0px;
            cursor: pointer;
            transition: 0.5s all ease-in-out;
            p {
              font-family: "SVN-Gilroy";
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 170%;
              color: #000000;
              padding-left: 10px;
              margin: 0;
              transition: 0.5s all ease-in-out;
            }
          }
          &:hover {            
              width: 94px;
              .tooltiptext {
                visibility: visible;
              }            
          }
        }
      }
`;
