import React, { useCallback } from 'react'

//style
import { RowFilter } from './index.styles'
//component
import DropDownFilterInfoUsers from './DropDownFilterInfoUsers'
import DropDownProcessStatus from './DropDownProcessStatus'

const Filters = () => {


    const _handleChangeValuesFilterByUsers = useCallback(

    )

    const _handleChangeValuesFilterByProcessStatus = useCallback(

    )
    return (

        <RowFilter className="filter-controls" enableClick={false}>
            <div className="filter-users">
                <DropDownFilterInfoUsers
                    onChangeValues={_handleChangeValuesFilterByUsers}
                />
            </div>
            <div className="filter-status">
                <DropDownProcessStatus
                    onChangeValues={_handleChangeValuesFilterByProcessStatus}
                />
            </div>
        </RowFilter>

    )
}

export default Filters
