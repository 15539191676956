import * as types from "./types";

export const intitialState = {
    listReasons: [],
    isLoadingLockAccount: false,
    isLoadingUnLockAccount: false,
};

const detailsAccount = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_USER_INFOS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.GET_LIST_REASON_LOCK: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.LOCK_ACCOUNT_START: {
            return {
                ...state,
                isLoadingLockAccount: true
            }
        }
        case types.LOCK_ACCOUNT_END: {
            return {
                ...state,
                isLoadingLockAccount: false
            }
        }
        case types.UNLOCK_ACCOUNT_START: {
            return {
                ...state,
                isLoadingUnLockAccount: true
            }
        }
        case types.UNLOCK_ACCOUNT_END: {
            return {
                ...state,
                isLoadingUnLockAccount: false
            }
        }
        case types.CLEAR_DETAILS_ACCOUNT: {
            return {
                ...intitialState,
                listReasons: state.listReasons
            }
        }
        default:
            return state;
    }
};

export default detailsAccount;
