import React, { useState, useCallback, useRef } from 'react'

// image
import IcPlayCircle from '../../../../../../../../assets/images/components/IcPlayCircle'

// style
import { WrapperVideo } from './index.style';

const mockVideo = "http://techslides.com/demos/sample-videos/small.ogv"

const VideoCensorship = (props) => {
    const { videoURL } = props
    const refVideo = useRef(null);
    const [isShowPlay, setIsShowPlay] = useState(true)

    const _handleControlsVideo = useCallback(
        () => {
            const video = refVideo.current;
            if (!video) return;
            setIsShowPlay(false)
            video.play();
        },
        [refVideo],
    )

    const _handleEndedVideo = useCallback(() => {
        setIsShowPlay(true)
    }, [])

    return (
        <WrapperVideo>
            {
                videoURL && videoURL.length > 0 ?
                <video
                    width="307"
                    height="409"
                    loop 
                    autoplay="autoplay"
                    // ref={refVideo}
                    // onEnded={_handleEndedVideo}
                    // controls
                // className="video-censorship"
                >
                    <source src={mockVideo} type="video/ogg" />
                        Your browser does not support the video tag.
                </video>
                :
                <p>No video</p>
            }
            
            {/* {
                isShowPlay &&
                <div
                    className="overlay-control"
                    onClick={_handleControlsVideo}
                >
                    <IcPlayCircle />
                </div>
            } */}
        </WrapperVideo>
    )
}

export default VideoCensorship
