import React from 'react'

const IcUserCheck = ({ colorState }) => <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <circle opacity="0.1" cx={16} cy={16} r={16} fill={colorState} />
    <path d="M18.5455 21.4545V20.1818C18.5455 19.5067 18.2773 18.8593 17.7999 18.3819C17.3225 17.9045 16.6751 17.6364 16 17.6364H11.5455C10.8704 17.6364 10.2229 17.9045 9.74555 18.3819C9.26818 18.8593 9 19.5067 9 20.1818V21.4545" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.7727 15.0909C15.1786 15.0909 16.3182 13.9513 16.3182 12.5455C16.3182 11.1396 15.1786 10 13.7727 10C12.3669 10 11.2273 11.1396 11.2273 12.5455C11.2273 13.9513 12.3669 15.0909 13.7727 15.0909Z" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19.1818 15.0909L20.4545 16.3636L22.9999 13.8182" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width={32} height={32} fill="white" />
    </clipPath>
  </defs>
</svg>

export default IcUserCheck
