import styled from 'styled-components'

const NotifyWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
`;

export { 
    NotifyWrapper
}