import React, { useCallback } from 'react'

// styles
import { WrapBoxFilterByTime } from './index.styles'
import { extendStylesCheckbox } from '../BoxFilterByText/index.styles'

// component
import CheckBoxField from '../CheckBoxField'
import DateRangePicker from './DateRangePicker'

// constant
export const INCREASE = "INCREASE"
export const DECREASE = "DECREASE"

const BoxFilterByTime = ({ stateFilter, dispatchStateFilter, onChangeValues }) => {

    const _onChangeDateTime = useCallback(
        (keyTime) => (dateTime) => {
            if(!dateTime) return;
            if(dateTime?.toDateString() === stateFilter[keyTime].toDateString()) return;
            dispatchStateFilter({ [keyTime]: dateTime })
            onChangeValues({
                ...stateFilter,
                [keyTime]: dateTime
            })
        },
        // [dispatchStateFilter],
        [dispatchStateFilter, onChangeValues, JSON.stringify(stateFilter)],
    )

    const _handleChange = useCallback((objChange) => {
        if(objChange.sort === stateFilter.sort) return
        dispatchStateFilter(objChange)
        onChangeValues({
            ...stateFilter,
            ...objChange
        })
    }, [dispatchStateFilter, JSON.stringify(stateFilter), onChangeValues])

    return (
        <WrapBoxFilterByTime>
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === INCREASE}
                    // onClick={() => dispatchStateFilter({ sort: INCREASE })}
                    onClick={() => _handleChange({ sort: INCREASE })}
                    data="Tăng dần" 
                    extendStyles={extendStylesCheckbox}
                />
            </div>
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === DECREASE}
                    // onClick={() => dispatchStateFilter({ sort: DECREASE })}
                    onClick={() => _handleChange({ sort: DECREASE })}
                    data="Giảm dần" 
                    extendStyles={extendStylesCheckbox}
                />
            </div>
            <div className="field select-date-range">
                <div className="wrap-select-date-range">
                    <DateRangePicker 
                        startDateTime={stateFilter.startDateTime}
                        endDateTime={stateFilter.endDateTime}
                        onChangeStartDateTime={_onChangeDateTime('startDateTime')}
                        onChangeEndDateTime={_onChangeDateTime('endDateTime')}
                        dateFormat="dd/MM/yyyy"
                        timeCaption=""
                        popperPlacement="bottom-right"
                    />
                </div>
            </div>
        </WrapBoxFilterByTime>
    )
}

export default BoxFilterByTime
