import moment from 'moment'
// export const host = "http://192.168.1.217:7090/v1";

// export const host = "https://admin-dev.facepay.vn/v1";
// export const host = "http://192.168.1.217:7092/v1";
// export const host = "https://admin-qc.facepay.vn/v1"
// const host = "http://admin-beta.facepay.vn/v1"

const hostConfigs = {
  dev: 'https://cs-dev.facepay.vn/v1',
  // qc: 'https://admin-qc.facepay.vn/v1',
  qc: 'https://cs-qc.facepay.vn/v1',
  qcUpComing: 'https://admin-qc-upcom.facepay.vn/v1',
  demo: 'https://admin-demo.facepay.vn/v1',
  staging: 'https://admin-staging.facepay.vn/v1',
  production: 'https://cs.facepay.vn/v1',
  uat:'https://cs-uat.facepay.vn/v1'
}

const refCountNewUserConfigs = {
  dev: 'facepay-cs/count_new_user/count',
  production: 'facepay-cs-prod/count_new_user/count',
}

// const hostVideoTrans = 'http://weezi.biz:7091/v1'
const hostVideoTrans = (() => {
  switch(process.env.REACT_APP_BUILD_TYPE) {
    case 'qc':
      // return 'https://admin-qc.facepay.vn'
      return 'https://cs-qc.facepay.vn'
    case 'qcUpComing':
      return 'https://admin-qc-upcom.facepay.vn'
    case 'demo':
      return 'https://admin-demo.facepay.vn'
    case 'staging':
      return 'https://admin-staging.facepay.vn'
    case 'production':
      return 'https://cs.facepay.vn'
    case 'uat':
      return 'https://cs-uat.facepay.vn'
    default:
      return 'https://cs-dev.facepay.vn'
  }
})()

export const host = process.env.REACT_APP_BUILD_TYPE
  ? hostConfigs[process.env.REACT_APP_BUILD_TYPE]
  : hostConfigs.dev


export const refNotifyNewUser = refCountNewUserConfigs[process.env.REACT_APP_BUILD_TYPE] || refCountNewUserConfigs.dev
console.log(refNotifyNewUser)
// export const apiVerifyImageInfo = (id = "") => {
//   return `${host}/backoffice/user-face?id=${id}`;
// };
export const apiAvatarImage = (userID = "") => {
  return `${host}/get/thumbnail?id=${userID}&&time=${moment().unix()}`;
};

export const apiAvatarImageMini = (userID = "") => {
  return `${host}/user-thumbnail?id=${userID}&&time=${moment().unix()}`;
};

export const apiVerifyImageHistory = (userID = "") => {
  return `${host}/backoffice/history-face?id=${userID}&&time=${moment().unix()}`;
};

export const apiGetImageEkyc = (id = "") => {
  return `${host}/backoffice/ekyc/get-picture?id=${id}`
}

export const apiLogout = () => {
  return `${host}/backoffice/logout`;
};

export const apiAvatarChain = (id = "", time=moment().unix()) => {
  return `${host}/chain/avatar?id=${id}&&time=${time}`;
};

export const apiAvatarShop = (id = "") => {
  return `${host}/shop/avatar?avatarId=${id}&&time=${moment().unix()}`;
};

export const apiAvatarImageTransaction = (userID = "") => {
  return `${host}/transaction/face?id=${userID}&&time=${moment().unix()}`;
};

export const apiVideoDetailTransaction = (videoId = "") => {
  return `${hostVideoTrans}/video?facepayNumber=${videoId}&&time=${moment().unix()}`;
};
