import React, { useState } from 'react'
//style
import * as style from './index.style'
//tools
import { convertDateTime, buildMoney } from '../../../../../../assets/helper/tools'
//data
import { dataBank } from '../../../../../../data/dataBank'
//host
import { apiAvatarShop } from '../../../../../../assets/constants/host'
//component
import IcDollarBlack from '../../../../../../assets/images/components/IcDollarBlack'
import LogoBankDefault from '../../../../../../assets/images/components/LogoBankDefault'
import LogoStoreDefault from '../../../../../../assets/images/components/LogoStoreDefault'

const RowTransHistory = ({ data }) => {

    const [imageBankError, setImageBankError] = useState(false)
    const [imageShopError, setImageShopError] = useState(false)

    const _returnBankImage = (FromBankCode) => {
        const bank = dataBank.find((item) => item.BankCode === FromBankCode);
        if (bank) return bank.BankImage;
        else return "";
    };


    return (
        <style.Bound>
            <div className='order-code-wrap'>
                {/* <IcDollarBlack className='icon-dollar' /> */}
                <div className='order-code' data-toggle="tooltip" title={data?.BillNumber || " "}>
                    {data?.BillNumber || "-"}
                </div>
            </div>
            <div className='time-wrap'>
                <div className='date'>
                    {convertDateTime(data?.CreateTime, "DD [Tháng] MM, YYYY") || "-"}
                </div>
                <div className='time'>
                    {convertDateTime(data?.CreateTime, "HH:mm:ss") || " "}
                </div>
            </div>
            <div className='money'>
                {buildMoney(`${data?.Amount} đ` || "")}
            </div>
            <div className='account-sent'>
                {
                    imageBankError ?
                        <LogoBankDefault className="image-bank-code" />
                        :
                        <img
                            className="image-bank-code"
                            src={_returnBankImage(data?.FromBankCode || ' ')}
                            alt="logo-bank"
                            onError={() => setImageBankError(true)}
                        />
                }
                <div className='number-bank-code'>{data?.FromBankAccount || "-"}</div>
            </div>
            <div className='shop'>
                {
                    imageShopError ?
                        <LogoStoreDefault className="image-shop" />
                        :
                        <img
                            className="image-shop"
                            src={data?.ShopAvatarID ? apiAvatarShop(data.ShopAvatarID) : ''}
                            alt="logo-bank"
                            onError={() => setImageShopError(true)}
                        />
                }
                <div className='name-shop' data-toggle="tooltip" title={data?.ShopName || " "}>
                    {data?.ShopName || "-"}
                </div>

            </div>
            <div className='trans-code-wrap'>
                <div className='trans-code' data-toggle="tooltip" title={data?.FacepayNumber || " "}>
                    {data?.FacepayNumber || '-'}
                </div>
            </div>
        </style.Bound>
    )
}

export default RowTransHistory
