import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// components
import Row from './Row'
// import DropDownFilterUsers from './DropDownFilterUsers'
// import DropDownFilterTimes from './DropDownFilterTimes'
// import DropDownFilterTranCodes from './DropDownFilterTranCodes'
// import DropDownFilterStores from './DropDownFilterStores'
// import DropDownFilterMoneyPayments from './DropDownFilterMoneyPayments'
import Filters from './Filters'
import Pagination from './Pagination'
import EmptyDataControl_v3 from '../../../Control/EmptyDataControl_v3'

// styles
import { WrapTransList, RowFilter, RowEmpty } from './index.styles'

// data
// import { TransactionData } from '../../../../data/mockTransactionData'
import { getDefaultStartDateTime, getDefaultEndDateTime } from './Filters/DropDownFilterTimes'

// action
import { getListTransactions, clearListTransactions } from '../../../../redux/transactions/listTransactions/action'
import { clearDetailsTransaction, getTransactionDetails } from '../../../../redux/transactions/transactionDetails/action'

// hook
import useDidMount from '../../../../hook/useDidMount'

// const data = TransactionData

const Transaction = () => {
    const dispatch = useDispatch();

    const [lstTransaction, setLstTransaction] = useState([])
    const [selected, setSelected] = useState({})

    const { transactions, pageNumber, pageSize, condFilters } = useSelector(state => ({
        transactions: state.transactions.listTransactions,
        pageNumber: state.transactions.listTransactions.condFilters.PageNumber,
        pageSize: state.transactions.listTransactions.condFilters.PageSize,
        condFilters: state.transactions.listTransactions.condFilters
    }))

    useEffect(() => {
        dispatch(getListTransactions({
            PageNumber: 1,
            PageSize: 8,
            Time: {
                FromDate: moment(getDefaultStartDateTime()).format('YYYY/MM/DD'),
                ToDate: moment(getDefaultEndDateTime()).format('YYYY/MM/DD')
            }
        }))
    }, [])

    useEffect(() => {
        return () => {
            dispatch(clearListTransactions())
        }
    }, [])

    useDidMount(() => {
        if (!transactions.list.length) return false
        setSelected(transactions.list[0])
        dispatch(getTransactionDetails({ PaymentID: transactions.list[0].PaymentID }))
        return true
    }, [JSON.stringify(transactions.list)])

    useEffect(() => {
        if (transactions?.list) {
            setLstTransaction(transactions.list)
        }
    }, [JSON.stringify(transactions.list)])

    const onChangePreviousPage = () => {
        dispatch(getListTransactions({
            ...condFilters,
            PageNumber: pageNumber - 1,
            PageSize: pageSize
        }))
    }

    const onChangeNextPage = () => {
        dispatch(getListTransactions({
            ...condFilters,
            PageNumber: pageNumber + 1,
            PageSize: pageSize
        }))
    }

    const _handleSelectedUser = useCallback((data) => {
        // dispatch(clearDetailsAccount())
        // dispatch(getUserInfos({ UserID: data.ID }))
        dispatch(clearDetailsTransaction())
        dispatch(getTransactionDetails({ PaymentID: data.PaymentID }))
    }, [])

    return (
        <WrapTransList>
            <RowEmpty style={{ marginTop: '18px' }} enableClick={false} />
            <Filters />
            <div className="list-transaction-container">
                {
                    lstTransaction.length === 0 ?
                        <div className="empty-control" >
                            <EmptyDataControl_v3 labelDes={"Không có kết quả được tìm thấy!"} />
                        </div>
                        :
                        <div className='list-transaction'>
                            <div className="wrap-list">
                                {
                                    lstTransaction.map((item, index) => (
                                        <Row
                                            key={item.ID}
                                            data={item}
                                            selected={selected}
                                            setSelected={setSelected}
                                            onSelect={_handleSelectedUser}
                                        />
                                    ))
                                }
                            </div>
                            <div className="wrap-pagination">
                                <Pagination
                                    total={transactions.total}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    onChangePreviousPage={onChangePreviousPage}
                                    onChangeNextPage={onChangeNextPage}
                                />
                            </div>
                        </div>
                }
            </div>
        </WrapTransList >
    )
}

export default Transaction
