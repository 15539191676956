import React, { useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//component
import ButtonControl from '../../../../../../Control/Button'
import BgArrowDown from '../../../../../../../assets/images/components/BgArrowDown'
//style
import * as style from './index.style'
import { styleButtonNo, styleButtonYes } from './index.style'
import { WrapOverlay } from '../../../../../../Control/HeaderComponent/ChainsMainBoardHeader/PopupEditChains/ContentPopupEditChains/PopupConfirmDeleteChain/index.styles'

// action
import { getListShopsByChain, delShopFromChain } from '../../../../../../../redux/chains/chainInfos/stores/action'

const PopupConfirmDelete = ({ store, onClose, onClosePopupEdit, chainID }) => {
    const dispatch = useDispatch()

    const { stores } = useSelector((state) => ({
        stores: state.chains.chainInfos.stores,
    }))

    const  popupRef = useRef(null)

    const _handleClickConfirm = useCallback(() => {
        if(!store) return;
        if(!chainID) return;
        const { ID } = store;
        dispatch(delShopFromChain({ ID }, () => {
            onClose()
            onClosePopupEdit()
            dispatch(getListShopsByChain({ 
                ChainID: chainID, 
                PageNo: stores.firstFilter.PageNo, 
                PageSize: stores.firstFilter.PageSize 
            }))
        }))
    }, [JSON.stringify(store), chainID])

    const _handleClickCancel = () => {
        onClose()
    }

    const _handleClickPopup = useCallback((e) => {
        if(!popupRef.current.contains(e.target)){
            onClose()
        }
    }, [onClose])

    return (
        <WrapOverlay onClick={_handleClickPopup}>
            <style.Bound ref={popupRef}>
                <div className="title-popup">Bạn có chắc muốn xóa cửa hàng?</div>
                <div className="button-wrapper">                
                    <ButtonControl
                        extendStyles={styleButtonNo}
                        children="Có"
                        handleClick={_handleClickConfirm}
                    />
                    <ButtonControl
                        extendStyles={styleButtonYes}
                        children="Không"
                        handleClick={_handleClickCancel}
                    />
                </div>
                <div className="icon-arrow">
                    <BgArrowDown />
                </div>

            </style.Bound>
        </WrapOverlay>
    )
}

export default PopupConfirmDelete
