import React from "react";

const IcLockRed = (props) => (
  <svg
    {...props}
    width={13}
    height={14}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M10.3331 6.4668H2.86647C2.27737 6.4668 1.7998 6.94436 1.7998 7.53346V11.2668C1.7998 11.8559 2.27737 12.3335 2.86647 12.3335H10.3331C10.9222 12.3335 11.3998 11.8559 11.3998 11.2668V7.53346C11.3998 6.94436 10.9222 6.4668 10.3331 6.4668Z"
        stroke="#FA8C8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93359 6.46675V4.33341C3.93359 3.62617 4.21455 2.94789 4.71464 2.4478C5.21474 1.9477 5.89302 1.66675 6.60026 1.66675C7.30751 1.66675 7.98578 1.9477 8.48588 2.4478C8.98598 2.94789 9.26693 3.62617 9.26693 4.33341V6.46675"
        stroke="#FA8C8C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="12.8"
          height="12.8"
          fill="white"
          transform="translate(0.200195 0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IcLockRed;
