import Axios from "axios";

// host
import { host } from "../../../../assets/constants/host";

// helper
import mapErrors from "../../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../../assets/constants/codeResponse";

const apiGetChainDetails = `${host}/backoffice/get-chain-detail`
const apiDelChain = `${host}/backoffice/remove-chain`
const apiEditChain = `${host}/backoffice/update-chain`
const apiUpdateChainLogo = `${host}/backoffice/update-chain-logo`

export const getChainDetails = ({ ChainID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        ChainID
    })

    try {
        const res = await Axios.post(apiGetChainDetails, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.GET_CHAIN_DETAILS,
                payload: {
                    details: {
                        ...data
                    }
                }
            })
        }
        else {
            dispatch({
                type: types.GET_CHAIN_DETAILS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_CHAIN_DETAILS_ERR))
    }
}

export const editChain = (
    {
        ID,
        Name,
        Address,
        PhoneNumber,
        Category,
        Email,
        RepPosition,
        Logo,
        TaxNumber,
        RepName,
    },
    callbackSuccess
) => async (dispatch, getState) => {
    const store = getState();
    const { isLoading } = store.chains.chainInfos.chainDetails
    if(isLoading) return;

    dispatch({ type: types.EDIT_CHAIN_START })

    const dataRequest = JSON.stringify({
        ID,
        Name,
        Address,
        PhoneNumber,
        Category,
        Email,
        RepPosition,
        TaxNumber,
        RepName,
    })

    const dataRequestLogo = JSON.stringify({
        ChainID: ID,
        Logo,
    })

    try {
        !!Logo && await Axios.post(apiUpdateChainLogo, dataRequestLogo)
        const res = await Axios.post(apiEditChain, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.EDIT_CHAIN
            })
            callbackSuccess && callbackSuccess()
        }
        else{
            dispatch({
                type: types.EDIT_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.EDIT_CHAIN_ERR))
    }
    dispatch({ type: types.EDIT_CHAIN_FINISH })

}

export const delChainById = ({ ID }, callbackSuccess) => async (dispatch, getState) => {

    const store = getState()
    const { isLoading } = store.chains.chainInfos.chainDetails
    if(isLoading) return;

    dispatch({ type: types.DEL_CHAIN_BY_ID_START})

    const dataRequest = JSON.stringify({
        ID
    })

    try {
        const res = await Axios.post(apiDelChain, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.DEL_CHAIN_BY_ID
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.DEL_CHAIN_BY_ID_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.DEL_CHAIN_BY_ID_ERR))
    }
    dispatch({ type: types.DEL_CHAIN_BY_ID_FINISH})

}

export const resetChainDetail = () => async dispatch => {
    dispatch({
        type: types.RESET_CHAIN_DETAIL,
    })
}
