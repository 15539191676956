import React, { useRef, useCallback, useState, useEffect } from 'react'

// styles
import { WrapFilterByUser } from './index.styles'

// hooks
// import useDebounce from '../../../../../../hook/useDebouce.hook'


const BoxFilterByUser = ({ 
    closeBox, 
    stateFilter,
    dispatchStateFilter
}) => {

    const handleOnChangeOf = useCallback(
        (key) => (e) => {
            const val = e.target.value.trimStart().replace(/\s+/g, " ")
            
            dispatchStateFilter({ [key]: val })
        },
        []
    )
    
    return (
        <WrapFilterByUser>
            <div className="field">
                <div className="field__name">Họ và tên</div>
                <input 
                    type="text" 
                    placeholder="Nhập giá trị" 
                    value={stateFilter.name}
                    onChange={handleOnChangeOf("name")}
                />
            </div>
            <div className="field">
                <div className="field__name">Username</div>
                <input 
                    type="text" 
                    placeholder="Nhập giá trị" 
                    value={stateFilter.username}
                    onChange={handleOnChangeOf("username")}
                />
            </div>
            <div className="field">
                <div className="field__name">Điện thoại</div>
                <input 
                    type="text" 
                    placeholder="Nhập giá trị" 
                    value={stateFilter.phoneNum}
                    onChange={handleOnChangeOf("phoneNum")}
                />
            </div>
        </WrapFilterByUser>
    )
}

export default BoxFilterByUser
