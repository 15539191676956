import React from "react";
import { css } from "styled-components";

//constant
import * as stylesConstants from "../../../../assets/constants/ui";
import * as keys from '../../../../assets/constants/keys'
//action
import { getListShopsByChain } from '../../../../redux/chains/chainInfos/stores/action'

export const ISALLOWCLICK = false;
export const ISCONNECT = false;
export const KEY = keys.STORE;
export const HEIGHT_ROW_ITEM_CONTAINER = "82px";

export const TABLE_CONTENT_STYLED = css`
  /* padding: 0 24px 0 58px;
    width: calc(100% - (24px + 58px)); */
  width: 100%;
`;

export const TABLE_ROW_ITEM_STYLED = css`
  grid-template-columns: 30px 190px 200px 180px 1fr 240px 70px;
  grid-gap: 10px;
  background-color: rgba(244, 244, 244, 0.6);
  mix-blend-mode: normal;
  box-shadow: inset 0px -0.5px 0px #e2e2e2;
  width: 100%;
  height: 82px;
  div {
    font-family: "SVN-Gilroy";
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
    line-height: 170%;
    color: #051321;
    display: flex;
    align-items: center;
  }
  .empty {
  }
  .address {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .device {
    font-weight: bold;
    display: flex;
    align-items: center;
    .total {
      width: 4ch;
      margin-right: 7px;
    }
    .small-dot {
      width: 9px;
      height: 9px;
      border-radius: 50px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    .green-dot {
      background: #09ac10;
    }
    .gray-dot {
      background: #1c1c1c66;
    }
    .active-person {
      color: #09ac10;
      font-size: 10px;
      margin-right: 20px;
    }
    .inactive-person {
      color: #1c1c1c66;
      font-size: 10px;
    }
  }
  .edit {
    color: #184da2;
    display: flex;
    justify-content: flex-end;
    padding: 0 22px 0 0;
    cursor: pointer;    
  }
`;

export const caculateTableHeightContent = () => {
  let vh = document.body.offsetHeight;
  if (!vh) return;
  return (
    vh -
    (stylesConstants.HEADER_HEIGHT +
      stylesConstants.MAIN_CONTENT_PADDING_TOP +
      stylesConstants.TABLE_HEADER_HEIGHT +
      197)
  );
};

export const _onLoadMore = ({ size, chainID }) => getListShopsByChain({ ChainID: chainID, PageNo: 1, PageSize: size })

export const renderItem = ({ item, onClickEditButton }) => {
  if (!item) return;
  return (
    <React.Fragment>
      <div className="empty"></div>
      <div
        className="address store-code"
        data-toogle="tooltip"
        title={item?.Code ? item.Code : null}
      >
        {item?.Code ? item.Code : "-"}
      </div>
      <div
        className="address store-name"
        data-toogle="tooltip"
        title={item?.Name ? item.Name : null}
      >
        {item?.Name ? item.Name : "-"}
      </div>
      <div
        className="address store-area"
        data-toogle="tooltip"
        title={item?.AreaName ? item.AreaName : null}
      >
        {item?.AreaName ? item.AreaName : "-"}
      </div>
      <div className="address"
        data-toogle="tooltip"
        title={item?.Address ? item.Address : null}
      >
        {item?.Address ? item.Address : "-"}
      </div>
      <div className="device">
        <div className="total">
          {item &&
            (item.TotalOnlineDevices || item.TotalOnlineDevices === 0) &&
            (item.TotalOfflineDevices || item.TotalOfflineDevices === 0)
            ? item.TotalOnlineDevices + item.TotalOfflineDevices
            : "0"}
        </div>
        <div className="active">
          <span className="small-dot green-dot"></span>
          <div className="active-person">
            {`${item?.TotalOnlineDevices ? item.TotalOnlineDevices : "0"
              }   Online`}
          </div>
        </div>
        <div className="inactive">
          <span className="small-dot gray-dot"></span>
          <div className="inactive-person">
            {`${item?.TotalOfflineDevices ? item.TotalOfflineDevices : "0"
              }   Offline`}
          </div>
        </div>
      </div>
      <div className="edit" onClick={() => onClickEditButton(item)}>Sửa</div>
    </React.Fragment>
  );
};
