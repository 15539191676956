import styled from 'styled-components'

const InputSearch = styled.div`
    position: relative;
    margin-top: ${props => props.marginTop ? props.marginTop : "0px"};
    display: flex;
    align-items: center;
    border: 1px solid #C6D5EB;
    border-radius: 6px;
    box-sizing: border-box;
    background: #FFFFFF;


    input{
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 6px;
        width: ${props => props.widthSearch ? props.widthSearch : "297px"};        
        height: ${props => props.heightSearch || "50px"};
        display: flex;
        border: none;
        outline: none;
        align-items: center;
        padding: 6px 10px;
        outline: none;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #212121;
        ::placeholder{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: rgba(28, 28, 28, 0.4);
        }
    }
    .ic_search{
        /* position: absolute; */
        /* top: 17px; */
        /* left: 10px;         */
        margin-left: 12px;
    }

    ${props => props.extendStyles}

`
export { InputSearch }