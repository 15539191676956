import React, { useState, useEffect, useLayoutEffect } from 'react'

// api
import { apiAvatarImageMini } from '../../../../../../assets/constants/host'

// component
import IcAgainBgGreen from '../../../../../../assets/images/components/IcAgainBgGreen';
import IcAgainBgRed from '../../../../../../assets/images/components/IcAgainBgRed';
import AvatarVerifyDefault from '../../../../../../assets/images/components/AvatarVerifyDefault';
import IcCheckWhite from '../../../../../../assets/images/components/IcCheckWhite';
import VerifyFaceItem from './VerifyFaceItem';

// styles
import { Step } from '../index.styles';

const VerifyFace = ({ data, index, isHiddenLine, extendStyles }) => {

    const [isImgError, setIsImgError] = useState(false);
    const [isImgUserFoundError, setIsImgUserFoundError] = useState(false)
    const [infoUserFound, setInfoUserFound] = useState(null)
    const [imgUserFound, setImgUserFound] = useState('');

    useLayoutEffect(() => {
        setInfoUserFound(null)
        setIsImgError(false)
    }, [data.FacepayNumber])

    useLayoutEffect(() => {
        setIsImgUserFoundError(false)
    }, [infoUserFound?.ID])

    useLayoutEffect(() => {
        if (infoUserFound) return
        const { Steps } = data || {}
        const { VerifyFace } = Steps || {}
        const { UsersFound } = VerifyFace || {}
        if (!UsersFound) return
        setInfoUserFound(UsersFound[0])
    }, [infoUserFound, data])

    useEffect(() => {
        const { ID } = infoUserFound || {}
        if(!ID) {
            setImgUserFound('')
            return;
        }
        setImgUserFound(apiAvatarImageMini(ID))
    }, [infoUserFound]);

    const _handleClickShowAvatar = (userResult) => {
        setInfoUserFound(userResult)
    }

    return (
        <Step step={index + 1} paddingLeft={40} isHiddenLine={isHiddenLine} extendStyles={extendStyles}>
            <div className="face-scan-container">
                <div className="scan-avatar-frist">
                    {
                        data?.Steps?.VerifyFace?.First?.map((faceResult, j) => {
                            return (
                                <VerifyFaceItem 
                                    key={faceResult.FaceLogID}
                                    index={j}
                                    faceResult={faceResult}
                                />
                            )
                        })
                    }
                </div>
                {
                    data?.Steps?.VerifyFace?.Retry?.length > 0 &&
                    <div className="icon-retry">
                        {
                            data?.Steps?.VerifyFace?.Retry.some(faceResult => faceResult.ErrorCode === 0) ?
                                <IcAgainBgGreen />
                                :
                                <IcAgainBgRed />
                        }
                    </div>
                }
                <div className="scan-avatar-retry">
                    {
                        data?.Steps?.VerifyFace?.Retry?.map((faceResult, j) => {
                            return (
                                <VerifyFaceItem 
                                    key={faceResult.FaceLogID}
                                    index={j}
                                    faceResult={faceResult}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="user-found-container">
                {
                    !!infoUserFound &&
                    <div className="avatar-user-wrap">
                        <div className="title">Ảnh enroll</div>
                        <div className="detail-item username" title={infoUserFound?.Username || ""}>
                            {infoUserFound?.Username ? `@${infoUserFound.Username}` : "-"}
                        </div>
                        <div className="avatar-user">
                            {
                                isImgUserFoundError ?
                                    <AvatarVerifyDefault className="avatar" />
                                    :
                                    <img
                                        className="avatar"
                                        // src={infoUserFound?.ID ? apiAvatarImageMini(infoUserFound.ID) : ""}
                                        src={imgUserFound}
                                        alt="scan-face"
                                        onError={() => setIsImgUserFoundError(true)}
                                    />
                            }
                        </div>
                    </div>
                }
                <div className="user-found-wrap">
                    <div className="title">Các user được tìm thấy</div>
                    <div className="list-user-wrap">
                        {
                            data?.Steps?.VerifyFace?.UsersFound?.map((userResult, j) => (
                                <div
                                    className={userResult?.ID === infoUserFound?.ID ? " list-users choose" : "list-users"}
                                    key={userResult?.ID}
                                    onClick={() => _handleClickShowAvatar(userResult)}>
                                    {
                                        userResult?.ID === infoUserFound?.ID ?
                                            <IcCheckWhite className="icon-check" />
                                            :
                                            ""
                                    }
                                    {`@${userResult.Username}`}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Step>
    )
}

export default VerifyFace
