import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// styles
import { WrapUserList, RowEmpty } from './index.styles'

// components
import Row from './Row'
// import Pagination from './Pagination'
import EmptyDataControl_v3 from '../../../Control/EmptyDataControl_v3'
import Filters from './Filters'
//action
import { getListInfoUsers } from '../../../../redux/listInfoUsers/action'
import Pagination from '../Transaction/Pagination'
import { getUserInfos } from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'
import { clearAllDetailsAboutUser } from '../../../../redux/userCensorship/detailsUser/commonAction'
// const total = 80
// const pageNumber = 1
// const pageSize = 8

const UserList = () => {
    const dispatch = useDispatch()

    const [lstUsers, setLstUsers] = useState([])
    const [selected, setSelected] = useState({})

    const { listInfoUsers, total, pageNumber, pageSize, detailsUser } = useSelector(state => ({
        listInfoUsers: state.listInfoUsers,
        total: state.listInfoUsers.total,
        pageNumber: state.listInfoUsers.condFilters.PageNumber,
        pageSize: state.listInfoUsers.condFilters.PageSize,
        detailsUser: state.userCensorship.detailsUser
    }))

    useEffect(() => {
        dispatch(getListInfoUsers({ PageNumber: 1, PageSize: 8 }))
    }, [])

    useEffect(() => {
        if (listInfoUsers?.list) {
            setLstUsers(listInfoUsers.list)
        }
    }, [JSON.stringify(listInfoUsers.list)])

    useEffect(() => {
        if(!detailsUser.detailsAccount.ID) return;
        if(!selected || (typeof(selected) === 'object' && Object.keys(selected).length === 0)) {
            dispatch(clearAllDetailsAboutUser())
            return
        }
        if(selected.ID !== detailsUser.detailsAccount.ID) {
            dispatch(clearAllDetailsAboutUser())
            return
        }
    }, [selected, detailsUser.detailsAccount.ID]);

    const _handleSelectedUser = useCallback((data) => {
        dispatch(clearAllDetailsAboutUser())
        dispatch(getUserInfos({ UserID: data.ID }))
    },[])

    const onChangePreviousPage = () => {
        dispatch(getListInfoUsers({
            PageNumber: pageNumber - 1,
            PageSize: pageSize
        }))
    }

    const onChangeNextPage = () => {
        dispatch(getListInfoUsers({
            PageNumber: pageNumber + 1,
            PageSize: pageSize
        }))
    }

    return (
        <WrapUserList>
            <RowEmpty style={{ marginTop: '18px' }} enableClick={false} />
            <Filters />
            <div className="list-user-container">
                {
                    lstUsers.length === 0 ?
                        <div className="empty-control" >
                            <EmptyDataControl_v3 labelDes={"Không có kết quả được tìm thấy!"} />
                        </div>
                        :
                        <div className='list-user'>
                            <div className='wrap-list'>
                                {
                                    lstUsers.map((item, index) => (
                                        <Row
                                            key={item.ID}
                                            data={item}
                                            selected={selected}
                                            setSelected={setSelected}
                                            onSelect={_handleSelectedUser}
                                        />
                                    ))
                                }
                            </div>
                            <div className="wrap-pagination">
                                <Pagination
                                    total={total}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    onChangePreviousPage={onChangePreviousPage}
                                    onChangeNextPage={onChangeNextPage}
                                />
                            </div>
                        </div>
                }
            </div>
        </WrapUserList>
    )
}

export default UserList
