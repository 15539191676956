import styled from 'styled-components'

const WrapVerticalScroll = styled.div`
    display: grid;
    /* grid-template-rows: 53px 261px 53px 165px repeat(11, 54px); */
    grid-template-rows: 
        minmax(261px, 0.24fr) 
        minmax(53px, 0.05fr) 
        minmax(165px, 0.15fr) 
        repeat(12, minmax(54px, 0.05fr));
    /* max-height: calc(100% - 319px); */
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    /* 1073 */

    & > div:nth-child(odd) {
        background: #E5E5E5;
    }

    /* & > div.type-data {
        background: #184DA2;
        color: #FFFFFF;
    } */

    & > div {
        display: flex;
        align-items: center;
        padding-left: 30px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        box-sizing: border-box;
        border-top: 1px solid #C6D5EB;
    }

    .important-data {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
    }
`;

// const WrapperTitleColumn = styled.div`
//     display: grid;
//     grid-template-rows: 319px calc(100vh - 80px - 319px);

//     .empty-data {
//         background: #E5E5E5;
//     }
// `;

export {
    WrapVerticalScroll,
    // WrapperTitleColumn
}