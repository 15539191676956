import React from 'react'

const IcNotRegister = (props) => {
    return (
        <svg {...props} width={15} height={15} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 0C3.36433 0 0 3.36433 0 7.5C0 11.6357 3.36433 15 7.5 15C11.6357 15 15 11.6357 15 7.5C15 3.36433 11.6357 0 7.5 0Z" fill="#98ACC5" />
            <path d="M7.51214 9.51705C7.21811 9.51705 6.97244 9.29318 6.94519 9.00042L6.5 4.21692V3C6.5 2.44772 6.94772 2 7.5 2C8.05228 2 8.5 2.44772 8.5 3V4.21692L8.07934 8.99757C8.05347 9.29156 7.80726 9.51705 7.51214 9.51705ZM7.27093 12C6.88478 12 6.57175 11.687 6.57175 11.3008C6.57175 10.9147 6.88478 10.6016 7.27093 10.6016H7.72907C8.11522 10.6016 8.42825 10.9147 8.42825 11.3008C8.42825 11.687 8.11522 12 7.72907 12H7.27093Z" fill="white" />
        </svg>
    )
}

export default IcNotRegister
