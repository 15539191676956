import Axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';

const NUM_CHUNKS = 5;
const FIRST_CHUNK_SIZE = 1000000;
// const DEFAULT_URL = 'https://example.com/file';

const videoType = 'video/mp4'
// const codecs = "avc1.42E01E, mp4a.40.2"
const codecs = "avc1.42001f" //terminal: MP4Box -dash 1000 -rap -frag-rap <video name>.mp4
const mimeCodec = `${videoType}; codecs="${codecs}"`
console.log(mimeCodec)

const PartialContent = ({ urlVideo, ...props }) => {
    // const [isFileLoaded, setIsFileLoaded] = useState(false);
    // const [loadedBytes, setLoadedBytes] = useState(0);
    // const [chunkSize, setChunkSize] = useState(FIRST_CHUNK_SIZE);
    const [contentSrc, setContentSrc] = useState(null);

    const isFileLoaded = useRef(false)
    const loadedBytes = useRef(0)
    const chunkSize = useRef(FIRST_CHUNK_SIZE)
    const sourceBuffer = useRef(null)
    const mediaSource = useRef(null)
    
    
    // let sourceBuffer = null;
    const videoRef = useRef(null)
    // let mediaSource = null;


    const sourceOpen = () => {
        // sourceBuffer.current = mediaSource.current.addSourceBuffer('video/mp4;codecs="avc1.42001f"');
        sourceBuffer.current = mediaSource.current.addSourceBuffer(mimeCodec);
        appendBuffer();
        sourceBuffer.current.addEventListener('updateend', appendBuffer);
        
        // sourceBuffer.current.addEventListener('updateend', () => {
        //     mediaSource.current.readyState === "open" &&  mediaSource.current.endOfStream()
        //     removeEventListeners();
        //     videoRef.current.play()
        // });
        // addSource()
        
    };

    // const addSource = async () => {
    //     const res = await Axios.get(urlVideo, {
    //         responseType: 'arraybuffer'
    //     })
    //     console.log(res)
    //     sourceBuffer.current.appendBuffer(res.data);
    // }
    
    const setMediaSource = () => {
        mediaSource.current = new MediaSource();
        mediaSource.current.addEventListener('sourceopen', sourceOpen);
        // mediaSource.current.addEventListener('sourceclose', () => {
        //     console.log('close')
        // });
        // mediaSource.current.addEventListener('sourceended', (e) => {
        //     console.log(e)
        //     console.log('sourceended')
        // });
        setContentSrc(URL.createObjectURL(mediaSource.current));
    };

    const appendBuffer = async () => {
        
        const start = loadedBytes.current ? loadedBytes.current + 1 : loadedBytes.current;
        const end = loadedBytes.current ? loadedBytes.current + chunkSize.current : chunkSize.current;
        
        console.log('<----------------------->')
        console.log(mediaSource.current)
        console.log(loadedBytes.current, '-', chunkSize.current)
        console.log(start, '-', end)
        console.log("isFileLoaded.current", isFileLoaded.current)
        console.log('--------------------------')
        // debugger
        // console.log(mediaSource.current)

        if (!isFileLoaded.current) {
            rangeContent(urlVideo, start, end)
                .then(({ data, contentRange }) => {
                    // URL.revokeObjectURL(mediaSource.current)
                    // setContentSrc(URL.createObjectURL(mediaSource.current));

                    // console.log(data)

                    // debugger
                    sourceBuffer.current.appendBuffer(data);

                    // setLoadedBytes(end);
                    loadedBytes.current = end;
                    isFileLoaded.current = data.byteLength < chunkSize.current;

                    if (chunkSize.current === FIRST_CHUNK_SIZE) {
                        // Content-Range example: bytes 200-1000/67589
                        chunkSize.current = Math.ceil(contentRange.substring(contentRange.indexOf('/') + 1) / NUM_CHUNKS);
                    }

                    
                })
                .catch((err) => console.log(err));
        } else {
            // URL.revokeObjectURL(mediaSource.current)
            // setContentSrc(URL.createObjectURL(mediaSource.current));
            mediaSource.current.readyState === "open" && mediaSource.current.endOfStream();
            // console.log(mediaSource.current)
            removeEventListeners();
            videoRef.current.play()
        }
    };

    const removeEventListeners = () => {
        mediaSource.current && mediaSource.current.removeEventListener('sourceopen', sourceOpen);
        sourceBuffer.current && sourceBuffer.current.removeEventListener('updateend', appendBuffer);
    };

    const rangeContent = (url = urlVideo, start = 0, end = null) => {
        // debugger
        const fetchSettings = {
            headers: new Headers({
                Range: `bytes=${start}-${end ? end : ''}`
            })
        };

        // console.log(fetchSettings)

        const fetchMethod = fetch(url, fetchSettings);
        const data = fetchMethod.then((res) => res.arrayBuffer());
        const header = fetchMethod.then((res) => res.headers.get('Content-Range'));

        return Promise.all([data, header])
        .then(([data, contentRange]) => ({
            data,
            contentRange
        }))
    };

    useEffect(() => {
        console.log('effect')
        setMediaSource();

        return () => {
            removeEventListeners();
            URL.revokeObjectURL(mediaSource.current)
        };
    // }, [setMediaSource]);
    }, [])

    // return <audio src={contentSrc}></audio>;
    return <video ref={videoRef} {...props}>
        <source src={contentSrc} type={videoType} />
    </video>;
};

export default React.memo(PartialContent);