import React from 'react'

const BgArrowRight = (props) => {
    return (
        <svg {...props} width={12} height={26} viewBox="0 0 12 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 13L3.1153e-07 25.1244L2.23668e-08 0.875645L12 13Z" fill="white" />
        </svg>
    )
}

export default BgArrowRight
