import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import * as styles from "./index.style";


// components
import HeaderComponent from "../../../Control/HeaderComponent";
import EmptydataControl_v2 from '../../../Control/EmptyDataControl_v2';
import TransactionDetailsFlow from "./TransactionDetailsFlow";
import EmptydataControl_v3 from "../../../Control/EmptyDataControl_v3"

// constant
import * as stylesConstants from "../../../../assets/constants/ui";
import InfoTransactionUser from "./InfoTransactionUser";
import HeaderMenuInformationBoard from "../../../Control/HeaderComponent/HeaderMenuInformationBoard";
import { headerTransactionDetailsInfomationBoard } from "../../../../assets/data/dataHeaderMenu";
// import { clearDetailsTransaction } from "../../../../redux/transactions/transactionDetails/action";

let bound;
let transactionDetailBar;

const data = {
    FacepayNumber: "FU482E",
    BillNumber: "7463578367482764",
    isStatus: true,
    FullName: "Nguyễn Ngọc Trường Linh",
    Username: "tuonglinh",
    PhoneNumber: "0358338487",
    ShopName: "Family Bạch Đằng",
    Amount: "20190",
    TotalAmount: "210190",
    FreeWee: "0",
    FreeNapas: "1000",
    mockVideo: "http://techslides.com/demos/sample-videos/small.ogv"
}

const TransactionDetail = (props) => {

    const dispatch = useDispatch()    

    const [isEmptyData, setIsEmptyData] = useState(true)
    const [stepDetailsSelected, setStepDetailsSelected] = useState(
        headerTransactionDetailsInfomationBoard[0].key
    );
    const [dataTransactionDetail, setDataTransactionDetail] = useState(null)

    const { transactions, transactionDetails } = useSelector(state => ({
        transactions: state.transactions.listTransactions,
        transactionDetails: state.transactions.transactionDetails
    }))

    useEffect(() => {
        if(dataTransactionDetail) setIsEmptyData(false)
        else setIsEmptyData(true)
        setDataTransactionDetail(transactionDetails)
    }, [transactionDetails])

    useEffect(() => {
        caculateTransactionDetailBarHeightContent();
        return () => { };
    }, []);

    useEffect(() => {
        refreshUI()
    }, [transactions, transactionDetails])

    // useEffect(() => {        
    //     return () => {
    //         dispatch(clearDetailsTransaction())
    //     }
    // }, [])

    const refreshUI = () => {
        caculateTransactionDetailBarHeightContent()
    }

    const _callBackClickNavLink = (data) => {
        setStepDetailsSelected(data.key);
    };

    const caculateTransactionDetailBarHeightContent = () => {
        bound = document.getElementById("transaction-detail-bound-id");
        transactionDetailBar = document.getElementById("transaction-detail-id");
        if (!transactionDetailBar || !bound) return;

        let vh = document.body.offsetHeight;
        if (!vh) return;
        let barHeight =
            bound.offsetHeight -
            (stylesConstants.HEADER_HEIGHT +
                stylesConstants.MAIN_CONTENT_PADDING_TOP) -
            15;

        transactionDetailBar.style.height = `${barHeight}px`;
    };

    return (
        <styles.Bound id="transaction-detail-bound-id">
            {
                isEmptyData ?
                    <EmptydataControl_v2 />
                    :
                    <div className="scroll">
                        {/* SUMMARY BAR */}
                        <div className="transaction-summary">
                            <HeaderComponent
                                height={68}
                                backgroundColor="rgba(198, 213, 235, 0.1)"
                                position="absolute"
                                zIndex={2}
                                child={
                                    <div className="title-summary">Tổng quan</div>
                                }
                            />
                            <InfoTransactionUser data={dataTransactionDetail} />
                        </div>

                        {/* TRANSACTION DETAIL BAR */}
                        <div className="transaction-detail" id="transaction-detail-id">
                            <HeaderComponent
                                height={69}
                                backgroundColor="rgba(198, 213, 235, 0.1)"
                                borderBottom="1px solid #C6D5EB"
                                position="absolute"
                                zIndex={3}
                                child={
                                    <HeaderMenuInformationBoard
                                        dataMenu={headerTransactionDetailsInfomationBoard}
                                        callBackClickNavLink={_callBackClickNavLink}
                                    />
                                }
                            />
                            <div className="empty-header" />
                            {
                                stepDetailsSelected === headerTransactionDetailsInfomationBoard[0].key ?
                                    < TransactionDetailsFlow data={dataTransactionDetail}/>
                                    :
                                    <div style={{ flex: 1 }}><EmptydataControl_v3 /></div>
                            }

                        </div>
                    </div>
            }
        </styles.Bound>
    );
};

export default React.memo(TransactionDetail);


