import React from 'react'

const IcDataGrey = (props) => <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx={12} cy={12} r={12} fill="#959595" />
<path d="M12 10C14.4853 10 16.5 9.32843 16.5 8.5C16.5 7.67157 14.4853 7 12 7C9.51472 7 7.5 7.67157 7.5 8.5C7.5 9.32843 9.51472 10 12 10Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
<path d="M16.5 12C16.5 12.83 14.5 13.5 12 13.5C9.5 13.5 7.5 12.83 7.5 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
<path d="M7.5 8.5V15.5C7.5 16.33 9.5 17 12 17C14.5 17 16.5 16.33 16.5 15.5V8.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
</svg>

export default IcDataGrey
