import React from 'react'

const LogoBankDefault = (props) => {
    return (
        <svg {...props} width={75} height={30} viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M25.0826 23.1772H6.90892C6.4068 23.1772 6 23.584 6 24.0866C6 24.5883 6.4068 24.9951 6.90892 24.9951H25.0821C25.5843 24.9951 25.9911 24.5883 25.9911 24.0866C25.9906 23.584 25.5838 23.1772 25.0826 23.1772Z" fill="#93BFF2" />
                <path d="M8.04466 20.8146C7.54254 20.8146 7.13574 21.2214 7.13574 21.7236C7.13574 22.2257 7.54254 22.6329 8.04466 22.6329H23.9464C24.4486 22.6329 24.8554 22.2257 24.8554 21.7236C24.8554 21.2214 24.4486 20.8146 23.9464 20.8146H23.7196V12.6361H23.9464C24.1979 12.6361 24.4005 12.4327 24.4005 12.1821C24.4005 11.9314 24.1971 11.728 23.9464 11.728H8.04466C7.7936 11.728 7.59063 11.9314 7.59063 12.1821C7.59063 12.4327 7.79403 12.6361 8.04466 12.6361H8.27189V20.8142H8.04466V20.8146ZM21.9018 12.6361V20.8142H19.1759V12.6361H21.9018ZM17.3581 12.6361V20.8142H14.6322V12.6361H17.3581ZM10.0889 12.6361H12.8148V20.8142H10.0889V12.6361Z" fill="#93BFF2" />
                <path d="M6.90893 10.9109H25.0822C25.0851 10.9109 25.0886 10.9109 25.0911 10.9109C25.5936 10.9109 26 10.5041 26 10.002C26 9.60287 25.743 9.26458 25.386 9.14203L16.3692 5.08467C16.1321 4.97829 15.8611 4.97829 15.6236 5.08467L6.53618 9.17352C6.14512 9.34968 5.93023 9.77436 6.02044 10.1931C6.11023 10.6118 6.48043 10.9109 6.90893 10.9109Z" fill="#93BFF2" />
            </g>
            <path d="M38.552 13.918C39.322 13.442 39.7 12.742 39.7 11.832C39.7 11.118 39.448 10.502 38.93 9.984C38.412 9.466 37.796 9.2 37.068 9.2H33.12V19H37.39C38.146 19 38.79 18.734 39.322 18.202C39.854 17.67 40.12 17.026 40.12 16.27C40.12 15.206 39.504 14.324 38.552 13.918ZM37.068 10.124C37.53 10.124 37.908 10.292 38.23 10.628C38.552 10.964 38.72 11.37 38.72 11.832C38.72 12.308 38.552 12.714 38.23 13.05C37.908 13.372 37.53 13.54 37.068 13.54H34.1V10.124H37.068ZM34.1 18.076V14.464H37.39C37.88 14.464 38.286 14.646 38.622 14.996C38.972 15.346 39.14 15.78 39.14 16.27C39.14 16.774 38.972 17.208 38.622 17.558C38.286 17.908 37.88 18.076 37.39 18.076H34.1ZM47.6816 13.372C47.0376 12.35 46.0996 11.832 44.8536 11.832C43.8596 11.832 43.0056 12.182 42.2916 12.896C41.5916 13.61 41.2416 14.478 41.2416 15.5C41.2416 16.522 41.5916 17.39 42.2916 18.104C43.0056 18.818 43.8596 19.168 44.8536 19.168C46.0996 19.168 47.0376 18.65 47.6816 17.628V19H48.6056V12H47.6816V13.372ZM42.9636 17.474C42.4316 16.928 42.1656 16.27 42.1656 15.5C42.1656 14.73 42.4316 14.072 42.9636 13.54C43.4956 12.994 44.1536 12.728 44.9236 12.728C45.6936 12.728 46.3516 12.994 46.8836 13.54C47.4156 14.072 47.6816 14.73 47.6816 15.5C47.6816 16.27 47.4156 16.928 46.8836 17.474C46.3516 18.006 45.6936 18.272 44.9236 18.272C44.1536 18.272 43.4956 18.006 42.9636 17.474ZM53.838 11.832C52.788 11.832 52.004 12.252 51.486 13.092V12H50.562V19H51.486V15.332C51.486 13.554 52.41 12.728 53.754 12.728C54.972 12.728 55.658 13.456 55.658 14.702V19H56.582V14.702C56.582 12.952 55.532 11.832 53.838 11.832ZM60.2727 15.36L63.8847 12H62.6247L59.2927 15.094V9.2H58.3687V19H59.2927V15.626L62.7927 19H64.0247L60.2727 15.36Z" fill="#93BFF2" />
            <defs>
                <clipPath id="clip0">
                    <rect width={20} height={20} fill="white" transform="translate(6 5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default LogoBankDefault
