import styled from 'styled-components'

const WrapperCheckBoxItem = styled.div`
    display: flex;
    align-items: center;

    .check-box {
        flex-shrink: 0;
        width: 16px;
        height: 16px;

        &.disabled {
            &, & + .text-condition {
                &:hover {
                    cursor: auto;
                }
            }
        }
        
        :hover {
            cursor: pointer;
        }
    }

    .text-condition {
        margin-left: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #15314C;
        
        :hover {
            cursor: pointer;
        }
    }

    ${props => props.extendStyles}
`;

export {
    WrapperCheckBoxItem
}