import React from "react";

const IcClose = (props) => (
  <svg
    {...props}
    className="btn-close"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.342 1.6578C10.1589 1.47474 9.8621 1.47474 9.67904 1.6578L5.99988 5.33697L2.32071 1.6578C2.13766 1.47474 1.84086 1.47474 1.6578 1.6578C1.47474 1.84086 1.47474 2.13766 1.6578 2.32071L5.33697 5.99988L1.6578 9.67904C1.47474 9.8621 1.47474 10.1589 1.6578 10.342C1.84086 10.525 2.13766 10.525 2.32071 10.342L5.99988 6.66279L9.67904 10.342C9.8621 10.525 10.1589 10.525 10.342 10.342C10.525 10.1589 10.525 9.8621 10.342 9.67904L6.66279 5.99988L10.342 2.32071C10.525 2.13766 10.525 1.84086 10.342 1.6578Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);

export default IcClose;
