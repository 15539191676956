import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    width: 100%;
    ${props => props.styled}
`

const HeaderItem = styled.div`
    display: flex;
    align-items: center;
    p{
        display: flex;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 170%;
        text-transform: uppercase;
        color: #1C1C1C;
        ${props => props.styled}
    }
`

export { Bound, HeaderItem }