import React from 'react'

// component
import ButtonControl from '../Button'
import ExportExcel from '../../../assets/images/components/ExportExcel'

// styles
import { extendStylesBtnExport } from './index.styles'

const ButtonExportExcel = (props) => {
    return (
        <ButtonControl extendStyles={extendStylesBtnExport} {...props}>
            <ExportExcel />
            <div className="btn-name">Xuất dữ liệu</div>
        </ButtonControl>
    )
}

export default ButtonExportExcel
