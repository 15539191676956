import React, { useState, useRef, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";

// style
import * as styles from "./index.style";

// images
import IcCloseBlack from "../../../../../assets/images/components/Ic_close_black";
import IcArrowDownBlack from "../../../../../assets/images/components/Ic_arrow_down_black";

// action
import { getUserInfos, lockAccount } from "../../../../../redux/userCensorship/detailsUser/detailsAccount/action";
import { getListInfoUsers } from "../../../../../redux/listInfoUsers/action";

// const listDropdown = [
//   {
//     key: "A",
//     value: "AAAAAAAAAAA",
//   },
//   {
//     key: "B",
//     value: "BBBBBBBBBBB",
//   },
//   {
//     key: "C",
//     value: "CCCCCCCCCCC",
//   },
// ];
const valueDefault = "Chọn loại vi phạm"

const PopupLockDetailAccount = ({ callbackClosePopup }) => {
  const valueDescriptionRef = useRef();
  const isClickOutsiteRef = useRef(false);
  const dispatch = useDispatch()

  const [showListDropdown, setShowListDropdown] = useState(false);
  const [listDropdown, setListDropdown] = useState([])
  const [value, setValue] = useState(valueDefault)
  const [reasonId, setReasonId] = useState('')

  // const listReasons = useSelector(state => state.userCensorship.detailsUser.detailsAccount.listReasons)
  const { pageNumber, detailsAccount } = useSelector(state => ({
    detailsAccount: state.userCensorship.detailsUser.detailsAccount,
    pageNumber: state.listInfoUsers.condFilters.PageNumber,
  }))

  const _handleClickOutSite = useCallback(() => {
    if (isClickOutsiteRef.current === false)
      setTimeout(() => {
        setShowListDropdown(false);
      }, 0);
  }, []);

  useEffect(() => {
    setListDropdown(detailsAccount.listReasons)
  }, [detailsAccount.listReasons])

  useEffect(() => {
    if (showListDropdown)
      document.addEventListener("click", _handleClickOutSite, false);
    return () =>
      document.removeEventListener("click", _handleClickOutSite, false);
  }, [_handleClickOutSite, showListDropdown]);

  const _callbackLockSuccess = useCallback(
    () => {
      callbackClosePopup(1);
      dispatch(getUserInfos({ UserID: detailsAccount.ID }))
      dispatch(getListInfoUsers({
        PageNumber: pageNumber,
        PageSize: 8,
      }))
    },
    [detailsAccount],
  )

  const _onSubmitLock = useCallback(() => {
    const { ID } = detailsAccount;
    const moreDetailsInput = valueDescriptionRef.current;
    const { value } = moreDetailsInput;
    if (!reasonId) return;
    // console.log(ID, value, reasonId)
    // debugger
    const dataSubmmit = {
      UID: ID,
      LockedReason: value.trim().replace(/\s+/g, ' '),
      LockReasonID: reasonId
    }
    // console.log(dataSubmmit)
    dispatch(lockAccount(dataSubmmit, _callbackLockSuccess))

  }, [detailsAccount, reasonId, _callbackLockSuccess]);

  const onChangeValue = (item) => {
    setValue(item.Reason)
    setReasonId(item.ID)
  }

  return (
    <styles.Bound>
      <div className="container">
        <div className="header">
          <p className="title">Khóa tài khoản</p>
          <div className="ic_close" onClick={callbackClosePopup}>
            <IcCloseBlack />
          </div>
        </div>

        <p className="content">
          Tài khoản này sẽ bị khóa và không sử dụng được tiếp?
        </p>

        <styles.Dropdown
          onMouseEnter={() => (isClickOutsiteRef.current = true)}
          onMouseLeave={() => (isClickOutsiteRef.current = false)}
          onClick={() => setShowListDropdown(!showListDropdown)}
        >
          <p className="choose-content">{value}</p>
          <IcArrowDownBlack />

          {showListDropdown && (
            <div className="list">
              {listDropdown.map((item, index) => {
                return <p
                  className="item-reason"
                  key={index}
                  onClick={() => onChangeValue(item)}>
                  {item.Reason}
                </p>;
              })}
            </div>
          )}
        </styles.Dropdown>
        <input
          type="text"
          ref={valueDescriptionRef}
          placeholder="Nhập thông tin mô tả thêm"
        />
      </div>
      <div className="block__btn">
        <div className={value !== valueDefault ? "block__btn--yes" : "block__btn--yes lock"} onClick={_onSubmitLock}>
          <p>CÓ</p>
        </div>
        <div className="block__btn--no" onClick={callbackClosePopup}>
          <p>KHÔNG</p>
        </div>
      </div>
      <div className="triangle-right" />
    </styles.Bound>
  );
};

PopupLockDetailAccount.propTypes = {
  callbackClosePopup: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(PopupLockDetailAccount);
