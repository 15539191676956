import styled from 'styled-components'
import { WrapFilter } from '../BoxFilterByText/index.styles';

const WrapFilterByUser = styled(WrapFilter)`
    right: 0;
    left: auto;

    .field {
        justify-content: space-between;

        /* &:not(:first-child) {
            margin-top: 12px;
        } */

        .field__name {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #1C1C1C;
        }

        input {
            border: 1px solid #F4F4F4;
            box-sizing: border-box;
            border-radius: 6px;
            width: 130px;
            height: 30px;
            padding: 5px 6px 4px 6px;
            outline: none;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #1C1C1C;
            margin-left: 18px;


            ::placeholder {
                color: #BFBFBF;
            }
        }
    }
    
`;

// const WrapFilterByUser = styled.div`
//     position: absolute;
//     top: calc(100% + 5px);
//     right: 0;
//     background: #FFFFFF;
//     box-sizing: border-box;
//     padding: 6px 6px 6px 12px;
//     box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);

//     .field {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         &:not(:first-child) {
//             margin-top: 12px;
//         }

//         .field__name {
//             font-family: SVN-Gilroy;
//             font-style: normal;
//             font-weight: normal;
//             font-size: 12px;
//             line-height: 170%;
//             color: #1C1C1C;
//         }

//         input {
//             border: 1px solid #F4F4F4;
//             box-sizing: border-box;
//             border-radius: 6px;
//             width: 130px;
//             height: 30px;
//             padding: 5px 6px 4px 6px;
//             outline: none;
//             font-family: SVN-Gilroy;
//             font-style: normal;
//             font-weight: normal;
//             font-size: 12px;
//             line-height: 170%;
//             color: #1C1C1C;
//             margin-left: 18px;


//             ::placeholder {
//                 color: #BFBFBF;
//             }
//         }
//     }
// `;

export {
    WrapFilterByUser
}