import * as types from "./types";

const intitialState = {
  details: null,
  isLoading: false
};

const chainDetails = (state = intitialState, action) => {
  switch (action.type) {
    case types.GET_CHAIN_DETAILS: {
      return {
        ...state,
        ...action.payload
      }
    }

    case types.EDIT_CHAIN_START:
    case types.DEL_CHAIN_BY_ID_START: {
      return {
        ...state,
        isLoading: true
      }
    }

    case types.EDIT_CHAIN_FINISH:
    case types.DEL_CHAIN_BY_ID_FINISH: {
      return {
        ...state,
        isLoading: false
      }
    }

    case types.RESET_CHAIN_DETAIL: {
      return intitialState
    }
    default:
      return state;
  }
};

export default chainDetails;
