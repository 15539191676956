import React, { useRef, useState, useReducer, useEffect} from 'react'

// styles
import { WrapFilterMoneyPayments } from './index.styles'

// component
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'
import BoxFilterByNum from '../../../../../Control/BoxFilterByNum'

// hooks
import { useOnClickOutside } from '../../../../../../hook/useClickOutside.hook'
import usePrevious from '../../../../../../hook/usePrevious'
import useDebounce from '../../../../../../hook/useDebouce.hook'

// constant
import { lstTypesKeyFilterNum } from '../../../../../Control/BoxFilterByNum/DropdownSelectTypeFilterNum/BoxListDropdownItem'


const DropDownFilterMoneyPayments = ({ values, onChangeValues }) => {

    const filterMoneyRef = useRef()

    const [activeFilter, setActiveFilter] = useState(false)
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [stateFilter, dispatchStateFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            sort: "",
            firstCompares: {
                type: lstTypesKeyFilterNum.GREATER_THAN,
                numVal: ''
            },
            secondCompares: {
                type: lstTypesKeyFilterNum.LESS_THAN,
                numVal: ''
            }
        }
    )

    const numValFirstCompareDelay = useDebounce(stateFilter.firstCompares.numVal, 500)
    const prevNumValFirstCompareDelay = usePrevious(numValFirstCompareDelay)

    const numValSecondCompareDelay = useDebounce(stateFilter.secondCompares.numVal, 500)
    const prevNumValSecondCompareDelay = usePrevious(numValSecondCompareDelay)
    
    const _handleOpenBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }

    useOnClickOutside(filterMoneyRef, () => setIsShowDropdown(false))

    useEffect(() => {
        const { sort, firstCompares, secondCompares } = stateFilter

        const firstComparesIsNumber = typeof(firstCompares.numVal) === 'number'
        const secondComparesIsNumber = typeof(secondCompares.numVal) === 'number'
        const haveFilter = !!sort || firstComparesIsNumber || secondComparesIsNumber

        if(haveFilter && !activeFilter) {
            setActiveFilter(true)
            return;
        }

        if(!haveFilter && activeFilter) {
            setActiveFilter(false)
            return;
        }

    }, [JSON.stringify(stateFilter), activeFilter])

    useEffect(() => {
        dispatchStateFilter({ sort: values.sort || "" })
    }, [values.sort])

    useEffect(() => {
        if(!values.firstCompares) return;
        if(!values.firstCompares.type) return;
        // if(!values.firstCompares.numVal) return;
        dispatchStateFilter({ firstCompares: values.firstCompares })
    }, [JSON.stringify(values.firstCompares)])

    useEffect(() => {
        if(!values.secondCompares) return;
        if(!values.secondCompares.type) return;
        // if(!values.secondCompares.numVal) return;
        dispatchStateFilter({ secondCompares: values.secondCompares })
    }, [JSON.stringify(values.secondCompares)])

    useEffect(() => {
        // debugger
        if(numValFirstCompareDelay === prevNumValFirstCompareDelay) return;
        if(numValFirstCompareDelay !== 0 && prevNumValFirstCompareDelay !== 0 && !prevNumValFirstCompareDelay && !numValFirstCompareDelay) return;
        // numValFirstCompareDelay = ""
        // prevNumValFirstCompareDelay = 0
        onChangeValues({
            ...stateFilter,
            firstCompares: {
                ...stateFilter.firstCompares,
                numVal: numValFirstCompareDelay
            }
        })
    }, [numValFirstCompareDelay, prevNumValFirstCompareDelay, JSON.stringify(stateFilter), onChangeValues])

    useEffect(() => {
        if(numValSecondCompareDelay === prevNumValSecondCompareDelay) return;
        if(numValSecondCompareDelay !== 0 && prevNumValSecondCompareDelay !== 0 && !prevNumValSecondCompareDelay && !numValSecondCompareDelay) return;
        onChangeValues({
            ...stateFilter,
            secondCompares: {
                ...stateFilter.secondCompares,
                numVal: numValSecondCompareDelay
            }
        })
    }, [numValSecondCompareDelay, prevNumValSecondCompareDelay, JSON.stringify(stateFilter), onChangeValues])

    return (
        <WrapFilterMoneyPayments ref={filterMoneyRef}>
            <TitleDropdownFilter onClick={_handleOpenBox} active={activeFilter}>Số tiền</TitleDropdownFilter>
            {
                isShowDropdown &&
                <BoxFilterByNum 
                    stateFilter={stateFilter}
                    dispatchStateFilter={dispatchStateFilter}
                    onChangeValues={onChangeValues}
                />
            }
        </WrapFilterMoneyPayments>
    )
}

export default DropDownFilterMoneyPayments
