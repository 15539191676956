import React from 'react'
import Lottie from 'react-lottie'
import * as styles from './index.style'

import * as questions from '../../../assets/json/questions.json'

const EmptyDataControl = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: questions.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return (
        <styles.Bound>
            <div className='animation-container'>
                <Lottie options={defaultOptions} height={500} width={500} />  
                <h1>Tài khoản chưa thực hiện eKYC!</h1>
                <p>eKYC giúp đảm bảo quyền lợi khách hàng cao hơn, thực hiện giao dịch trên 2 triệu đồng và sử dụng mPOS</p>
            </div>
        </styles.Bound>
    )
}

export default React.memo(EmptyDataControl)
