import * as types from "./types";

const intitialState = {
    list: [],
    totals: 0,
    conditionFilter: null,
};

const facesPending = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_FACE_PENDING_USERS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.VERIFY_AVATAR: {
            return {
                ...state,
                verifyID: action.payload?.ID
            }
        }
        default:
            return state;
    }
};

export default facesPending;
