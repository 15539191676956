import React from 'react'

const IcCheckBox = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="3" fill="#1279DA"/>
            <path d="M5.12075 7.41187L6.74347 9.08095C6.81837 9.15798 7.04306 9.20933 7.16788 9.08095C7.30768 8.93715 9.98891 6.16221 11.312 4.79271C11.6098 4.48457 12.2282 4.38186 12.6102 4.79271C12.9847 5.19539 12.9098 5.7428 12.6602 6.02526L7.792 11.0325C7.46745 11.3663 6.84333 11.8028 6.16928 11.1095C5.52721 10.4491 4.43917 9.31633 3.82258 8.67311C3.59816 8.43899 3.24405 7.8484 3.69776 7.36052C3.93655 7.10374 4.4467 6.71856 5.12075 7.41187Z" fill="white" stroke="#1279DA"/>
        </svg>
    )
}

export default IcCheckBox
