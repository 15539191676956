import * as types from "./types";
import { DANGER, WARNING, INFO } from "../../../../assets/constants/noteTypes";

export const intitialState = {
  list: [],
  lstClassification: {
    [DANGER]: 0,
    [WARNING]: 0,
    [INFO]: 0
  }
};

const notes = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_NOTES_BY_USER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_NOTES: {
            return {
                ...intitialState
            }
        }
        default:
            return state;
    }
};

export default notes;
