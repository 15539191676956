import Axios from 'axios'
import md5 from 'md5'

const getBase64Image = (blobImg) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blobImg); 
    reader.onloadend = function() {
        const base64data = reader.result;   
        resolve(base64data.split(',')[1])
    }
})

export const createImgFromLink = async (imgLink) => {
    const res = await Axios.get(imgLink, {
        responseType: 'blob'
    });

    const { data } = res;
    return await getBase64Image(data)

}

export default getBase64Image