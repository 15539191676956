import React, { useRef } from "react";
import * as style from "./index.style";
import { styleButtonLeft, styleButtonRight } from './index.style'
//image
import ic_close from "../../../assets/images/ic_close.svg";
//component
import ButtonControl from "../../Control/Button/index";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { logoutAction } from "../../../redux/actions/auth.action";
import { logoutAction } from "../../../redux/authenticates/action";
import { useOnClickOutside } from "../../../hook/useClickOutside.hook";

const LogOut = ({ expand, dispatchNavigation }) => {
  const containerRef = useRef();

  useOnClickOutside(containerRef, () => {
    dispatchNavigation(["openLogOut", false]);
  });

  const onCloseModal = () => {
    dispatchNavigation(["openLogOut", false]);
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(logoutAction(history));
  };
  return (
    <style.Bound expand={expand}>
      <div className="container" ref={containerRef}>
        <button className="container-close" onClick={onCloseModal}>
          <img src={ic_close} alt="multi" />
        </button>
        <div className="title">Đăng xuất</div>
        <p className="content">Bạn có chắc chắn đăng xuất không?</p>
        <div className="button-wrapper">
          <ButtonControl
            handleClick={onSubmit}
            extendStyles={styleButtonLeft} 
            children="Có"
          />

          <ButtonControl
            handleClick={onCloseModal}
            extendStyles={styleButtonRight} 
            children="Không" />
        </div>
      </div>
    </style.Bound>
  );
};

export default React.memo(LogOut);
