import React, { useState } from 'react'

// style
import { WrapperItem } from './index.style'

// component
import SimpleItemDetails from './SimpleItemDetails'
import AdvancedItemDetails from './AdvancedItemDetails'
import Collapse from '../../../../../Control/Collapse'

const PictureCensorshipItem = ({
    data,
    defaultExpands=false
    // isExpand=false
}) => {
    const [isExpand, setIsExpand] = useState(defaultExpands)

    return (
        // <WrapperItem>
            // <SimpleItemDetails 
            //     data={data}
            // />
        // </WrapperItem>
        <Collapse
            panelComponent={
                <SimpleItemDetails
                    data={data}
                />
            }
            defaultExpands={defaultExpands}
        >
            <AdvancedItemDetails 
                data={data}
            />
        </Collapse>
    )
}

export default PictureCensorshipItem
