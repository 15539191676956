import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import * as styles from "./index.style";

// components
import HeaderComponent from "../../../Control/HeaderComponent";
import HeaderMenuInformationBoard from "../../../Control/HeaderComponent/HeaderMenuInformationBoard";
import ChainInformation from "./ChainInformation";
import AdminNote from "../AdminNote";
import { HeaderDetailChain } from "../../../Control/HeaderComponent/HeaderDetailChain";
import HeaderNote from "../../../Control/HeaderComponent/HeaderNote";
import EmptydataControl_v2 from '../../../Control/EmptyDataControl_v2';
import EmptydataControl_v3 from '../../../Control/EmptyDataControl_v3';
import ListStore from "./ListStore";

// constant
import * as stylesConstants from "../../../../assets/constants/ui";
import * as keys from '../../../../assets/constants/keys'
//data
import { headerChainMenuInfomationBoard } from "../../../../assets/data/dataHeaderMenu";

// action
import { getListNotesByChain, addNoteToChain } from "../../../../redux/chains/chainInfos/notes/action";
import { useCallback } from "react";
import { useMemo } from "react";
import { resetChainDetail } from "../../../../redux/chains/chainInfos/chainDetails/action";

let bound;
let chainManageBar;

const Chains = (props) => {
    const dispatch = useDispatch();

    const { chainDetails, listChains, notes } = useSelector(state => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        listChains: state.chains.listChains,
        notes: state.chains.chainInfos.notes
    }))

    const { tabView } = props

    const [chainManageSeleted, setChainManageSeleted] = useState(
        headerChainMenuInfomationBoard[0].key
    );

    const [chainSelected, setChainSelected] = useState({})
    const [isEmptyData, setIsEmptyData] = useState(true)

    useEffect(() => {
        caculateChainManageBarHeightContent();
        return () => { };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(resetChainDetail())
        }
    }, [])

    useEffect(() => {
        refreshUI()
    }, [tabView, listChains])

    useEffect(() => {
        if(isEmptyData) return
        caculateChainManageBarHeightContent()
        return () => {
            
        }
    }, [isEmptyData])

    const refreshUI = () => {
        if (tabView === keys.CHAINS) {
            if(listChains?.list?.length>0) setIsEmptyData(false) 
            else setIsEmptyData(true)
        }
        caculateChainManageBarHeightContent()
    }

    const caculateChainManageBarHeightContent = () => {
        // bound = document.getElementById("chain-id");
        let bound = document.getElementById('main-bound-id')
        chainManageBar = document.getElementById("chain-manage-id");
        if (!chainManageBar || !bound) return;
        let vh = document.body.offsetHeight;
        if (!vh) return;
        let barHeight =
            bound.offsetHeight -
            (stylesConstants.HEADER_HEIGHT +
                stylesConstants.MAIN_CONTENT_PADDING_TOP) -
            15;
        chainManageBar.style.height = `${barHeight}px`;
    };

    const _callBackClickNavLink = (data) => {
        setChainManageSeleted(data.key);
    };

    const _handleGetAllNote = useCallback(
        () => {
            if(!chainDetails.details) return;

            const { ID } = chainDetails.details
            dispatch(getListNotesByChain({ChainID: ID}))
        },
        [JSON.stringify(chainDetails.details)],
    )
    
    const _handleAddNote = useCallback(
        (Type, Message) => {
            if(!chainDetails.details) return;
            const { ID } = chainDetails.details

            dispatch(addNoteToChain(
            {
                ChainID: ID,
                Type,
                Message
            }, 
            () => _handleGetAllNote()
        ))
        },
        [JSON.stringify(chainDetails.details), _handleGetAllNote],
    )

    const listNotes = useMemo(() => notes.list, [JSON.stringify(notes.list)])

    return (
        <styles.Bound id="chain-id">
            {
                isEmptyData ?
                <EmptydataControl_v2 />
                :
                <div className="scroll">

                    {/* CHAIN INFORMATION BAR */}
                    <div className="chain-info">
                        <HeaderComponent
                            height={68}
                            backgroundColor="rgba(198, 213, 235, 0.1)"
                            position="absolute"
                            zIndex={2}
                            child={<HeaderDetailChain />}
                        />
                        <ChainInformation />
                    </div>

                    {/* ADMIN NOTE BAR */}
                    <div className="admin-note">
                        <HeaderComponent
                            height={68}
                            backgroundColor="rgba(198, 213, 235, 0.1)"
                            position="absolute"
                            child={<HeaderNote lstClassification={notes.lstClassification} />}
                        />
                        <AdminNote 
                            handleGetAllNote={_handleGetAllNote}
                            handleAddNote={_handleAddNote}
                            listNotes={listNotes}
                        />
                    </div>

                    {/* CHAIN MANAGE BAR */}
                    <div className="chain-manage" id="chain-manage-id">
                        <HeaderComponent
                            height={69}
                            backgroundColor="rgba(198, 213, 235, 0.1)"
                            borderBottom="1px solid #C6D5EB"
                            position="absolute"
                            zIndex={1}
                            child={
                                <HeaderMenuInformationBoard
                                    dataMenu={headerChainMenuInfomationBoard}
                                    callBackClickNavLink={_callBackClickNavLink}
                                />
                            }
                        />
                        <div className="empty-header" />
                        {
                            chainManageSeleted === headerChainMenuInfomationBoard[0].key ?
                            <ListStore />
                            :
                            <div style={{ flex: 1 }}><EmptydataControl_v3 /></div>
                        }
                        
                    </div>
                </div>
            }
        </styles.Bound>
    );
};

export default React.memo(Chains);


