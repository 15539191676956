import styled, { css } from 'styled-components'
const Bound = styled.div`
    /* height: 100%; */
    /* width: 100%; */
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: calc(100% - 64px - 53px);
    .input-field {
        .title-input{
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 128%;
            color: #959595;
            margin: 0 0 10px 10px;
        }

        .input-number {
            width: 100%;
            height: ${props => props.heightInput ? props.heightInput : "48px"};
            border: 1px solid #C6D5EB;
            box-sizing: border-box;
            border-radius: 3px;    
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: #000000;
            ::placeholder{
                color: rgba(28, 28, 28, 0.5);
            }
        }
    }

    .content-top{
        display: grid;
        grid-template-columns: 270px 470px 470px;
        grid-column-gap: 30px;
        margin-bottom: 30px;
        .import-logo{
            width: 270px;
            height: 270px;
            /* border: 1px solid black; */
        }
        .info-left{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        .info-right{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
    }
    .content-bottom{
        display: grid;
        grid-template-columns: 770px 470px;
        grid-column-gap: 30px;        
    }
    .button-container{
        display: flex;
        justify-content: flex-end;
        margin-top: 53px;

        .wrap-btn-delete-chain {
            position: relative;
        }
    }

`
const styleButtonDelete = css`
    width: 320px;
    height: 48px;
    background-color: #ffffff;
    border: 1px solid #FA8C8C;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FA8C8C;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    .icon{
        margin-right: 14px
    }

`

const styleButtonSave = css`
    width: 320px;
    height: 48px;
    background-color: #184DA2;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    pointer-events: ${props => props.isDisable?"none":"auto"};
    opacity: ${props => props.isDisable?"0.3":"1"};
    .icon{
        margin-right: 10px
    }

`
export { Bound, styleButtonDelete, styleButtonSave }