import { useReducer } from "react";

const isEqualState = (left, right) => {
  return JSON.stringify(left) === JSON.stringify(right);
};

const reducer = (state, action) => {
  if (Array.isArray(action)) {
    const [key, value] = action;
    if (isEqualState(state[key], value)) return state;
    return {
      ...state,
      [key]: value,
    };
  }
  if (typeof action === "object") {
    const { key, value, override } = action;
    if (override) {
      return {
        ...state,
        ...override,
      };
    }
    if (isEqualState(state[key], value)) return state;
    if (key) {
      return {
        ...state,
        [key]: value,
      };
    }
  }

  return state;
};

export const useReducerState = (initialState) => {
  const [state, setState] = useReducer(reducer, initialState);
  return [state, setState];
};
