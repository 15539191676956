import { css } from 'styled-components'

const extendStylesBtnExport = css`
    height: 50px;
    box-sizing: border-box;
    border-radius: 6px;
    background: #184DA2;
    outline: none;
    border: none;
    padding: 0 34px;

    div.btn-name {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-left: 16px;
        height: 11px;
    }
`;

export {
    extendStylesBtnExport
}