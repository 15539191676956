import styled from 'styled-components'

const RowIconDevice = styled.div`    
    display: flex;
    flex-direction: row;
    align-items: center;
    .row-icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
        /* :nth-child(1){
            margin-right: 40px;
        }
        :nth-child(2){
            margin-right: 20px;
        }
        :nth-child(3){
            margin-right: 20px;
        } */
        .icon{
            margin-right: 15px;
            
        }
        span{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #15314C;
        }
        .total{
            width: 4ch;
        }
        .icon-circle-online{
            height: 9px;
            width: 9px;
            border-radius: 50%;
            background: #09AC10;            
        }
        .content-online{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            color: #09AC10;
            margin-left: 10px;
        }
        .icon-circle-offline{
            height: 9px;
            width: 9px;
            border-radius: 50%;
            background: rgba(28, 28, 28, 0.4);
        }
        .content-offline{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            color: rgba(28, 28, 28, 0.4);
            margin-left: 10px;
        }
        
    }
    

`
export { RowIconDevice }