import styled from 'styled-components'
import { WrapFilter } from '../BoxFilterByText/index.styles';

const WrapBoxFilterByTime = styled(WrapFilter)`
    

    .field {
        &.select-date-range {
            background: #ffffff;
            border-radius: 0 0 6px 6px;
            .wrap-select-date-range {
                box-sizing: border-box;
                width: 208px;
                height: 30px;
            }
        }
    }
`;

export {
    WrapBoxFilterByTime
}