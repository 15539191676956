export const USERCENSOSHIP = "USERCENSOSHIP"
export const AVATAR = "AVATAR"
export const EKYC = "EKYC"
export const USERS = "USERS"
export const LOGIN_HISTORY = "LOGIN_HISTORY"
export const CHAINS = "CHAINS"
export const LOCK = "LOCK"
export const STORE = "STORE"

export const CMND = "CMND"
export const CCCD = "CCCD"
export const PASSPORT = "PASSPORT"

export const FULLNAME = "FULLNAME"
export const BIRTHDAY = "BIRTHDAY"
export const GENDER = "GENDER"
export const HOMETOWN = "HOMETOWN"
export const VALID_DATE = "VALID_DATE"
export const ISSUEDPLACE = "ISSUEDPLACE"
export const EXPIRE_DATE = "EXPIRE_DATE"
export const ADDRESS = "ADDRESS"
export const NATIONALITY = "NATIONALITY"
export const PLACEOFBIRTH = "PLACEOFBIRTH"

export const CHAINSNAME = "CHAINSNAME"
export const CHAINSCATEGORY = "CHAINSCATEGORY"
export const TAXNUMBER = "TAXNUMBER"
export const REPNAME = "REPNAME"
export const REPPOSITION = "REPPOSITION"
export const EMAIL = "EMAIL"
export const OFFICEADDRESS = "OFFICEADDRESS"
export const PHONENUMBER = "PHONENUMBER"

export const STORECODE = "STORECODE"
export const STORENAME = "STORENAME"
export const LATITUDE = "LATITUDE"
export const LONGITUDE = "LONGITUDE"