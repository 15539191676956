import styled from 'styled-components'

const WrapperFillReason = styled.div`
    position: absolute;
    bottom: calc(100% + 20px);
    left: 0;
    padding: 30px 30px 25px 25px;
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    transition: all 0s ease 0s!important;

    &::before {
        content: '';
        width: 21px;
        height: 21px;
        background: #FFFFFF;
        position: absolute;
        bottom: -10px;
        transform: rotate(45deg);
    }

    &:hover {
        cursor: auto;
    }

    /* .bg-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    } */

    .title {
        color: #15314C;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        text-transform: none;
    }

    .wrapper-reason {
        margin-top: 21px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            width: 353px;
            box-sizing: border-box;
            padding: 17px 10px 16px 10px;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 122.34%;
            color: #1C1C1C;
            border: 1px solid #C6D5EB;
            border-radius: 3px;

            ::placeholder {
                color: #1C1C1C;
                opacity: 0.2;
            }
        }
        
    }
`;

const BtnSend = styled.div`
    background: ${props => props.isDisabled ? "#184da299": "#184DA2" };
    border-radius: 3px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 23px;
    margin-left: 30px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    pointer-events: ${props => props.isDisabled ? "none" : "auto"};

    &:hover {
        cursor: ${props => props.isDisabled ? "auto" : "pointer"};
    }
`;

export {
    WrapperFillReason,
    BtnSend
}