import styled, { css } from "styled-components";

export const Bound = styled.div`
  width: 100%;
  height: 100%;
  font-family: SVN-Gilroy;
  font-style: normal;

  .menu {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0 30px 0 30px;

    .line-active {
      position: absolute;
      height: 5px;
      bottom: 0;
      left: 55px;
      transition-property: left;
      transition-duration: 300ms;
      transition-timing-function: linear;
      transition: 0.5s all ease-in-out;
    }
  }
`;
export const ItemMenu = styled.div`
  ${({ width }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      cursor: pointer;
      width: ${width};

      .icon {
        background-color: rgba(149, 149, 149, 0.1);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `;
  }}
`;

export const Label = styled.p`
  ${({ active, colorGradient }) => {
    if (!active)
      return css`
        margin: 0 0 0 10px;
        font-size: 16px;
        color: #959595;
        font-weight: normal;
      `;
    else
      return css`
        margin: 0 0 0 10px;
        font-size: 16px;
        font-weight: bold;
        background: ${colorGradient};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `;
  }}
`;
