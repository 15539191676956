import styled, { css } from "styled-components"

const Bound = styled.div`
    height: calc(100% - 99px);
    padding: 30px 27px 0 25px;
    display: flex;
    flex-direction: column;
    position: relative;

    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        position: relative;

        .title{
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #1C1C1C;
            text-transform: uppercase;
        }
    } 

    .popup-edit-store {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
    }  

`
const styleButtonAddStore = css`
    width: 197px;
    height: 50px;
    background: #184DA2;
    border: 1px solid #184DA2;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    .icon{
        width: 14px;
        height: 14px;
        position: absolute;
        top: 16px;
        left: 15px;
    }

`
export { Bound, styleButtonAddStore }