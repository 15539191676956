import React, { useState, useRef, useCallback, Fragment, useEffect, useLayoutEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Axios from 'axios'

//style
import * as styles from './index.style'
//tools
import { buildMoney, convertDateTime } from '../../../../../assets/helper/tools'
//constant
import * as transactionDetailStatus from '../../../../../assets/constants/transactionDetailStatus'
//host
import { apiAvatarImageMini, apiAvatarShop, apiVideoDetailTransaction } from '../../../../../assets/constants/host'
//component
import AvatarVerifyDefault from '../../../../../assets/images/components/AvatarVerifyDefault'
import LogoStoreDefault from '../../../../../assets/images/components/LogoStoreDefault'
import PartialContent from '../../../../Control/PartialContent'


const InfoTransactionUser = ({ data = {} }) => {
    const { t } = useTranslation('common');
    const refVideo = useRef(null);
    const videoSrc = useRef("");
    const sourceRef = useRef();

    const [isShowPlay, setIsShowPlay] = useState(false)
    const [imgAvatarError, setImgAvatarError] = useState(false)
    const [imgShopError, setImgShopError] = useState(false)
    const [avatar, setAvatar] = useState("");
    const [shopAvatar, setShopAvatar] = useState("");

    const isHaveVideo = useMemo(() => !!data?.FacepayNumber && data?.IsVideoUploaded, [JSON.stringify(data)])
    // const isHaveVideo = useMemo(() => !!data?.FacepayNumber, [JSON.stringify(data)])

    useLayoutEffect(() => {
        setImgAvatarError(false)
        setImgShopError(false)
    }, [data?.UserInfo?.ID])

    const _handleEndedVideo = useCallback(() => {
        setIsShowPlay(true)
    }, [])

    useEffect(() => {
        const { UserInfo } = data || {}
        const { ID } = UserInfo || {}
        if(!ID) {
            setAvatar("")
            return;
        }
        setAvatar(apiAvatarImageMini(ID))
    }, [data]);

    useEffect(() => {
        const { ShopAvatarID } = data || {}
        if(!ShopAvatarID) {
            setShopAvatar("")
            return;
        }
        setShopAvatar(apiAvatarShop(ShopAvatarID))
    }, [data]);

    useEffect(() => {
        const { FacepayNumber } = data || {}
        if (!FacepayNumber) return

        if (!isHaveVideo) return;

        const CancelToken = Axios.CancelToken;
        const source = CancelToken.source();

        const requestGetVideo = async () => {
            try {
                const res = await Axios.get(apiVideoDetailTransaction(FacepayNumber), {
                    responseType: 'blob',
                    cancelToken: source.token
                })
                const { data } = res
                // console.log(data)
                videoSrc.current = URL.createObjectURL(data)
                refVideo.current.src = videoSrc.current
            }
            catch (err) {
                console.log('Something wrong when load video!')
                console.dir(err)
            }
        }
        requestGetVideo()

        return () => {
            URL.revokeObjectURL(videoSrc.current)
            source.cancel("cancel request")
        }
    }, [isHaveVideo])

    // useEffect(() => {
    //     return () => {
    //         sourceRef.current.src = ""
    //     }
    // }, [])

    const urlVideo = useMemo(() => apiVideoDetailTransaction(data.FacepayNumber), [data.FacepayNumber]);

    return (
        <styles.Bound>
            <div className="info-trans-container">
                <div className="status-trans-wrap">
                    <div className="code-wrap">
                        <div className="trans-code">
                            <p className="title-code">{t('transaction_detail.header.ref_number')}</p>
                            <p className="content-code" data-toggle='tooltip' title={data?.FacepayNumber || ""}>
                                {data?.FacepayNumber || "-"}
                            </p>
                        </div>
                        <div className="order-code">
                            <p className="title-code">{t('transaction_detail.header.bill_number')}</p>
                            <p className="content-code" data-toggle='tooltip' title={data?.BillNumber || ""}>
                                {data?.BillNumber || "-"}
                            </p>
                        </div>
                    </div>
                    <div className="status-wrap">
                        <p className="title-status">{t('transaction_detail.header.payment_status')}</p>
                        <p className={data?.PaymentStatus === transactionDetailStatus.Success ? "content-status success" : "content-status failed"}>
                            {
                                data?.PaymentStatus ?
                                    <Fragment>
                                        {
                                            data?.PaymentStatus === transactionDetailStatus.Success ?
                                                t('transaction_detail.status.success')
                                                :
                                                <Fragment>
                                                    {
                                                        data?.PaymentStatus === transactionDetailStatus.Pending ?
                                                            t('transaction_detail.status.pending')
                                                            :
                                                            <Fragment>
                                                                {
                                                                    data?.PaymentStatus === transactionDetailStatus.Failed ?
                                                                        t('transaction_detail.status.failed')
                                                                        :
                                                                        t('transaction_detail.status.refund')
                                                                }
                                                            </Fragment>
                                                    }
                                                </Fragment>
                                        }
                                    </Fragment>
                                    :
                                    " "
                            }
                        </p>
                    </div>
                    <div className="refund-wrap">
                        <p className="title-refund">{t('transaction_detail.header.refund_time')}</p>
                        <p className="content-code" data-toggle='tooltip' title={data?.RefundTime ? convertDateTime(data.RefundTime, "DD/MM/YYYY HH:mm:ss") : ""}>
                            {data?.RefundTime ? convertDateTime(data.RefundTime, "DD/MM/YYYY HH:mm:ss") : "-"}
                        </p>
                    </div>
                </div>
                <div className="info-trans-wrap">
                    <div className="info-user-wrap">
                        <div className="wrapper">
                            <div className="avatar-user-container">
                                <div className='avatar-user-wrap '>
                                    {
                                        imgAvatarError ?
                                            <AvatarVerifyDefault className='avatar-user' />
                                            :
                                            <img
                                                className='avatar-user'
                                                // src={data?.UserInfo?.ID ? apiAvatarImageMini(data?.UserInfo?.ID) : ""}
                                                src={avatar}
                                                alt='avatar-user'
                                                onError={() => setImgAvatarError(true)}
                                            />
                                    }
                                </div>
                                <div className='info-user'>
                                    <div className='fullname-user' data-toggle='tooltip' title={data?.UserInfo?.FullName || ""}>
                                        {data?.UserInfo?.FullName || "-"}
                                    </div>
                                    <div className='wrapper-username'>
                                        <span data-toggle='tooltip' title={data?.UserInfo?.Username || ""}>
                                            {data?.UserInfo?.Username ? `@${data.UserInfo?.Username}` : ""}
                                        </span>
                                        {`- ${data?.UserInfo?.PhoneNumber || ""}`}
                                    </div>
                                </div>
                            </div>
                            <div className="money-wrap">
                                <p className="title-money">{t('transaction_detail.header.amount')}</p>
                                <p className="content-money">
                                    {!isNaN(data?.Amount) ? buildMoney(`${data.Amount} VND`) : "0"}
                                </p>
                            </div>
                        </div>
                        <div className="info-store">
                            <div className="store-wrap">
                                <div className="logo-store">
                                    {imgShopError ?
                                        <LogoStoreDefault />
                                        :
                                        <img
                                            // src={data?.ShopAvatarID ? apiAvatarShop(data.ShopAvatarID) : ""}
                                            src={shopAvatar}
                                            alt='logo-shop'
                                            onError={() => setImgShopError(true)}
                                        />
                                    }
                                </div>
                                <div className="name-store" data-toggle='tooltip' title={data?.ShopName || ""}>
                                    {data?.ShopName || "-"}
                                </div>
                            </div>
                            <div className="money-wrap total-amount">
                                <p className="title-money">{t('transaction_detail.header.total_amount')}</p>
                                <p className="content-money">
                                    {
                                        (!isNaN(data?.Amount) && !isNaN(data?.WeeFee) && !isNaN(data?.NapasFee)) ?
                                            buildMoney(`${(data.Amount - (data?.WeeFee + data?.NapasFee))} VND`)
                                            :
                                            "-"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="trans-wrap">
                        <p className="title-trans">{t('transaction_detail.header.transaction_ref')}</p>
                        <p className="content-trans" title={data?.RefNumber || ""}>
                            {data?.RefNumber || "-"}
                        </p>
                    </div>
                    <div className="trans-wrap">
                        <p className="title-trans">{t('transaction_detail.header.transaction_info')}</p>
                        <p className="content-trans" title={data?.PaymentID || ""}>
                            {data?.PaymentID || "-"}
                        </p>
                    </div>
                </div>
            </div>
            <div className="video-container">
                {
                    isHaveVideo ?
                        <>
                            <video
                                className="video"
                                width="370"
                                height="233"
                                ref={refVideo}
                                onEnded={_handleEndedVideo}
                                controls
                                preload="none"
                            >
                                {/* <source ref={sourceRef} src={apiVideoDetailTransaction(data?.FacepayNumber) || ""} type="video/mp4" /> */}
                                Your browser does not support the video tag.
                            </video>
                            {/* <PartialContent 
                                className="video"
                                width="370"
                                height="233"
                                controls
                                urlVideo={urlVideo}
                                // urlVideo="http://nickdesaulniers.github.io/netfix/demo/frag_bunny.mp4"
                            /> */}

                        </>
                        :
                        <div className='no-video'>No Video</div>
                }
            </div>
        </styles.Bound>
    )
}

export default InfoTransactionUser
