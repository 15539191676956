import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 260px 1fr;
    grid-gap: 40px;
    padding-left: ${props => props.paddingLeft ? props.paddingLeft : "58px"};
    .infomation-left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .avatar-user{
            height: 174px;
            width: 174px;
            border-radius: 50%;
            object-fit: cover;
            margin: 20px 0 30px 0;
        }
    }
    .infomation-avatar{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 10px 0;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        :last-child{
            margin: 0 0 20px 0;
        }
        .title-info{            
            color: rgba(28, 28, 28, 0.5);
            width: 100px;
            height: 24px;
            margin: 0 20px 0 0;
            &.right{
                width: 136px
            }
        }
        .content-info{
            color: #1C1C1C;
            width: 130px;
            display: flex;
            align-items: center;
            span{
                color: rgba(28, 28, 28, 0.5);
                margin: 0 0 0 5px;
            }
        }
    }
    .infomation-right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name-container{
            display: flex;
            flex-direction: column;
            margin: 57px 0 71px 0;
            .name-user{
                font-family: "SVN-Gilroy";
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #1C1C1C;
                margin: 0 0 5px 0;
            }
            .phone-user{
                font-family: "UTM Avo";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
                color: #1C1C1C;
            }
        }
        .gender-container{
            display: flex;
            flex-direction: column;
        }
    }
    
`

export { Bound }