import React, { useState, useEffect, useCallback } from 'react'
import NumberFormat from 'react-number-format'

// styles
import { WrapFilterByNum } from './index.styles'
import { extendStylesCheckbox } from '../BoxFilterByText/index.styles'

// component
import CheckBoxField from '../CheckBoxField'
import DropdownSelectTypeFilterNum from './DropdownSelectTypeFilterNum'

// hooks
import useDebounce from '../../../hook/useDebouce.hook'
import usePrevious from '../../../hook/usePrevious'

// constant
export const INCREASE = "INCREASE"
export const DECREASE = "DECREASE"

const BoxFilterByNum = ({ stateFilter, dispatchStateFilter, onChangeValues }) => {

    const [numValFirstCompare, setNumValFirstCompare] = useState(stateFilter.firstCompares.numVal)
    const [numValSecondCompare, setNumValSecondCompare] = useState(stateFilter.secondCompares.numVal)

    const prevNumValFirstCompare = usePrevious(numValFirstCompare)
    const prevNumValSecondCompare = usePrevious(numValSecondCompare)

    const _handleChangeSort = useCallback(
        (objChange) => {
            if(objChange.sort === stateFilter.sort) return
            dispatchStateFilter(objChange)
            onChangeValues({
                ...stateFilter,
                ...objChange
            })
        },
        [onChangeValues, dispatchStateFilter, stateFilter],
    )

    const _handleChangeTypeFilterNum = useCallback(
        (keyCompare) => (value) => {
            if(stateFilter[keyCompare].type === value) return;

            const dataChangeFilter = { 
                [keyCompare]: {
                    ...stateFilter[keyCompare],
                    type: value
                }
            }

            dispatchStateFilter(dataChangeFilter)

            const { numVal } = stateFilter[keyCompare]
            if(numVal !== 0 && !numVal) return;

            onChangeValues({
                ...stateFilter,
                ...dataChangeFilter
            })
        },
        [numValFirstCompare, stateFilter, onChangeValues, dispatchStateFilter],
    )

    useEffect(() => {
        // if(numValFirstCompare === prevNumValFirstCompare) return;
        // debugger
        if(stateFilter.firstCompares.numVal === numValFirstCompare) return;
        if(numValFirstCompare !== 0 && !numValFirstCompare && prevNumValFirstCompare !== 0 && !prevNumValFirstCompare) return;
        dispatchStateFilter({ firstCompares: {
            ...stateFilter.firstCompares,
            numVal: numValFirstCompare
        }})
    }, [numValFirstCompare, prevNumValFirstCompare, JSON.stringify(stateFilter)])

    useEffect(() => {
        // if(numValSecondCompare === prevNumValSecondCompare) return;
        if(stateFilter.secondCompares.numVal === numValSecondCompare) return;
        if(numValSecondCompare !== 0 && !numValSecondCompare && prevNumValSecondCompare !== 0 && !prevNumValSecondCompare) return;

        dispatchStateFilter({ secondCompares: {
            ...stateFilter.secondCompares,
            numVal: numValSecondCompare
        }})
    }, [numValSecondCompare, prevNumValSecondCompare, JSON.stringify(stateFilter)])
    

    return (
        <WrapFilterByNum>
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === INCREASE}
                    onClick={() => _handleChangeSort({ sort: INCREASE })}
                    data="Tăng dần" 
                    extendStyles={extendStylesCheckbox}
                />
            </div>
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === DECREASE}
                    onClick={() => _handleChangeSort({ sort: DECREASE })}
                    data="Giảm dần" 
                    extendStyles={extendStylesCheckbox}
                />
            </div>
            <div className="field selection-range-num">
                <div className="selection-filter-num">
                    <DropdownSelectTypeFilterNum 
                        value={stateFilter.firstCompares.type} 
                        onChangeValue={_handleChangeTypeFilterNum("firstCompares")} 
                    />
                </div>
                <NumberFormat 
                    className="num-filter" 
                    displayType='input'
                    // type="tel"
                    placeholder="Nhập giá trị"
                    onValueChange={objVal => setNumValFirstCompare(objVal.floatValue)}
                    defaultValue={stateFilter.firstCompares.numVal}
                    allowNegative={false}
                    allowLeadingZeros={true}
                    thousandSeparator=","
                    isAllowed={values => {
                        const isValid = !values.value.includes('.')
                        return isValid
                    }}
                />
            </div>
            <div className="field selection-range-num">
                <div className="selection-filter-num">
                    <DropdownSelectTypeFilterNum 
                        value={stateFilter.secondCompares.type} 
                        onChangeValue={_handleChangeTypeFilterNum("secondCompares")} 
                    />
                </div>
                <NumberFormat 
                    className="num-filter" 
                    displayType='input'
                    // type="tel"
                    placeholder="Nhập giá trị"
                    onValueChange={objVal => setNumValSecondCompare(objVal.floatValue)}
                    allowNegative={false}
                    allowLeadingZeros={true}
                    defaultValue={stateFilter.secondCompares.numVal}
                    thousandSeparator=","
                    isAllowed={values => {
                        const isValid = !values.value.includes('.')
                        return isValid
                    }}
                />
            </div>
        </WrapFilterByNum>
    )
}

export default BoxFilterByNum
