export const GET_USER_INFOS = 'GET_USER_INFOS'
export const GET_USER_INFOS_ERR = 'GET_USER_INFOS_ERR'

export const GET_LIST_REASON_LOCK = "GET_LIST_REASON_LOCK"
export const GET_LIST_REASON_LOCK_ERR = "GET_LIST_REASON_LOCK_ERR"

export const LOCK_ACCOUNT_START = "LOCK_ACCOUNT_START"
export const LOCK_ACCOUNT = "LOCK_ACCOUNT"
export const LOCK_ACCOUNT_END = "LOCK_ACCOUNT_END"
export const LOCK_ACCOUNT_ERR = "LOCK_ACCOUNT_ERR"

export const UNLOCK_ACCOUNT_START = "UNLOCK_ACCOUNT_START"
export const UNLOCK_ACCOUNT = "UNLOCK_ACCOUNT"
export const UNLOCK_ACCOUNT_END = "UNLOCK_ACCOUNT_END"
export const UNLOCK_ACCOUNT_ERR = "UNLOCK_ACCOUNT_ERR"

export const CLEAR_DETAILS_ACCOUNT = 'CLEAR_DETAILS_ACCOUNT'

