import styled from "styled-components";

export const Bound = styled.div`
  width: 100%;
  height: 100%;
  font-family: SVN-Gilroy;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 30px;
  box-sizing: border-box;

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 170%;
    color: #1c1c1c;
  }

  .status_normal {
    position: relative;
    display: flex;
    align-items: center;    
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 115px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #00b887;
      box-sizing: border-box;
      border-radius: 40px;
      margin: 0 0 0 15px;   
      .text {
        font-weight: normal;
        font-size: 14px;
        color: #00b887;
        margin: 3px 0 0 0;
      }
      &:hover + .wrapper-lock{
        display: block;
      }      
    }
    .wrapper-lock{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      .btn-lock{      
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 115px;
        height: 36px;
        background: #184DA2;
        border-radius: 40px;
        .text {
          font-weight: normal;
          font-size: 14px;
          color: #ffffff;
        }      
      }
      &:hover {
        display: block;
      }
    }    
  }

  .status_lock {
    display: flex;
    align-items: center;
    position: relative;    
    .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 87px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #fa8c8c;
      box-sizing: border-box;
      border-radius: 40px;
      margin: 0 0 0 15px;
      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #fa8c8c;
        margin: 3px 0 0 0;
      }
      &:hover + .wrapper-unlock{
        display: block;
      } 
    }
    .wrapper-unlock{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      .btn-unlock{      
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 87px;
        height: 36px;
        background: #184DA2;
        border-radius: 40px;
        .text {
          font-weight: normal;
          font-size: 14px;
          color: #ffffff;
        }      
      }
      &:hover {
        display: block;
      }
    }            
  }

  .tooltip-wrap{
    display: flex;
    & .tooltip-content {
      display: none;
      position: absolute;
      bottom: 35px;
      left: 0;
      right: 0;
    }
    &:hover .tooltip-content {
      display: block;
    }
  }
    
`;
