import React, { useCallback } from 'react'

// styles
import { WrapFilterByText, extendStylesCheckbox, extendStylesInput } from './index.styles'

// component
import CheckBoxField from '../CheckBoxField'
import InputSearch from '../InputSearch'

// constant
export const INCREASE = "INCREASE"
export const DECREASE = "DECREASE"

const BoxFilterByText = ({ stateFilter, dispatchStateFilter, onChangeValues }) => {
    
    const _handleChange = useCallback(
        (objChange) => {
            if(objChange.sort === stateFilter.sort) return
            dispatchStateFilter(objChange)
            onChangeValues({
                ...stateFilter,
                ...objChange
            })
        },
        [stateFilter],
    )

    return (
        <WrapFilterByText data-testid="filter-by-test">
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === INCREASE}
                    onClick={() => _handleChange({ sort: INCREASE })}
                    data="Tăng dần" 
                    extendStyles={extendStylesCheckbox}
                    data-testid="ascending"
                />
            </div>
            <div className="field">
                <CheckBoxField 
                    isChecked={stateFilter.sort === DECREASE}
                    onClick={() => _handleChange({ sort: DECREASE })}
                    data="Giảm dần" 
                    extendStyles={extendStylesCheckbox} 
                    data-testid="decreasing"
                />
            </div>
            <div className="field search-field">
                <InputSearch 
                    placeHolderSearch="Tìm kiếm"
                    widthSearch="180px"
                    heightSearch="30px"
                    value={stateFilter.searchKey}
                    onChangeValue={(val) => dispatchStateFilter({ searchKey: val.trimStart().replace(/\s+/g, " ")})}
                    extendStyles={extendStylesInput}
                />
            </div>
        </WrapFilterByText>
    )
}

export default BoxFilterByText
