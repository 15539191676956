import * as types from "./types";
import { DANGER, INFO, WARNING } from "../../../../assets/constants/noteTypes";

const intitialState = {
    list: [],
    lstClassification: {
        [DANGER]: 0,
        [WARNING]: 0,
        [INFO]: 0
    }
};

const notes = (state = intitialState, action) => {
  switch (action.type) {
    case types.GET_NOTES_BY_CHAIN: {
        return {
            ...state,
            ...action.payload
        }
    }
    case types.CLEAR_NOTES_BY_CHAIN: {
        return {
            ...intitialState
        }
    }
    default:
      return state;
  }
};

export default notes;
