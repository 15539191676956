import Axios from "axios";

// host
import { host } from "../../../../assets/constants/host";

// helper
import mapErrors from "../../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../../assets/constants/codeResponse";

const apiGetShopByChains = `${host}/backoffice/get-shops-by-chain`
const apiAddShopToChain = `${host}/backoffice/add-shop`
const apiUpdateShop = `${host}/backoffice/update-shop`
const apiRemoveShop = `${host}/backoffice/remove-shop`

export const getListShopsByChain = ({ ChainID, PageNo, PageSize, isInitial=false }) => async dispatch => {

    const dataFilter = {
        ChainID, 
        PageNo, 
        PageSize
    }

    const dataRequest = JSON.stringify(dataFilter)

    try {
        const res = await Axios.post(apiGetShopByChains, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const payload = {
                list: data.Shops || [],
                total: data.Total,
            }

            if(isInitial) {
                payload.firstFilter = {
                    PageNo, 
                    PageSize
                }
            }
                
            dispatch({
                type: types.GET_LIST_SHOPS_BY_CHAIN,
                payload
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_SHOPS_BY_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_LIST_SHOPS_BY_CHAIN_ERR))
    }

}

export const addShopToChain = ({ Code, Name, Address, AreaID, ChainID, Lat, Lng }, callbackSuccess) => async (dispatch, getState) => {
    const store = getState();
    const { isLoading } = store.chains.chainInfos.stores
    if(isLoading) return;
    
    dispatch({ type: types.ADD_SHOP_TO_CHAIN_START })
    const dataRequest = JSON.stringify({
        Code,
        Name, 
        Address, 
        AreaID, 
        ChainID, 
        Lat, 
        Lng
    })

    try {
        const res = await Axios.post(apiAddShopToChain, dataRequest);
        const { data } =  res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.ADD_SHOP_TO_CHAIN,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ADD_SHOP_TO_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.ADD_SHOP_TO_CHAIN_ERR))
    }
    dispatch({ type: types.ADD_SHOP_TO_CHAIN_FINISH })
}

export const editShopFromChain = ({
    ID,
    Name,
    Address,
    AreaID,
    Lat,
    Lng,
    Code,
}, callbackSuccess) => async (dispatch, getState) => {

    const store = getState();
    const { isLoading } = store.chains.chainInfos.stores
    if(isLoading) return;

    dispatch({ type: types.EDIT_SHOP_TO_CHAIN_START })

    const dataRequest = JSON.stringify({
        ID,
        Name,
        Address,
        AreaID,
        Lat,
        Lng,
        Code,
    })

    try {
        const res = await Axios.post(apiUpdateShop, dataRequest)
        const { data } = res;
        const  isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.EDIT_SHOP_FROM_CHAIN
            })
            callbackSuccess && callbackSuccess()
        }
        else{
            dispatch({
                type: types.EDIT_SHOP_FROM_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.EDIT_SHOP_FROM_CHAIN_ERR))
    }
    dispatch({ type: types.EDIT_SHOP_TO_CHAIN_FINISH })
}

export const delShopFromChain = ({ ID }, callbackSuccess) => async (dispatch, getState) => {

    const store = getState();
    const { isLoading } = store.chains.chainInfos.stores
    if(isLoading) return;

    dispatch({ type: types.REMOVE_SHOP_TO_CHAIN_START })

    const dataRequest = JSON.stringify({
        ID
    })  
    try {
        const res = await Axios.post(apiRemoveShop, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.REMOVE_SHOP_FROM_CHAIN,
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.REMOVE_SHOP_FROM_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.REMOVE_SHOP_FROM_CHAIN_ERR))
    }
    dispatch({ type: types.REMOVE_SHOP_TO_CHAIN_FINISH })
}