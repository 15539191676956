import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './index.style'

const HeaderComponent = (props) => {
    const { height, backgroundColor, borderBottom, position, zIndex, child } = props

    return (
        <styles.Bound 
            styled = { {height, backgroundColor, borderBottom, position, zIndex} } >
            {
                child ? child : 'This is Header Component'
            }
        </styles.Bound>
    )
}

HeaderComponent.propTypes = {
    height: PropTypes.number, 
    backgroundColor: PropTypes.string, 
    borderBottom: PropTypes.string, 
    position: PropTypes.string, 
    zIndex: PropTypes.number,
    child: PropTypes.any 
}

export default React.memo(HeaderComponent)
