import React, { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'


// styles
import { WrapperAdvancedItem } from './index.style'

// data
// import conditionCheckPassFace from '../../../../../../../data/conditionCheckPassFace'

// constant
import { apiVerifyImageHistory } from '../../../../../../../assets/constants/host'
import { PENDING, REPENDING, REJECT, ACCEPT } from '../../../../../../../assets/constants/faceCensorShipStatus'

// component
import ListConditionCheckPass from './ListConditionCheckPass'
import FooterDetails from './FooterDetails'
import VideoCensorship from './VideoCensorship'

// const mockImg = "https://picsum.photos/307/409"

const AdvancedItemDetails = ({ data }) => {

    const listReasons = useSelector(state => state.userCensorship.detailsUser.faces.listReasons)

    const [isImgError, setIsImgError] = useState(false)

    const [stateCondition, dispatchCondition] = useReducer(
        (state, action) => ({ ...state, ...action }), 
        {
            listChecked: [],
            listCondition: {},
            isDisableCheck: false,
            status: '',
            optinalReason: ''
        }
    )

    useEffect(() => {
        switch (data.Status) {
            case PENDING: {
                const listCondition =
                    Object.keys(listReasons).reduce(
                        (lst, id) =>
                            listReasons[id].IsRePending
                                ? lst
                                : { ...lst, [id]: listReasons[id] }
                        ,{}
                    )
                dispatchCondition({ 
                    listCondition,
                    listChecked: [],
                    isDisableCheck: false,
                    status: PENDING,
                    optinalReason: ''
                })
                break;
            }
            case REPENDING: {
                dispatchCondition({ 
                    listCondition: listReasons,
                    listChecked: [],
                    isDisableCheck: false,
                    status: REPENDING,
                    optinalReason: ''
                })
                break;
            }
            case REJECT: {
                const listCondition =
                    data.IsFirst
                        ? Object.keys(listReasons).reduce(
                            (lst, id) =>
                                listReasons[id].IsRePending
                                    ? lst
                                    : { ...lst, [id]: listReasons[id] }
                            , {}
                        )
                        : listReasons
                const listChecked = Object.keys(listCondition).filter(
                    item => data.Reasons.includes(item)
                )
                dispatchCondition({
                    listCondition,
                    listChecked,
                    isDisableCheck: true,
                    status: REJECT,
                    optinalReason: data.OptionalReason
                })
                break;
            }
            case ACCEPT: {
                const listCondition =
                    data.IsFirst
                        ? Object.keys(listReasons).reduce(
                            (lst, id) =>
                                listReasons[id].IsRePending
                                    ? lst
                                    : { ...lst, [id]: listReasons[id] }
                            , {}
                        )
                        : listReasons
                const listChecked = Object.keys(listCondition)
                dispatchCondition({
                    listCondition,
                    listChecked,
                    isDisableCheck: true,
                    status: ACCEPT,
                    optinalReason: ''
                })
                break;
            }
            default: 
                break;
        }
    }, [data, listReasons])

    return (
        <WrapperAdvancedItem>
            {
                isImgError
                ? <div className="face-censorship" />
                : <img 
                    src={apiVerifyImageHistory(data.FaceID)} 
                    alt="face-censorship" 
                    className="face-censorship" 
                    onError={() => setIsImgError(true)}
                />
            }

            <VideoCensorship />
            
            <div className="wrapper-condition-action">
                <ListConditionCheckPass 
                    stateCondition={stateCondition}
                    dispatchCondition={dispatchCondition}
                />
                <FooterDetails 
                    data={data}
                    stateCondition={stateCondition}
                />
            </div>
        </WrapperAdvancedItem>
    )
}

export default AdvancedItemDetails
