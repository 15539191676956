import * as types from "./types";

const intitialState = {
    list: [],
    total: 0,
    isLoading: false,
    firstFilter: {
        PageNo: 1, 
        PageSize: 10
    }
};

const stores = (state = intitialState, action) => {
  switch (action.type) {
    case types.GET_LIST_SHOPS_BY_CHAIN: {
        return {
            ...state,
            ...action.payload
        }
    }
    case types.ADD_SHOP_TO_CHAIN_START: 
    case types.EDIT_SHOP_TO_CHAIN_START: 
    case types.REMOVE_SHOP_TO_CHAIN_START: {
        return {
			...state,
			isLoading: true
        }
    }
    case types.ADD_SHOP_TO_CHAIN_FINISH: 
    case types.EDIT_SHOP_TO_CHAIN_FINISH: 
    case types.REMOVE_SHOP_TO_CHAIN_FINISH: {
        return {
			...state,
			isLoading: false
        }
    }
    default:
      return state;
  }
};

export default stores;
