import React from 'react'

// styles
import { WrapTitleDropdownFilter } from './index.styles'

// component
import ArrowDownBlack from '../../../assets/images/components/ArrowDownBlack'
import ArrowDownBlue from '../../../assets/images/components/ArrowDownBlue'

const TitleDropdownFilter = ({ children, active, disabled=false, ...props }) => {
    return (
        <WrapTitleDropdownFilter {...props}>
            <div className={active ? "title active" : "title"}>{children}</div>
            {
                disabled ?
                    <div />
                    :
                    active?
                        <ArrowDownBlue className="arrow-dropdown" />
                        :
                        <ArrowDownBlack className="arrow-dropdown" />
            }
        </WrapTitleDropdownFilter>
    )
}

export default TitleDropdownFilter
