import React from 'react'

const ArrowDownBlack = (props) => {
    return (
        <svg {...props} width={9} height={6} viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 5.59314C4.67757 5.58807 4.83991 5.52212 4.97182 5.38006L8.82751 1.43305C8.93912 1.32144 9 1.17939 9 1.01197C9 0.677132 8.73619 0.408248 8.40135 0.408248C8.23901 0.408248 4.50507 0.408203 4.50507 0.408203C4.50507 0.408203 0.766065 0.408248 0.598647 0.408248C0.263811 0.408248 0 0.677132 0 1.01197C0 1.17939 0.0608794 1.32144 0.172492 1.43305L4.03326 5.38006C4.17024 5.52212 4.32244 5.59314 4.5 5.59314Z" fill="#1C1C1C" />
        </svg>
    )
}

export default ArrowDownBlack
