import styled, { css } from 'styled-components'
import Bound from '../../PopupAddStore/index.styles'
const Bounds = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    box-sizing: border-box;
    .row-input{
        display: grid;
        grid-template-columns: 370px 370px;
        grid-gap: 30px;
    }
    .wrapper-input{
        margin-bottom: 30px;
        :nth-child(4){
            margin-bottom: 0px;
        }           
    }
    .drop-down{
        margin-bottom: 30px;        
    }
    .button-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 53px;
        .button-delete{
            margin-right: 30px
        }

        .btn-del-wrap {
            position: relative;
        }
    }

`
const styleButtonDelete = css`
    width: 320px;
    height: 48px;
    background-color: #ffffff;
    border: 1px solid #184DA2;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #184DA2;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    .icon{
        margin-right: 14px
    }

`

const styleButtonSave = css`
    width: 320px;
    height: 48px;
    background-color: #184DA2;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    pointer-events: ${props => props.isDisable?"none":"auto"};
    opacity: ${props => props.isDisable?"0.3":"1"};
    .icon{
        margin-right: 10px
    }

`

export { Bounds, styleButtonDelete, styleButtonSave }