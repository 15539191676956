import styled, { css } from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-rows: repeat(2, auto) 327px;
    grid-gap: 10px;
    width: 100%;
    .title-container{
        display:flex;
        align-items: center;
        padding: 20px 0;
        .p-title{
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #1C1C1C;
        }
    }
    .gttt-image-container{
        display: grid;
        grid-template-columns: 540px 1fr;
        grid-gap: 30px;
        div{
            flex-direction: row;
            img{
                width: 540px;
                height: 345px;
                border-radius: 6px;                
            }
        }
        .img-identify {
            display: inline-block;
            position: relative;
            overflow: hidden;
            .tools {
                position: absolute;
                bottom: 3px;
                right: 0;
                z-index: 2;
                display: flex;

                .btn-support-zoom {
                    background: #FFFFFF;
                    border: 1px solid #EDEDED;
                    box-sizing: border-box;
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
                    border-radius: 2px;
                    width: 24px;
                    height: 24px;
                    margin-right: 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .gttt-information-container{
        display: grid;
        grid-template-columns: 440px 1fr;
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 30px;
        border-bottom: 1px solid #C6D5EB;
        .image-verify{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            padding-bottom: 30px;
            grid-column-gap: 40px;
            .title-container{
                grid-column: 1 / span 2;
            }
            
            .image-container{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    object-fit: cover
                }
                .border-image{
                    display: flex;
                    width: 194px;
                    height: 194px;
                    border-radius: 50%;
                    border: 3px solid #2D98F0;
                    justify-content: center;
                    align-items: center;
                    img{
                        display: flex;
                        width: 188px;
                        height: 188px;
                    }
                    &.error{
                        border: 3px solid #FA8C8C;
                    }
                }
                p{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 128%;
                    color: #959595;
                    text-align: center;
                    margin: 20px 0 0 0;
                }
            }
        }
        .export-info{
            grid-column: 2 / span 1;
            grid-row: 1 / span 2;
            display: grid;
            grid-template-rows: repeat(2, auto) 1fr;
            .info-bound{
                ${props => props.templateInformationStyle}
            }
            .manage-control{
                grid-row: 3 / span 1;
                width: 100%;
                height: calc(100% - 40px);
                padding-top: 40px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .admin-confirm{
                    display: flex;
                    flex-direction: column;
                    .admin{
                        font-family: "UTM Avo";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        color: #959595;
                        p{
                            margin-left: 5px;
                        }
                        span{
                            color: #2D98F0;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
    .gttt-controller-container{
        display: grid;
        grid-template-columns: 340px 1fr;
        grid-gap: 30px;
        .item-existed{
            display: grid;
            grid-template-rows: auto 1fr;
        }
        .ability-container{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            /* .user-coincide{
                display: flex;
                flex-direction: row;
                .avatar-diff{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    object-fit: contain;
                    border: 1px solid #FA8C8C;
                    margin: 0 10px 0 0;
                    box-sizing: border-box;
                }
                .user-coincide-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    .name{
                        font-family: SVN-Gilroy;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 170%;
                        color: #051321;
                    }
                    .info{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        span{
                            font-family: SVN-Gilroy;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 170%;
                            color: #2D98F0;
                            margin: 0 10px 0 0;
                        }
                    }
                }
            } */
            .empty-duplicate {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                color: #959595;
            }
            /* .ability{
                display: flex;
                align-items: center;
                margin: 0 0 20px 0;
                .circle{
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    background-color: #EDEDED;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #959595;
                    cursor: pointer;
                    &.active{
                        background-color: #2D98F0;
                        color: #FFFFFF;
                    }
                }
                .note{
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 170%;
                    color: #15314C;
                    margin: 0 0 0 10px;
                    flex: 1;
                }

            } */
        }
        .reason{
            display: grid;
            grid-template-rows: auto 1fr auto;
        }
    }
`
const styleButtonEdit = css`
    background: #2D98F0;
    border-radius: 3px;
    width: 240px;
    height: 50px;
    border: none;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
`
const styleButtonSave = css`
    background: #2D98F0;
    border-radius: 3px;
    width: 240px;
    height: 50px;
    border: none;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
        margin: 0 10px 0 0;
    }
`
const styleButtonCompare = css`
    background-color: #ffffff;
    border: 1px solid #2D98F0;
    box-sizing: border-box;
    border-radius: 22px;
    width: 118px;
    height: 32px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    color: #2D98F0;
    margin: 5px 0 0 0;
`

export { Bound, styleButtonEdit, styleButtonSave, styleButtonCompare }