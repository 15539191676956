import React from 'react'

import { WrapVerticalScroll/* , WrapperTitleColumn */ } from './index.style'

const TitleColumn = () => {
    return (
        // <WrapperTitleColumn>
        //     <div className="empty-data"></div>
            <WrapVerticalScroll>
                {/* <div className="type-data important-data">Loại giấy tờ</div> */}
                <div></div>
                <div className="important-data">Số giấy tờ  được trích xuất</div>
                <div className="important-data">Hình ảnh</div>
                <div>Họ và tên</div>
                <div>Ngày sinh</div>
                <div>Giới tính</div>
                <div>Nguyên quán</div>
                <div>Hộ khẩu thường trú</div>
                <div>Quốc tịch</div>
                <div>Ngày cấp</div>
                <div>Nơi cấp</div>
                <div>Ngày hết hạn</div>
                <div>Trạng thái duyệt thông tin eKYC</div>
                <div>Người duyệt</div>
                <div>Thời gian duyệt</div>
            </WrapVerticalScroll>
        // </WrapperTitleColumn>
    )
}

export default TitleColumn
