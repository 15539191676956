import React, { useState, useEffect } from 'react'

// api
import { apiAvatarImageTransaction } from '../../../../../../../assets/constants/host'

// helper
import { convertDateTime } from '../../../../../../../assets/helper/tools';

// component
import AvatarVerifyDefault from '../../../../../../../assets/images/components/AvatarVerifyDefault';

// styles
import { WrapVerifyFaceItem } from './index.styles'

const VerifyFaceItem = ({ index, faceResult }) => {

    const [isImgError, setIsImgError] = useState(false);
    const [faceImage, setFaceImage] = useState('');

    useEffect(() => {
        const { FaceLogID } = faceResult || {}
        if(!FaceLogID) {
            setFaceImage('')
            return;
        }
        setFaceImage(apiAvatarImageTransaction(FaceLogID))
    }, [faceResult]);

    return (
        <WrapVerifyFaceItem className={index !== 0 ? "again-step" : ""}>
            <div>
                <div className={`detail-item ${faceResult?.ErrorCode === 0 ? 'success' : 'failed'}`}>
                    {faceResult.ErrorCode === 0 ? "Thành công" : "Thất bại"}
                </div>
                <div className="title">Quét khuôn mặt</div>
                <div className="time-wrap">
                    {faceResult?.CreateTime ? convertDateTime(faceResult.CreateTime, "DD/MM/YYYY") : '-'}
                    <span className="time">
                        {faceResult?.CreateTime ? convertDateTime(faceResult.CreateTime, "HH:mm:ss") : ''}
                    </span>
                </div>
                <div className={`scan-face ${faceResult?.ErrorCode === 0 ? 'success' : 'failed'}`}>
                    {
                        isImgError ?
                            <AvatarVerifyDefault className="avatar-scan" />
                            :
                            <img
                                className="avatar-scan"
                                // src={faceResult?.FaceLogID ? apiAvatarImageTransaction(faceResult.FaceLogID) : ""}
                                src={faceImage}
                                alt="scan-face"
                                onError={() => setIsImgError(true)}
                            />
                    }
                </div>
            </div>
        </WrapVerifyFaceItem>
    )
}

export default VerifyFaceItem
