import React, { useRef, useState, useCallback, } from 'react'

// styles
import { WrapPopup, Header, Content, Footer } from './index.styles'

// image
import IcCloseBlack from '../../../assets/images/components/Ic_close_black'

// hooks
import { useOnClickOutside } from '../../../hook/useClickOutside.hook';
import PopupConfirmExit from './PopupConfirmExit';

const Popup = ({ title, children, extendStyles, handleClose=null, isNeedConfirmClose, isExecutedData=true }) => {
    const popupRef = useRef(null);

    const [isOpenPopup, setIsOpenPopup] = useState(false)

    const _handleClosePopup = useCallback(
        () => {
            if(!isExecutedData) return;

            if(isNeedConfirmClose){
                setIsOpenPopup(true)
                return;
            }
            handleClose && handleClose()
        },
        [isNeedConfirmClose, isExecutedData],
    )

    useOnClickOutside(popupRef, _handleClosePopup)
    
    return (
        <WrapPopup extendStyles={extendStyles} ref={popupRef}>
            <Header>
                <div className="title">{title}</div>
                <IcCloseBlack className="icon-close" onClick={_handleClosePopup} />
            </Header>
            {children}
            {
                isOpenPopup && <PopupConfirmExit onAccept={handleClose} onReject={() => setIsOpenPopup(false)} />
            }

        </WrapPopup>
    )
}

export default Popup
