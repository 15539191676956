import styled from "styled-components";

export const Bound = styled.div`
  font-family: SVN-Gilroy;
  font-style: normal;
  position: absolute;
  top: -10px;
  left: -450%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 510px;
  height: max-content;

  .container {
    padding: 30px 20px 25px 25px;

    .header {
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #15314c;
      }
      .ic_close {
        cursor: pointer;
        padding: 5px;
      }
    }

    .time_lock {
      font-weight: normal;
      font-size: 18px;
      line-height: 170%;
      color: #15314c;
      margin: 20px 0 0 0;

      span {
        color: #2d98f0;
      }
    }

    .type,
    .description,
    .lock_by {
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #15314c;
      margin: 10px 0 0 0;

      &.type {
        margin: 20px 0 0 0;
      }
    }

    .lock_by {
      span {
        color: #2d98f0;
      }
    }
  }

  .btn--unlock {
    width: 100%;
    height: 50px;
    background-color: #00b887;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 0 0 6px 6px;
    p {
      color: #ffffff;
      font-weight: bold;
    }
  }

  .triangle-right {
    position: absolute;
    top: 12px;
    right: -13px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 25px solid #ffffff;
    border-bottom: 15px solid transparent;
  }
`;
