import styled from 'styled-components'

const WrapDatePicker = styled.div`
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #1C1C1C;
    padding: 6px;

    .calendar {
        flex-shrink: 0;
    }

    .input-date {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #1C1C1C;
        border: none;
        outline: none;
        background: transparent;
        width: 66px;
        display: inline-block;
        line-height: 14px;
        height: 14px;

        &.first-input {
            text-align: right;
            margin-left: 6px;
        }
    }
`;

export {
    WrapDatePicker,
}