import React, { useReducer, useState, useRef, useEffect } from 'react'

// styles
import { WrapFilterStores } from './index.styles'

// component
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'
import BoxFilterByText from '../../../../../Control/BoxFilterByText'

// hooks
import useDebounce from '../../../../../../hook/useDebouce.hook'
import { useOnClickOutside } from '../../../../../../hook/useClickOutside.hook'
import usePrevious from '../../../../../../hook/usePrevious'

const DropDownFilterStores = ({ values, onChangeValues }) => {
    const filterStoresRef = useRef();

    const [activeFilter, setActiveFilter] = useState(false)
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [stateFilter, dispatchStateFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            sort: "",
            searchKey: ""
        }
    )

    const searchKeyDebounce = useDebounce(stateFilter.searchKey, 500)
    const prevSearchKeyDebounce = usePrevious(searchKeyDebounce)

    const _handleOpenBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }

    useOnClickOutside(filterStoresRef, () => setIsShowDropdown(false))

    useEffect(() => {
        const { sort, searchKey } = stateFilter

        const haveFilter = sort || searchKey

        if(haveFilter && !activeFilter) {
            setActiveFilter(true)
            return
        }

        if(!haveFilter && activeFilter) {
            setActiveFilter(false)
            return
        }

    }, [JSON.stringify(stateFilter), activeFilter])

    useEffect(() => {
        dispatchStateFilter({ sort: values.sort })
    }, [values.sort])

    useEffect(() => {
        dispatchStateFilter({ searchKey: values.searchKey })
    }, [values.searchKey])

    useEffect(() => {
        if(searchKeyDebounce === prevSearchKeyDebounce) return;
        if(prevSearchKeyDebounce === undefined) return;

        onChangeValues({
            ...stateFilter,
            searchKey: searchKeyDebounce
        })
    }, [searchKeyDebounce, prevSearchKeyDebounce, onChangeValues, JSON.stringify(stateFilter)])

    return (
        <WrapFilterStores ref={filterStoresRef}>
            <TitleDropdownFilter onClick={_handleOpenBox} active={activeFilter}>Cửa hàng</TitleDropdownFilter>
            {
                isShowDropdown &&
                <BoxFilterByText 
                    stateFilter={stateFilter}
                    dispatchStateFilter={dispatchStateFilter}
                    onChangeValues={onChangeValues}
                />
            }
        </WrapFilterStores>
    )
}

export default DropDownFilterStores
