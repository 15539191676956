import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";

// styles
import { ButtonStyles } from './index.style';

const types = {
  PRIMARY: "PRIMARY",
  WARNING: "WARNING",
  ERROR: "ERROR",
  OPTIONAL: "OPTIONAL",
};

const stylesFollowTypes = {
  [types.PRIMARY]: `
        color: #FFFFFF;
        background-color: #1279DA;
        border: none;
    `,
  [types.WARNING]: `
        color: #FFFFFF;
        background-color: #FF9500;
        border: none;
    `,
  [types.ERROR]: `
        color: #FFFFFF;
        background-color: #FF3B30;
        border: none;
    `,
  [types.OPTIONAL]: ``,
};

const ButtonControl = React.forwardRef(({ type, icon, children, handleClick, extendStyles, ...props }, ref) => {
  return (
    <ButtonStyles
      onClick={handleClick || (() => { })}
      specificStyles={stylesFollowTypes[type]}
      extendStyles={extendStyles}
      ref={ref}
      {...props}
    >
      <div className="icon">
        {
          icon ? icon : ""
          // <img src={icon} alt="icon" />
        }
      </div>
      <div className="text">{children}</div>
    </ButtonStyles>
  );
});

ButtonControl.defaultProps = {
  type: types.OPTIONAL,
};

ButtonControl.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  children: PropTypes.any.isRequired,
  handleClick: PropTypes.func,
  extendStyles: PropTypes.any,
};

export default ButtonControl;
