import React, { useState } from 'react'

// style
import { WrapperCollapse, WrapperPanel, WrapperContent } from './index.styles'

// image
import IcKeyBoardArrowUp from '../../../assets/images/components/IcKeyBoardArrowUp'

const Collapse = ({
    panelComponent,
    children,
    defaultExpands=false,
    // maxHeight=500,
    marginTop=20
}) => {
    const [isExpand, setIsExpand] = useState(defaultExpands);

    return (
        <WrapperCollapse>
            <WrapperPanel
                onClick={() => setIsExpand(!isExpand)}
                isExpand={isExpand}
            >
                {panelComponent}
                <IcKeyBoardArrowUp 
                    className="ic-expand"
                    onClick={() => setIsExpand(!isExpand)}
                />
            </WrapperPanel>
            <WrapperContent 
                isExpand={isExpand}
                // maxHeight={maxHeight} 
                marginTop={marginTop}
            >
                {children}
            </WrapperContent>
        </WrapperCollapse>
    )
}

export default Collapse
