import React, { Suspense, useEffect } from 'react';

import { Switch, Route, Redirect } from "react-router-dom";

//component
import LoginPage from './ui/LoginPage'
import Main from './ui/Main'
import PrivateRoute from './ui/Control/PrivateRoute';
import Notify from './ui/Notify';
import NotifyNewUser from './ui/NotifyNewUser';

//path
import * as pathNames from './assets/constants/pathName'
import ComparePage from './ui/ComparePage';

function App() {
  useEffect(() => {
    var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    if(isFirefox){
      let htmlDOM = document.getElementsByTagName('HTML')[0]
      if(htmlDOM) htmlDOM.style.overflowY = 'auto'
    }
    return () => {
      
    }
  }, [])
  return (
    <Suspense fallback={<div/>}>
      <Switch>
        <Route exact path={`/${pathNames.LOGIN}`}>
          <LoginPage />
        </Route>
        <PrivateRoute path={`/${pathNames.MAIN}`}>
          <Main />
          <Notify />
          <NotifyNewUser />
        </PrivateRoute>
        <PrivateRoute path={`/${pathNames.COMPARE_PAGE}/:idPrimary/:idSecond`}>
          <ComparePage />
          <Notify />
          <NotifyNewUser />
        </PrivateRoute>
        <Redirect to={`/${pathNames.MAIN}`} />
      </Switch>
    </Suspense>
  );
}

export default App;
