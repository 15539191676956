import React from 'react'

const IcEdit = (props) => <svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#184DA2" />
    <g clipPath="url(#clip0)">
        <path d="M14.878 11.4639H11.4633C11.2046 11.4639 10.9564 11.5667 10.7735 11.7496C10.5905 11.9326 10.4877 12.1807 10.4877 12.4395V19.2687C10.4877 19.5275 10.5905 19.7756 10.7735 19.9586C10.9564 20.1416 11.2046 20.2444 11.4633 20.2444H18.2926C18.5514 20.2444 18.7995 20.1416 18.9825 19.9586C19.1654 19.7756 19.2682 19.5275 19.2682 19.2687V15.8541" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.5365 10.7318C18.7306 10.5377 18.9938 10.4287 19.2682 10.4287C19.5427 10.4287 19.8059 10.5377 19.9999 10.7318C20.194 10.9259 20.303 11.1891 20.303 11.4635C20.303 11.7379 20.194 12.0011 19.9999 12.1952L15.3658 16.8294L13.4146 17.3172L13.9024 15.3659L18.5365 10.7318Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0">
            <rect width="11.7073" height="11.7073" fill="white" transform="translate(9.51215 9.5127)" />
        </clipPath>
    </defs>
</svg>

export default IcEdit
