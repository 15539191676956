import styled from 'styled-components'
import { WrapFilter } from '../BoxFilterByText/index.styles';

const WrapFilterByNum = styled(WrapFilter)`
    right: 0;
    left: auto;
    .field {

        &.selection-range-num {
            background: #ffffff;
            :last-child{
                border-radius: 0 0 6px 6px;
            }
        }

        .selection-filter-num {
            width: 136px;
            height: 30px;
        }

        input.num-filter {
            width: 107px;
            height: 30px;
            box-sizing: border-box;
            padding: 5px 6px 4px 6px;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #212121;
            outline: none;
            border: 1px solid #F4F4F4;
            border-radius: 6px;
            margin-left: 6px;

            ::placeholder {
                color: #BFBFBF;
            }
        }
    }
`;

export {
    WrapFilterByNum
}