import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'

// styles
import * as style from './index.style'
import { BgWrap } from '../../../Popup/index.styles'

//component
import Popup from '../../../Popup'
import ContentPopupEditChains from './ContentPopupEditChains'
import ModalControl from '../../../ModalControl'

const PopupEditChains = ({ btnRef, handleClose }) => {

    const [pos, setPos] = useState({})
    const [isNeedConfirmClose, setIsNeedConfirmClose] = useState(false)
    const [isExecutedData, setIsExecutedData] = useState(false)
    

    useLayoutEffect(() => {
        const { current } = btnRef;
        if (!current) return;
        const posOfBtn = current.getBoundingClientRect();
        // console.log(posOfBtn)
        setPos({ 
            top: posOfBtn.top + posOfBtn.height + 16,
            right: posOfBtn.right
        })
    }, [btnRef])

    const handleChangePos = useCallback(() => {
        const { current } = btnRef;
        if (!current) return;
        const posOfBtn = current.getBoundingClientRect();
        setPos({
            top: posOfBtn.top + posOfBtn.height + 16,
            right: posOfBtn.right
        })
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleChangePos)
        return () => {
            window.removeEventListener("scroll", handleChangePos)
        }
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleChangePos)
        return () => {
            window.removeEventListener("resize", handleChangePos)
        }
    }, [])

    // useEffect(() => {
    //     const html = document.querySelector("html")
    //     if (!html) return;
    //     html.style.overflowX = "hidden"
    //     return () => {
    //         html.style.overflowX = "auto"
    //     }
    // }, [])

    return (
        <ModalControl>
            <BgWrap>
                <style.Bound pos={pos}>
                    <Popup
                        title="Chỉnh sửa thông tin chuỗi"
                        handleClose={handleClose}
                        isExecutedData={isExecutedData}
                        isNeedConfirmClose={isNeedConfirmClose}
                    >
                        <ContentPopupEditChains 
                            setIsExecutedData={setIsExecutedData}
                            handleClose={handleClose} 
                            setIsNeedConfirmClose={setIsNeedConfirmClose} 
                        />
                    </Popup>
                </style.Bound>
            </BgWrap>
        </ModalControl>
    )
}

export default React.memo(PopupEditChains)
