import styled, { css, keyframes } from 'styled-components'

const moveBottom = keyframes`
    from {
        top: -300px;
    }

    to {
        top: 76px;
    }
`

const moveTop = keyframes`
    from {
        top: 76px;
    }

    to {
        top: -300px;
    }
`

const moveLeft = keyframes`
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-47px);
    }
`

const moveRight = keyframes`
    from {
        transform: translateX(-47px);
    }

    to {
        transform: translateX(100%);
    }
`

const WrapToast = styled.div`
    width: 439px;
    min-height: 89px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 17px 20px;
    transform: translateX(100%);

    &.show-toast {
        animation: ${moveLeft} 1s forwards;
    }

    &.hidden-toast {
        animation: ${moveRight} 1s forwards;
    }

    .icon {
        flex-shrink: 0;
    }

    span {
        margin-left: 17px;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #1C1C1C;
    }
`;

export {
    WrapToast
}