export const GET_REASON_FACES = 'GET_REASON_FACES'
export const GET_REASON_FACES_ERR = 'GET_REASON_FACES_ERR'

export const GET_AVATAR_HISTORY_BY_USER = "GET_AVATAR_HISTORY_BY_USER"
export const GET_AVATAR_HISTORY_BY_USER_ERR = "GET_AVATAR_HISTORY_BY_USER_ERR"

export const VERIFY_AVATAR_START = "VERIFY_AVATAR_START"
export const VERIFY_AVATAR_FINISH = "VERIFY_AVATAR_FINISH"
export const VERIFY_AVATAR = "VERIFY_AVATAR"
export const VERIFY_AVATAR_ERR = "VERIFY_AVATAR_ERR"

export const CLEAR_FACES = 'CLEAR_FACES'