import styled from "styled-components"

export const Bound = styled.div`
    font-family: SVN-Gilroy;
    font-style: normal;
    position: absolute;
    top: -10px;
    left: -360%;
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 510px;
    height: max-content;
    .container{
        padding: 30px 20px 25px 25px;
        .header {
            display: flex;
            justify-content: space-between;
            .title {
                font-weight: bold;
                font-size: 20px;
                line-height: 170%;
                letter-spacing: 0.05px;
                color: #15314c;
            }
        }
        .account-nomal{
            font-weight: normal;
            font-size: 18px;
            line-height: 170%;
            color: #15314C;
            margin: 20px 0 0 0;
        }
        .time_lock {
            font-weight: normal;
            font-size: 18px;
            line-height: 170%;
            color: #15314c;
            margin: 20px 0 0 0;
            span {
                color: #2d98f0;
                margin: 0 0 0 5px;
            }
        }
        .lock_by {
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: #15314c;
            margin: 10px 0 0 0;
            span {
                color: #2d98f0;
                margin: 0 0 0 5px;
            }
        }
    }
    .triangle-right {
        position: absolute;
        top: 12px;
        right: -13px;
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-left: 25px solid #ffffff;
        border-bottom: 15px solid transparent;
  }
`;