import Axios from "axios";

// host
import { host } from "../../../assets/constants/host";

// helper
import mapErrors from "../../../assets/helper/mapErrors";

// constant
import { CODE_SUCCESS } from "../../../assets/constants/codeResponse";
// import * as types from './types'
import { EKYC, AVATAR } from "../../../assets/constants/keys";
import * as typesFacePending from "./facesPending/types";
import * as typesEKYCsPending from "./eKYCsPending/types";

const apiGetPendingUser = `${host}/backoffice/get-pending-users`

const typeGetPending = {
    [AVATAR]: typesFacePending.GET_LIST_FACE_PENDING_USERS,
    [EKYC]: typesEKYCsPending.GET_LIST_EKYC_PENDING_USERS
}

const typeGetPendingErr = {
    [AVATAR]: typesFacePending.GET_LIST_FACE_PENDING_USERS_ERR,
    [EKYC]: typesEKYCsPending.GET_LIST_EKYC_PENDING_USERS_ERR
}

export const getPendingUsers = ({ PageNo, PageSize, Type }) => async dispatch => {
    
    const requestData = JSON.stringify({
        PageNo,
        PageSize,
        Type
    })

    try {
        const res = await Axios.post(apiGetPendingUser, requestData);
        const { data } = res;

        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const type = typeGetPending[Type]
            dispatch({
                type,
                payload: {
                    list: data.Users || [],
                    totals: data.Total || 0,
                    conditionFilter: {
                        PageNo,
                        PageSize,
                        Type
                    }
                }
            })
        }
        else {
            // console.dir(data)
            const type = typeGetPendingErr[Type]
            dispatch({
                type,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        // console.dir(err)
        const type = typeGetPendingErr[Type]
        dispatch(mapErrors(err, type))
    }

}