import styled from 'styled-components'
import * as styles from '../../../assets/constants/ui'

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex: 1;
    width: 100%;
    height: calc(100% - ${styles.MAIN_CONTENT_PADDING_TOP}px);
    justify-content: center;
    align-items: center;
    .animation-container{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    /* img{
        display:flex;
        width:180px;
        height:180px;
        margin-bottom:16px;
    } */

    .ic-no-data {
        display:flex;
        width:180px;
        height:180px;
        margin-bottom:16px;
    }

    font-family: SVN-Gilroy;
    font-style: normal;
    h1{
        font-weight: bold;
        font-size: 16px;
        line-height: 27px;
        display: flex;
        margin-bottom:7px;
        color: #222222;
    }
    p{
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        text-align: center;
        display: flex;
        color: #959595;
    }
`

export { Bound }