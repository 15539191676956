import styled from 'styled-components'


const Title = styled.div`
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #1C1C1C;
    margin-bottom: 10px;
`;

const WrapperListCondidtion = styled.div`

    & > *:not(:first-child) {
        margin-top: 10px;
    }

    .opt-reason {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #15314C;
    }
`;

export {
    Title,
    WrapperListCondidtion
}