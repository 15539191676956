import React from 'react'

const IcDoneWhite = (props) => {
    return (
        <svg {...props} width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.66667 3.33333L0 5L5 10L13.3333 1.66667L11.6667 0L5 6.66667L1.66667 3.33333Z" fill="#FFFFFF" />
        </svg>
    )
}

export default IcDoneWhite
