import styled from "styled-components";
import bg_side_menu from '../../../assets/images/background_side_menu.svg'

export const Bound = styled.div`
    display: flex;
    flex: 1;
    width: 272px;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .menu {
        width: 272px;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: url(${bg_side_menu});
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        /* .bg-side-menu{
            position: absolute;
            top: 0;
            left: 0;
        } */
        .icon-logofacepay {
            margin-top: 24px;
            width: 100%;
        }
        .navigation-routes {
            display: flex;
            flex-direction: column;
            /* margin: 0 0 0 47px; */
            margin: 66px 0 0 47px;
            flex: 1;
            .navigation-routes-link {
                height: 41px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 0 40px 0;
                text-decoration: none;
                &.active {
                    border-right: 2px solid #FF9500;
                }
                .navigation-routes-link-image {
                    margin: 0 8px 0px 0px;
                }
                .navigation-routes-link-text {
                    font-family: 'SVN-Gilroy';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 13px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.02em;
                    text-transform: uppercase;
                    color: #c6d5eb;
                    &.active {
                        color: #FF9500;                        
                    }
                }
            }
        }
        .navigation-logOut {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 0 34px 47px;
            text-transform: uppercase;
            cursor: pointer;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 13px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #c6d5eb;
            .navigation-logOut-image {
                margin: 0 8px 0 0;
            }
            .navigation-logOut-text {

            }
        }
    }
    .introduce-wee {
        width: 272px;
        height: 58px;
        background-color: #292a33;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            font-family: "SVN-Gilroy";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: rgba(255, 255, 255, 0.4);
            margin: 0 10px 0 0;
        }
    }
`;
