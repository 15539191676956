import styled from 'styled-components'

const WrapPopup = styled.div`
    position: relative;
    z-index: 12;
    height: 100%;
    ${props => props.extendStyles}
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 0px 30px;
    margin: 0 0 53px 0;
    background-color: rgba(198, 213, 235, 0.1);
    border-radius: 6px 6px 0px 0px;
    height: 64px;
    .title{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 170%;
        text-transform: uppercase;
        color: #1C1C1C;
    }
    .icon-close{
        cursor: pointer;
        width: 14px;
        height: 14px;
    }
`;

const Content = styled.div`
    
`;

const Footer = styled.div`

`

const BgWrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
`;

export {
    WrapPopup,
    Header,
    Content,
    Footer,
    BgWrap
}