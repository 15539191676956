import React from "react";

const IcMaximize = ({ colorIcon }) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.38889 1H3.05556C2.643 1 2.24733 1.16389 1.95561 1.45561C1.66389 1.74733 1.5 2.143 1.5 2.55556V4.88889M15.5 4.88889V2.55556C15.5 2.143 15.3361 1.74733 15.0444 1.45561C14.7527 1.16389 14.357 1 13.9444 1H11.6111M11.6111 15H13.9444C14.357 15 14.7527 14.8361 15.0444 14.5444C15.3361 14.2527 15.5 13.857 15.5 13.4444V11.1111M1.5 11.1111V13.4444C1.5 13.857 1.66389 14.2527 1.95561 14.5444C2.24733 14.8361 2.643 15 3.05556 15H5.38889"
      stroke={colorIcon ? colorIcon : "#184DA2"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcMaximize;
