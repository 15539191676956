import React, { Component, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as styles from "./index.style"
//component
import ButtonControl from '../../../../Control/Button'
import { styleButtonAddStore } from './index.style'
import EmptydataControl_v3 from '../../../../Control/EmptyDataControl_v3';
import TableComponent from "../../../../Control/TableComponent";
import PopupAddStore from './PopupAddStore'
import IcAdd14x14 from '../../../../../assets/images/components/IcAdd14x14'
import PopupEditStore from './PopupEditStore'
import Popup from '../../../../Control/Popup'

//template
import * as templateHeader from '../../../../Control/TableComponent/Template/store.template.header'
import * as templateContent from '../../../../Control/TableComponent/Template/store.template.content'
//data
import * as mockData from '../../../../../data/mockData'

// action
import { getListShopsByChain } from '../../../../../redux/chains/chainInfos/stores/action'
import { getAreasByChain } from '../../../../../redux/chains/chainInfos/areas/action'

// hooks
import useDidMount from '../../../../../hook/useDidMount'
import areas from '../../../../../redux/chains/chainInfos/areas/areas'

const heightStoreItem = 82;
const minPageSize = 10

const ListStore = () => {
    // let data=[]
    // const randomLength = Math.floor(Math.random() * mockData.user_login_history.length)
    // for(let i=0; i< randomLength; i++){
    //     data.push(mockData.user_login_history[Math.floor(Math.random() * mockData.user_login_history.length) + 1])
    // }
    const dispatch = useDispatch()

    const { chainDetails, stores, areas } = useSelector(state => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        stores: state.chains.chainInfos.stores,
        areas:  state.chains.chainInfos.areas
    }))

    const [data, setData] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenPopupEditStore, setIsOpenPopupEditStore] = useState(false)
    const [storeSelected, setStoreSelected] = useState(null)
    const [pageSize, setPageSize] = useState(minPageSize)

    const _handleAddStore = useCallback(
        () => {
            setIsOpen(!isOpen)
        },
        [isOpen],
    )

    const _handleClickEditStore = useCallback(
        (store) => {
            setStoreSelected(store)
            setIsOpenPopupEditStore(true)
        },
        [setIsOpenPopupEditStore, setStoreSelected],
    )
    
    useEffect(() => {
        if(!chainDetails.details?.ID) return;
        dispatch(getListShopsByChain({ ChainID: chainDetails.details.ID, PageNo: 1, PageSize: pageSize, isInitial: true }))
    }, [chainDetails.details?.ID, pageSize])

    useEffect(() => {
        // if(!areas.list.length) return; 
        // if(!stores.list.length) return
        const data = stores?.list.map(item => {
            const area = areas?.list.find(area => area.ID === item.AreaID)
            
            return ({ ...item, AreaName: area?.Name })
        })
        if(!data) data=[]
        setData(data)
    }, [JSON.stringify(stores.list), JSON.stringify(areas.list)])

    useEffect(() => {
        if(!chainDetails.details) return
        dispatch(getAreasByChain({ ChainID: chainDetails.details.ID }))
    }, [JSON.stringify(chainDetails.details)])

    const _handleChangePageSize = (height) => {
        let size = Math.floor(height / heightStoreItem)
        size++;
        if(size < minPageSize)
            size = minPageSize
        setPageSize(size)
    }
    
    return (
        <styles.Bound>
            <div className="header">
                <div className="title">danh sách cửa hàng</div>
                <ButtonControl
                    handleClick={_handleAddStore}
                    extendStyles={styleButtonAddStore}
                    children="Thêm cửa hàng mới"
                    icon={<IcAdd14x14 />}
                />
                {
                    isOpen &&
                    <PopupAddStore  handleClose={_handleAddStore}/>
                }
            </div>
            {
                data.length === 0 ?
                <div style={{ flex: 1 }}><EmptydataControl_v3 /></div>
                :
                <TableComponent 
                    data = {data}
                    templateHeader = { templateHeader }
                    templateContent = { templateContent }
                    handleEditStore={_handleClickEditStore}
                    handleChangePageSize={_handleChangePageSize}
                />
            }
            {
                isOpenPopupEditStore &&
                <div className="popup-edit-store">
                    <PopupEditStore 
                        handleClose={() => setIsOpenPopupEditStore(false)}
                        store={storeSelected}
                    />
                </div>
            }
        </styles.Bound >
    )
}

export default React.memo(ListStore)
