import React, { useCallback, useState } from 'react'

// style
import { WrapUserDuplicateItem, styleButtonCompare } from './index.style'

// image
import ic_copy from '../../../../../../../../assets/images/ic_copy.svg'

// component
import ButtonControl from '../../../../../../../Control/Button'

// constant
import { COMPARE_PAGE } from '../../../../../../../../assets/constants/pathName'

// host
import { apiAvatarImage } from '../../../../../../../../assets/constants/host'

const UserDuplicateItem = ({ data, primaryUser }) => {

    const [isErrorImg, setIsErrorImg] = useState(false)

    const _handleOnClickCompare = useCallback(() => {
        // const link = window.location.origin + "/" + COMPARE_PAGE + "/" + primaryUser.UserID + "/" + data.UserID;
        const link = window.location.origin + "/" + COMPARE_PAGE + "/" + primaryUser.ID + "/" + data.ID;
        window.open(link)
    }, [data])

    return (
        <WrapUserDuplicateItem>
            {
                isErrorImg?
                <div className="avatar-diff" />
                :
                <img 
                    className="avatar-diff" 
                    src={apiAvatarImage(data.UserID)} 
                    alt="avatar" 
                    onError={() => setIsErrorImg(true)}
                />

            }
            <div className="user-coincide-info">
                <p className="name">{data.FullName}</p>
                <div className="info">
                    <span>{data.WeeID}</span>
                    <img src={ic_copy} alt="icon-copy" />
                </div>
            </div>
            <ButtonControl
                handleClick={_handleOnClickCompare}
                extendStyles={styleButtonCompare}
                children="So sánh chi tiết"
            />
        </WrapUserDuplicateItem>
    )
}

export default UserDuplicateItem
