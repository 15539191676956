import styled from "styled-components";

export const Bound = styled.div`
  font-family: SVN-Gilroy;
  font-style: normal;
  position: absolute;
  top: -10px;
  left: -420%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0px 0px;
  width: 510px;
  height: 322px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
  .container {
    padding: 30px 20px 25px 25px;
    height: 272px;
    .header {
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #15314c;
      }
      .ic_close {
        cursor: pointer;
      }
    }

    .content {
      font-weight: normal;
      font-size: 18px;
      line-height: 170%;
      color: #15314c;
      margin: 20px 0 0 0;
    }

    input {
      width: 460px;
      height: 48px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #c6d5eb;
      border-radius: 3px;
      margin: 20px 0 0 0;
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #1C1C1C;
      &::placeholder {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: rgba(28, 28, 28, 0.2);
        border: none;
      }
    }
  }

  .block__btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    &--no {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F4F4F4;
      border-radius: 0 0 0 6px;
      p {
        color: #184DA2;
        font-size: 14px;
        font-weight: bold;
      }
    }
    &--yes {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #00B887;
      border-radius: 0 0 6px 0;
      p {
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .triangle-right {
    position: absolute;
    top: 12px;
    right: -13px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 25px solid #ffffff;
    border-bottom: 15px solid transparent;
  }
`;


