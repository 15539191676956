import styled from 'styled-components'

const StyleRow = styled.div`
    position: relative;
    width: 740px;
    height: 82px;   
    margin-top: 15px;    
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    &.filter-controls {
        height: auto;
    }    
    .dot {
        width: 16px;
        height: 16px;
        background: #2D98F0;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 0;
        .dot-selected {
            z-index: 1;
        }
    }

    .content-data {
        z-index: 1;
        background: ${props => props.enableClick ? '#FFFFFF' : 'none'};
        flex-basis: 100%;
        transition: flex-basis 250ms;
        box-sizing: border-box;
        border-radius: 6px;        
        display: grid;
        /* grid-template-columns: 65px 325px 1fr; */
        grid-template-columns: 65px 1fr 325px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        cursor: pointer;
        &.content-data-selected {
            flex-basis: calc(100% - 16px - 4px);
            border: 2px solid #2D98F0;
        }
        .avatar-user-container{
            display: flex;
            align-items: center;
            padding-left: 20px;            
            .avatar-user{
                width: 44px;
                height: 44px;
                border-radius: 50%;
                object-fit: cover;
                &.block{
                    border: 2px solid #FA8C8C;
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .info-user{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            padding-left: 10px;
            .fullname-user{
                font-weight: 600;
                font-size: 14px;
                line-height: 170%;
                color: #051321;
                margin-bottom: 4px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 300px;
            }
            .wrapper-username{
                font-weight: normal;
                font-size: 12px;
                line-height: 170%;
                color: #6F6F6F;
                display: flex;
                align-items: center;
                max-width: 300px;
                span{
                    color: #2D98F0;
                    padding-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 200px;
                }
            }
        }
        .status-container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            .email-status{
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                box-sizing: border-box;
                width: 82px;
                height: 32px;
                border-radius: 35px;
                color: #ffffff;
                margin-right: 10px;                               
            }
            .avatar-status {
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                box-sizing: border-box;
                width: 120px;
                height: 32px;
                border-radius: 35px;
                color: #ffffff;
                margin-right: 10px;               
            }
            .ekyc-status{
                display: flex;
                justify-content: center;
                align-items: center;
                width: fit-content;
                box-sizing: border-box;
                border-radius: 35px;
                color: #ffffff;
                width: 82px;
                height: 32px;                               
            }
            .accept {
                background: #00B887;
            }
            .reject {
                background: #ffffff;
                border: 1px solid #98ACC5;
                color: #98ACC5;
            }
            span {
                font-family: 'UTM Avo';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.05px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
            }
        } 
    }
    
`;

export {
    StyleRow
}