import { NETWORK_ERR, AUTHENTICATED_ERR, SYSTEM_ERR } from "../../redux/notifies/types";

const mapErrors = (errors, typeAction) => {
    console.dir(errors)
    if(!/.*_ERR/.test(typeAction)){
        if(process.env.NODE_ENV === 'development') {
            throw Error('type action error must end with "_ERR"')
        }
        return {
            type: SYSTEM_ERR
        }
    }

    const response = errors?.response;
    // console.log(response)
    if(response) {
        const { status, data } = response;

        switch(status) {
            case 403:
                return {
                    type: AUTHENTICATED_ERR
                }
            case 400:
                return {
                    type: typeAction,
                    payload: {
                        ...data
                    }
                };
            default:
                return {
                    type: SYSTEM_ERR,
                    payload: {
                        ...data
                    }
                }
        }
    }

    const request = errors?.request;
    if(request) {
        // console.log('error request', request)
        return {
            type: NETWORK_ERR
        }
    }

    return {
        type: SYSTEM_ERR
    }
}

export default mapErrors;