export const GET_EKYC_BY_USER = "GET_EKYC_BY_USER"
export const GET_EKYC_BY_USER_ERR = "GET_EKYC_BY_USER_ERR"

export const GET_REASON_EKYC = "GET_REASON_EKYC"
export const GET_REASON_EKYC_ERR = "GET_REASON_EKYC_ERR"

export const CHECK_DUPLICATE_NUMBER = "CHECK_DUPLICATE_NUMBER"
export const CHECK_DUPLICATE_NUMBER_ERR = "CHECK_DUPLICATE_NUMBER_ERR"

export const VERIFY_EKYCS_START = 'VERIFY_EKYCS_START'
export const VERIFY_EKYCS = 'VERIFY_EKYCS'
export const VERIFY_EKYCS_END = 'VERIFY_EKYCS_END'
export const VERIFY_EKYCS_ERR = 'VERIFY_EKYCS_ERR'

export const EDIT_EKYC_INFOS_START = 'EDIT_EKYC_INFOS_START'
export const EDIT_EKYC_INFOS = 'EDIT_EKYC_INFOS'
export const EDIT_EKYC_INFOS_END = 'EDIT_EKYC_INFOS_END'
export const EDIT_EKYC_INFOS_ERR = 'EDIT_EKYC_INFOS_ERR'

export const CLEAR_EKYCS = 'CLEAR_EKYCS'