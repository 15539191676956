import React, { useState, useReducer, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import * as styles from './index.style'
import { styleButtonEdit, styleButtonSave, styleButtonCompare } from './index.style'
//component
import InputText from '../../../../../../Control/InputText'
import ListConditionEKYC from './ListConditionEKYC'
import FooterDetailsEKYC from './FooterDetailsEKYC'
import UserDuplicateItem from './UserDuplicateItem'
import IcCheck from '../../../../../../../assets/images/components/IcCheck';

import * as templateCCCD from '../Template/cccdInformation.template'
import * as templateCMND from '../Template/cmndInformation.template'
import * as templatePASSPORT from '../Template/passportInformation.template'
import ButtonControl from '../../../../../../Control/Button'
import { convertDateTime } from '../../../../../../../assets/helper/tools'
//image
import avatar_verify_default from '../../../../../../../assets/images/avatar_verify_default.svg'
import IcZoomIn from '../../../../../../../assets/images/components/IcZoomIn';
import IcZoomOut from '../../../../../../../assets/images/components/IcZoomOut';
import IcXCircle from '../../../../../../../assets/images/components/IcXCircle';

// constant
import { PENDING, REPENDING, REJECT, ACCEPT } from '../../../../../../../assets/constants/faceCensorShipStatus'
import * as keys from '../../../../../../../assets/constants/keys'
// import { COMPARE_PAGE } from '../../../../../../../assets/constants/pathName'
import { apiGetImageEkyc, apiAvatarImage, apiVerifyImageHistory } from '../../../../../../../assets/constants/host'

// helper
import useDebounce from '../../../../../../../hook/useDebouce.hook'
import isDate from '../../../../../../../assets/helper/isDate';

// action
import { checkDuplicateNumber, editEKYCInfos, getEKYCByUser } from '../../../../../../../redux/userCensorship/detailsUser/eKYCs/action'

// hooks
import useDidMount from '../../../../../../../hook/useDidMount';


// const abilityData = {
//     1: {
//         number: 3,
//         note: "giấy tờ được tìm thấy có khả năng trùng với tài khoản này!",
//     },
//     2: {
//         number: 0,
//         note: "khuôn mặt được tìm thấy có khả năng trùng với tài khoản này!",
//     },

// }

var informationExtracted;

const AdvancedItemDetails = ({
    data,
    disable = true,
}) => {
    const dispatch = useDispatch()
    // const location = useLocation()

    const listReasons = useSelector(state => state.userCensorship.detailsUser.eKYCs.listReasons)

    const [isImgError, setIsImgError] = useState(false)
    const [isImgAccountError, setIsImgAccountError] = useState(false)

    const [stateCondition, dispatchCondition] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            listChecked: [],
            listCondition: {},
            isDisableCheck: false,
            status: '',
            optinalReason: ''
        }
    )
    
    const [fullcardType, setFullcardType] = useState("")
    const [shortcardType, setShortcardType] = useState("")
    const [number, setNumber] = useState("")
    const [templateInformationStyle, setTemplateInformationStyle] = useState(null)
    const [templateInformation, setTemplateInformation] = useState()
    const [stateInformationExtracted, dispatchInformationExtracted] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {}
    )
    const [stateIsInvalid, dispatchIsInvalid] = useReducer(
        (state, action) => {
            if(!Object.keys(action).length) 
                return {}
            return ({ ...state, ...action })
        },
        {

        }
    )

    useEffect(() => {
        if (!data) return;
        switch (data.Status) {
            case PENDING: {
                const listCondition =
                    Object.keys(listReasons).reduce(
                        (lst, id) =>
                            listReasons[id].IsRePending
                                ? lst
                                : { ...lst, [id]: listReasons[id] }
                        , {}
                    )
                dispatchCondition({
                    listChecked: [],
                    listCondition,
                    isDisableCheck: false,
                    status: PENDING,
                    optinalReason: ''

                })
                break;
            }
            case REPENDING: {
                dispatchCondition({
                    listChecked: [],
                    listCondition: listReasons,
                    isDisableCheck: false,
                    status: REPENDING,
                    optinalReason: ''
                })
                break;
            }
            case REJECT: {
                const listCondition =
                    data.IsFirst
                        ? Object.keys(listReasons).reduce(
                            (lst, id) =>
                                listReasons[id].IsRePending
                                    ? lst
                                    : { ...lst, [id]: listReasons[id] }
                            , {}
                        )
                        : listReasons
                const listChecked = Object.keys(listCondition).filter(
                    item => data.Reasons.includes(item)
                )
                // debugger
                dispatchCondition({
                    listChecked,
                    listCondition,
                    isDisableCheck: true,
                    status: REJECT,
                    optinalReason: data.OptionalReason,
                })
                break;
            }
            case ACCEPT: {
                const listCondition =
                    data.IsFirst
                        ? Object.keys(listReasons).reduce(
                            (lst, id) =>
                                listReasons[id].IsRePending
                                    ? lst
                                    : { ...lst, [id]: listReasons[id] }
                            , {}
                        )
                        : listReasons
                const listChecked = Object.keys(listCondition)
                // debugger
                dispatchCondition({
                    listChecked,
                    listCondition,
                    isDisableCheck: true,
                    status: ACCEPT,
                    optinalReason: ''
                })
                break;
            }
            default:
                debugger;
                break;
        }
    }, [data, listReasons])

    useEffect(() => {
        if(!data) return false
        let fullcardType = ''
        let shortcardType = ''
        let number = ''
        let templateInformationStyle = null
        let templateInformation;
        let informationExtracted;
        switch (data.Type) {
            case 'CCCD': {
                fullcardType = 'căn cước công dân'
                shortcardType = 'căn cước'
                templateInformationStyle = templateCCCD.INFORMATION_STYLED
                templateInformation = templateCCCD
                number = data?.FrontInfo?.Number
                informationExtracted = {
                    ID: data?.ID,
                    EKYCType: data?.Type,
                    CardNumber: data?.FrontInfo?.Number,
                    Name: data?.FrontInfo?.FullName,
                    Birthday: data?.FrontInfo?.Birthday,
                    Sex: data?.FrontInfo?.Gender,
                    HomeTown: data?.FrontInfo?.HomeTown,
                    IssueDate: data?.BackInfo?.Valid,
                    Expire: data?.FrontInfo?.Expire,
                    Address: data?.FrontInfo?.Address
                }
            }
                break;
            case 'CMND': {
                fullcardType = 'chứng minh nhân dân'
                shortcardType = 'chứng minh nhân dân'
                templateInformationStyle = templateCMND.INFORMATION_STYLED
                templateInformation = templateCMND
                number = data?.FrontInfo?.Number
                informationExtracted = {
                    ID: data?.ID,
                    EKYCType: data?.Type,
                    CardNumber: data?.FrontInfo?.Number,
                    Name: data?.FrontInfo?.FullName,
                    Birthday: data?.FrontInfo?.Birthday,
                    Sex: data?.FrontInfo?.Gender,
                    HomeTown: data?.FrontInfo?.HomeTown,
                    IssueDate: data?.BackInfo?.Valid,
                    IssuedPlace: data?.BackInfo?.IssuedPlace,
                    Address: data?.FrontInfo?.Address
                }
            }
                break;
            case 'PASSPORT': {
                fullcardType = 'Passport'
                shortcardType = 'Passport'
                templateInformationStyle = templatePASSPORT.INFORMATION_STYLED
                templateInformation = templatePASSPORT
                number = data?.PassportInfo?.Number
                informationExtracted = {
                    ID: data?.ID,
                    EKYCType: data?.Type,
                    CardNumber: data?.PassportInfo?.Number,
                    Name: data?.PassportInfo?.FullName,
                    Birthday: data?.PassportInfo?.Birthday,
                    Sex: data?.PassportInfo?.Gender,
                    Nationality: data?.PassportInfo?.Nationality,
                    IssueDate: data?.BackInfo?.Valid,
                    Expire: data?.PassportInfo?.Expire,
                    PlaceOfBirth: data?.PassportInfo?.PlaceOfBirth
                }
            }
                break;
            default:
                break;
        }
        setFullcardType(fullcardType)
        setShortcardType(shortcardType)
        setNumber(number)
        setTemplateInformationStyle(templateInformationStyle)
        setTemplateInformation(templateInformation)
        dispatchInformationExtracted(informationExtracted)
        dispatchIsInvalid({})

    }, [data.ID])


    const [isDisabled, setIsDisabled] = useState(disable)
    const [idCardNumber, setIdCardNumber] = useState(number)
    const idCardNumberDebouce = useDebounce(idCardNumber, 1000)

    useDidMount(() => {
        if(!data) return false
        if(!number) return false
        dispatch(checkDuplicateNumber({ Number: number, UserID: data.UserID }, data.ID))
        return true
    }, [number])

    useEffect(() => {
        if (!data) return
        if (!idCardNumberDebouce) return;
        // console.log(data.ID)
        // informationExtracted.CardNumber = idCardNumberDebouce
        dispatchInformationExtracted({ CardNumber: idCardNumberDebouce })
        dispatch(checkDuplicateNumber({ Number: idCardNumberDebouce, UserID: data.UserID }, data.ID))
    }, [idCardNumberDebouce, JSON.stringify(data)])

    const _handleClickEdit = () => {
        setIsDisabled(false)
    }

    // const _handleOnChange = () => {
    const _handleOnChangeIdCardNum = useCallback(
        (_, val) => {
            // console.log(val)
            setIdCardNumber(val)
        },
        [],
    )

    const _onGetListSuccess = useCallback(
        () => {
            dispatch(checkDuplicateNumber({ Number: number, UserID: data.UserID }, data.ID))
        },
        [data, number],
    )

    const _onEditSuccess = useCallback(
        () => {
            setIsDisabled(true)
            dispatch(getEKYCByUser({ UserID: data.UserID }, _onGetListSuccess))
            
        },
        [data, _onGetListSuccess],
    )

    const _handleClickSave = () => {
        if(!stateInformationExtracted) return;
        if(Object.values(stateIsInvalid).some(item => item)) return;
        // console.log(informationExtracted)
        dispatch(editEKYCInfos(stateInformationExtracted, _onEditSuccess))
    }

    // const _handleOnClickCompare = () => {
    //     const link = window.location.origin + "/" + COMPARE_PAGE + "/" + data.ID;
    //     window.open(link)
    // }

    // const onImageErr = () => {
    //     let image = document.getElementById(`user-id`)
    //     if (image) image.src = avatar_verify_default
    // }

    const onChangeInformationExtracted = (key, value, type) => {
        switch (key) {
            case keys.FULLNAME:
                // informationExtracted.Name = value
                dispatchInformationExtracted({ Name: value })
                break;
            case keys.BIRTHDAY:
                // informationExtracted.Birthday = value
                dispatchInformationExtracted({ Birthday: value })
                dispatchIsInvalid({ Birthday: !isDate(value) })
                break;
            case keys.GENDER:
                // informationExtracted.Sex = value
                dispatchInformationExtracted({ Sex: value })
                break;
            case keys.HOMETOWN:
                // informationExtracted.HomeTown = value
                dispatchInformationExtracted({ HomeTown: value })
                break;
            case keys.VALID_DATE:
                // informationExtracted.IssueDate = value
                dispatchInformationExtracted({ IssueDate: value })
                dispatchIsInvalid({ IssueDate: !isDate(value) })
                break;
            case keys.EXPIRE_DATE:
                // informationExtracted.Expire = value
                dispatchInformationExtracted({ Expire: value })
                dispatchIsInvalid({ Expire: !isDate(value) })
                break;
            case keys.ADDRESS:
                // informationExtracted.Address = value
                dispatchInformationExtracted({ Address: value })
                break;
            case keys.NATIONALITY:
                // informationExtracted.Nationality = value
                dispatchInformationExtracted({ Nationality: value })
                break;
            case keys.PLACEOFBIRTH:
                // informationExtracted.PlaceOfBirth = value
                dispatchInformationExtracted({ PlaceOfBirth: value })
                break;
            case keys.ISSUEDPLACE:
                // informationExtracted.IssuedPlace = value
                dispatchInformationExtracted({ IssuedPlace: value })
                break
            default:
                break;
        }
    }

    if (!templateInformation) return null
    return (
        <styles.Bound templateInformationStyle={templateInformationStyle}>
            <div className='gttt-image-container'>
                <div>
                    <div className='title-container'><p className='p-title'>Mặt trước {fullcardType}</p></div>
                    {/* <img
                        src={
                            data?.Type === "CMND" || data?.Type === "CCCD" ?
                                apiGetImageEkyc(data.FrontIdentityID)
                                :
                                apiGetImageEkyc(data.PassportIdentityID)
                        }
                        alt='front'
                    /> */}
                    <div className="img-identify">
                        <TransformWrapper
                            defaultScale={1}
                            defaultPositionX={200}
                            defaultPositionY={100}
                        >
                            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                <React.Fragment>
                                    <div className="tools">
                                        {/* <button onClick={zoomIn}>+</button>
                                        <button onClick={zoomOut}>-</button>
                                        <button onClick={resetTransform}>x</button> */}
                                        <div className="btn-support-zoom" onClick={zoomIn}>
                                            <IcZoomIn />
                                        </div>
                                        <div className="btn-support-zoom" onClick={zoomOut}>
                                            <IcZoomOut />
                                        </div>
                                        <div className="btn-support-zoom" onClick={resetTransform}>
                                            <IcXCircle />
                                        </div>
                                    </div>
                                    <TransformComponent>
                                        <img 
                                            src={
                                                data?.Type === "CMND" || data?.Type === "CCCD" ?
                                                    apiGetImageEkyc(data.FrontIdentityID)
                                                    :
                                                    apiGetImageEkyc(data.PassportIdentityID)
                                            } 
                                            alt="front" 
                                        />
                                    </TransformComponent>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                </div>
                {
                    (data.Type === "CCCD" || data.Type === "CMND") &&
                    <div>
                        <div className='title-container'><p className='p-title'>Mặt sau {fullcardType}</p></div>
                        {/* <img
                            src={
                                data?.Type === "CMND" || data?.Type === "CCCD" ? apiGetImageEkyc(data.BackIdentityID) : ""
                            }
                            alt='back'
                        /> */}
                        <div className="img-identify">
                            <TransformWrapper
                                defaultScale={1}
                                defaultPositionX={200}
                                defaultPositionY={100}
                            >
                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                    <React.Fragment>
                                        <div className="tools">
                                            {/* <button onClick={zoomIn}>+</button>
                                            <button onClick={zoomOut}>-</button>
                                            <button onClick={resetTransform}>x</button> */}
                                            <div className="btn-support-zoom" onClick={zoomIn}>
                                                <IcZoomIn />
                                            </div>
                                            <div className="btn-support-zoom" onClick={zoomOut}>
                                                <IcZoomOut />
                                            </div>
                                            <div className="btn-support-zoom" onClick={resetTransform}>
                                                <IcXCircle />
                                            </div>
                                        </div>
                                        <TransformComponent>
                                            <img 
                                                src={apiGetImageEkyc(data.BackIdentityID)} 
                                                alt="back" 
                                            />
                                        </TransformComponent>
                                    </React.Fragment>
                                )}
                            </TransformWrapper>
                        </div>
                    </div>
                }
            </div>
            <div className='gttt-information-container'>
                <div className='card-num'>
                    <div className='title-container'><p className='p-title'>Số {shortcardType}</p></div>
                    <InputText
                        defaultValue={number}
                        heightInput={"80px"}
                        isCardNumber={true}
                        disabled={isDisabled}
                        // onChange={_handleOnChange}
                        onChange={_handleOnChangeIdCardNum}
                    />
                </div>
                <div className='export-info'>
                    <div className='title-container'>
                        <p className='p-title'>Thông tin trích xuất</p>
                    </div>
                    <div className='info-bound'>
                        {
                            templateInformation.renderItem(data, isDisabled, onChangeInformationExtracted, stateIsInvalid)
                        }
                    </div>
                    <div className='manage-control'>
                        <div className="admin-confirm">
                            {
                                data?.AdminID !== '000000000000000000000000' &&
                                <React.Fragment>
                                    <div className="admin">
                                        Người kiểm tra thông tin: <span>@{data.AdminUsername}</span>
                                    </div>
                                    <div className="admin">
                                        Lúc:<p>{convertDateTime(data.VerifiedAt, "DD/MM/YYYY, HH:mm")}</p>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                        {
                            data?.Status === "ACCEPT" || data?.Status === "REJECT"?
                                ""
                                :
                                isDisabled ?
                                    <ButtonControl
                                        handleClick={_handleClickEdit}
                                        extendStyles={styleButtonEdit}
                                        children="Chỉnh sửa"
                                    />
                                    :
                                    <ButtonControl
                                        handleClick={_handleClickSave}
                                        extendStyles={styleButtonSave}
                                        icon={<IcCheck />}
                                        children="Lưu lại"
                                    />
                        }
                    </div>
                </div>
                <div className='image-verify'>
                    <div className='title-container'><p className='p-title'>Hình ảnh</p></div>
                    <div className='image-container'>
                        <div className={data.IsMatchedFace === true ? "border-image" :"border-image error"}>
                            {
                                isImgError ?
                                    <img src={avatar_verify_default} alt="default" />
                                    :
                                    <img
                                        src={
                                            data?.Type === "CMND" || data?.Type === "CCCD" ?
                                                (`data:image/jpeg;base64,${data?.FrontInfo?.Avatar}`)
                                                :
                                                (`data:image/jpeg;base64,${data?.PassportInfo?.Avatar}`)
                                        }
                                        alt='gttt image'
                                        onError={() => setIsImgError(true)}
                                    />
                            }
                        </div>
                        <p>Hình cắt từ giấy tờ</p>
                    </div>
                    <div className='image-container'>
                        <div>
                            {
                                isImgAccountError ?
                                    <img src={avatar_verify_default} alt="default" />
                                    :
                                    <img
                                        src={
                                            data?.UserID ? apiAvatarImage(data?.UserID) : ""
                                        }
                                        alt='verify image'
                                        onError={() => setIsImgAccountError(true)}
                                    />
                            }
                        </div>
                        <p>Hình đăng ký tài khoản</p>
                    </div>
                </div>
            </div>
            <div className='gttt-controller-container'>
                <div className='item-existed'>
                    <div className='title-container'><p className='p-title'>Khả năng trùng</p></div>
                    <div className="ability-container" >
                        {/* {
                            Object.values(abilityData).map((item, index) => {
                                return (
                                    <div className="ability" key={index}>
                                        <div
                                            className={item.number && item.number > 0 ? "circle active" : "circle"}
                                            onClick={_handleOnClickAbility}
                                        >
                                            {item.number}
                                        </div>
                                        <p className="note">{item.note}</p>
                                    </div>
                                )
                            })
                        } */}
                        {
                            !!data.duplicatesNumber && !!data.duplicatesNumber.length ?
                                data.duplicatesNumber.map((item, key) => (
                                    <UserDuplicateItem key={key} data={item} primaryUser={data} />
                                ))
                                :
                                <div className="empty-duplicate">Không trùng với hệ thống!</div>
                        }

                        {/* <div className="user-coincide">
                            <img className="avatar-diff" src={"https://picsum.photos/200"} alt="avatar" />
                            <div className="user-coincide-info">
                                <p className="name">Khánh Hoàn</p>
                                <div className="info">
                                    <span>848298759819833</span>
                                    <img src={ic_copy} alt="icon-copy" />
                                </div>
                            </div>
                        </div> */}
                        {/* <ButtonControl
                            handleClick={_handleOnClickCompare}
                            extendStyles={styleButtonCompare}
                            children="So sánh chi tiết"
                        /> */}

                    </div>
                </div>
                <div className='reason'>
                    <div className='title-container'>
                        <p className='p-title'>
                        {
                            data.Status === REJECT ?
                            "Lý do từ chối:" 
                            :
                            data.Status === ACCEPT ?
                            "Hình ảnh đáp ứng các điều kiện sau:"
                            :
                            "Hình ảnh phải đáp ứng các điều kiện sau:"
                        }
                        </p>
                    </div>
                    <ListConditionEKYC
                        data={data}
                        stateCondition={stateCondition}
                        dispatchCondition={dispatchCondition}
                    />
                    <FooterDetailsEKYC
                        data={data}
                        stateCondition={stateCondition}
                        setIsDisabledEdit={setIsDisabled}
                    />
                </div>
            </div>
        </styles.Bound>
    )
}

export default React.memo(AdvancedItemDetails)
