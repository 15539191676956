import React, { useCallback, useRef } from 'react'
import ButtonControl from '../../Button'
//style
import * as style from './index.style'
//component
import { styleButtonYes, styleButtonNo } from './index.style'
import BgArrowRight from '../../../../assets/images/components/BgArrowRight'
import { WrapOverlay } from '../../HeaderComponent/ChainsMainBoardHeader/PopupEditChains/ContentPopupEditChains/PopupConfirmDeleteChain/index.styles'

const PopupConfirmExit = ({ onAccept, onReject }) => {
    const popupRef = useRef(null)

    const _handleClickConfirm = () => {
        onAccept()
    }

    const _handleClickUnConfirm = () => {
        onReject()
    }

    const _handleClickPopup = useCallback((e) => {
        if(!popupRef.current.contains(e.target)){
            onReject()
        }
    }, [onReject])

    return (
        <WrapOverlay onClick={_handleClickPopup}>
            <style.Bound ref={popupRef}>
                <div className="icon-arrow">
                    <BgArrowRight />
                </div>
                <div className="title-popup">Bạn có chắc muốn thoát</div>
                <div className="content-popup">
                    Dữ liệu mới chưa được cập nhật. Bạn có chắc chắn muốn thoát.
                </div>
                <div className="button-wrapper">
                    <ButtonControl
                        extendStyles={styleButtonYes}
                        children="Có"
                        handleClick={_handleClickConfirm}
                    />
                    <ButtonControl
                        extendStyles={styleButtonNo}
                        children="Không"
                        handleClick={_handleClickUnConfirm}
                    />
                </div>

            </style.Bound>
        </WrapOverlay>
    )
}

export default PopupConfirmExit
