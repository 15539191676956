import styled from 'styled-components'

const WrapPopup = styled.div`
    /* position: absolute;
    top: 70px;
    right: 0px; */
    width: 830px;
    height: 636px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #C6D5EB;
    z-index: 9999;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
`;

export {
    WrapPopup
}