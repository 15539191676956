import styled, { css } from "styled-components";

const Bound = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 58px;
    .title{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #1C1C1C;
    }


`
const styleButtonAddChain = css`
    width: 196px;
    height: 50px;
    background: #184DA2;
    border: 1px solid #184DA2;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 28px;
    .icon{
        width: 14px;
        height: 14px;
        position: absolute;
        top: 15px;
        left: 28px;

    }

`
export { Bound, styleButtonAddChain }
