import React from 'react'

const IcSave = (props) => {
    return (
        <svg {...props} width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.44444 17.7778V11.1111H15.5556V17.7778H17.7778V5.35556L14.6444 2.22222H2.22222V17.7778H4.44444ZM1.11111 0H15.5556L20 4.44444V18.8889C20 19.2 19.8926 19.463 19.6778 19.6778C19.463 19.8926 19.2 20 18.8889 20H1.11111C0.799998 20 0.537038 19.8926 0.322222 19.6778C0.107406 19.463 0 19.2 0 18.8889V1.11111C0 0.799998 0.107406 0.537038 0.322222 0.322222C0.537038 0.107406 0.799998 0 1.11111 0ZM6.66667 13.3333V17.7778H13.3333V13.3333H6.66667Z" fill="white" />
        </svg>
    )
}

export default IcSave
