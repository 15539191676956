import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { WrapperFooter, Button } from '../../../../PicturesCensorship/PictureCensorshipItem/AdvancedItemDetails/FooterDetails/index.style'

// component
import StatusFace from '../../../../PicturesCensorship/PictureCensorshipItem/SimpleItemDetails/StatusFace'
import FillReasonReject from '../../../../PicturesCensorship/PictureCensorshipItem/AdvancedItemDetails/FooterDetails/FillReasonReject'

// constant
import { ACCEPT, REJECT } from '../../../../../../../../assets/constants/faceCensorShipStatus'

// image
import IcCloseWhite from '../../../../../../../../assets/images/components/IcCloseWhite'
import IcDoneWhite from '../../../../../../../../assets/images/components/IcDoneWhite'

// action
import { verifyEKYCs, getEKYCByUser } from '../../../../../../../../redux/userCensorship/detailsUser/eKYCs/action'

// helper
import { convertDateTime } from '../../../../../../../../assets/helper/tools'
import { getListInfoUsers } from '../../../../../../../../redux/listInfoUsers/action'

const FooterDetailsEKYC = ({ data, stateCondition, setIsDisabledEdit }) => {

    const dispatch = useDispatch()

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [isCheckedAllCondition, setIsCheckedAllCondition] = useState(false)
    const [isNotDuplicateIdCard, setIsNotDuplicateIdCard] = useState(false)

    const { pageNumber } = useSelector(state => ({
        pageNumber: state.listInfoUsers.condFilters.PageNumber,
    }))

    const _callbackRejectSuccess = useCallback(
        () => {
            setIsDisabledEdit(true)
            dispatch(getEKYCByUser({ UserID: data.UserID }))
            dispatch(getListInfoUsers({ PageNumber: pageNumber, PageSize: 8 }))
        },
        [data, pageNumber],
    )

    const _handleClickReject = useCallback(
        () => {
            if (!isCheckedAllCondition) {
                // setIsShowPopup(false)
                const { UserID, ID } = data;
                const reasonNotPassed = Object.values(stateCondition.listCondition)
                    .filter(item => !stateCondition.listChecked.includes(item.ID))
                    .map(item => item.ID)
                const params = {
                    UserID,
                    ID,
                    Status: REJECT,
                    OptionalReason: '',
                    Reasons: reasonNotPassed
                }
                // console.log(params)
                // return
                dispatch(verifyEKYCs(params, _callbackRejectSuccess))
                return
            }
            setIsShowPopup(!isShowPopup)
        },
        [isShowPopup, isCheckedAllCondition, data, stateCondition],
    )

    const _callbackAcceptSuccess = useCallback(() => {
        setIsDisabledEdit(true)
        dispatch(getEKYCByUser({ UserID: data.UserID }))
        dispatch(getListInfoUsers({ PageNumber: pageNumber, PageSize: 8 }))
    }, [data, pageNumber])

    const _handleClickAccept = useCallback(
        () => {
            if (!isCheckedAllCondition || !isNotDuplicateIdCard) {
                return
            }

            const { UserID, ID } = data;
            const reasonNotPassed = Object.values(stateCondition.listCondition)
                .filter(item => !stateCondition.listChecked.includes(item.ID))
                .map(item => item.ID)
            const params = {
                UserID,
                ID,
                Status: ACCEPT,
                OptionalReason: '',
                Reasons: reasonNotPassed
            }
            // console.log(params)
            // return
            dispatch(verifyEKYCs(params, _callbackAcceptSuccess))

        },
        [isCheckedAllCondition, data, stateCondition, isNotDuplicateIdCard]
    )

    const _handleRejectAndFillReason = useCallback(
        (optionalReason) => {
            const { UserID, ID } = data;
            const params = {
                UserID,
                ID,
                Status: REJECT,
                OptionalReason: optionalReason,
                Reasons: []
            }
            // console.log(params)
            // return;
            dispatch(verifyEKYCs(params, _callbackRejectSuccess))
        },
        [data],
    )

    useEffect(() => {
        setIsNotDuplicateIdCard(!data.duplicatesNumber || data.duplicatesNumber.length === 0)
    }, [data])

    useEffect(() => {
        const isCheckedAllCondition =
            Object.keys(stateCondition.listCondition).length === stateCondition.listChecked.length
        setIsCheckedAllCondition(isCheckedAllCondition)
        if (!isCheckedAllCondition) {
            setIsShowPopup(false)
            return;
        }
    }, [stateCondition])

    return (
        <WrapperFooter>
            {
                [ACCEPT, REJECT].includes(data.Status)
                    ? <>
                        <StatusFace status={data.Status} />
                        <div className="approved-infos">
                            <div>Từ: @{data.AdminUsername}</div>
                            <div>Lúc: {convertDateTime(data.VerifiedAt, 'DD/MM/YYYY HH:mm')}</div>
                        </div>
                    </>
                    :
                    <>
                        <div className="wrapper-btn-reject">
                            <Button
                                border="1px solid #FA8C8C"
                                bg="#FA8C8C"
                                isDecrease={isCheckedAllCondition}
                                onClick={_handleClickReject}
                            >
                                <IcCloseWhite />
                                <span>Từ chối</span>
                            </Button>
                            {
                                isCheckedAllCondition &&
                                isShowPopup &&
                                <FillReasonReject
                                    onClick={_handleRejectAndFillReason}
                                />
                            }
                        </div>
                        <Button
                            border="none"
                            bg={isCheckedAllCondition && isNotDuplicateIdCard ? "#00B887" : "rgba(28, 28, 28, 0.2)"}
                            isIncrease={isCheckedAllCondition}
                            onClick={_handleClickAccept}
                        >
                            <IcDoneWhite />
                            <span>Duyệt hình</span>
                        </Button>
                    </>
            }
        </WrapperFooter>
    )
}

export default FooterDetailsEKYC
