import React from 'react'

const IcCheckCircle = (props) => {
    return (
        <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="18" height="18" rx="9" fill="#184DA2"/>
            <path d="M6 9L9 12" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 12L13 7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="0.5" y="0.5" width="18" height="18" rx="9" stroke="#184DA2"/>
        </svg>

    )
}

export default IcCheckCircle
