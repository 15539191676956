
export const GET_CHAIN_DETAILS = "GET_CHAIN_DETAILS"
export const GET_CHAIN_DETAILS_ERR = "GET_CHAIN_DETAILS_ERR"

export const DEL_CHAIN_BY_ID_START = 'DEL_CHAIN_BY_ID_START'
export const DEL_CHAIN_BY_ID_FINISH = 'DEL_CHAIN_BY_ID_FINISH'
export const DEL_CHAIN_BY_ID = 'DEL_CHAIN_BY_ID'
export const DEL_CHAIN_BY_ID_ERR = 'DEL_CHAIN_BY_ID_ERR'

export const EDIT_CHAIN_START = "EDIT_CHAIN_START"
export const EDIT_CHAIN_FINISH = "EDIT_CHAIN_FINISH"
export const EDIT_CHAIN = "EDIT_CHAIN"
export const EDIT_CHAIN_ERR = "EDIT_CHAIN_ERR"

export const RESET_CHAIN_DETAIL = "RESET_CHAIN_DETAIL"