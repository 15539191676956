import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './index.style'

//component
import TableHeader from './TableHeader'
import TableContent from './TableContent'

const TableComponent = (props) => {
    const { data, templateHeader, templateContent, typeView, handleEditStore, handleChangePageSize } = props

    return (
        <styles.Bound>
            {
                templateHeader &&
                <TableHeader template = { templateHeader }/>
            }
            <TableContent data = { data } template = { templateContent } typeView = {typeView} 
                handleEditStore={handleEditStore}
                handleChangePageSize={handleChangePageSize} />
        </styles.Bound>
    )
}

TableComponent.propTypes = {
    data: PropTypes.array.isRequired,
    templateHeader: PropTypes.any,
    templateContent: PropTypes.any.isRequired
}

export default React.memo(TableComponent)
