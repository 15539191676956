import Axios from "axios";

// host
import { host } from "../../../assets/constants/host";

// helper
import mapErrors from "../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../assets/constants/codeResponse";

const apiGetListChains = `${host}/backoffice/get-chains`
const apiAddChain = `${host}/backoffice/add-chain`

export const getListChains = ({ PageNo, PageSize, Filter }) => async dispatch => {
    const dataRequest = JSON.stringify({
        PageNo, 
        PageSize, 
        Filter
    })

    try {
        const res = await Axios.post(apiGetListChains, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS
        if(isSuccess){
            dispatch({
                type: types.GET_LIST_CHAINS,
                payload: {
                    list: data.Chains || [],
                    total: data.Total,
                    filter: {
                        keyword: Filter || ""
                    }
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_CHAINS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_LIST_CHAINS_ERR))
    }
}

export const addChain = ({ 
    Name, 
    Address, 
    PhoneNumber, 
    Category, 
    Email, 
    RepPosition, 
    Logo, 
    TaxNumber, 
    RepName 
}, callbackSuccess) => async (dispatch, getState) => {
    const store = getState()
    const { isLoading } = store.chains.listChains
    if(isLoading) return;

    dispatch({ type: types.ADD_CHAIN_START })
    const dataRequest = JSON.stringify({
        Name, 
        Address, 
        PhoneNumber, 
        Category, 
        Email, 
        RepPosition, 
        Logo, 
        TaxNumber, 
        RepName
    })

    try {
        const res = await Axios.post(apiAddChain, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.ADD_CHAIN
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ADD_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
        dispatch({ type: types.ADD_CHAIN_FINISH })
    }
    catch(err){
        dispatch(mapErrors(err, types.ADD_CHAIN_ERR))
        dispatch({ type: types.ADD_CHAIN_FINISH })
    }
}

export const resetListChain = () => async dispatch => {
    dispatch({
        type: types.RESET_LIST_CHAIN,
    })
}
