import React from "react";

//constant
import * as pathNames from '../../../assets/constants/pathName'
//images
import IcMonitor from "../../../assets/images/components/IcMonitor";
import IcUserCheck from "../../../assets/images/components/IcUserCheck";
import IcDollar from "../../../assets/images/components/IcDollar";
import IcUserPlus from "../../../assets/images/components/IcUserPlus";
import IcServer from "../../../assets/images/components/IcServer";
import IcSetting from "../../../assets/images/components/IcSetting";
import IcCodeSanbox from "../../../assets/images/components/IcCodeSanbox";
import IcCompass from "../../../assets/images/components/IcCompass";
import IcLog from "../../../assets/images/components/IcLog";


export const menuData = [
    // {
    //     path: `/${pathNames.MAIN}/${pathNames.HOME}`,
    //     label: "side_menu.home",
    //     icon: (props) => <IcMonitor {...props} />,
    // },
    {
        path: `/${pathNames.MAIN}/${pathNames.USER_CENSORSHIP}`,
        label: "side_menu.user_censorship",
        icon: (props) => <IcUserCheck {...props} />,
    },
    {
        path: `/${pathNames.MAIN}/${pathNames.USERS}`,
        label: "side_menu.users",
        icon: (props) => <IcUserPlus {...props} />,
    },
    {
        path: `/${pathNames.MAIN}/${pathNames.TRANSACTIONS}`,
        label: "side_menu.transactions",
        icon: (props) => <IcDollar {...props} />,
    },
    {
        path: `/${pathNames.MAIN}/${pathNames.CHAINS}`,
        label: "side_menu.chains",
        icon: (props) => <IcServer {...props} />,
    },
    // {
    //     path: `/${pathNames.MAIN}/${pathNames.MPOS}`,
    //     label: "side_menu.mpos",
    //     icon: (props) => <IcCodeSanbox {...props} />,
    // },
    // {
    //     path: `/${pathNames.MAIN}/${pathNames.VERSION_RELEASED}`,
    //     label: "side_menu.version_released",
    //     icon: (props) => <IcCompass {...props} />,
    // },
    // {
    //     path: `/${pathNames.MAIN}/${pathNames.SYSTEM_LOG}`,
    //     label: "side_menu.system_log",
    //     icon: (props) => <IcLog {...props} />,
    // },
    // {
    //     path: `/${pathNames.MAIN}/${pathNames.SETTING}`,
    //     label: "side_menu.setting",
    //     icon: (props) => <IcSetting {...props} />,
    // },
]