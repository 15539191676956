import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
// import IcTotal from "../../../../assets/images/components/IcTotal";
import PropTypes from "prop-types";

// style
import * as styles from "./index.style";

const HeaderMenuInformationBoard = ({ dataMenu, callBackClickNavLink }) => {
  const { t } = useTranslation('common')
  const [idNav, setIdNav] = useState(0);

  const _toggleLineActive = (idNav) => {
    const lineActive = document.getElementById("line-active-inforBoard-id");
    const item = document.getElementById("itemMenu-inforBoard-id-" + idNav);
    if (lineActive && item) {
      lineActive.style.left = item.offsetLeft + "px";
      lineActive.style.width = item.offsetWidth + 5 + "px";
    }
  };

  useEffect(() => {
    _toggleLineActive(idNav);
    callBackClickNavLink(dataMenu[idNav]);
  }, [idNav, callBackClickNavLink, dataMenu]);

  const _renderDataMenu = useCallback(
    (data) => {
      return data.map((item, index) => {
        return (
          <styles.ItemMenu
            key={index}
            className="itemMenu"
            id={`itemMenu-inforBoard-id-${index}`}
            width={item.width}
            onClick={() => {
              setIdNav(index);
              callBackClickNavLink(item);
            }}
          >
            <span className="icon">
              {item.icon({
                active: idNav === index ? true : false,
              })}
            </span>
            <styles.Label
              className="label"
              active={idNav === index ? true : false}
              colorGradient={item.color_gradient_label}
            >
              {t(item.label)}
            </styles.Label>
          </styles.ItemMenu>
        );
      });
    },
    [idNav, callBackClickNavLink]
  );

  return (
    <styles.Bound>
      <div className="menu">
        {_renderDataMenu(dataMenu)}
        <div
          id="line-active-inforBoard-id"
          className="line-active"
          style={{
            backgroundImage: dataMenu[idNav].color_line,
          }}
        />
      </div>
    </styles.Bound>
  );
};

HeaderMenuInformationBoard.propTypes = {
  dataMenu: PropTypes.array.isRequired,
  callBackClickNavLink: PropTypes.func,
};

export default React.memo(HeaderMenuInformationBoard);
