import * as types from "./types";

const intitialState = {
    list: [],
    isLoadingAddArea: false
};

const areas = (state = intitialState, action) => {
  switch (action.type) {
    case types.ADD_AREA_TO_CHAIN_START: {
      return {
        ...state,
        isLoadingAddArea: true
      }
    }
    case types.ADD_AREA_TO_CHAIN_END: {
      return {
        ...state,
        isLoadingAddArea: false
      }
    }
    case types.GET_AREAS_BY_CHAIN: {
        return {
            ...state,
            ...action.payload
        }
    }
    default:
      return state;
  }
};

export default areas;
