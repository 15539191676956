import Axios from 'axios'

// host
import { host } from '../../../../assets/constants/host'

// helper
import mapErrors from '../../../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../../../assets/constants/codeResponse'
import { AVATAR } from '../../../../assets/constants/keys'

export const apiGetReasons = `${host}/backoffice/get-reasons`
export const apiGetAvatarHistoryByUser = `${host}/backoffice/get-avatar-history`
export const apiVerifyAvatar = `${host}/backoffice/verify-avatar`

export const getReasonsFace = () => async dispatch => {
    const dataRequest = JSON.stringify({
        Type: AVATAR
    })

    try {
        const res = await Axios.post(apiGetReasons, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const listReasons = data.Reasons || []
            dispatch({
                type: types.GET_REASON_FACES,
                payload: {
                    listReasons: listReasons.reduce((obj, curr) => ({...obj, [curr.ID]: curr}), {})
                }
            })
        }
        else{
            // console.dir(data)
            dispatch({
                type: types.GET_REASON_FACES_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_REASON_FACES_ERR))
    }
}

export const getAvatarHistoryByUser = ({ UserID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        UserID
    })

    try {
        const res = await Axios.post(apiGetAvatarHistoryByUser, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;

        if(isSuccess){
            dispatch({
                type: types.GET_AVATAR_HISTORY_BY_USER,
                payload: {
                    listHistory: data.History || []
                }
            })
        }
        else {
            // console.dir(data)
            dispatch({
                type: types.GET_AVATAR_HISTORY_BY_USER_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_AVATAR_HISTORY_BY_USER_ERR))
    }
}

export const verifyAvatar = (
    { 
        UserID, 
        ID, 
        Status, 
        OptionalReason, 
        Reasons 
    },
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
    const store = getState()
    const { isLoading } = store.userCensorship.detailsUser.faces
    if(isLoading) return;
    dispatch({ type: types.VERIFY_AVATAR_START })

    const dataRequest = JSON.stringify({
        UserID, 
        ID, 
        Status, 
        OptionalReason, 
        Reasons
    })

    try {
        const res = await Axios.post(apiVerifyAvatar, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            callbackSuccess && callbackSuccess()
            dispatch({
                type: types.VERIFY_AVATAR,
                payload: {
                    ID
                }
            })
        }
        else {
            // console.dir(data)
            callbackError && callbackError()
            dispatch({
                type: types.VERIFY_AVATAR_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        // console.dir(err)
        callbackError && callbackError()
        dispatch(mapErrors(err, types.VERIFY_AVATAR_ERR))
    }
    dispatch({ type: types.VERIFY_AVATAR_FINISH })
}

export const clearFaces = () => ({
    type: types.CLEAR_FACES
})