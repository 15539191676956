import React, { useCallback } from 'react'

// style
import { AcceptFace, RejectFace } from './index.style'

// image
import IcDone from '../../../../../../../../assets/images/components/IcDone'
import IcCloseRed from '../../../../../../../../assets/images/components/IcCloseRed'

// data
import { ACCEPT, REJECT } from '../../../../../../../../assets/constants/faceCensorShipStatus'

// export const statusTypes = {
//     ACCEPTED: 'ACCEPTED',
//     REJECTED: 'REJECTED'
// }

const AcceptStatus = () => (
    <AcceptFace>
        <IcDone />
        <span>Đã được duyệt</span>
    </AcceptFace>
)

const RejectStatus = () => (
    <RejectFace>
        <IcCloseRed />
        <span>Bị từ chối</span>
    </RejectFace>
)

const StatusFace = ({ status }) => {

    const _renderFollowStatus = useCallback(
        () => {
            switch(status){
                case ACCEPT:
                    return <AcceptStatus />
                case REJECT:
                    return <RejectStatus />
                default:
                    return null
            }
        },
        [status],
    )

    return _renderFollowStatus()
}

export default StatusFace
