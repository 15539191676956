import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: ${props => props ? props.styled.height: 80}px;
    background-color: ${props => props ? props.styled.backgroundColor: 'rgba(147, 191, 242, 0.1)'};
    border-bottom: ${props => props ? props.styled.borderBottom: 'unset'};
    position: ${props => props ? props.styled.position: 'unset'};
    top: 0;
    left: 0;
    z-index: ${props => props ? props.styled.zIndex: 'unset'};
    backdrop-filter: blur(20px);
    /* z-index: 2; */
`

export { Bound }