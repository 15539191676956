import React from 'react'

const BgArrowDown = (props) => {
    return (
        <svg {...props} width={26} height={12} viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 12L0.875646 -2.28386e-06L25.1244 -1.63974e-07L13 12Z" fill="white" />
        </svg>
    )
}

export default BgArrowDown
