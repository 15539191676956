import styled from 'styled-components'

const WrapperSimpleDetails = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time-submit {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #1C1C1C;
        opacity: 0.5;
    }
`;

export {
    WrapperSimpleDetails
}