import React, { useRef, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapPopupConfirmDeleteChain, extendStylesBtnDel, WrapOverlay } from './index.styles'

// component
import BgArrowRight from '../../../../../../../assets/images/components/BgArrowRight'
import ButtonControl from '../../../../../Button'

// action
import { delChainById } from '../../../../../../../redux/chains/chainInfos/chainDetails/action'
import { getListChains } from '../../../../../../../redux/chains/listChains/action'

const PopupConfirmDeleteChain = ({ chainDetails, onClose, onClosePopupEditChains }) => {
    const dispatch = useDispatch()
    const  popupRef = useRef(null)

    const { listChains } = useSelector((state) => ({
        listChains: state.chains.listChains
    }))

    const [valueInput, setValueInput] = useState("")

    const { keyword } = useMemo(() => listChains.filter, [JSON.stringify(listChains)])

    const _handleClickPopup = useCallback((e) => {
        if(!popupRef.current.contains(e.target)){
            onClose()
        }
    }, [onClose])

    const _handleChangeInput = useCallback(
        (e) => {
            setValueInput(e.target.value.trimStart().replace(/\s+/g, " "))
        },
        [],
    )

    const _handleDelChain = useCallback(
        () => {
            if(!chainDetails.details) return;

            const { ID } = chainDetails.details
            dispatch(delChainById({ ID }, () => {
                dispatch(getListChains({ PageNo: 1, PageSize: 50, Filter: keyword }))
                onClose()
                onClosePopupEditChains()
            }))
        },
        [keyword],
    )

    const nameOfChain = useMemo(() => {
        if(!chainDetails.details) return null;
        return chainDetails.details.Name
    }, [JSON.stringify(chainDetails.details)])

    return (
        <WrapOverlay onClick={_handleClickPopup}>
            <WrapPopupConfirmDeleteChain ref={popupRef}>
                <div className="question-confirm">Bạn có chắc muốn xóa chuỗi</div>
                <div className="note-when-delte">Khi chuỗi xóa đồng thời toàn bộ dữ liệu cửa hàng, sẽ bị xóa theo.</div>
                <div className="wrap-form">
                    <input 
                        type="text" 
                        placeholder="Nhập lại chính xác tên chuỗi" 
                        value={valueInput}
                        onChange={_handleChangeInput}
                    />
                    <ButtonControl 
                        extendStyles={extendStylesBtnDel}
                        onClick={_handleDelChain}
                        isDisable={!valueInput || valueInput !== nameOfChain}
                    >
                        Xóa
                    </ButtonControl>
                </div>
                <BgArrowRight className="arrow" />
            </WrapPopupConfirmDeleteChain>
        </WrapOverlay>
    )
}

export default PopupConfirmDeleteChain
