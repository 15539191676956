import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

// import RootModal from './RootModal';
import { ModalStyles } from "./index.styles"

const ModalControl = ({ children, extendStyles }) => {
    const rootModal = document.getElementById('modal-root');

    if(rootModal)
        return createPortal(
            children,
            rootModal
        )
    return createPortal(
        <ModalStyles 
            extendStyles={extendStyles}
        >
            {children}
        </ModalStyles>,
        document.body
    )
}

export default ModalControl
