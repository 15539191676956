import React, { Component, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import * as style from './index.style'
import HeaderComparePage from './HeaderComparePage'
import MainComparePage from './MainComparePage'

// action
import { getCompareInfosEKYCs } from '../../redux/compareEKYCs/action'

const ComparePage = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { idPrimary, idSecond } = useParams()

    useEffect(() => {
        if(!idPrimary || !idSecond){
            history.push('/')
            return
        }
        dispatch(getCompareInfosEKYCs({
            PrimaryEKYCID: idPrimary,
            SecondaryEKYCID: idSecond
        }))
    }, [idPrimary, idSecond, history])

    return (
        <style.Bound>

            <HeaderComparePage />
            
            <MainComparePage />           
            
        </style.Bound>
    )
}

export default React.memo(ComparePage)
