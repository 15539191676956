import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// styles
import { WrapDropDown, ShowBox, Dropdown, SearchBox, ListArea, AreaItem, AddInput } from './index.styles'

// component
import IcArrowDownBlack from '../../../assets/images/components/Ic_arrow_down_black'
import InputSearch from '../InputSearch'
import IcCircleAdd from '../../../assets/images/components/IcCircleAdd'
import IcCheckCircle from '../../../assets/images/components/IcCheckCircle'
import IcCancel from '../../../assets/images/components/IcCancel'
import IcSelected from '../../../assets/images/components/IcSelected'
import IcUnSelected from '../../../assets/images/components/IcUnSelected'

// action
import { getAreasByChain, addAreaToChain } from '../../../redux/chains/chainInfos/areas/action'

// hooks
import { useOnClickOutside } from '../../../hook/useClickOutside.hook'
import useDebounce from '../../../hook/useDebouce.hook'

let timer;

const DropdownArea = ({ placeholder = "Chọn khu vực ...", onChangeValue, defaultArea }) => {
    const dispatch = useDispatch()
    const refInputAdd = useRef(null)
    const dropdownRef = useRef(null)

    const { chainDetails, areas } = useSelector(state => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        areas: state.chains.chainInfos.areas
    }))

    const [selected, setSelected] = useState(defaultArea || {})
    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [isShowInputAdd, setIsShowInputAdd] = useState(false)
    const [valueSearch, setValueSearch] = useState("")
    const [nameAreaAdd, setNameAreaAdd] = useState("")
    // const debounceValueSearch = useDebounce(valueSearch, 1000)

    const _handleAddArea = useCallback(
        () => {
            if (!chainDetails.details) return;
            const { ID } = chainDetails.details

            // const { current } = refInputAdd;
            // if (!current) return;

            // const { value } = current;
            // if (!value) return;

            // const nameArea = value.trim().replace(/\s+/g, " ")
            // if (!nameArea) return;

            // current.value = ''

            const areaName = nameAreaAdd.trim()
            if(!areaName) return;

            dispatch(addAreaToChain({ ChainID: ID, Name: areaName }, () => {
                dispatch(getAreasByChain({ ChainID: ID }))
                setNameAreaAdd('')
            }))
        },
        [JSON.stringify(chainDetails.details), nameAreaAdd],
    )

    const _handleSearchValue = (valueSearch) => {

        // Prevent double space
        if (valueSearch.includes("  ")) return;

        // Prevent first string is space
        if (valueSearch[0] === " ") return;

        setValueSearch(valueSearch);
        if (timer !== null) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            if (!chainDetails.details) return;
            const { ID } = chainDetails.details
            dispatch(getAreasByChain({ ChainID: ID, Filter: valueSearch.trim() }))
            timer = null
        }, 1000);
    };

    const _handleChangeInputAdd = useCallback((e) => {
        const { value } = e.target;
        // if(!value) return;
        // if(!value.trimSt()) return;
        // if(/\s+/g.test(value)) return;

        setNameAreaAdd(value.trimStart().replace(/\s+/g, " "))
    })

    useEffect(() => {
        onChangeValue(selected)
    }, [JSON.stringify(selected)])

    useEffect(() => {
        if (!chainDetails.details) return;
        const { ID } = chainDetails.details
        dispatch(getAreasByChain({ ChainID: ID }))
    }, [JSON.stringify(chainDetails.details)])

    // useEffect(() => {
    //     if (!debounceValueSearch) return;
    //     if (!chainDetails.details) return;
    //     const { ID } = chainDetails.details
    //     dispatch(getAreasByChain({ ChainID: ID, Filter: debounceValueSearch }))
    // }, [debounceValueSearch, JSON.stringify(chainDetails.details)])

    // useEffect(() => {
    //     if(!areas.list.length) return;
    //     if(Object.keys(selected).length) return;
    //     const itemSelected = areas.list.find(item => item.ID === defaultAreaID)
    //     setSelected(itemSelected || {})
    // }, [JSON.stringify(selected), defaultAreaID, JSON.stringify(areas.list)])

    useOnClickOutside(dropdownRef, () => setIsShowDropdown(false))

    

    return (
        <WrapDropDown>
            <div className="label">Khu vực *</div>
            <div className="wrap-content" ref={dropdownRef}>
                <ShowBox onClick={() => setIsShowDropdown(!isShowDropdown)}>
                    <div className="value">
                        {selected.Name ?
                            <p>{selected.Name}</p>
                            :
                            <p className="placeholder">{placeholder}</p>
                        }
                    </div>
                    <IcArrowDownBlack />
                </ShowBox>
                {
                    isShowDropdown &&
                    <Dropdown>
                        <SearchBox>
                            <InputSearch
                                widthSearch="100%"
                                heightSearch="36px"
                                value={valueSearch}
                                onChangeValue={_handleSearchValue}
                                placeHolderSearch="Tìm kiếm khu vực"
                            />
                            <div className="area-control">
                                <div className="area-control__label">Khu vực</div>
                                {
                                    isShowInputAdd ?
                                        <IcCancel onClick={() => {setIsShowInputAdd(false); setNameAreaAdd('')}} className="ic ic-cancel" />
                                        :
                                        <IcCircleAdd onClick={() => setIsShowInputAdd(true)} className="ic ic-open-add" />
                                }
                            </div>
                        </SearchBox>
                        {
                            isShowInputAdd &&
                            <AddInput>
                                <input
                                    type="text"
                                    className="add-area"
                                    placeholder="Nhập tên khu vực"
                                    maxLength="200"
                                    value={nameAreaAdd}
                                    onChange={_handleChangeInputAdd}
                                    // ref={refInputAdd}
                                />
                                <IcCheckCircle
                                    className="ic ic-add"
                                    onClick={_handleAddArea}
                                />
                            </AddInput>
                        }
                        <ListArea>
                            {
                                areas.list.map((item, key) => (
                                    <AreaItem key={item.ID} onClick={() => setSelected(item)}>
                                        <div className="icon-wrapper">
                                            {
                                                selected.ID === item.ID ?
                                                    <IcSelected />
                                                    :
                                                    <IcUnSelected />
                                            }
                                        </div>
                                        <div className="area-name">{item.Name}</div>
                                    </AreaItem>
                                ))
                            }
                        </ListArea>
                    </Dropdown>
                }
            </div>
        </WrapDropDown>
    )
}

export default DropdownArea
