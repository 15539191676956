import styled from 'styled-components'

const StylePagination = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    color: #1C1C1C;
    height: 40px;
    /* margin-top: 20px; */
    .btn-previous, .btn-next{
        display: flex;
        align-items: center;    
        cursor: pointer;
        width: 10px;
    }
    .paging-container{
        display: flex;
        align-items: center;
        padding: 0 10px;
        .current-page{
            color: #2D98F0;
            min-width: 10px;
        }
        .total-page{
            font-weight: 500;
            width: fit-content;
        }
    }

`
export { StylePagination }