import React, { useState, useRef, useReducer, useMemo, useEffect } from 'react'
import moment from 'moment'

// styles
import { WrapDropDownFilterTimes } from './index.styles'

// conponent
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'
import BoxFilterByTime from '../../../../../Control/BoxFilterByTime'
import { useOnClickOutside } from '../../../../../../hook/useClickOutside.hook'

export const getDefaultStartDateTime = () => moment().subtract(29, 'days').startOf('day').toDate()
export const getDefaultEndDateTime = () => moment().startOf('day').toDate()

const DropDownFilterTimes = ({ values, onChangeValues }) => {
    const DEFAULT_START_DATE = useMemo(getDefaultStartDateTime, [])
    const DEFAULT_END_DATE = useMemo(getDefaultEndDateTime, [])

    const filterTimesRef = useRef()

    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [isActiveFilter, setIsActiveFilter] = useState(false)

    const [stateFilter, dispatchStateFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            sort: "",
            startDateTime: DEFAULT_START_DATE,
            endDateTime: DEFAULT_END_DATE
        }
    )

    const _handleOpenBox = () => {
        setIsShowDropdown(!isShowDropdown)
    }

    useOnClickOutside(filterTimesRef, () => setIsShowDropdown(false))

    useEffect(() => {
        const { sort, startDateTime, endDateTime } = stateFilter;
        
        const isDefaultStartDate = startDateTime.toDateString() === DEFAULT_START_DATE.toDateString()
        const isDefaultEndDate = endDateTime.toDateString() === DEFAULT_END_DATE.toDateString()
        const isDefaultRangeDate = isDefaultStartDate && isDefaultEndDate
        
        const haveFilter = sort || !isDefaultRangeDate;

        if(haveFilter && !isActiveFilter) {
            setIsActiveFilter(true)
            return;
        }

        if(!haveFilter && isActiveFilter) {
            setIsActiveFilter(false)
            return;
        }

    }, [stateFilter, isActiveFilter])

    useEffect(() => {
        // if(!values.sort) return;
        dispatchStateFilter({ sort: values.sort })
    }, [values.sort])

    useEffect(() => {
        if(!values.startDateTime) return;
        dispatchStateFilter({ startDateTime: values.startDateTime })
    }, [values.startDateTime])

    useEffect(() => {
        if(!values.endDateTime) return;
        dispatchStateFilter({ endDateTime: values.endDateTime })
    }, [values.endDateTime])

    return (
        <WrapDropDownFilterTimes ref={filterTimesRef}>
            <TitleDropdownFilter onClick={_handleOpenBox} active={isActiveFilter}>Thời gian</TitleDropdownFilter>
            {
                isShowDropdown &&
                <BoxFilterByTime 
                    stateFilter={stateFilter}
                    dispatchStateFilter={dispatchStateFilter}
                    onChangeValues={onChangeValues}
                />
            }
        </WrapDropDownFilterTimes>
    )
}

export default DropDownFilterTimes
