import React, { useState, useEffect, useCallback } from 'react'
import * as style from './index.style'

const InputText = ({
  titleInput,
  defaultValue,
  placeholder,
  isTitle,
  onChange,
  heightInput,
  isCardNumber,
  disabled = true,
  keyValue,
  isInvalid=false,
  ...props
}) => {

  const [value, setValue] = useState(defaultValue)
  const [isDisabled, setIsDisabled] = useState(disabled)

  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const _handleOnChange = useCallback(
    (e) => {
      const { value } = e.target
      const val = value.trimStart().replace(/\s+/g, " ")
      setValue(val)
      onChange(keyValue, val)
    },
    [value, onChange, setValue],
  )
  return (
    <style.Bound heightInput={heightInput}>
      {
        isTitle &&
        <p className="title-input">{titleInput}</p>
      }
      <style.Input
        isInvalid={isInvalid}
        className={isCardNumber ? "value cardnumber" : "value"}
        onChange={_handleOnChange}
        type="text"
        disabled={isDisabled}
        value={value}
        placeholder={placeholder}
        style={{backgroundColor: disabled?'#EDEDED':'unset'}}
        {...props}
      />
    </style.Bound>
  )
}

export default React.memo(InputText)
