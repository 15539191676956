import React, { useEffect, useRef, useState } from 'react'

// component
import DragAndDrop from '../DragAndDrop'

// img
import IcUpload from "../../../assets/images/components/IcUpload"
import IcDelRedBgPink from '../../../assets/images/components/IcDelRedBgPink'

// styles
import { DropArea, WrapImg } from './index.styles'

// constant
import { ERR_IMG_INVALID_RATIO, ERR_IMG_INVALID_SIZE, ERR_IMG_INVALID_TYPE } from '../../../assets/constants/errorImgTypes'

const BYTE_IN_1_KB = 1024
const KB_IN_1_MB = 1024
const LIMIT_SIZE = 10 * KB_IN_1_MB * BYTE_IN_1_KB

const DropOrChooseLogo = ({ file, handleDropFiles, handleReturnError }) => {

    const inputRef = useRef(null);

    const [isErrorImg, setIsErrorImg] = useState(false)

    useEffect(() => {
        const inputFile = inputRef.current = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = (e) => { 
            handleCheckMatchImg(e.target.files) 
        }
    }, [])

    const handleChooseFile = () => {
        if (!inputRef) {
            return;
        }

        inputRef.current.click()
    }

    const checkMatchType = (file) => {
        const { type } = file
        const [typeFile, subTypeFile] = type.split("/")
        const arrayTypeMatch = ["image"]
        const arraySubTypeMatch = ["png", "jpg", "jpeg"]

        return arrayTypeMatch.includes(typeFile) && arraySubTypeMatch.includes(subTypeFile)
    }

    const checkMatchSize = (file) => {
        const { size } = file

        return size <= LIMIT_SIZE
    }

    const checkMatchRatio = (file) => {
        return new Promise((resolve, _) => {
            const img = new Image();
            img.onload = function() {
                const isMatchMinWidth = this.width >= 300;
                const isMatchMinHeight = this.height >= 300;
                const isMatchRatio = this.width === this.height;
                resolve(isMatchMinWidth && isMatchMinHeight && isMatchRatio)
            }

            const reader = new FileReader();
            reader.onload = function() {
                img.src = reader.result;
            }
            reader.readAsDataURL(file);
        })
    }

    const handleCheckMatchImg = async (files) => {
        if(files.length !== 1){
            inputRef.current.value = '';
            return
        }

        const file = files[0]

        const isMatchType = checkMatchType(file)
        if (!isMatchType){
            setIsErrorImg(true)
            handleReturnError(ERR_IMG_INVALID_TYPE)
            inputRef.current.value = '';
            return;
        }

        const isMatchSize = checkMatchSize(file)
        if(!isMatchSize){
            setIsErrorImg(true)
            handleReturnError(ERR_IMG_INVALID_SIZE)
            inputRef.current.value = '';
            return;
        }

        const isMatchRatio = await checkMatchRatio(file)
        if(!isMatchRatio){
            setIsErrorImg(true)
            handleReturnError(ERR_IMG_INVALID_RATIO)
            inputRef.current.value = '';
            return;
        }
        setIsErrorImg(false)
        handleDropFiles(files)
        inputRef.current.value = '';
    }

    return ( 
        file?
            <WrapImg>
                <IcDelRedBgPink 
                    className="delete-img" 
                    onClick={() => handleDropFiles("")}
                />
                <img src={file} alt="logo" />
            </WrapImg>
            :
            <DragAndDrop handleDropFiles={handleCheckMatchImg}>
                <DropArea isError={isErrorImg}>
                    <IcUpload />
                    <div className="drag-logo">Kéo thả logo *</div>
                    <div className="choose-local">hoặc <span onClick={handleChooseFile}>chọn từ máy tính</span> loại tệp tin png, jpg</div>
                    <div className="note">
                        <div>(*) Tỉ lệ hình ảnh khuyến khích 1:1</div>
                        <div>Ví dụ: kích thước logo 300x300 pixels</div>
                    </div>
                </DropArea>
            </DragAndDrop>
    )
}

export default React.memo(DropOrChooseLogo)
