import styled from "styled-components";

//constant
import * as stylesConstants from '../../../../assets/constants/ui'

const Bound = styled.div`
  
  height: calc(100% - ${stylesConstants.HEADER_HEIGHT}px - ${stylesConstants.MAIN_CONTENT_PADDING_TOP}px);
  
  padding: calc(${stylesConstants.HEADER_HEIGHT}px + ${stylesConstants.MAIN_CONTENT_PADDING_TOP}px) 54px 0 30px;
  width: calc(100% - (54px + 30px));
  overflow-x: hidden;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    width: 6px;
    background-color:transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #E2E2E2;
    background-clip: padding-box;
    border-radius: 20px;
  }
  .scroll{
    display: grid;
    grid-template-columns: 770px 570px;
    grid-template-rows: 370px auto;
    grid-gap: 30px;
    padding-bottom: 30px;
    .user-info, .user-note, .user-manage{
      display: flex;
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #fff;
      border-radius: 6px;
      border: 0.5px solid #C6D5EB;
      box-sizing: border-box;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
      overflow: hidden;
    }
    .user-info{
        padding-top: 68px;
    }
    .user-note{
        padding-top: 68px;
    }
    .user-manage {
      grid-column: 1 / span 2;
      flex-direction: column;
      
      .empty-header {
        height: 69px;
      }
    }
  }
  
`;

export { Bound };
