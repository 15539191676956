import React, { useState, useReducer, useRef, useEffect } from 'react'


//style
import { WrapFilterUsers } from './index.style'
//component
import TitleDropdownFilter from '../../../../../../Control/TitleDropdownFilter'


const DropDownFilterTransCodes = () => {

    const [activeFilter, setActiveFilter] = useState(false)
    const [isShowDropdown, setIsShowDropdown] = useState(false)

    const _handleOpenBox = () => {

    }
    return (
        <WrapFilterUsers >
            <TitleDropdownFilter onClick={_handleOpenBox} active={activeFilter} disabled={true}>
                Mã Giao dịch
            </TitleDropdownFilter>
            {/* {
                isShowDropdown &&
                
            } */}
        </WrapFilterUsers>
    )
}

export default DropDownFilterTransCodes
