import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as styles from './index.style'

// component
import EKYCCensorshipItem from './eKYCCensorshipItem'
import EmptyDataControl from '../../../../Control/EmptyDataControl'
//action
import { getEKYCByUser } from '../../../../../redux/userCensorship/detailsUser/eKYCs/action'
// mock-data
import { eKYC_censorship } from '../../../../../data/mockData'

const EKYCCensorship = (props) => {

    // let data = []
    // const randomLength = Math.floor(Math.random() * 10) + 1
    // for(let i=0; i< randomLength; i++){
    //     data.push(eKYC_censorship[Math.floor(Math.random() * eKYC_censorship.length) + 1])
    // }

    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const { detailsAccount, eKYCCensorship } = useSelector(state => ({
        detailsAccount: state.userCensorship.detailsUser.detailsAccount,
        eKYCCensorship: state.userCensorship.detailsUser.eKYCs
    }))

    useEffect(() => {
        if(!detailsAccount?.ID) return
        setData([])
        dispatch(getEKYCByUser({UserID: detailsAccount.ID}))
    }, [detailsAccount])

    useEffect(() => {
        if(!eKYCCensorship) return
        if(eKYCCensorship?.listEKYC) setData(eKYCCensorship.listEKYC)
    }, [eKYCCensorship])

    return (
        <styles.Bound>
            {
                data && data.length>0?
                    data.map((item, key) =>
                        <EKYCCensorshipItem 
                            key={key}
                            defaultExpands={key===0}
                            data={item}
                        />
                    )
                :
                <EmptyDataControl />
            }
        </styles.Bound>
    )
}

export default React.memo(EKYCCensorship)
