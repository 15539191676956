import Axios from 'axios'

// host
import { host } from '../../assets/constants/host'

// helper
import mapErrors from '../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../assets/constants/codeResponse'


const apiGetCompareInfosEKYCs = `${host}/backoffice/ekyc/compare-info`

export const getCompareInfosEKYCs = ({ PrimaryEKYCID, SecondaryEKYCID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        PrimaryEKYCID, 
        SecondaryEKYCID
    })
    
    try {
        const res = await Axios.post(apiGetCompareInfosEKYCs, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.GET_COMPARE_INFOS_EKYCS,
                payload: {
                    primaryUser: data.PrimaryUser || {},
                    primaryEKYCUser: data.PrimaryEKYCUser || {},
                    secondaryUser: data.SecondaryUser || {},
                    secondaryEKYCUser: data.SecondaryEKYCUser || {}
                }
            })
        }
        else {
            dispatch({
                type: types.GET_COMPARE_INFOS_EKYCS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_COMPARE_INFOS_EKYCS_ERR))
    }
}