import React from 'react'

const IcCheck = (props) => {
    return (
        <svg {...props} width={14} height={10} viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.75 3.33333L0 5L5.25 10L14 1.66667L12.25 0L5.25 6.66667L1.75 3.33333Z" fill="white" />
        </svg>
    )
}

export default IcCheck
