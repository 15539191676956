import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

// styles
import { Bound } from "./index.styles"

// image
// import icSuccess from "../../../images/ic_success.svg"
// import icInfo from "../../../images/ic_info.svg"
// import icWarning from "../../../images/ic_warning.svg"
// import icError from "../../../images/ic_error.svg";
// import icClose from "../../../images/ic_close.svg"
import IcSuccess from "../../../assets/images/components/IcSuccess"
import IcInfo from "../../../assets/images/components/IcInfo"
import IcWarning from "../../../assets/images/components/IcWarning"
import IcError from "../../../assets/images/components/IcError"
import IcClose from "../../../assets/images/components/IcClose"

const types = {
    SUCCESS: "SUCCESS",
    INFO: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR"
}

const icFollowTypes = {
    [types.SUCCESS]: <IcSuccess className="ic-detail" />,
    [types.INFO]: <IcInfo className="ic-detail" />,
    [types.WARNING]: <IcWarning className="ic-detail" />,
    [types.ERROR]: <IcError className="ic-detail" />
}

const stylesFollowTypes = {
    [types.SUCCESS]: `
        background-color: #F6FFED;
        border: 1px solid #B7EB8F;
    `,
    [types.INFO]: `
        background-color: #E6F7FF;
        border: 1px solid #91D5FF;
    `,
    [types.WARNING]: `
        background-color: #FFFBE6;
        border: 1px solid #FFE58F;
    `,
    [types.ERROR]: `
        background-color: #FFF1F0;
        border: 1px solid #FFA39E;
    `
}



const AlertPopup = ({ 
    type, 
    children, 
    btn, 
    handleClose,
    handleClickBtn,
    extendStyles,
    isAutoClose=true
}) => {

    const [classAnimation, setClassAnimation] = useState('fade-in')
    // const [isClose, setIsClose] = useState(false)

    const _addTimeoutAutoClose = useCallback(
        () => {
            setTimeout(() => {
                // setIsClose(true)
                handleClose && handleClose()
            }, 1500)
        },
        [],
    )

    const _handleClick = useCallback(
        () => {
            setClassAnimation('fade-out')
            handleClickBtn && handleClickBtn()
            _addTimeoutAutoClose()
        },
        [setClassAnimation, handleClickBtn, _addTimeoutAutoClose],
    )

    useEffect(() => {
        if(!isAutoClose) return;
        const timeOut = setTimeout(() => {
            setClassAnimation('fade-out')
            _addTimeoutAutoClose()
        }, 4500)
        return () => {
            clearTimeout(timeOut)
        }
    }, [])

    // useEffect(() => {
    //     if(!isClose) return;
    //     handleClose && handleClose()
    // }, [isClose])

    return (
        <Bound 
            specificStyles={stylesFollowTypes[type]}
            extendStyles={extendStyles}
            className={classAnimation}
        >
            {icFollowTypes[type]}

            <div className="content">{children}</div>
            <div onClick={_handleClick}>
                {btn || <IcClose  />}
            </div>
        </Bound>
    )
}



AlertPopup.propTypes = {
    type: PropTypes.oneOf(Object.values(types)).isRequired,
    children: PropTypes.any.isRequired,
    btn: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]),
    handleClickBtn: PropTypes.func,
    extendStyles: PropTypes.string
}

export default AlertPopup
