import styled from 'styled-components'

const Bound = styled.div`
  display:grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 272px 1fr;
  background-color: #F4F4F4;
  overflow-x: hidden;
  overflow-y: overlay;
`

const MainBound = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 828px 1fr;
    position: relative;
    overflow: hidden;
`

export { Bound, MainBound }