import styled from 'styled-components'

const HeaderNoteWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-family: SVN-Gilroy;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px 0 30px;
  box-sizing: border-box;

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 170%;
    color: #1c1c1c;
  }

  .listNode {
    display: flex;
    align-items: center;
  }
`;

const NodeItem = styled.div`
  font-family: SVN-Gilroy;
  font-style: normal;
  display: flex;
  align-items: center;

  p {
    color: #000000;
    font-size: 12px;
    margin: 0 10px 0 30px;
    font-weight: bold;
  }

  span {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${(props) => props.color || "#fa8c8c"};
    border: 1px solid #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  }
`;

export { HeaderNoteWrapper, NodeItem };
