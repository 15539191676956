import React from "react";

const IcWarningRed = (props) => (
  <svg
    {...props}
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00042 0C3.58148 0 4.3409e-08 3.58148 4.3409e-08 7.99958C-0.000109406 9.05024 0.206753 10.0906 0.608773 11.0613C1.01079 12.032 1.60009 12.914 2.34302 13.657C3.08595 14.3999 3.96796 14.9892 4.93866 15.3912C5.90937 15.7932 6.94976 16.0001 8.00042 16C12.4177 16 16 12.4185 16 7.99958C16 3.58148 12.4177 0 8.00042 0ZM8.74705 2.88818C9.52701 2.88818 9.75616 3.34066 9.75616 3.85813C9.75616 4.50393 9.23952 5.1014 8.35707 5.1014C7.61877 5.1014 7.26712 4.73059 7.28879 4.11645C7.28879 3.59898 7.72127 2.88818 8.74705 2.88818ZM6.74882 12.791C6.21551 12.791 5.82636 12.4669 6.19884 11.0461L6.80965 8.52539C6.91547 8.12208 6.93297 7.96042 6.80965 7.96042C6.65049 7.96042 5.95802 8.23874 5.54971 8.51372L5.28389 8.07791C6.57966 6.99547 8.06958 6.3605 8.70705 6.3605C9.24035 6.3605 9.32868 6.99047 9.06286 7.96042L8.3629 10.6103C8.23874 11.0786 8.29207 11.2402 8.41623 11.2402C8.57622 11.2402 9.09953 11.0469 9.6145 10.6411L9.91615 11.0461C8.65538 12.306 7.28129 12.791 6.74882 12.791Z"
      fill="#FA8C8C"
    />
  </svg>
);

export default IcWarningRed;
