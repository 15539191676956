import React from "react";

// import IcTotal from "../images/components/IcTotal";
import IcUser from "../images/components/IcUser";
import IcMaximize from "../images/components/IcMaximize";
import IcKhuonMatOn from "../images/components/Ic_khuonmat_on";
import IcKhuonMatOff from "../images/components/Ic_khuonmat_off";
import IcEKYCOn from "../images/components/Ic_ekyc_on";
import IcEKYCOff from "../images/components/Ic_ekyc_off";
import IcDangNhapOn from "../images/components/Ic_dangnhap_on";
import IcDangNhapOff from "../images/components/Ic_dangnhap_off";
//constant
import * as keys from '../constants/keys'
import IcPaymentAccountOff from "../images/components/IcPaymentAccountOff";
import IcPaymentAccountOn from "../images/components/IcPaymentAccountOn";
import IcOperationHistoryOn from "../images/components/IcOperationHistoryOn";
import IcOperationHistoryOff from "../images/components/IcOperationHistoryOff";
import IcPaymentHistoryOn from "../images/components/IcPaymentHistoryOn";
import IcPaymentHistoryOff from "../images/components/IcPaymentHistoryOff";
import IcStoreOn from "../images/components/Ic_StoreOn";
import IcStoreOff from "../images/components/Ic_StoteOff";
import IcStepDetailsOn from "../images/components/IcStepDetailsOn";
import IcStepDetailsOff from "../images/components/IcStepDetailsOff";

export const headerMenuMainBoard = [
  // {
  //   key: "All",
  //   path: "/all",
  //   label: "Tất cả",
  //   icon: (props) => <IcTotal {...props} />,
  // },
  {
    key: keys.AVATAR,
    path: "/face",
    label: "header.face_censorship",
    width: "170px",
    icon: (props) => <IcUser {...props} />,
  },
  {
    key: keys.EKYC,
    path: "/ekyc",
    label: "header.ekyc_censorship",
    width: "140px",
    icon: (props) => <IcMaximize {...props} />,
  },
];

export const headerUsercensorshipMenuInfomationBoard = [
  {
    key: "Face",
    path: "/face",
    label: "header.face_censorship",
    color_gradient_label:
      "-webkit-linear-gradient(16.7deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",
    color_line: "-webkit-linear-gradient(16.7deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",
    width: "123px",
    icon: (props) => (props.active ? <IcKhuonMatOn /> : <IcKhuonMatOff />),
  },
  // {
  //   key: "trading-account",
  //   path: "/trading-account",
  //   label: "header.trading_account",
  //   color_gradient_label:
  //     "-webkit-linear-gradient(90deg, #F7BB25 0%, #FF2424 100%)",
  //   color_line: "-webkit-linear-gradient(90deg, #F7BB25 0%, #FF2424 100%)",
  //   width: "205px",
  //   icon: (props) => (props.active ? <IcPaymentAccountOn /> : <IcPaymentAccountOff />),
  // },
  {
    key: "eKYC",
    path: "/ekyc",
    label: "header.ekyc_censorship",
    color_gradient_label:
      "-webkit-linear-gradient(16.7deg, #9000FF 0.47%, #8100E1 24.79%, #005CEC 100%, #005BEC 100%)",
    color_line: "-webkit-linear-gradient(16.7deg, #9000FF 0.47%, #8100E1 24.79%, #005CEC 100%, #005BEC 100%)",
    width: "82px",
    icon: (props) => (props.active ? <IcEKYCOn /> : <IcEKYCOff />),
  },
  // {
  //   key: "Login",
  //   path: "/login",
  //   label: "header.login_history",
  //   color_gradient_label:
  //     "-webkit-linear-gradient(16.7deg, #00A548 0%, #BDD50A 97.45%)",
  //   color_line: "-webkit-linear-gradient(16.7deg, #00A548 0%, #BDD50A 97.45%)",
  //   width: "180px",
  //   icon: (props) => (props.active ? <IcDangNhapOn /> : <IcDangNhapOff />),
  // },
  // {
  //   key: "activity-history",
  //   path: "/activity-history",
  //   label: "header.activity_history",
  //   color_gradient_label:
  //     "-webkit-linear-gradient(45deg, #DCBE00 0%, #D1D50A 97.45%)",
  //   color_line: "-webkit-linear-gradient(45deg, #DCBE00 0%, #D1D50A 97.45%)",
  //   width: "175px",
  //   icon: (props) => (props.active ? <IcOperationHistoryOn /> : <IcOperationHistoryOff />),
  // },
  {
    key: "trading-history",
    path: "/trading-history",
    label: "header.trading_history",
    color_gradient_label:
      "-webkit-linear-gradient(258.49deg, #F61257 2.51%, #E15386 100%)",
    color_line: "-webkit-linear-gradient(258.49deg, #F61257 2.51%, #E15386 100%)",
    width: "180px",
    icon: (props) => (props.active ? <IcPaymentHistoryOn /> : <IcPaymentHistoryOff />),
  },
];

export const headerChainMenuInfomationBoard = [
  {
    key: "Store",
    path: "/store",
    label: "header.store",
    color_gradient_label:
      "-webkit-linear-gradient(45deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",
    color_line: "-webkit-linear-gradient(45deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",
    width: "130px",
    icon: (props) => (props.active ? <IcStoreOn /> : <IcStoreOff />),
  },
  // {
  //   key: "device",
  //   path: "/device",
  //   label: "header.device",
  //   color_gradient_label:
  //     "-webkit-linear-gradient(45deg, #00A548 0%, #BDD50A 97.45%)",
  //   color_line: "-webkit-linear-gradient(45deg, #00A548 0%, #BDD50A 97.45%)",
  //   width: "132px",
  //   icon: (props) => (props.active ? <IcPaymentAccountOn /> : <IcPaymentAccountOff />),
  // },
];

export const headerTransactionDetailsInfomationBoard = [
  {
    key: "step-details",
    path: "/step-details",
    label: "header.step_details",
    color_gradient_label:
      "-webkit-linear-gradient(45deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",
    color_line: "-webkit-linear-gradient(45deg, #00CFFF 0%, #0065FF 100%, #005BEC 100%)",    
    width: "180px",
    icon: (props) => (props.active ? <IcStepDetailsOn /> : <IcStepDetailsOff />),
  },
];
