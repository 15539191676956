import styled from 'styled-components'

const WrapTitleDropdownFilter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }
    
    div.title {
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 170%;
        color: #1C1C1C;
        text-transform: uppercase;

        &.active {
            color: #2D98F0; 
        }
    }

    .arrow-dropdown {
        margin-left: 10px;
    }
`;

export {
    WrapTitleDropdownFilter
}