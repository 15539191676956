import styled from 'styled-components'

const Bound = styled.div`
    /* width: 1273px; */
    display: grid;
    grid-template-columns: 40px 207px 165px 148px 250px 337px 164px;
    /* padding-right: 26px; */
    width: 100%;
    height: 21px;
    margin: 30px 0 10px 0;
    .filter-wrap-order-code{
        padding-left: 16px;
        width: calc(100% - 36px);
    }
    .filter-wrap{
        /* padding-left: 20px; */
        width: calc(100% - 20px);
    }
`
export {Bound}