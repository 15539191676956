import React, { useEffect, useState } from "react";
import { Route, Redirect, useRouteMatch, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as styles from "./index.style";

//component
import SideMenu from "./SideMenu";
import MainBoard from "./MainBoard";
import InformationBoard from "./InformationBoard";
import EmptyComponent from '../Control/EmptyComponent'
//constant
import * as pathNames from '../../assets/constants/pathName'
import * as keys from '../../assets/constants/keys'
//action
import { getReasonsFace } from '../../redux/userCensorship/detailsUser/faces/action'
import { getReasonsEKYC } from '../../redux/userCensorship/detailsUser/eKYCs/action'
import { getReasonsLock } from '../../redux/userCensorship/detailsUser/detailsAccount/action'

const Main = (props) => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();

    const [tabView, setTabView] = useState('')

    const authReducer = useSelector(state => state.authReducer)

    useEffect(() => {
        //get user censorship information
        
        
    }, [])

    useEffect(() => {
        if(!authReducer?.dataLogin?.UID) return
        let UID = authReducer.dataLogin.UID
        //get list reason face & eKYC censorship
        dispatch(getReasonsFace({AdminID: UID}))
        dispatch(getReasonsEKYC({AdminID: UID}))
        dispatch(getReasonsLock({AdminID: UID}))
        
    }, [authReducer.dataLogin])

    return (
        <styles.Bound>
        {/* ------ Side Menu ------ */}
            <SideMenu />
            {/* ------ Main Container ------ */}
            <styles.MainBound  id="main-bound-id">
                <Switch>
                    <Route path={`${path}/${pathNames.HOME}`}>
                        <EmptyComponent />
                    </Route>
                    <Route path={`${path}/${pathNames.USER_CENSORSHIP}`}>
                        <React.Fragment>
                            <MainBoard changeTabView={(key)=>setTabView(key)}/>
                            <InformationBoard tabView={tabView}/>
                        </React.Fragment>
                    </Route>
                    <Route path={`${path}/${pathNames.USERS}`}>
                        <React.Fragment>
                            <MainBoard changeTabView={(key)=>setTabView(key)}/>
                            <InformationBoard tabView={tabView}/>
                        </React.Fragment>
                    </Route>
                    <Route path={`${path}/${pathNames.TRANSACTIONS}`}>
                        {/* <EmptyComponent /> */}
                        <MainBoard changeTabView={(key)=>setTabView(key)}/>
                        <InformationBoard tabView={tabView}/>
                    </Route>
                    <Route path={`${path}/${pathNames.CHAINS}`}>
                        <React.Fragment>
                            <MainBoard changeTabView={(key)=>setTabView(key)}/>
                            <InformationBoard tabView={tabView}/>
                        </React.Fragment>
                    </Route>
                    <Route path={`${path}/${pathNames.MPOS}`}>
                        <EmptyComponent />
                    </Route>
                    <Route path={`${path}/${pathNames.VERSION_RELEASED}`}>
                        <EmptyComponent />
                    </Route>
                    <Route path={`${path}/${pathNames.SYSTEM_LOG}`}>
                        <EmptyComponent />
                    </Route>
                    <Route path={`${path}/${pathNames.SETTING}`}>
                        <EmptyComponent />
                    </Route>
                    <Redirect to={`${path}/${pathNames.USER_CENSORSHIP}`} />
                </Switch>
            </styles.MainBound>
        </styles.Bound>
    );
};

export default React.memo(Main);
