import React from 'react'

const IcZoomOut = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.9996 14.0001L11.0996 11.1001" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.33301 7.33325H9.33301" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default IcZoomOut
