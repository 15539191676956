import React from 'react'

const LessThanOrEqualHaveBg = (props) => {
    return (
        <svg {...props} width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 3.5C0 1.84315 1.34315 0.5 3 0.5H13C14.6569 0.5 16 1.84315 16 3.5V13.5C16 15.1569 14.6569 16.5 13 16.5H3C1.34315 16.5 0 15.1569 0 13.5V3.5Z" fill="#C6D5EB" />
            <path d="M9.68066 9.5L5.68066 7L9.68066 4.5" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round" />
            <line x1="5.5" y1={11} x2="10.5" y2={11} stroke="black" strokeLinecap="round" />
        </svg>
    )
}

export default LessThanOrEqualHaveBg
