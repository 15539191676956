import React, { useState } from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
//  styled
import * as style from "./index.style";
//image
import IcLogoFacepay from "../../../assets/images/components/IcLogoFacePay";
import IcLogoWee from "../../../assets/images/components/IcLogoWee";
import IcLogOut from "../../../assets/images/components/IcLogOut";
//component
import LogOut from "../../Control/LogOut";
import { useReducerState } from '../../../hook/useReducerState.hook'
//data
import * as dataSideMenu from './data'

const SideMenu = (props) => {
    const { t } = useTranslation('common');
    const colorState = {
        active: "#FF9500",
        inactive: "#C6D5EB",
    };
    const initialState = {        
        menuData: dataSideMenu.menuData,
        openLogOut: false,
    }

    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;
    const [expandNavigation, setExpandNavigation] = useState(false);
    const [state, dispatchState] = useReducerState(initialState);

    const onClickLoggout = () => {
        dispatchState(["openLogOut", true]);
    };

    return (
        <style.Bound
            onMouseEnter={() => setExpandNavigation(true)}
            onMouseLeave={() => setExpandNavigation(false)}
            expandNavigation={expandNavigation}
        >
            <div className="menu">
                {/* <BgSideMenu className="bg-side-menu" /> */}
                <IcLogoFacepay className="icon-logofacepay" />
                <div className="navigation-routes">
                    {state.menuData &&
                        state.menuData.map((item, index) => (
                            <NavLink
                                to={item.path}
                                key={index}
                                className={
                                    pathname === item.path || pathname.includes(item.path)
                                        ? "navigation-routes-link active"
                                        : "navigation-routes-link"
                                }
                                onClick={() => history.push(item.path)}
                            >
                                <div className="navigation-routes-link-image">
                                    {item.icon({
                                        colorState:
                                            pathname === item.path || pathname.includes(item.path)
                                                ? colorState.active
                                                : colorState.inactive,
                                    })}
                                </div>
                                <div
                                    className={
                                        pathname === item.path || pathname.includes(item.path)
                                            ? "navigation-routes-link-text active"
                                            : "navigation-routes-link-text"
                                    }
                                >
                                    {/* {item.label} */}
                                    {t(item.label)}
                                </div>
                            </NavLink>
                        ))}
                </div>
                <div className="navigation-logOut" onClick={() => onClickLoggout()}>
                    <div className="navigation-logOut-image">
                        <IcLogOut />
                    </div>
                    <div className="navigation-logOut-text">{t('side_menu.logout')}</div>
                </div>
                <LogOut
                    expand={state.openLogOut}
                    dispatchNavigation={dispatchState} />
            </div>
            <div className="introduce-wee">
                <p>{t('side_menu.product_by')}</p>
                <IcLogoWee />
                {/* <img src={ic_logo_wee} alt="logo-wee" /> */}
            </div>
        </style.Bound>
    );
};

export default React.memo(SideMenu);
