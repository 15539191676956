import React, { useEffect, useReducer, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

// style
import * as styles from "./index.style";

// images
import IcCloseBlack from "../../../../../assets/images/components/Ic_close_black";

// helper
import { convertDateTime } from "../../../../../assets/helper/tools";

// action
import { unlockAccount, getUserInfos } from "../../../../../redux/userCensorship/detailsUser/detailsAccount/action";

const PopupUnLockDetailAccount = ({ callbackClosePopup, unlock }) => {
  const dispatch = useDispatch()

  const { detailsAccount } = useSelector(state => ({
    detailsAccount: state.userCensorship.detailsUser.detailsAccount
  }))

  const [stateLock, dispatchLock] = useReducer(
    (state, action) => ({...state, ...action}),
    {
      lockedAt: '',
      lockedReason: '',
      lockedMoreDetails: '',
      adminLocked: ''
    }
  )

  // const _callbackSuccess = useCallback(() => {
  //   callbackClosePopup(1);
  //   dispatch(getUserInfos({UserID: detailsAccount.ID}))
  // }, [callbackClosePopup, detailsAccount])

  // const _onSubmitUnLock = useCallback(() => {
  //   const { ID } = detailsAccount
  //   dispatch(unlockAccount({ UID: ID }, _callbackSuccess))
  // }, [detailsAccount, _callbackSuccess]);

  // const _onSubmitUnLock = () => {
  //   unlock()
  // };

  useEffect(() => {
    const lockedAt = convertDateTime(detailsAccount.LockedAt, 'DD/MM/YYYY HH:mm')
    // debugger
    const lockedReasonObj = detailsAccount.listReasons.find(item => 
      item.ID === detailsAccount.LockReasonID
    )
    if(!lockedReasonObj) return;

    dispatchLock({
      lockedAt,
      lockedReason: lockedReasonObj.Reason,
      lockedMoreDetails: detailsAccount.LockedReason,
      adminLocked: detailsAccount.LockAdminUsername
    })
  }, [detailsAccount])

  return (
    <styles.Bound>
      <div className="container">
        <div className="header">
          <p className="title">Khóa tài khoản</p>
          {/* <div className="ic_close" onClick={callbackClosePopup}>
            <IcCloseBlack />
          </div> */}
        </div>

        <p className="time_lock">
          Tài khoản này đã bị khóa vào <span>{stateLock.lockedAt}</span>
        </p>

        <p className="type">
          Loại vi phạm: <span>{stateLock.lockedReason}</span>
        </p>
        <p className="description">
          Mô tả thêm: <span>{stateLock.lockedMoreDetails}</span>
        </p>
        <p className="lock_by">
          Khóa bởi: <span>@{stateLock.adminLocked}</span>
        </p>
      </div>
      {/* <div className="btn--unlock" onClick={_onSubmitUnLock}>
        <p>MỞ KHÓA</p>
      </div> */}
      <div className="triangle-right" />
    </styles.Bound>
  );
};

PopupUnLockDetailAccount.propTypes = {
  callbackClosePopup: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default React.memo(PopupUnLockDetailAccount);
