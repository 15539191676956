import styled, { css } from 'styled-components';

const ButtonStyles = styled.button`
    outline: none;
    font-family: 'SVN-Gilroy';
    .text{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    :hover {
        cursor: pointer;
    }
    
    ${props => css`${props.specificStyles}`}
    ${props => props.extendStyles}
`;

export {
    ButtonStyles
}