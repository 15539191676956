import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as style from './index.style'
// images
import IcCloseBlack from "../../../../../assets/images/components/Ic_close_black";

// action
import { getUserInfos, unlockAccount } from '../../../../../redux/userCensorship/detailsUser/detailsAccount/action';
import { getListInfoUsers } from '../../../../../redux/listInfoUsers/action';

const PopupOpenDetailAccount = ({ callbackClosePopup }) => {
    const valueDescriptionRef = useRef();
    const dispatch = useDispatch();

    const { pageNumber, detailsAccount } = useSelector(state => ({
        detailsAccount: state.userCensorship.detailsUser.detailsAccount,
        pageNumber: state.listInfoUsers.condFilters.PageNumber,
    }))

    const _callbackSuccess = useCallback(() => {
        callbackClosePopup();
        dispatch(getUserInfos({ UserID: detailsAccount.ID }))
        dispatch(getListInfoUsers({
            PageNumber: pageNumber,
            PageSize: 8,
        }))

    }, [callbackClosePopup, detailsAccount])

    const _onSubmitUnLock = useCallback(() => {
        const { ID } = detailsAccount
        const valueDescription = valueDescriptionRef.current;
        const { value } = valueDescription

        // console.log(ID, value)
        dispatch(unlockAccount({ UID: ID, UnlockReason: value }, _callbackSuccess))
    }, [detailsAccount, _callbackSuccess]);

    return (
        <style.Bound>
            <div className="container">
                <div className="header">
                    <p className="title">Mở khóa tài khoản</p>
                    <div className="ic_close" onClick={callbackClosePopup}>
                        <IcCloseBlack />
                    </div>
                </div>

                <p className="content">
                    Tài khoản này sẽ sử dụng bình thường!
                </p>
                <input
                    type="text"
                    ref={valueDescriptionRef}
                    placeholder="Nhập thông tin mô tả thêm"
                />
            </div>
            <div className="block__btn">
                <div className="block__btn--no" onClick={callbackClosePopup}>
                    <p>Đóng</p>
                </div>
                <div className="block__btn--yes" onClick={_onSubmitUnLock}>
                    <p>Mở khóa</p>
                </div>
            </div>
            <div className="triangle-right" />

        </style.Bound>
    )
}

export default React.memo(PopupOpenDetailAccount)
