import React from "react";
import { css } from "styled-components";

//tool
import { renderNameCategory } from "../../../../assets/helper/tools";
//host
import { apiAvatarChain } from "../../../../assets/constants/host"
//component
import RowIconDevice from "../../RowIconDevice";
import RowContent from "../../RowContent";
//constant
import * as stylesConstants from '../../../../assets/constants/ui'
import * as keys from '../../../../assets/constants/keys'
//action
import { getListChains, resetListChain } from '../../../../redux/chains/listChains/action'
import { getChainDetails, resetChainDetail } from "../../../../redux/chains/chainInfos/chainDetails/action";

export const ISALLOWCLICK = true;
export const ISCONNECT = false;
export const KEY = keys.CHAINS

export const HEIGHT_ROW_ITEM_CONTAINER = '336px'

export const TABLE_CONTENT_STYLED = css`
    padding: 0 24px 0 58px;
    width: calc(100% - (24px + 58px));
`;

export const TABLE_ROW_ITEM_STYLED = css`
    grid-template-columns: 174px 1fr;
    grid-template-rows: 174px 1fr;
    grid-gap: 30px;
    height: calc(336px - 40px);
    padding: 20px;
    .logo-chain{
        width: 174px;
        height: 174px;
        background: #F4F4F4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-chain{
            object-fit: contain;
            width: 174px;
            height: 174px;
            border-radius: 10px;
        }
    }
    .info-right-chain{
        background: #FFFFFF;        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
            font-family: "SVN-Gilroy";
            font-style: normal;
            margin: 0;            
        }
        .name-retail {
            font-family: "SVN-Gilroy";
            font-weight: bold;
            font-size: 30px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #15314c;
            width: 475px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        .name-category {
            font-family: "SVN-Gilroy";
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: rgba(21, 49, 76, 0.5);
            margin: 0 0 15px 0;
        }
        .phone-number {
            font-family: "UTM Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #15314c;
            margin: 0 0 12px 0;
        }
    
    }
    .info-bottom-chain{
        width: 100%;
        grid-column: 1/ span 2;
    }
`;

export const caculateTableHeightContent = () => {
    let vh = document.body.offsetHeight
    if (!vh) return
    let mainBound = document.getElementById('main-bound-id')
    if(mainBound){
        return mainBound.offsetHeight -
            (stylesConstants.HEADER_HEIGHT)
    }
    else
        return vh -
            (stylesConstants.HEADER_HEIGHT)
}

export const _actionOnClickItem = (item) => getChainDetails({ ChainID: item.ID })

export const _onLoadMore = ({size, keyword, typeView}) => getListChains({ PageNo: 1, PageSize: size, Filter: keyword })

export const _onUnmount = (dispatch) => {
    dispatch(resetListChain())
    dispatch(resetChainDetail())
}

export const renderItem = ({item, funcTranslate, funcImageError, index}) => {

    if (!item) return;
    return (
        <React.Fragment>
            <div className='logo-chain'>
                <img
                    className="avatar-chain"
                    src={item && item.ID ? apiAvatarChain(item.ID, item.UpdateTime) : ""}
                    alt="avatar-chain"
                    id = {`${KEY}-${index}`}
                    onError={()=>funcImageError(`${KEY}-${index}`)}
                />
            </div>
            <div className='info-right-chain'>
                <p className="name-retail" 
                    data-toogle="tooltip"
                    title={item?.Name ? item.Name : null} >
                    {item?.Name ? item.Name : "-"}
                </p>
                <p className="name-category">
                    {renderNameCategory(item && item.Category)}
                </p>
                <p className="phone-number">
                    {item && item.PhoneNumber ? item.PhoneNumber : "-"}
                </p>
                <RowIconDevice data={item ? item : null} />
            </div>
            <div className='info-bottom-chain'>
                <RowContent title="Email" content={item?.Email} />
                <RowContent title="Trụ sở" content={item?.Address} />
                <RowContent title="Khu vực" content={item?.Area.join(", ")} />
            </div>
        </React.Fragment>
    );
};


