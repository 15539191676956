import React from 'react'

const IcCheckGrey = (props) => {
    return (
        <svg {...props} width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M7.5 0.5C3.36433 0.5 0 3.86433 0 8C0 12.1357 3.36433 15.5 7.5 15.5C11.6357 15.5 15 12.1357 15 8C15 3.86433 11.6357 0.5 7.5 0.5Z" fill="#98ACC5" />
                <path d="M11.3012 6.4108L7.2387 10.4732C7.11682 10.5951 6.95683 10.6564 6.79684 10.6564C6.63686 10.6564 6.47687 10.5951 6.35499 10.4732L4.32378 8.44201C4.07933 8.19768 4.07933 7.80263 4.32378 7.5583C4.56811 7.31385 4.96304 7.31385 5.20749 7.5583L6.79684 9.14765L10.4175 5.52708C10.6619 5.28264 11.0568 5.28264 11.3012 5.52708C11.5456 5.77142 11.5456 6.16635 11.3012 6.4108Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={15} height={15} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcCheckGrey
