import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import * as styles from './index.style'

//data
import { user_censorship } from '../../../../../data/mockData'
//tools
import { convertDateTime, renderNameCategory } from '../../../../../assets/helper/tools'
import { apiAvatarChain } from '../../../../../assets/constants/host';
//component
import RowIconDevice from '../../../../Control/RowIconDevice';
import RowContent from '../../../../Control/RowContent';
import LogoChainsDefault from '../../../../../assets/images/components/LogoChainsDefault';

const ChainInformation = () => {
    const chains = useSelector(state => state.chains.chainInfos.chainDetails)

    const { t } = useTranslation('common')

    const [data, setData] = useState({})

    const [isImgError, setIsImgError] = useState(false)

    useEffect(() => {
        setData(chains.details)
        setIsImgError(false)
    }, [chains.details])


    return (
        <styles.Bound>
            <div className='logo-chain'>
                {
                    isImgError || !data?.ID ?
                        <LogoChainsDefault className="avatar-chain" />
                        :
                        <img
                            className="avatar-chain"
                            src={apiAvatarChain(data.ID, data.UpdateTime)}
                            alt="avatar-chain"
                            onError={() => setIsImgError(true)}
                        />
                }
            </div>
            <div className='info-right-chain'>
                <p
                    className="name-retail"
                    data-toogle="tooltip"
                    title={data?.Name ? data.Name : null}
                >
                    {data?.Name ? data.Name : "-"}
                </p>
                <p className="name-category">
                    {renderNameCategory(data && data.Category)}
                </p>
                <p className="phone-number">
                    {data && data.PhoneNumber ? data.PhoneNumber : "-"}
                </p>
                <RowIconDevice data={data ? data : null} />
            </div>
            <div className='info-bottom-chain'>
                <RowContent title="Email" content={data?.Email} />
                <RowContent title="Trụ sở" marginBottom="0" content={data?.Address} />
            </div>

        </styles.Bound>
    )
}

export default React.memo(ChainInformation)
