export const HOME = 'home'
export const USER_CENSORSHIP = 'user-censorship'
export const USERS = 'users'
export const TRANSACTIONS = 'transactions'
export const CHAINS = 'chains'
export const MPOS = 'mpos'
export const VERSION_RELEASED = 'version-released'
export const SYSTEM_LOG = 'system-log'
export const SETTING = 'setting'

export const MAIN = 'main'
export const LOGIN = 'login'
export const COMPARE_PAGE = "compare"