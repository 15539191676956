import React from 'react'

const IcCancel = (props) => {
    return (
        <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="19" height="19" rx="9.5" fill="white"/>
            <path d="M6 6L13.0005 13.0005" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 13L13.0005 5.99955" stroke="#1C1C1C" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default IcCancel
