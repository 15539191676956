import styled from 'styled-components'
const Bound = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 20px 100px 30px 30px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    .info-trans-container{
        display: flex;
        flex-direction: column;       
        .status-trans-wrap{
            display: grid;
            grid-template-columns: 240px 170px 1fr;
            grid-gap: 30px;
            margin-bottom: 42px;
            .code-wrap{
                display: flex;
                flex-direction: column; 
                height: 60px;               
                .trans-code{
                    display: flex;                   
                }
                .order-code{
                    display: flex;
                }                
            }
            .title-code{
                color: #6F6F6F;
                margin: 0 20px 12px 0;
                width: 86px;
            }
            .content-code{                    
                color: #1C1C1C;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 130px;
            }
            .status-wrap{
                display: flex;
                flex-direction: column;
                height: 60px; 
                .title-status{
                    color: #6F6F6F;
                    margin-bottom: 12px;
                }
                .content-status{
                    font-weight: 600;                    
                    &.success{
                        color: #09AC10;
                    }
                    &.failed{
                        color:#FA8C8C
                    }
                }
            }
            .refund-wrap{
                display: flex;
                flex-direction: column;
                height: 60px;
                .title-refund{
                    color: #6F6F6F;
                    margin-bottom: 12px;
                }
                .content-refund{
                    color: #1C1C1C;
                } 
            }
        }
        .info-trans-wrap{
            display: grid;
            grid-template-columns: 440px 166px 166px;
            grid-gap: 30px;            
            .info-user-wrap{
                display: flex;
                flex-direction: column;               
                .wrapper{
                    display: grid;
                    grid-template-columns: 240px 1fr;
                    grid-gap: 30px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
                    width: 100%;
                    .avatar-user-container{
                        display: flex;
                        margin-bottom: 15px;
                        .avatar-user-wrap{
                            display: flex;
                            align-items: center;           
                            .avatar-user{
                                width: 44px;
                                height: 44px;
                                border-radius: 50%;
                                object-fit: cover;                                
                            }
                        }
                        .info-user{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 100%;
                            padding-left: 10px;
                            .fullname-user{
                                font-weight: 600;                                
                                color: #051321;
                                margin-bottom: 10px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: inline-block;
                                max-width: 186px;
                            }
                            .wrapper-username{                                
                                color: #6F6F6F;
                                display: flex;
                                align-items: center;
                                max-width: 186px;
                                span{
                                    color: #2D98F0;
                                    padding-right: 5px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;
                                    max-width: 90px;
                                }
                            }
                        }
                    }
                }
                .info-store{
                    display: grid;
                    grid-template-columns: 240px 1fr;
                    grid-gap: 30px;                    
                    .store-wrap{
                        display: flex;
                        flex-direction: column;
                        margin-top: 15px;
                        .logo-store{
                            height: 30px;
                            margin-bottom: 8px;
                            img{
                                height: 30px;
                            }
                        }
                        .name-store{
                            color: #051321;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                            max-width: 240px;
                        }
                    }
                }
                .money-wrap{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &.total-amount{
                        margin-top: 17px
                    }                 
                    .title-money{
                        color: #6F6F6F;
                        height: 24px;
                        margin-bottom: 10px;
                    }
                    .content-money{
                        font-size: 20px;
                        line-height: 21px;
                        color: #FA8C8C;
                        margin-bottom: 15px;
                        &.amount{
                            margin-top: 15px
                        }                       
                    }
                }
            }
            .trans-wrap{
                display: flex;
                flex-direction: column;                
                .title-trans{
                    color: #6F6F6F;
                    margin-bottom: 10px;                    
                }
                .content-trans{
                    color: #1C1C1C;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    word-break: break-word;
                }
            }
        }
    }
    .video-container{
        display: flex;
        align-items: center;
        width: 370px;
        height: 233px;
        border-radius: 3px;
        overflow: hidden;
        .no-video{
            width: 370px;
            height: 233px;
            background-color:#eaeff4cc; 
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .video{
            outline: none;
        }   
    }


`
export { Bound }