import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
//tool
import { convertDateTime } from '../../../../../assets/helper/tools'
//style
import * as style from './index.style'

const PopupInfoDetailAccount = () => {

    const { detailsAccount } = useSelector(state => ({
        detailsAccount: state?.userCensorship?.detailsUser?.detailsAccount
    }))

    const [stateLock, dispatchLock] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            UnlockAdminID: '',
            unlockAt: '',
            unlockAdminUsername: '',
        }
    )

    useEffect(() => {
        if (detailsAccount)
            dispatchLock({
                unlockAdminID: detailsAccount.UnlockAdminID,
                unlockAt: detailsAccount.UnlockAt,
                unlockAdminUsername: detailsAccount.UnlockAdminUsername
            })
    }, [detailsAccount])

    return (
        <style.Bound>
            <div className="container">
                <div className="header">
                    <p className="title">Thông tin</p>
                </div>
                {
                    stateLock?.unlockAdminID === "000000000000000000000000" ?
                        <p className="account-nomal">Tài khoản hoạt động bình thường</p>
                        :
                        <>
                            <p className="time_lock">
                                Tài khoản được mở khóa vào ngày:
                    <span>{stateLock?.unlockAt ? convertDateTime(stateLock.unlockAt, 'DD/MM/YYYY HH:mm') : ""}</span>
                            </p>
                            <p className="lock_by">
                                Mở khóa bởi:
                    <span>{stateLock?.unlockAdminUsername ? `@${stateLock.unlockAdminUsername}` : ""}</span>
                            </p>
                        </>
                }
            </div>
            <div className="triangle-right" />
        </style.Bound>
    )
}

export default React.memo(PopupInfoDetailAccount)
