import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// style
import * as styles from "./index.style";

// images component
import BackGroundLogin from "../../assets/images/components/BackGroundLogin";
import IcLogoFacepayWhite from "../../assets/images/components/IcLogoFacePayWhite";
import IcPasswordHide from "../../assets/images/components/IcPasswordHide";
import IcPasswordShow from "../../assets/images/components/IcPasswordShow";
import { checkMail } from "../../assets/helper/tools";

// constant
import { FACE_PAY_BO_V2_LOGIN } from "../../assets/constants/localStore";
import { NETWORK_ERR } from "../../redux/notifies/types";
// import { loginAction } from "../../redux/actions/auth.action";
import { loginAction } from "../../redux/authenticates/action";
import { clearStores } from "../../redux/commonAction";
// path
import * as pathNames from '../../assets/constants/pathName'

const errConnectNetwork = "Lỗi kết nối mạng"
const titleWrongLogin = "Sai thông tin đăng nhập!";

const LoginPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();


  const [errContent, setErrContent] = useState("");
  const [showPass, setShowPass] = useState(true);
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  const _callbackError = (errType) => {
    if(errType === NETWORK_ERR) {
      setErrContent(errConnectNetwork);
      return;
    }
    setErrContent(titleWrongLogin);
  }

  const _handleSubmit = (e) => {
    e.preventDefault();
    const checkEmail = checkMail(emailValue);
    const dataLogin = {
      Email: emailValue,
      Password: passwordValue,
    };
    if (!checkEmail || !emailValue || !passwordValue) {
      setErrContent(titleWrongLogin);
      return;
    }
    dispatch(loginAction(history, dataLogin, null, _callbackError));
  };

  const _changeInputEmail = (e) => {
    const { value } = e.target;
    // Check prevent space
    if (value.includes(" ")) return;

    setEmailValue(value);
    setErrContent("");
  };

  const _changeInputPassword = (e) => {
    const { value } = e.target;
    // Check prevent space
    // if (value.includes(" ")) return;

    setPasswordValue(value);
    setErrContent("");
  };

  useEffect(() => {
    const login = localStorage.getItem(FACE_PAY_BO_V2_LOGIN);
    if (!login){
      dispatch(clearStores())
      return;
    }

    history.push(`/${pathNames.MAIN}`);
  }, []);

  return (
    <styles.Bound>
      <BackGroundLogin className="bg-image" />
      <div className="login-container">
        <IcLogoFacepayWhite className="logo-facepay" />
        <div className="login-input">
          <p className="title">Đăng nhập</p>
          <form onSubmit={_handleSubmit}>
            <p className="label-email">Email</p>
            <input
              className="input-email"
              onChange={_changeInputEmail}
              type="text"
              value={emailValue}
              placeholder="Vui lòng nhập email"
            />

            <p className="label-password">Mật khẩu</p>
            <div className="block-pass">
              <input
                className="input-password"
                onChange={_changeInputPassword}
                type={showPass ? "password" : "text"}
                value={passwordValue}
                placeholder="Vui lòng nhập mật khẩu"
              />
              <div className="hint-pass" onClick={() => setShowPass(!showPass)}>
                {showPass ? <IcPasswordHide /> : <IcPasswordShow />}
              </div>
              <p className="err-content">{errContent}</p>
            </div>

            <styles.Button className="btn-login">ĐĂNG NHẬP</styles.Button>
          </form>
        </div>
      </div>
    </styles.Bound>
  );
};

export default React.memo(LoginPage);
