import styled, { css } from 'styled-components'

const WrapPopupConfirmDeleteChain = styled.div`
    box-sizing: border-box;
    position: absolute;
    bottom: calc(100% + 22px);
    padding: 30px 25px 25px;
    font-family: SVN-Gilroy;
    width: 403px;
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
    border-radius: 3px;

    .question-confirm {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 19px;
        color: #15314C;
    }

    .note-when-delte {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #15314C;
        margin-top: 10px;
    }

    .wrap-form {
        margin-top: 30px;
        display: flex;
        align-items: center;

        input {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 122.34%;
            outline: none;
            padding: 17px 10px;
            border: 1px solid #C6D5EB;
            box-sizing: border-box;
            border-radius: 3px;
            background: #FFFFFF;
            flex-grow: 1;
            height: 48px;
        }
    }

    .arrow {
        position: absolute;
        top: calc(100% - 7px);
        transform: rotate(90deg);
    }
`;

const extendStylesBtnDel = css`
    background: #FA8C8C;
    border-radius: 3px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
    flex-shrink: 0;
    padding: 16px 12px 10px 12px;
    text-transform: uppercase;
    height: 48px;
    box-sizing: border-box;
    border: none;
    margin-left: 20px;
    pointer-events: ${props => props.isDisable? 'none': 'auto'};
    opacity: ${props => props.isDisable? '0.4': '1'};
`

const WrapOverlay = styled.div`
    ::before {
        content: '';
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        /* background: rgba(0, 0, 0, 0.3); */
    }

    & > * {
        z-index: 4;
    }
`;

export {
    WrapPopupConfirmDeleteChain,
    extendStylesBtnDel,
    WrapOverlay
}