import * as types from "./types";

export const intitialState = {
    listReasons: {},
    listHistory: [],
    isLoading: false
};

const faces = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_REASON_FACES: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.GET_AVATAR_HISTORY_BY_USER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.VERIFY_AVATAR_START: {
            return {
                ...state,
                isLoading: true
            }
        }
        case types.VERIFY_AVATAR_FINISH: {
            return {
                ...state,
                isLoading: false    
            }
        }
        case types.CLEAR_FACES: {
            return {
                listReasons: state.listReasons
            }
        }
        default:
            return state;
    }
};

export default faces;
