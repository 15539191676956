import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding: 30px 27px 0 25px;
    .wrapper-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        .button-export{
            display: flex;
            justify-content: flex-end;
        }
        .list-trans-history-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow-x: hidden;
            overflow-y: overlay;
            .empty-control{
                margin-top: 215px;
            }
            .list-trans-history{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                box-sizing: border-box;
                overflow-x: hidden;
                /* overflow-y: overlay; */
                .wrap-list{
                    height: 776px;
                    .wrapper-row{
                        display: flex;
                        position: relative;
                        width: 100%;
                        margin-bottom: 15px;
                        transition: opacity 500ms, height 500ms, padding 500ms;
                        :last-child{
                            margin-bottom: 0px;
                        }
                        .line-connect-container{
                            position: absolute;
                            top: 0;
                            left: -37px;
                            height: 100%;
                            width: 18px;
                            .circle{
                                width: 17px;
                                height: 17px;
                                border-radius: 50%;
                                border: 1px solid #2D98F0;
                                background-color: #fff;
                                position: absolute;
                                top: 40%;
                                left: 0;
                                z-index:1;
                            }
                            .line{
                                width: 1px;
                                height: calc(100% + 15px);
                                background-color: #2D98F0;
                                position: absolute;
                                top: 0;
                                left: 9px;
                                z-index:0;
                            }
                        }
                        :first-child{
                            .line-connect-container{
                                .line{
                                    top: calc(25% + 12px);
                                }
                            }
                        }
                        :last-child{
                            .line-connect-container{
                                .line{
                                    height: calc(25% + 12px);
                                }
                            }
                        }
                    }
                }                
            }
            .wrap-pagination{
                width: 100%;
                height: 40px;
                margin: 20px 0 30px 0;
                padding-right: 5px;
            }
        }
    }

`
export { Bound }