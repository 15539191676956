
import Axios from 'axios'

// host
import { host } from '../../assets/constants/host'

// helper
import mapErrors from '../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../assets/constants/codeResponse'


const apiGetListInfoUsers = `${host}/backoffice/get-user-list`

export const getListInfoUsers = ({ PageNumber, PageSize }) => async dispatch => {
    const condFilters = {
        PageNumber,
        PageSize,
        // Key: ''
    }

    const dataRequest = JSON.stringify(condFilters)

    try {
        const res = await Axios.post(apiGetListInfoUsers, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS
        if(isSuccess) {
            dispatch({
                type: types.GET_LIST_INFO_USERS,
                payload: {
                    list: data.Users || [],
                    total: data.Total || 0,
                    condFilters
                }
            })
        }
        else {
            dispatch({
                type: types.GET_LIST_INFO_USERS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_LIST_INFO_USERS_ERR))
    }
}