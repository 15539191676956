import styled from "styled-components";

const Bound = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .bg-image {
    position: absolute;
    z-index: -1;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
  .login-container {
    width: 566px;
    height: 578px;
    background-color: #002249;
    backdrop-filter: blur(40px);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    .logo-facepay {
      padding: 60px 0;
      width: 100%;
    }
    .login-input {
      padding: 0 60px;
      .title {
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 170%;
        color: #ffffff;
        height: 42px;
        display: flex;
        align-items: center;
        margin: 0;
      }
      form {
        margin: 30px 0 0 0;
        .input-email,
        .input-password {
          background-color: #ffffff;
          border: 1px solid #c6d5eb;
          box-sizing: border-box;
          border-radius: 3px;
          padding: 0 10px;
          width: 445px;
          height: 48px;
          font-family: "SVN-Gilroy";
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 15px;
          color: #212121;
          &:focus {
            border: 2px solid #1279da;
            outline: none;
          }
          &::placeholder {
            opacity: 0.5;
          }
        }
        .input-email {
          margin: 0 0 20px 0;
        }
        .label-email,
        .label-password {
          margin: 0 0 10px 10px;
          font-size: 12px;
          line-height: 128%;
          color: #959595;
        }
        .block-pass {
          position: relative;
          width: fit-content;
          .hint-pass {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translate(-50%, -10px);
            cursor: pointer;
          }
          .input-password {
            padding-right: 50px;
          }
        }
      }
      .err-content {
        position: absolute;
        top: 55px;
        left: 0;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: #ff3e3e;
      }
    }
  }
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2D98F0;
  border-radius: 3px;
  width: 445px;
  height: 60px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: 0.05px;
  color: #ffffff;
  outline: none;
  border: none;
  margin: 60px 0 52px 0;
  cursor: pointer;
`;

export { Bound, Button };
