import React, { useState, useReducer, useRef, useEffect } from 'react'

//style
import { WrapFilterUsers } from './index.styles'
//component
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'


const DropDownFilterInfoUsers = () => {

    const [activeFilter, setActiveFilter] = useState(false)
    const [isShowDropdown, setIsShowDropdown] = useState(false)

    const _handleOpenBox = () => {

    }
    return (
        <WrapFilterUsers >
            <TitleDropdownFilter onClick={_handleOpenBox} active={activeFilter} disabled={true}>
                Thông tin User
            </TitleDropdownFilter>
            {/* {
                isShowDropdown &&
                
            } */}
        </WrapFilterUsers>
    )
}

export default DropDownFilterInfoUsers
