const ERR_IMG_INVALID_TYPE = 'ERR_IMG_INVALID_TYPE'
const ERR_IMG_INVALID_SIZE = 'ERR_IMG_INVALID_SIZE'
const ERR_IMG_INVALID_RATIO = 'ERR_IMG_INVALID_RATIO'

const errorImgTypes = {
    [ERR_IMG_INVALID_TYPE]: 'File upload không đúng tiêu chuẩn',
    [ERR_IMG_INVALID_SIZE]: 'File upload không đúng tiêu chuẩn',
    [ERR_IMG_INVALID_RATIO]: 'File upload không đúng tiêu chuẩn',
    // [ERR_IMG_INVALID_TYPE]: 'Vui lòng chọn 1 file ảnh có định dạng png hoặc jpg',
    // [ERR_IMG_INVALID_SIZE]: 'Vui lòng chọn 1 file có dung lượng tối đa 10MB',
    // [ERR_IMG_INVALID_RATIO]: 'File của bạn phải đạt kích cỡ 300x300 hoặc tỉ lệ 1:1',
}

export {
    ERR_IMG_INVALID_TYPE,
    ERR_IMG_INVALID_SIZE,
    ERR_IMG_INVALID_RATIO
}

export default errorImgTypes