import { combineReducers } from "redux";

import chainDetails from './chainDetails'
import stores from './stores'
import areas from './areas'
import notes from './notes'

const chainInfos = combineReducers({
    chainDetails,
    stores,
    areas,
    notes
})

export default chainInfos