import React from 'react'
import { css } from "styled-components";
//component 
import avatar from '../../../../assets/images/avatar.svg'
import avatar_default from '../../../../assets/images/avatar_default.svg'
import IcKey from '../../../../assets/images/components/IcKey'
import IcNote from '../../../../assets/images/components/IcNote';
import IcUserBlue from '../../../../assets/images/components/IcUserBlue';
import IcDataBlue from '../../../../assets/images/components/IcDataBlue';
import IcDataGrey from '../../../../assets/images/components/IcDataGrey';
import IcUserGrey from '../../../../assets/images/components/IcUserGrey';
//tools
import { convertDateTime } from '../../../../assets/helper/tools';
//constant
import * as stylesConstants from '../../../../assets/constants/ui'
import * as keys from '../../../../assets/constants/keys'

export const ISALLOWCLICK = false;
export const ISCONNECT = true;
export const KEY = keys.LOGIN_HISTORY
export const HEIGHT_ROW_ITEM_CONTAINER = '82px'

export const TABLE_CONTENT_STYLED = css`
    /* padding: 0 24px 0 58px;
    width: calc(100% - (24px + 58px)); */
    width: 100%;
`

export const TABLE_ROW_ITEM_STYLED = css`
    grid-template-columns: 53px 254px 306px 1fr 298px;
    background-color: rgba(244, 244, 244, 0.6);
    mix-blend-mode: normal;
    box-shadow: inset 0px -0.5px 0px #E2E2E2;
    left: 45px;
    width: calc(100% - 45px);
    height: 82px;
    .key{
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .time-create{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .login-type{
        display: flex;
        flex-direction: column;
        justify-content: center;        
        .p-bold{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #051321;
        }
        .p-thin-blue{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            display: flex;
            align-items: center;
            color: #2D98F0;
            .icon-note{
                margin-left: 5px            
            }
        }
    }
    .location{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .status-container{
        display: flex;
        align-items: center;
        .status{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .avatar{
                width: 24px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid #184DA2;
                &.default{
                    border: 1px solid #FA8C8C;
                }             
            }           
            span{
                margin-top: 10px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 170%;
                color: #959595;
            }
        }
        .line{
            width: 20px;
            height: 0px;
            background: #184DA2;
            border: 1px solid #184DA2;
            margin: -30px 15px 0 15px;
        }
    }
    .demo{
        position: absolute;
        top: 50%;
        left: -100px;
        width: 25px;
        height: 25px;
        background-color: red;
    }
`

export const caculateTableHeightContent = () => {
    let vh = document.body.offsetHeight
    if(!vh) return
    return vh - (stylesConstants.HEADER_HEIGHT + stylesConstants.MAIN_CONTENT_PADDING_TOP + stylesConstants.TABLE_HEADER_HEIGHT)
}

export const renderItem = ({item}) => {
    if (!item) return
    return (
        <React.Fragment>
            <div className='demo'></div>
            <div className='key'>
                <IcKey />
            </div>
            <div className='time-create'>
                <p className='p-bold'>{item && item.timeCreate ? convertDateTime(item.timeCreate, "DD [Tháng] M, YYYY") : "-"}</p>
                <p className='p-thin'>{item && item.timeCreate ? convertDateTime(item.timeCreate, "HH:mm") : "-"}</p>
            </div>
            <div className='login-type'>
                <p className='p-bold'>{item && item.loginType ? item.loginType : "-"}</p>
                <p className='p-thin-blue'
                    data-toogle="tooltip"
                    title={
                        `Nhà sản xuất: ${item.DeviceBrand ? item.DeviceBrand : "---"}  |  ` +
                        `Model: ${item.DeviceName ? item.DeviceName : "---"}  |  ` +
                        `OS: ${item.DeviceOS ? item.DeviceOS : "---"}  |  ` +
                        `IP: ${item.IP ? item.IP : "---"}  `
                    }
                >
                    {item && item.DeviceName ? item.DeviceName : "-"}
                    <IcNote className="icon-note" />
                </p>
            </div>
            <div className='location'>
                <p className='p-normal'>{item && item.location ? item.location : "-"}</p>
            </div>
            <div className='status-container'>
                <div className="status">
                    {
                        item && item.usernameProccess ?
                            <IcUserBlue />
                            :
                            <IcUserGrey />
                    }
                    <span>Username</span>
                </div>
                <div className='line'></div>
                <div className="status">
                    {
                        item && item.faceProccess ?
                            <img className="avatar" src={avatar} alt='avartar-user' />
                            :
                            <img data-toogle="tooltip" title="Lỗi 81 không nhận được khuôn mặt" className="avatar default" src={avatar_default} alt="avatar-default" />
                    }
                    <span>Khuôn mặt</span>
                </div>
                <div className='line'></div>
                <div className="status">
                    {
                        item && item.dataProccess ?
                            <IcDataBlue />
                            :
                            <IcDataGrey />
                    }
                    <span>Dữ liệu</span>
                </div>
            </div>
        </React.Fragment>
    )
}