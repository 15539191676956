import React, { useState } from 'react'

// styles
import { WrapPopup } from './index.style'
import Popup from '../../../../../Control/Popup'
import ContentPopupEditStore from './ContentPopupEditStore'

const PopupEditStore = ({ handleClose, store }) => {

    const [isNeedConfirmClose, setIsNeedConfirmClose] = useState(false)

    return (
        <WrapPopup>
            <Popup
                title="Chỉnh sửa thông tin cửa hàng"
                handleClose={handleClose}
                isNeedConfirmClose={isNeedConfirmClose}
            >
                <ContentPopupEditStore store={store} handleClose={handleClose} setIsNeedConfirmClose={setIsNeedConfirmClose} />
            </Popup>
        </WrapPopup>
    )
}

export default PopupEditStore
