import Axios from "axios";

// host
import { host } from "../../../assets/constants/host";

// helper
import mapErrors from "../../../assets/helper/mapErrors";
import genApiGetRequest from "../../../assets/helper/genApiGetRequest";
import autoDownloadFromBlob from "../../../assets/helper/autoDownloadFromBlob";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../assets/constants/codeResponse";

// api
import { apiGetListTransactions, apiExportListTransactions } from "./api";

// verify params
import { verifyParamsActionGetListTrans } from "./verifyParams";

export const fetchListTransactions = (payload) => ({
    type: types.GET_LIST_TRANSACTIONS,
    payload
})

/**
 * @typedef { 1 | 0 | -1 } sortType - 1: ascending; 0: not use; -1: decreasing
 * @typedef { GREATER | LESS | GREATER_OR_EQUAL | LESS_OR_EQUAL | NOT_EQUAL | EQUAL } operatorType
 * @param {{
 *  PageNumber: number,
 *  PageSize: number,
 *  FacepayNumber: {
 *      Sort: sortType,
 *      No: string
 *  }
 *  Shop: {
 *      Sort: sortType,
 *      ShopName: string
 *  },
 *  Amount: {
 *      Sort: sortType,
 *      firstCompares: {
 *          Operator: operatorType,
 *          Value: number
 *      },
 *      secondCompares: {
 *          Operator: operatorType,
 *          Value: number
 *      }
 *  },
 *  Time: {
 *      Sort: sortType,
 *      FromDate: string,
 *      ToDate: string
 *  },
 *  User: {
 *      Name: string,
 *      Username: string,
 *      PhoneNumber: string,
 *  },
 *  Status: {
 *      Avatar: string[],
 *      Email: string[],
 *      EKYC: string[],
 *      Locked: string[('LOCKED' | 'NORMAL')]
 *  }
 * }} conditionFilter
 * @param conditionFilter.PageNumber - one value in range from 1 to n (n > 1)
 * @param conditionFilter.PageSize - one value in range from 1 to n (n > 1)
 * @returns {{
 *  Total: number,
 *  Transactions: object[]
 * */
export const getListTransactions = ({ 
    PageNumber, 
    PageSize,
    FacepayNumber,
    Shop,
    Amount,
    Time,
    User,
    Status
}) => async dispatch => {
    
    const condFilters = {
        PageNumber, 
        PageSize,
        FacepayNumber,
        Shop,
        Amount,
        Time,
        User,
        Status
    }

    // dispatch(fetchListTransactions({
    //     list: [],
    //     total: 0,
    //     condFilters
    // }))

    // return;

    const condRequestFilters = (() => {
        if(!condFilters.Amount) return condFilters
        return {
            ...condFilters,
            Amount: {
                Sort: condFilters.Amount.Sort,
                Comparers: [
                    condFilters.Amount?.firstCompares, 
                    condFilters.Amount?.secondCompares
                ].filter(item => !!item)
            }
        }
    })()

    verifyParamsActionGetListTrans(condRequestFilters)

    const dataRequest = JSON.stringify(condRequestFilters)

    try {
        const res = await Axios.post(apiGetListTransactions, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;

        if(isSuccess) {
            // console.log(data)
            dispatch(fetchListTransactions({
                list: data.Transactions || [],
                total: data.Total || 0,
                condFilters,
                condRequestFilters
            }))
            // dispatch({
            //     type: types.GET_LIST_TRANSACTIONS,
            //     payload: {
            //         list: data.Transactions || [],
            //         total: data.Total || 0,
            //         condFilters
            //     }
            // })
        }
        else {
            dispatch(fetchListTransactions({
                condFilters,
                condRequestFilters
            }))
            dispatch({
                type: types.GET_LIST_TRANSACTIONS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_LIST_TRANSACTIONS_ERR))
    }
}

/**
 * @typedef { 1 | 0 | -1 } sortType - 1: ascending; 0: not use; -1: decreasing
 * @typedef { GREATER | LESS | GREATER_OR_EQUAL | LESS_OR_EQUAL | NOT_EQUAL | EQUAL } operatorType
 * @param {{
 *  PageNumber: number,
 *  PageSize: number,
 *  FacepayNumber: {
 *      Sort: sortType,
 *      No: string
 *  }
 *  Shop: {
 *      Sort: sortType,
 *      ShopName: string
 *  },
 *  Amount: {
 *      Sort: sortType,
 *      firstCompares: {
 *          Operator: operatorType
 *          Value: number
 *      },
 *      secondCompares: {
 *          Operator: operatorType
 *          Value: number
 *      }
 *  },
 *  Time: {
 *      Sort: sortType,
 *      FromDate: string,
 *      ToDate: string
 *  },
 *  User: {
 *      Name: string,
 *      Username: string,
 *      PhoneNumber: string,
 *  },
 *  Status: {
 *      Avatar: string[],
 *      Email: string[],
 *      EKYC: string[],
 *      Locked: string[('LOCKED' | 'NORMAL')]
 *  }
 * }} condFilters
 * @param condFilters.PageNumber - one value in range from 1 to n (n > 1)
 * @param condFilters.PageSize - one value in range from 1 to n (n > 1)
 * */
export const exportExcelListTransaction = (condFilters) => async dispatch => {
    const filterConds = { 
        ...condFilters, 
        PageNumber: undefined, 
        PageSize: undefined 
    }

    const encodeFilterCondsToURI = encodeURIComponent(JSON.stringify(filterConds))

    const linkExport = genApiGetRequest(apiExportListTransactions, { filter: encodeFilterCondsToURI })

    try {
        const res = await Axios.get(linkExport, {
            responseType: 'blob'
        })

        const { data } = res;

        autoDownloadFromBlob(data, 'TransactionReport')
        if(data) {
            dispatch({
                type: types.EXPORT_EXCEL_LIST_TRANSACTION
            })
        }
        else {
            dispatch({
                type: types.EXPORT_EXCEL_LIST_TRANSACTION_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.EXPORT_EXCEL_LIST_TRANSACTION_ERR))
    }
}

export const clearListTransactions = () => ({
    type: types.CLEAR_LIST_TRANSACTION
})

// export const exportListTransactions = ({ FromDate, ToDate, Facepaynumber, PageSize, PageNumber, Shops }) => async dispatch => {
//     const linkGetFile = genApiGetRequest(apiExportListTransactions, { FromDate, ToDate, Facepaynumber, PageSize, PageNumber, Shops })
//     try {

//     }

// }

