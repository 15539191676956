import React from "react";
import * as styles from "./index.style";

//component
import TableComponent from "../../../../Control/TableComponent";
//template
import * as templateHeader from '../../../../Control/TableComponent/Template/userlogin.template.header'
import * as templateContent from '../../../../Control/TableComponent/Template/userlogin.template.content'
//data
import * as mockData from '../../../../../data/mockData'

const UserLoginHistory = (props) => {
    let data=[]
    const randomLength = Math.floor(Math.random() * mockData.user_login_history.length)
    for(let i=0; i< randomLength; i++){
        data.push(mockData.user_login_history[Math.floor(Math.random() * mockData.user_login_history.length) + 1])
    }
    return (
        <styles.Bound>
            <TableComponent 
                data = { data }
                templateHeader = { templateHeader }
                templateContent = { templateContent }
            />
        </styles.Bound>
    );
};

export default React.memo(UserLoginHistory);
