import * as types from "./types";

export const intitialState = {
    listReasons: {},
    listEKYC: [],
    isLoadingEditEkycs: false,
    isLoadingVerifyEkycs: false
};

const eKYCs = (state = intitialState, action) => {
    switch (action.type) {
        case types.EDIT_EKYC_INFOS_START: {
            return {
                ...state,
                isLoadingEditEkycs: true
            }
        }
        case types.EDIT_EKYC_INFOS_END: {
            return {
                ...state,
                isLoadingEditEkycs: false
            }
        }
        case types.VERIFY_EKYCS_START: {
            return {
                ...state,
                isLoadingVerifyEkycs: true
            }
        }
        case types.VERIFY_EKYCS_END: {
            return {
                ...state,
                isLoadingVerifyEkycs: false
            }
        }
        case types.GET_EKYC_BY_USER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.GET_REASON_EKYC: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CHECK_DUPLICATE_NUMBER: {
            const { payload } = action
            const { duplicatesNumber, idCurrData } = payload
            return {
                ...state,
                listEKYC: state.listEKYC.map((item) => 
                    item.ID === idCurrData
                    ? { ...item, duplicatesNumber }
                    : item
                )
            }
        }
        case types.CLEAR_EKYCS: {
            return {
                listReasons: state.listReasons
            }
        }
        default:
            return state;
    }
};

export default eKYCs;
