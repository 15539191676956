
import React, { Component } from 'react'
import * as style from './index.style'
import LogoMenuCompare from '../../../assets/images/components/LogoMenuCompare'

const HeaderComparePage = () => {
    return (
        <style.Bound>
            <div className="logo-facepay">
                <LogoMenuCompare />
            </div>
            <div className="admin">
                <div className="admin-info">
                    <p className="name">Admin</p>
                    <p className="role">Administrator</p>
                </div>
                <img
                    className="admin-avatar"
                    src={"https://picsum.photos/200"}
                    alt="avatar" />
            </div>

        </style.Bound>
    )
}

export default React.memo(HeaderComparePage)
