import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// image
import UserCircleBlue from '../../../../assets/images/components/UserCircleBlue'
import ExportExcel from '../../../../assets/images/components/ExportExcel'

// component
import ButtonControl from '../../../Control/Button'
import ButtonExportExcel from '../../../Control/ButtonExportExcel'

// styles
import { WrapTransactionHeader } from './index.styles'

// action
import { exportExcelListTransaction } from '../../../../redux/transactions/listTransactions/action'

// helper
// api

const TransactionBoardHeader = () => {
    const dispatch = useDispatch()

    const { condFilters, condRequestFilters } = useSelector((state) => ({
        condFilters: state.transactions.listTransactions.condFilters,
        condRequestFilters: state.transactions.listTransactions.condRequestFilters,
    }))

    const _handleExportListTrans = useCallback(() => {
        dispatch(exportExcelListTransaction(condRequestFilters))
    }, [JSON.stringify(condRequestFilters)])

    return (
        <WrapTransactionHeader>
            <div className="wrap-menu">
                <div className="trans-list-menu">
                    <UserCircleBlue />
                    <div>Giao dịch</div>
                </div>
                <hr className="selected-line" />
            </div>
            <ButtonExportExcel onClick={_handleExportListTrans} />
        </WrapTransactionHeader>
    )
}

export default TransactionBoardHeader
