import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
// import IcTotal from "../../../../assets/images/components/IcTotal";
import PropTypes from "prop-types";

// style
import * as styles from "./index.style";
//constant
import * as keys from '../../../../assets/constants/keys'

const colorState = {
  active: "#184DA2",
  none: "#959595",
};

const HeaderMenuMainBoard = ({ dataMenu, callBackClickNavLink }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()

  const [idNav, setIdNav] = useState(0);
  const [totalFaces, setTotalFaces] = useState(0)
  const [totalEKYCs, setTotalEKYCs] = useState(0)

  const { userCensorship } = useSelector(state => ({
    userCensorship: state.userCensorship
  }))

  useEffect(() => {
    if(userCensorship?.listUsers?.facesPending){
      setTotalFaces(userCensorship.listUsers.facesPending.totals)
    }
    if(userCensorship?.listUsers?.eKYCsPending){
      setTotalEKYCs(userCensorship.listUsers.eKYCsPending.totals)
    }
  }, [userCensorship.listUsers])

  const _toggleLineActive = (idNav) => {
    const lineActive = document.getElementById("line-active-id");
    const item = document.getElementById("itemMenu-id-" + idNav);
    if (lineActive && item) {
      lineActive.style.left = item.offsetLeft + "px";
      lineActive.style.width = item.offsetWidth + 5 + "px";
    }
  };

  useEffect(() => {
    _toggleLineActive(idNav);
    callBackClickNavLink(dataMenu[idNav]);
  }, [idNav, callBackClickNavLink, dataMenu]);

  const _renderDataMenu = (
    (data) => {
      return data.map((item, index) => {
        return (
          <styles.ItemMenu
            key={index}
            className="itemMenu"
            id={`itemMenu-id-${index}`}
            onClick={() => {
              setIdNav(index);
              // callBackClickNavLink(item);
            }}
            width={item.width}
          >
            <span
              className="icon"
              style={{
                backgroundColor:
                  idNav === index
                    ? "rgba(24, 77, 162, 0.1)"
                    : "rgba(149, 149, 149, 0.1)",
              }}
            >
              {item.icon({
                colorIcon:
                  idNav === index ? colorState.active : colorState.none,
              })}
            </span>
            <p
              className="label"
              style={
                idNav === index
                  ? { color: colorState.active, fontWeight: "bold" }
                  : { color: colorState.none, fontWeight: "normal" }
              }
            >
              {t(item.label)} {`(${item.key === keys.AVATAR ? totalFaces: totalEKYCs})`}
            </p>
          </styles.ItemMenu>
        );
      });
    }
    // [idNav, callBackClickNavLink]
  );

  return (
    <styles.Bound>
      <div className="menu">
        {_renderDataMenu(dataMenu)}
        <div id="line-active-id" className="line-active" />
      </div>
    </styles.Bound>
  );
};

HeaderMenuMainBoard.propTypes = {
  dataMenu: PropTypes.array.isRequired,
  callBackClickNavLink: PropTypes.func,
};

export default React.memo(HeaderMenuMainBoard);
