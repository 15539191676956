import React from 'react'

const IcCheckWhite = (props) => {
    return (
        <svg {...props} width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M7.5 0.5C3.36433 0.5 0 3.86433 0 8C0 12.1357 3.36433 15.5 7.5 15.5C11.6357 15.5 15 12.1357 15 8C15 3.86433 11.6357 0.5 7.5 0.5Z" fill="white" />
                <path d="M11.3014 6.4108L7.23888 10.4732C7.117 10.5951 6.95702 10.6564 6.79703 10.6564C6.63704 10.6564 6.47705 10.5951 6.35517 10.4732L4.32396 8.44201C4.07951 8.19768 4.07951 7.80263 4.32396 7.5583C4.56829 7.31385 4.96323 7.31385 5.20767 7.5583L6.79703 9.14765L10.4177 5.52708C10.662 5.28264 11.057 5.28264 11.3014 5.52708C11.5458 5.77142 11.5458 6.16635 11.3014 6.4108Z" fill="#09AC10" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={15} height={15} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcCheckWhite
