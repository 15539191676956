import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import * as styles from './index.style'

const TableHeader = (props) => {
    const { t } = useTranslation('common');
    const { template } = props

    const renderUI = () => {
        if(!template) return
        return template.TABLE_HEADER_TITILE.map((data, i) => {
            return (
                <styles.HeaderItem key={i} styled={ data.styled }>
                    <p>{ t(data.label) }</p>
                </styles.HeaderItem>
            )
        })
    }

    return (
        <styles.Bound styled={ template.TABLE_HEADER_STYLED }>
            {renderUI()}
        </styles.Bound>
    )
}

TableHeader.propTypes = {
    template: PropTypes.any.isRequired
}

export default React.memo(TableHeader)
