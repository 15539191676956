import styled from 'styled-components'

const WrapTransList = styled.div`
    margin-left: 58px;
    .list-transaction-container{       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .empty-control{
            margin-top: 290px;
        }
        .list-transaction{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .wrap-list{
                height: 776px;
            }
            .wrap-pagination{
                height: 40px;
                margin-top: 20px;
            }
        }        
    }
`;

const RowEmpty = styled.div`
    width: 740px;
    height: 82px;
    margin-top: 15px;
`;

const RowFilter = styled(RowEmpty)`
    height: auto;
    display: grid;
    grid-template-columns: 90px 100px 250px 180px 1fr;
    /* grid-gap: 20px; */
    z-index: 4;
    position: relative;


    & > * {
        width: calc(100% - 20px);
    }

    .filter-transaction {
        padding-left: 8px;
    }
`;

export {
    WrapTransList,
    RowEmpty,
    RowFilter
}