import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as styles from './index.style'

//image 
import avatar_verify_default from '../../../../assets/images/avatar_verify_default.svg'

//action
import { verifyAvatar } from '../../../../redux/userCensorship/detailsUser/faces/action'
//constant
import * as keys from '../../../../assets/constants/keys'

// component
import AvatarVerifyDefault from '../../../../assets/images/components/AvatarVerifyDefault';

let tableContent;
let type = '';

const TableContent = (props) => {
    const { t } = useTranslation('common');
    const { data, template, typeView, handleEditStore, handleChangePageSize } = props
    const dispatch = useDispatch()

    const [idSelected, setIdSelected] = useState('')

    const chainsReducer = useSelector(
        state => state.chains
    )

    useEffect(() => {
        tableContent = document.getElementById(`table-content-id-${template.KEY}`)
        caculateTableHeightContent()
        _onClickItem(data[0])
        // return () => {
        //     if(template._onUnmount)
        //         template._onUnmount(dispatch)
        // }
    }, [])

    useEffect(() => {
        if (idSelected.length === 0) {
            _onClickItem(data[0])
        }
        else {
            let isExisted = false
            for (let i = 0; i < data.length; i++) {
                if (data[i].ID === idSelected) {
                    isExisted = true;
                    break;
                }
            }
            if (!isExisted) {
                _onClickItem(data[0])
            }
        }
        return () => {

        }
    }, [data])

    // useEffect(() => {
    //     console.log(userCensorship)
    //     switch (typeView) {
    //         case keys.AVATAR:{
    //             if(!userCensorship?.listUsers?.facesPending?.verifyID) return
    //             _startTimerRemoveItem(userCensorship.listUsers.facesPending.verifyID)
    //         }
    //             break;
    //         case keys.EKYC:{
    //             if(!userCensorship?.listUsers?.ekycsPending?.verifyID) return
    //             _startTimerRemoveItem(userCensorship.listUsers.ekycsPending.verifyID)
    //         }
    //             break;
    //         default:
    //             break;
    //     }


    //     return () => {

    //     }
    // }, [userCensorship.listUsers])

    // const _startTimerRemoveItem = (verifyID) => {
    //     timer = setTimeout(() => {
    //         let verifyItem = document.getElementById(`user-censorship-${typeView}-${verifyID}`)
    //         if(!verifyItem) return
    //         verifyItem.style.opacity = 0;
    //         setTimeout(() => {
    //             verifyItem.style.height = 0;
    //             verifyItem.style.padding = 0;
    //             //
    //             setTimeout(() => {
    //                 let nextItem = _getNextItem(verifyID)
    //                 console.log(nextItem)
    //                 if(nextItem) _onClickItem(nextItem)
    //             }, 500);
    //         }, 500);
    //     }, 5000);
    // }

    useEffect(() => {
        if (type !== typeView) {
            type = typeView
            _onClickItem(data[0])
        }
    }, [typeView])

    const caculateTableHeightContent = async () => {
        if(!tableContent) return
        let tableHeight = await template.caculateTableHeightContent()
        tableContent.style.height = `${tableHeight}px`
        if(handleChangePageSize) handleChangePageSize(tableHeight)
    }

    const _onScroll = (e) => {
        let dom = e.target
        if (!dom || !data) return
        if (dom.scrollTop >= dom.scrollHeight - dom.offsetHeight - 5) {
            let currentSize = data.length;
            let size = currentSize + 10;
            if (template._onLoadMore) {
                let keyword = ''
                switch (template.KEY) {
                    case keys.CHAINS:
                        keyword = chainsReducer?.listChains?.filter?.keyword
                        break;
                    case keys.STORE:{
                        let chainID = chainsReducer?.chainInfos?.chainDetails?.details?.ID
                        if(!chainID) return
                        dispatch(template._onLoadMore({ size, typeView, keyword, chainID }))
                        return
                    }
                    default:
                        break;
                }
                dispatch(template._onLoadMore({ size, typeView, keyword }))
            }
        }
    }

    const _onClickItem = (item, callBack) => {
        if (!item) return
        if (!template.ISALLOWCLICK) return
        if (template._actionOnClickItem)
            dispatch(template._actionOnClickItem(item))
        setIdSelected(item.ID)
        if (template._actionOnClickClearItem)
            dispatch(template._actionOnClickClearItem(item))
        if (callBack) callBack(item.ID)
    }

    // const _getNextItem = (ID) => {
    //     console.log(data, ID)
    //     if(!data) return null
    //     for(let i = 0; i < data.length; i++){
    //         if(data[i].ID !== ID) continue;
    //         if(i === data.length - 1) return data[i - 1]
    //         else return data[i + 1]
    //     }
    //     return null
    // }

    // const _verify = (ID) => {
    //     dispatch(verifyAvatar({ID}))
    // }

    const onImageErr = (id) => {
        // let image = document.getElementById(id)
        // if (image)
        //     image.src = avatar_verify_default
        const wrapImage = document.getElementById(id)
        wrapImage && ReactDOM.render(<AvatarVerifyDefault />, wrapImage)
        
    }

    const _renderItem = () => {
        if (!data) return
        // console.log(data)
        return data.map((item, i) => {
            if (!item) return
            return (
                <styles.RowItem
                    styled={template.TABLE_ROW_ITEM_STYLED}
                    type={template.KEY}
                    height={template.HEIGHT_ROW_ITEM_CONTAINER?template.HEIGHT_ROW_ITEM_CONTAINER:'unset'} 
                    key={item.ID} id={`user-censorship-${typeView}-${item.ID}`}
                    // onClick={()=>_onClickItem(item, _verify)}
                    onClick={() => _onClickItem(item)}
                >
                    {
                        template.ISALLOWCLICK &&
                        <div className={idSelected === item.ID ? 'dot dot-selected' : 'dot'} />
                    }
                    {
                        template.ISCONNECT &&
                        <div className='line-connect-container'>
                            <div className='circle' />
                            {
                                data.length > 1 &&
                                <div className='line' />
                            }
                        </div>
                    }
                    <div className={template.ISALLOWCLICK && idSelected === item.ID ? 'item-content selected' : 'item-content'}>
                        {template.renderItem({
                            item: item,
                            index: i,
                            typeView: typeView,
                            funcTranslate: t,
                            funcImageError: onImageErr,
                            onClickEditButton: (item) => { handleEditStore(item) }
                        })}
                    </div>
                </styles.RowItem>
            )
        })
    }

    return (
        <styles.Bound styled={template.TABLE_CONTENT_STYLED} id={`table-content-id-${template.KEY}`}
            type={template.KEY}
            onScroll={(e) => _onScroll(e)}>
            <div className='table-scroll'>
                {_renderItem()}
            </div>
        </styles.Bound>
    )
}

TableContent.propTypes = {
    data: PropTypes.array.isRequired,
    template: PropTypes.any.isRequired
}

export default React.memo(TableContent)
