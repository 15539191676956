// import * as types from "../types";

export const intitialState = {
  
};

const loginHistories = (state = intitialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default loginHistories;
