import React, { useState, useCallback, useRef, useReducer, useEffect } from 'react'

// styles
import { WrapFilterUser } from './index.styles'

// component
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'
import BoxFilterByUser from '../../../../../Control/BoxFilterByUser'

// hooks
import { useOnClickOutside } from '../../../../../../hook/useClickOutside.hook'
import useDebounce from '../../../../../../hook/useDebouce.hook'
import usePrevious from '../../../../../../hook/usePrevious'

const DropDownFilterUsers = ({ values, onChangeValues }) => {
    const dropdownRef = useRef()
    // const refValues = useRef(values)

    const [isShowDropdown, setIsShowDropdown] = useState(false)
    const [activeFilter, setActiveFilter] = useState(false)
    const [stateFilter, dispatchStateFilter] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            name: "",
            username: "",
            phoneNum: ""
        }
    )

    const nameDelay = useDebounce(stateFilter.name, 500)
    const prevNameDelay = usePrevious(nameDelay)

    const usernameDelay = useDebounce(stateFilter.username, 500)
    const prevUsernameDelay = usePrevious(usernameDelay)

    const phoneNumDelay = useDebounce(stateFilter.phoneNum, 500)
    const prevPhoneNumDelay = usePrevious(phoneNumDelay)

    const _handleOpenDropdown = useCallback(
        () => {
            setIsShowDropdown(!isShowDropdown)
        },
        [isShowDropdown],
    )

    const _handleCloseBox = useCallback(
        () => {
            setIsShowDropdown(false)
        },
        [setIsShowDropdown],
    )

    useOnClickOutside(dropdownRef, _handleCloseBox)

    useEffect(() => {
        const { name, username, phoneNum } = stateFilter
        const haveFilter = name || username || phoneNum
        if(haveFilter && !activeFilter) {
            setActiveFilter(true)
            return;
        }

        if(!haveFilter && activeFilter) {
            setActiveFilter(false)
            return;
        }

    }, [JSON.stringify(stateFilter)])

    useEffect(() => {
        dispatchStateFilter({ name: values.name })
    }, [values.name])

    useEffect(() => {
        dispatchStateFilter({ username: values.username })
    }, [values.username])

    useEffect(() => {
        dispatchStateFilter({ phoneNum: values.phoneNum })
    }, [values.phoneNum])

    // useEffect(() => {
    //     refValues.current = values
    // }, [JSON.stringify(values)])

    useEffect(() => {
        if(nameDelay === prevNameDelay) return;
        if(prevNameDelay === undefined) return;
        // debugger
        onChangeValues({
            ...stateFilter,
            name: nameDelay
        })
    }, [nameDelay, onChangeValues, prevNameDelay, JSON.stringify(stateFilter)])

    useEffect(() => {
        if(usernameDelay === prevUsernameDelay) return;
        if(prevUsernameDelay === undefined) return;
        // debugger
        onChangeValues({
            ...stateFilter,
            username: usernameDelay
        })
    }, [usernameDelay, onChangeValues, prevUsernameDelay, JSON.stringify(stateFilter)])

    useEffect(() => {
        if(phoneNumDelay === prevPhoneNumDelay) return;
        if(prevPhoneNumDelay === undefined) return;
        // debugger
        onChangeValues({
            ...stateFilter,
            phoneNum: phoneNumDelay
        })
    }, [phoneNumDelay, onChangeValues, prevPhoneNumDelay, JSON.stringify(stateFilter)])

    return (
        <WrapFilterUser ref={dropdownRef}>
            <TitleDropdownFilter 
                onClick={_handleOpenDropdown}
                active={activeFilter}
            >
                User
            </TitleDropdownFilter>
            {
                isShowDropdown &&
                <BoxFilterByUser 
                    closeBox={_handleCloseBox}
                    stateFilter={stateFilter}
                    dispatchStateFilter={dispatchStateFilter}
                />
            }
        </WrapFilterUser>
    )
}

export default DropDownFilterUsers
