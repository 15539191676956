import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// styles
import { WrapperAdvancedInfos } from './index.style'
//tool
import { converTypeOfPaper, convertDateTime } from '../../../../assets/helper/tools'
import { apiAvatarImage, apiGetImageEkyc } from '../../../../assets/constants/host'

const AdvancedInfos = ({ infos, paddingLeft }) => {
    const { t } = useTranslation('common')
    const [data, setData] = useState(infos)

    const [userName, setUserName] = useState('')
    const [number, setNumber] = useState('')
    const [birthday, setBirthday] = useState('')
    const [gender, setGender] = useState('')
    const [homeTown, setHomeTown] = useState('')
    const [address, setAddress] = useState('')
    const [nationality, setNationality] = useState('')
    const [valid, setvalid] = useState('')
    const [issuedPlace, setIssuedPlace] = useState('')
    const [expire, setExpire] = useState('')

    useEffect(() => {
        setData(infos)
    }, [infos])

    useEffect(() => {
        if (data?.EKYCType === "CMND" || data?.EKYCType === "CCCD") {
            setUserName(data?.FrontInfo?.FullName)
            setNumber(data?.FrontInfo?.Number)
            setBirthday(data?.FrontInfo?.Birthday)
            setGender(data?.FrontInfo?.Gender)
            setHomeTown(data?.FrontInfo?.HomeTown)
            setAddress(data?.FrontInfo?.Address)
            setNationality(data?.FrontInfo?.Nationality)
            setvalid(data?.BackInfo?.Valid)
            setIssuedPlace(data?.BackInfo?.IssuedPlace)
            setExpire(data?.FrontInfo?.Expire)
            return
        }
        setUserName(data.PassportInfo?.FullName)
        setNumber(data?.PassportInfo?.Number)
        setBirthday(data?.PassportInfo?.Birthday)
        setGender(data.PassportInfo?.Gender)
        setHomeTown(data?.PassportInfo?.HomeTown)
        setAddress(data?.PassportInfo?.Address)
        setNationality(data?.PassportInfo?.Nationality)
        setvalid(data?.PassportInfo?.Valid)
        setIssuedPlace(data?.PassportInfo?.IssuedPlace)
        setExpire(data?.PassportInfo?.Expire)
    }, [data])

    return (
        <WrapperAdvancedInfos paddingLeft={paddingLeft}>
            <div className="image-type-paper">
                <div className="front-side">
                    <p className="info">
                        Mặt trước <span>{converTypeOfPaper(data.EKYCType).shortcardType}</span>
                    </p>
                    <img className="image-paper"
                        src={
                            data?.EKYCType === "CMND" || data?.EKYCType === "CCCD" ?
                                apiGetImageEkyc(data.FrontIdentityID)
                                :
                                apiGetImageEkyc(data.PassportIdentityID)
                        }
                        alt='front'
                    />
                </div>
                {
                    data?.EKYCType === "CMND" || data?.EKYCType === "CCCD" ?
                    <div className="back-side">
                        <p className="info">
                            Mặt sau <span>{converTypeOfPaper(data.EKYCType).shortcardType}</span>
                        </p>
                        <img className="image-paper"
                            src={
                                data?.EKYCType === "CMND" || data?.EKYCType === "CCCD" ?
                                    apiGetImageEkyc(data.BackIdentityID)
                                    :
                                    ""
                            }
                            alt='back'
                        />
                    </div>
                    : ""
                }
            </div>
            <div className="card-number">{number}</div>
            <div className="avatar-container">
                <div className="avatar-info">
                    <div className={data?.IsMatchedFace === false ? "card-face error" : "card-face"}>
                        <img
                            src={
                                data?.EKYCType === "CMND" || data?.EKYCType === "CCCD" ?
                                    (`data:image/jpeg;base64,${data?.FrontInfo?.Avatar}`)
                                    :
                                    (`data:image/jpeg;base64,${data?.PassportInfo?.Avatar}`)
                            }
                        />
                    </div>
                    <p className="info-image">Hình cắt từ giấy tờ</p>
                </div>
                <div className="avatar-info">
                    <div>
                        <img className="avatar-image" src={data?.UserID ? apiAvatarImage(data.UserID) : ""} />
                    </div>
                    <p className="info-image">Hình đăng ký tài khoản</p>
                </div>
            </div>
            <div className="info-user-verify">{userName}</div>
            <div className="info-user-verify">{birthday}</div>
            <div className="info-user-verify">
                {gender === 0
                    ? t('user_detail.gender_default')
                    : gender === 1
                        ? t('user_detail.gender_male')
                        : gender === 2
                            ? t('user_detail.gender_female')
                            : gender === 3
                                ? t('user_detail.gender_other')
                                : "-"
                }
            </div>
            <div className="info-user-verify">{homeTown}</div>
            <div className="info-user-verify">{address}</div>
            <div className="info-user-verify">{nationality}</div>
            <div className="info-user-verify">{valid}</div>
            <div className="info-user-verify">{issuedPlace}</div>
            <div className="info-user-verify">{expire}</div>
            <div className="info-user-verify status">
                {
                    data.Status === "ACCEPT" ?
                        "Đã kiểm duyệt thông tin"
                        :
                        ""
                }
            </div>
            <div className="info-user-verify name">{data.AdminUsername}</div>
            <div className="info-user-verify">{convertDateTime(data.VerifiedAt, "DD/MM/YYYY")}</div>

        </WrapperAdvancedInfos>
    )
}

export default AdvancedInfos
