import React, { useEffect, useCallback, useState } from 'react'
import { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux';

// style
import { NotifyWrapper } from './index.style'

// component
import AlertPopup from './AlertPopup'
import MemoErrorConnect from './ErrorConnect';
import Error403 from './Error403';
import ErrorVerifyEKYCs from './ErrorVerifyEKYCs';


// action
import { popNotify } from '../../redux/notifies/action';

// constant
import { GET_USER_INFOS_ERR } from '../../redux/userCensorship/detailsUser/detailsAccount/types';
import { NETWORK_ERR, AUTHENTICATED_ERR, SYSTEM_ERR } from '../../redux/notifies/types';
import { VERIFY_EKYCS_ERR } from '../../redux/userCensorship/detailsUser/eKYCs/types';
import { PUSH_NOTIFY } from '../../redux/notifies/types';

const extendStyles = `
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 20px;   
`;

const alertPopupExtendStyles = `
    width: 540px;
    max-width: 540px;
    margin: 20px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
`;

const ErrorCodeConfigs = {
    1: "Invalid parameter, request data invalid",
    2: "email not exist",
    3: "password is wrong",
    4: "user not found",
    5: "reason not found",
    6: "bank account not exist",
    7: "info ekyc user not exist",
    8: "verify avatar not found",
    9: "face not exist",
    10: "manager not exist",
    11: "Pos not found",
    12: "add picture error",
    13: "chain not exist",
    14:	"shop not exist",
    15:	"area not exist",
    16:	"add note error",
    17:	"note exist",
    18:	"picture ekyc not exist",
    19:	"update avatar error",
    20:	"add chain error",
    21:	"add shop error",
    22:	"add admin error",
    23: "Tài khoản này đã được kiểm duyệt",
    24: "Mã cửa hàng đã tồn tại"
}

const Notify = () => {
    const dispatch = useDispatch()

    const notifies = useSelector(state => state.notifies)
    const [listNotifies, setListNotifies] = useState([])
    const [popup, setPopup] = useState(null)

    const _handleClose = useCallback(
        () => {
            setPopup(null)
        },
        [],
    )

    const _handleShowError = useCallback(
        (error) => {
            // debugger
            const { type, payload } = error;
            switch(type){
                case NETWORK_ERR:
                case SYSTEM_ERR:
                    setPopup(
                        <MemoErrorConnect
                            handleClose={_handleClose}
                        >
                            <div>Không thể kết nối được với hệ thống.</div>
                            <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                        </MemoErrorConnect>
                    )
                    return;
                    // break;
                case AUTHENTICATED_ERR:
                    setPopup(
                        <Error403 handleClose={_handleClose} />
                    )
                    return;
                default:
                    break;
                // case GET_USER_INFOS_ERR:
                //     // setPopup()
                //     break;
                // case VERIFY_EKYCS_ERR:
                //     setPopup(
                //         <ErrorVerifyEKYCs 
                //             dataError={payload}
                //             handleClose={_handleClose}
                //         />
                //     )
                //     break;
                // default:
                //     setPopup(
                //         <MemoErrorConnect
                //             handleClose={_handleClose}
                //         >
                //             <div>Không thể kết nối được với hệ thống.</div>
                //             <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                //         </MemoErrorConnect>
                //     )
                //     break;
            }

            const { Code } = payload;
            if(Object.keys(ErrorCodeConfigs).includes(String(Code))){
                setPopup(
                    <AlertPopup 
                        type="ERROR"
                        extendStyles={alertPopupExtendStyles}
                        handleClose={_handleClose}
                    >
                        {/* {`Code ${Code} - ${ErrorCodeConfigs[Code]}`} */}
                        {ErrorCodeConfigs[Code]}
                    </AlertPopup>
                )
                return;
            }

            setPopup(
                <MemoErrorConnect
                    handleClose={_handleClose}
                >
                    <div>Không thể kết nối được với hệ thống.</div>
                    <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                </MemoErrorConnect>
            )
            return;
        },
        []
    )

    useEffect(() => {
        setListNotifies(Object.values(notifies))
    }, [notifies])

    useEffect(() => {
        if(!listNotifies.length) return;
        if(popup) return;
        const { type, payload } = listNotifies[0];
        dispatch(popNotify(type))   
        if(/.*_ERR/.test(type)){
            _handleShowError(listNotifies[0])
            return;
        }
        if(type === PUSH_NOTIFY) {
            setPopup(
                <AlertPopup 
                    type="ERROR"
                    extendStyles={alertPopupExtendStyles}
                    handleClose={_handleClose}
                >
                    {payload.strError}
                </AlertPopup>
            )
            return;
        }
    }, [listNotifies, popup])
    
    return (
        <NotifyWrapper>
            {/* <AlertPopup 
                type="ERROR"
                extendStyles={extendStyles}
            >
                Error
            </AlertPopup> */}
            {popup}
        </NotifyWrapper>
    )
}

export default Notify
