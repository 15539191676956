import React, { useState, useCallback, useMemo } from 'react'
import { useEffect } from 'react';
import { apiAvatarImage, apiAvatarImageMini, apiAvatarShop } from '../../../../../assets/constants/host';
import { convertDateTime, buildMoney } from '../../../../../assets/helper/tools';
import AvatarVerifyDefault from '../../../../../assets/images/components/AvatarVerifyDefault';
import LogoChainDefault from '../../../../../assets/images/components/LogoChainDefault';

// styles
import { StyleRow } from './index.styles'

const Row = ({ data = {}, selected, setSelected, enableClick = true, onSelect, ...props }) => {

    const [imageError, setImageError] = useState(false)
    const [logoChainError, setLogoChainError] = useState(false)
    const [avatarMini, setAvatarMini] = useState('');
    const [shopAvatar, setShopAvatar] = useState('');

    const _handleClick = useCallback(
        () => {
            if (!enableClick) return;
            setSelected(data)
            onSelect(data)
        },
        [setSelected, data, enableClick, onSelect],
    )

    const isSelected = useMemo(() => enableClick ? selected.ID === data.ID : false, [enableClick, selected, data])
        
    useEffect(() => {
        setImageError(false)
        setLogoChainError(false)
    }, [data?.ID])

    useEffect(() => {
        const { UserID } = data
        if(!UserID) {
            setAvatarMini('')
            return;
        }
        setAvatarMini(apiAvatarImageMini(UserID))
    }, [data]);

    useEffect(() => {
        const { ShopAvatarID } = data
        if(!ShopAvatarID) {
            setShopAvatar('')
            return;
        }
        setShopAvatar(apiAvatarShop(ShopAvatarID))
    }, [data]);

    return (
        <StyleRow onClick={_handleClick} enableClick={enableClick} {...props}>
            {
                enableClick &&
                <div className={isSelected ? "dot dot-selected" : "dot"} />
            }
            <div className={isSelected ? "content-data content-data-selected" : "content-data"}>
                <div className='trading-code'>
                    {data?.FacepayNumber ? data.FacepayNumber : "-"}
                </div>
                <div className='date-time'>
                    <div className="date">{data?.CreateTime ? convertDateTime(data.CreateTime, "DD/MM/YYYY") : "-"}</div>
                    <div className="time">{data?.CreateTime ? convertDateTime(data.CreateTime, "HH:mm:ss") : ""}</div>

                </div>
                <div className='user'>
                    <div className='avatar-wrapper'>
                        {
                            imageError || !avatarMini ?
                                <AvatarVerifyDefault className='avatar-default' />
                                :
                                <img
                                    className='avatar-user'
                                    // src={data?.UserID ? apiAvatarImageMini(data.UserID) : ""}
                                    src={avatarMini}
                                    alt='avatar-user'
                                    onError={() => setImageError(true)}
                                />
                        }

                    </div>
                    <div className='info-user'>
                        <div className='name-user' data-toggle="tooltip" title={data?.UserFullName ? data.UserFullName : ""}>
                            {data?.UserFullName ? data.UserFullName : "-"}
                        </div>
                        <div className='wrapper-user'>
                            <span data-toggle="tooltip" title={data?.Username?.Value ? data.Username.Value : ""}>
                                {data?.Username?.Value ? `@${data.Username.Value}` : "-"}
                            </span>
                            - &nbsp;{data?.UserPhone ? data.UserPhone : "-"}
                        </div>
                    </div>
                </div >
                <div className='chain'>
                    <div className='logo-chain-wrapper'>
                        {
                            logoChainError || !shopAvatar ?
                                <LogoChainDefault className='logo-chain-default' />
                                :
                                <img
                                    className='logo-chain'
                                    src={shopAvatar}
                                    alt='logo-chain'
                                    onError={() => setLogoChainError(true)}
                                />
                        }
                    </div>
                    <div className='name-chain' data-togglt="tooltip" title={data?.ShopName ? data.ShopName : ""}>
                        {data?.ShopName ? data.ShopName : "-"}
                    </div>
                </div>
                <div className='money'>
                    {data?.Amount ? `${buildMoney(data.Amount)} đ`: "-"} 
                </div>
            </div>
        </StyleRow>
    )
}

export default React.memo(Row)
