
import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    .title{
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 128%;
        color: #959595;
        margin: 0 0 10px 10px;
    }
    .drop-down{
        width: 100%;
        height: 48px;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #000000;
        padding: 0 10px;
        position: relative;
        cursor: pointer;
        .value{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: #000000;
            .placeholder{
                color: rgba(28, 28, 28, 0.5);
            }
        }
        .list-data{
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 50px;
            right: -1px;
            width: ${props => props.widthListData ? props.widthListData : "138px"} ;
            height: max-content;
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
            border-radius: 6px 6px 0 0;
            background-color: #ffffff;
            z-index: 1;
            &::-webkit-scrollbar {
                width: 9px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #E2E2E2;
                background-clip: padding-box;
                border-radius: 20px;
                border: 3px solid white;
            }
            .label{
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                background: #F4F4F4;
                color: #959595;
                padding: 0 6px;
                border-radius: 6px 6px 0px 0px;
                display: flex;
                align-items: center;
                height: 34px;
            }
            .selected{
                display: flex;
                align-items: center;
                height: 42px;                
                box-sizing: border-box;                
                padding: 0 6px;
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                color: #212121;
                .icon-wrapper{
                    width: 22px;
                    display: flex;
                    align-items: center;
                }
                &:hover{
                    cursor: pointer;
                }
                .area-name {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    flex-grow: 1;
                    border-bottom: 1px solid #F4F4F4;
                }
            }
        }
    }
`
export { Bound } 