import styled from 'styled-components'

const WrapperAdvancedItem = styled.div`
    display: grid;
    grid-template-columns: 307px 307px 1fr;
    grid-gap: 30px;

    .face-censorship {
        width: 307px;
        height: 409px;
        border-radius: 3px;
        background: #eaeff4cc;
    }

    img.face-censorship {
        object-fit: contain;
    }

    .wrapper-condition-action {
        display: flex;
        flex-direction: column;

        & > * {
            flex-shrink: 0;
            :first-child {
                flex-grow: 1;
            }
        }
    }
`;

export { WrapperAdvancedItem }