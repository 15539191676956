import styled from "styled-components";

export const Bound = styled.div`
  /* width: 100%; */
  height: 100%;
  font-family: "UTM Avo";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .list__note {
    /* height: calc(100% - 60px); */
    /* flex-grow: 1; */
    overflow-y: overlay;
    padding: 0 25px 15px 30px;
    box-sizing: border-box;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .send__note {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 20px 5px 18px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 6px 6px;
    .priority {
      position: relative;
      &__choice {
        border: 1px solid #FFFFFF;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
        width: 31px;
        height: 31px;
        border-radius: 50%;
        cursor: pointer;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        p{
          height: 18px;
        }
      }

      &__block {
        position: absolute;
        top: -150px;
        width: 128px;
        height: 134px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title-priority-wrapper{
          height: 32px;
          background: #F9FBFD;
          border-radius: 3px 3px 0px 0px;
          display: flex;
          align-items: center;
          .title-priority{
            font-family: 'SVN-Gilroy';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #000000;
            padding: 0 0 0 10px;
          }          
        }
        .item-wrapper {
          width: fit-content;
          height: 24px;
          border-radius: 1px 20px 20px 1px;
          cursor: pointer;
          display: flex;
          align-items: center;        
          font-family: 'UTM Avo';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          color: #FFFFFF;
          margin: 6px 0 0 10px;
          padding: 0 6px;
          :last-child{
            margin: 6px 0 10px 10px;
          }   
        }
      }
    }

    form {
      display: flex;
      align-items: center;
      .input-wrap {
        width: 430px;
        min-height: 47px;
        background: #f4f4f4;
        border-radius: 50px;
        margin-left: 20px;
        padding: 10px 30px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }

      .input {
        background: #f4f4f4;
        outline: none;
        border: none;
        box-sizing: border-box;
        resize: none;
        width: 100%;
        font-family: "UTM Avo";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #1C1C1C;
        &::-webkit-scrollbar {
          width: 9px;
          background: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #E2E2E2;
          background-clip: padding-box;
          border-radius: 20px;
          border: 3px solid white;
        }

        &::placeholder {
          font-family: "UTM Avo";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          color: #959595;
          height: 21px;
        }
      }
      .btn {
        cursor: pointer;
        margin-left: 10px;
        border: none;
        background: none;
        outline: none;
      }
    }
  }
`;

