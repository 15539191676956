import styled from 'styled-components'

const WrapDropDown = styled.div`
    width: 100%;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: normal;

    .ic {
        &:hover {
            cursor: pointer;
        }
    }
   
    .label {
        margin-left: 10px;
        font-size: 12px;
        line-height: 128%;
        color: #959595;
        margin: 0 0 10px 10px;
    }

    .wrap-content {
        position: relative;
        z-index: 2;
    }
`;

const ShowBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    border: 1px solid #C6D5EB;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: normal;
    padding: 12px 10px;

    &:hover {
        cursor: pointer;
    }

    .value {
        font-size: 14px;
        line-height: 170%;
        color: #212121;
        .placeholder{
            color: rgba(28, 28, 28, 0.5);
        }
    }
`;

const Dropdown = styled.div`
    position: absolute;
    background: #FFFFFF;
    box-sizing: border-box;
    top: calc(100% + 10px);
    right: 0;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 0 0;

`;

const SearchBox = styled.div`
    background: #F4F4F4;
    padding: 6px;
    border-radius: 6px 6px 0px 0px;

    .area-control {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .area-control__label {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #959595;
        }

        
    }
`;

const ListArea = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(4 * 42px);
`;

const AreaItem = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    height: 42px;
    box-sizing: border-box;
    padding: 0 6px;
    background: #FFFFFF;
    .icon-wrapper{
        width: 22px;
        display: flex;
        align-items: center;
    }

    &:hover {
        cursor: pointer;
    }

    .radio {
        width: 16px;
        height: 16px;
        border: 1px solid #1279DA;
        border-radius: 50%;
        margin-right: 6px;

        &.checked {
            background: #1279DA;
        }
    }

    .area-name {
        height: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid red;
        flex-grow: 1;
        border-bottom: 1px solid #F4F4F4;
    }
`;

const AddInput = styled.div`
    display: flex;
    align-items: center;
    height: 42px;
    box-sizing: border-box;
    padding: 0 6px;
    background-color: #93BFF2;
    justify-content: space-between;

    input.add-area {
        padding: 3px 6px;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 6px;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        outline: none;
        margin-right: 6px;
    }
`;

export {
    WrapDropDown,
    ShowBox,
    Dropdown,
    SearchBox,
    ListArea,
    AreaItem,
    AddInput
}