import React,{ useCallback, useReducer, useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format';
import md5 from 'md5'
import moment from 'moment'

//styles
import * as style from './index.style'
import { styleButtonDelete, styleButtonSave } from './index.style'
//constant
import * as keys from '../../../../../../assets/constants/keys'
import { dataCategory } from '../../../../../../assets/data/dataChain'
import errorImgTypes from '../../../../../../assets/constants/errorImgTypes';

// helper
import fileToBase64 from '../../../../../../assets/helper/fileToBase64'
import { createImgFromLink } from '../../../../../../assets/helper/getBase64Image';
import isEmail from '../../../../../../assets/helper/isEmail';
// import hashString from '../../../../../../assets/helper/hashString';

//component
import IcCheck from '../../../../../../assets/images/components/IcCheck'
import ButtonControl from '../../../../Button'
import DropDownSingle from '../../../../DropDownSingle'
import DropOrChooseLogo from '../../../../DropOrChooseLogo'
import InputText from '../../../../InputText'
import IcDeleteRed from '../../../../../../assets/images/components/IcDeleteRed'
import IcSave from '../../../../../../assets/images/components/IcSave'
import PopupConfirmDeleteChain from './PopupConfirmDeleteChain';

// host
import { apiAvatarChain } from '../../../../../../assets/constants/host';

// hooks
import useDidMount from '../../../../../../hook/useDidMount';

// action
import { editChain, getChainDetails } from '../../../../../../redux/chains/chainInfos/chainDetails/action';
import { getListChains } from '../../../../../../redux/chains/listChains/action';
import { pushNotify } from '../../../../../../redux/notifies/action';

const ContentPopupEditChains = ({ handleClose, setIsNeedConfirmClose, setIsExecutedData }) => {

    const dispatch = useDispatch()
    const defaultValue = useRef()
    const defaultImage = useRef()

    const { listChains, chainDetails } = useSelector(state => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        listChains: state.chains.listChains
    }))

    const [imageShow, setImageShow] = useState("")
    const [defaultCategory, setDefaultCategory] = useState({})
    const [isOpenPopupConfirmDeleteChain, setIsOpenPopupConfirmDeleteChain] = useState(false)
    // const [isValidEmail, setIsValidEmail] = useState(true)

    const [state, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            Name: "",
            Address: "",
            PhoneNumber: "",
            Category: "",
            Email: "",
            RepPosition: "",
            Logo: "",
            TaxNumber: "",
            RepName: ""
        }
    )

    const { keyword } = useMemo(() => listChains.filter, [JSON.stringify(listChains)])

    const _handleDropFiles = useCallback(
        async (files) => {
            // setError('')
            if (imageShow) URL.revokeObjectURL(imageShow);
            try {
                const url = URL.createObjectURL(files[0]);
                setImageShow(url);
                const imgBase64 = await fileToBase64(files[0])
                
                const logo = imgBase64.split(',')[1]
                setState({ Logo: logo })
            } catch (err) {
                setImageShow("");
                setState({ Logo: "" })
            }
        },
        [imageShow],
    )

    const _handleErrorGetImg = useCallback(
        (err) => {
            // setError(err)
            const strError = errorImgTypes[err]
            dispatch(pushNotify(strError))
            setImageShow("");
            setState({ Logo: "" })
        },
        [],
    )

    const _handleClickDeleteChains = useCallback(
        () => {
            setIsOpenPopupConfirmDeleteChain(!isOpenPopupConfirmDeleteChain)
        },
        [isOpenPopupConfirmDeleteChain],
    )

    const _handleClickSaveChains = useCallback(() => {
        if(!chainDetails.details) return 
        const dataUpdate = {}

        const { current } = defaultValue;

        Object.keys(current).forEach(item => {
            const newDataItem = state[item]
            // if(current[item] !== newDataItem){
                dataUpdate[item] = newDataItem
            // }
        })

        const { Logo } = state;
        // if(md5(Logo) !== defaultImage.current) {
        if(Logo !== defaultImage.current) {
            dataUpdate.Logo = Logo
        }

        if(!Object.keys(dataUpdate).length) {
            handleClose()
            return;
        }

        dispatch(editChain({ID: chainDetails.details.ID, ...dataUpdate}, () => {
            dispatch(getListChains({ PageNo: 1, PageSize: 50, Filter: keyword }))
            dispatch(getChainDetails({ ChainID: chainDetails.details.ID }))
            handleClose()
        }))
    }, [JSON.stringify(state), handleClose, JSON.stringify(chainDetails.details), keyword])

    useLayoutEffect(() => {
        if(!chainDetails.details) return;
        const { 
            ID, 
            Name, 
            Email, 
            Address,
            PhoneNumber, 
            Category, 
            TaxNumber, 
            RepName, 
            RepPosition,
            UpdateTime
        } = chainDetails.details
        const state = {
            Name,
            Address,
            PhoneNumber,
            Email,
            Category,
            TaxNumber,
            RepName,
            RepPosition
        }
        setState(state)
        defaultValue.current = state
        setImageShow(apiAvatarChain(ID, UpdateTime))
        const objCategory = dataCategory.find(item => item.key === Category)
        setDefaultCategory(objCategory)
    }, [JSON.stringify(chainDetails.details)])

    useDidMount(() => {
        if(!imageShow) return false;
        const asyncGetBase64FromLink = async () => {
            const base64 = await createImgFromLink(imageShow)
            // defaultImage.current = md5(base64)
            defaultImage.current = base64
            setState({ Logo: base64 })
        }
        asyncGetBase64FromLink()
        return true
    }, [imageShow])

    // useEffect(() => {
    //     const isValidEmail = !state.Email  || (state.Email && isEmail(state.Email))
    //     setIsValidEmail(isValidEmail)
    // }, [state.Email])

    useEffect(() => {
        if(!chainDetails.details) return;
        if(!imageShow) return;
        // const { ID } = chainDetails.details
        // const image = apiAvatarChain(ID)
        // const isDiffImage = image !== imageShow
        // if(isDiffImage) {
        //     setIsNeedConfirmClose(true)
        //     return;
        // }
        const { Logo } = state;
        if(!Logo) return;

        // set is mapped data to UI
        setIsExecutedData(true)
        
        // const hashLogo = md5(Logo);
        const hashLogo = Logo;
        if(hashLogo !== defaultImage.current) {
            // debugger
            setIsNeedConfirmClose(true)
            return;
        }

        const stateValueCheck = Object.keys(defaultValue.current).reduce((obj, key) => ({...obj, [key]: state[key]}), {})
        
        const mixStateToDefaultValue = { 
            ...defaultValue.current, 
            ...stateValueCheck 
        }
        const isDiff = JSON.stringify(mixStateToDefaultValue) !== JSON.stringify(defaultValue.current)
        // debugger
        setIsNeedConfirmClose(isDiff)
    }, [
        defaultValue, 
        JSON.stringify(state), 
        setIsNeedConfirmClose, 
        // JSON.stringify(chainDetails.details), 
        // imageShow
    ])

    // useEffect(() => {
    //     if(!error) return;
    //     const strError = errorImgTypes[error]
    //     if(!strError) return;
    //     dispatch(pushNotify(strError))
    // }, [error]);

    return (
        <style.Bound>
            <div className="content-top">
                <div className="import-logo">
                    <DropOrChooseLogo
                        file={imageShow}
                        handleDropFiles={_handleDropFiles}
                        handleReturnError={_handleErrorGetImg}
                    />
                </div>
                <div className="info-left">
                    <InputText
                        defaultValue={state.Name}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên chuỗi ..."
                        titleInput="Nhập tên chuỗi *"
                        maxLength="200"
                        keyValue={keys.CHAINSNAME}
                        onChange={(_, value) => setState({ Name: value })}
                    />
                    <DropDownSingle
                        defaultValue={defaultCategory}
                        placeholder="Chọn loại chuỗi ..."
                        disabled={false}
                        titleDropDown="Loại chuỗi *"
                        label="Loại chuỗi"
                        widthListData={"300px"}
                        data={dataCategory}
                        keyValue={keys.CHAINSCATEGORY}
                        onChange={(_, value) => setState({ Category: value})}
                    />
                    <div className="input-field">
                        <div className="title-input">Mã số thuế (hoặc CMND của người đại diện) *</div>
                        <NumberFormat 
                            // customInput= {Input}
                            className='input-number'
                            displayType={'input'}
                            placeholder="Nhập mã số thuế  ..."
                            onValueChange={objVal => setState({ TaxNumber: objVal.value})}
                            allowNegative={false}
                            allowLeadingZeros={true}
                            value={state.TaxNumber}
                            isNumericString={true}
                            // decimalSeparator=""
                            isAllowed={values => {
                                const isValid = !values.value.includes('.')
                                return isValid
                            }}
                        />
                    </div>
                </div>
                <div className="info-right">
                    <InputText
                        defaultValue={state.RepName}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên đại diện ..."
                        titleInput="Tên đại diện *"
                        keyValue={keys.REPNAME}
                        onChange={(_, value) => setState({ RepName: value })}
                    />
                    <InputText
                        defaultValue={state.RepPosition}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập chức vụ ..."
                        titleInput="Chức vụ người đại diện *"
                        keyValue={keys.REPPOSITION}
                        onChange={(_, value) => setState({ RepPosition: value })}
                    />
                    <InputText
                        // isInvalid={!isValidEmail}
                        defaultValue={state.Email}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập email ..."
                        titleInput="Email đại diện *"
                        keyValue={keys.EMAIL}
                        onChange={(_, value) => setState({ Email: value })}
                    />
                </div>
            </div>
            <div className="content-bottom">
                <InputText
                    defaultValue={state.Address}
                    isTitle={true}
                    disabled={false}
                    placeholder="Nhập địa chỉ ..."
                    titleInput="Trụ sở *"
                    keyValue={keys.OFFICEADDRESS}
                    onChange={(_, value) => setState({ Address: value })}
                />
                <div className="input-field">
                    <div className="title-input">Số điện thoại đại diện *</div>
                    <NumberFormat 
                        isNumericString={true}
                        value={state.PhoneNumber}
                        className='input-number'
                        displayType={'input'}
                        type="text"
                        placeholder="Nhập số điện thoại ..."
                        onValueChange={objVal => setState({ PhoneNumber: objVal.value})}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        isAllowed={values => {
                            const isValid = !values.value.includes('.')
                            return isValid
                        }}
                        // decimalSeparator=""
                    />
                </div>
            </div>
            <div className="button-container">
                <div className="wrap-btn-delete-chain">
                    <ButtonControl
                        icon={<IcDeleteRed />}
                        isDisable={Object.values(state).some(item => !item)}
                        handleClick={_handleClickDeleteChains}
                        extendStyles={styleButtonDelete}
                        children="xóa bỏ"
                    />
                    {
                        isOpenPopupConfirmDeleteChain &&
                        <PopupConfirmDeleteChain 
                            chainDetails={chainDetails}
                            onClose={() => setIsOpenPopupConfirmDeleteChain(false)}
                            onClosePopupEditChains={handleClose}
                        />
                    }
                </div>
                <ButtonControl
                    icon={<IcSave />}
                    isDisable={Object.values(state).some(item => !item)}
                    handleClick={_handleClickSaveChains}
                    extendStyles={styleButtonSave}
                    children="lưu lại"
                />
            </div>
        </style.Bound>
    )
}

export default React.memo(ContentPopupEditChains)
