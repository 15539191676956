import * as types from './types'

const initialState = {

}

const transactionDetails = (state=initialState, action) => {
    switch(action.type) {
        case types.GET_TRANSACTION_DETAILS: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_TRANSACTION_DETAILS: {
            return {
                ...initialState
            }
        }
        default:
            return state
    }
}

export default transactionDetails