import Axios from 'axios'

// helper
import mapErrors from '../../../../assets/helper/mapErrors'
import genApiGetRequest from '../../../../assets/helper/genApiGetRequest'
import autoDownloadFromBlob from '../../../../assets/helper/autoDownloadFromBlob'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../../../assets/constants/codeResponse'

// api
import { apiGetTransHistoriesByUser, apiExportExcelTransHistoriesByUser } from './api'

// verify params
import { verifyParamsActionGetListTransHistoryByUser } from './verifyParams'

export const fetchTransHistoryByUser = (payload) => ({
    type: types.GET_LIST_TRANS_HISTORY_BY_USER,
    payload
})

/**
 * @typedef { 1 | 0 | -1 } sortType - 1: ascending; 0: not use; -1: decreasing
 * @typedef { GREATER | LESS | GREATER_OR_EQUAL | LESS_OR_EQUAL | NOT_EQUAL | EQUAL } operatorType
 * @param {{
 *  ID: string, 
 *  PageNumber: number,
 *  PageSize: number,
 *  FacepayNumber: {
 *      Sort: sortType,
 *      No: string
 *  }
 *  Shop: {
 *      Sort: sortType,
 *      ShopName: string
 *  },
 *  Amount: {
 *      Sort: sortType,
 *      firstCompares: {
 *          Operator: operatorType,
 *          Value: number
 *      },
 *      secondCompares: {
 *          Operator: operatorType,
 *          Value: number
 *      }
 *  },
 *  Time: {
 *      Sort: sortType,
 *      FromDate: string,
 *      ToDate: string
 *  },
 * }} conditionFilter
 * @param conditionFilter.ID - ID of user
 * @param conditionFilter.PageNumber - one value in range from 1 to n (n > 1)
 * @param conditionFilter.PageSize - one value in range from 1 to n (n > 1)
 * @returns {{
 *       Total: number,
 *       Transactions: object[]
 *  }}
 * */
export const getTransHistoriesByUser = ({
    ID,
    PageSize,
    PageNumber,
    FacepayNumber,
    Shop,
    Amount,
    Time
}) => async dispatch => {

    const condFilters = {
        ID,
        PageSize,
        PageNumber,
        FacepayNumber,
        Shop,
        Amount,
        Time
    }

    const condRequestFilters = (() => {
        if(!condFilters.Amount) return condFilters
        return {
            ...condFilters,
            Amount: {
                Sort: condFilters.Amount.Sort,
                Comparers: [
                    condFilters.Amount?.firstCompares, 
                    condFilters.Amount?.secondCompares
                ].filter(item => !!item)
            }
        }
    })()

    verifyParamsActionGetListTransHistoryByUser(condRequestFilters)

    const dataRequest = JSON.stringify(condRequestFilters)

    try {
        const res = await Axios.post(apiGetTransHistoriesByUser, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS
        if(isSuccess){
            dispatch(fetchTransHistoryByUser({
                list: data.Transactions || [],
                total: data.Total || 0,
                condFilters,
                condRequestFilters
            }))
        }
        else {
            dispatch(fetchTransHistoryByUser({
                condFilters,
                condRequestFilters
            }))
            dispatch({
                type: types.GET_LIST_TRANS_HISTORY_BY_USER_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_LIST_TRANS_HISTORY_BY_USER_ERR))
    }

}

export const exportExcelTransHistoryByUser = (condFilters) => async dispatch => {
    const filterConds = {
        ...condFilters, 
        PageNumber: undefined, 
        PageSize: undefined 
    }

    const encodeFilterCondsToBase64 = btoa(JSON.stringify(filterConds))

    const linkExport = genApiGetRequest(apiExportExcelTransHistoriesByUser, { filter: encodeFilterCondsToBase64})

    try {
        const res = await Axios.get(linkExport, {
            responseType: 'blob'
        })

        const { data } = res;

        autoDownloadFromBlob(data, 'TransactionReport')

        if(data) {
            dispatch({
                type: types.EXPORT_EXCEL_TRANS_HISTORY_BY_USER
            })
        }
        else {
            dispatch({
                type: types.EXPORT_EXCEL_TRANS_HISTORY_BY_USER_ERR,
                payload: {}
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.EXPORT_EXCEL_TRANS_HISTORY_BY_USER_ERR))
    }
}

export const clearListTransHistoriesByUser = () => ({
    type: types.CLEAR_LIST_TRANS_HISTORY_BY_USER
})