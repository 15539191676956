import styled, { css } from 'styled-components'
const Bound = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    box-sizing: border-box;
    .row-input{
        display: grid;
        grid-template-columns: 370px 370px;
        grid-gap: 30px;
    }
    .wrapper-input{
        margin-bottom: 30px;
        :nth-child(4){
            margin-bottom: 0px;
        }                
    }
    .drop-down{
        width: 770px;
        height:73px;
        /* border: 1px solid #000000; */
        margin-bottom: 30px;        
    }
    .button-container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 53px;
        .button-delete{
            margin-right: 30px
        }
    }

`
const styleButtonAddStore = css`
    width: 670px;
    height: 48px;
    background-color: rgba(24, 77, 162);
    opacity: ${props => props.isDisable ? 0.3 : 1};
    pointer-events: ${props => props.isDisable ? 'none' : 'auto'};
    border-radius: 3px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    .icon{
        margin-right: 21px;
    }
`

export { Bound, styleButtonAddStore }