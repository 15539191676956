import { host, apiLogout } from "../../assets/constants/host";

import Axios from "axios";
import checkIsSuccessAction from "../../assets/helper/checkIsSuccessAction";

import * as keys from "../../assets/constants/localStore";
//path
import * as pathNames from '../../assets/constants/pathName'

// action
import { popNotify } from "../notifies/action";

// constant 
import * as types from "./types";
import { NETWORK_ERR } from "../notifies/types";

const apiLogin = `${host}/backoffice/login`;

/**
 *
 * @param {*any} history
 * @param  {{ Email: string, Password: string }} dataLogin
 */
export const loginAction = (history, dataLogin, callbackSuccess, callbackError) => 
  async (dispatch, getState) => {
  const store = getState()
  const { isLoadingLogin } = store.authReducer
  if(isLoadingLogin) return;

  dispatch({ type: types.AUTH_LOGIN_START })


  const requestData = JSON.stringify(dataLogin);

  // history.push(`/${pathNames.MAIN}`);
  // localStorage.setItem(FACE_PAY_BO_V2, FACE_PAY_BO_V2);

  try {
    const res = await Axios.post(apiLogin, requestData);
    const { data } = res;
    if (checkIsSuccessAction(data.Code)) {
      history.push(`/${pathNames.MAIN}`);
      localStorage.setItem(keys.FACE_PAY_BO_V2, JSON.stringify({langKey: 'vn'}));
      localStorage.setItem(keys.FACE_PAY_BO_V2_LOGIN, JSON.stringify(data));
      dispatch(popNotify(types.AUTH_LOGIN_ERROR))
      dispatch({
        type: types.AUTH_LOGIN,
        data,
      });
      callbackSuccess && callbackSuccess()
    }
  } catch (error) {
    const response = error?.response;
    if(response) {
      callbackError && callbackError()
      dispatch({ type: types.AUTH_LOGIN_END })
      return;
    }

    const request = error?.request;
    if(request) {
      // console.log('error request', request)
      callbackError && callbackError(NETWORK_ERR)
      dispatch({ type: types.AUTH_LOGIN_END })
      return
    }

    // console.log(error);
    dispatch({
      type: types.AUTH_LOGIN_ERROR,
      error,
    });
  }

  dispatch({ type: types.AUTH_LOGIN_END })

};

export const logoutAction = (history, isCallApi=true) => async (dispatch, getState) => {
  const store = getState()
  const { isLoadingLogout } = store.authReducer
  if(isLoadingLogout) return;

  dispatch({ type: types.AUTH_LOGOUT_START })

//   localStorage.removeItem(keys.FACE_PAY_BO_V2);
  localStorage.removeItem(keys.FACE_PAY_BO_V2_LOGIN);
  if(isCallApi) {
    try {
      await Axios.post(apiLogout());
    } catch (error) {
      // console.log(error);
    }
  }
  history.push("/");
  dispatch({
    type: types.AUTH_LOGOUT,
  });

  dispatch({ type: types.AUTH_LOGOUT_END })

};
