import styled from 'styled-components'

const WrapDropdown = styled.div`
    position: relative;
    width: 100%;

    .title-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 170%;
        color: #6F6F6F;
        background: #F4F4F4;
        border-radius: 6px;
        height: 30px;
        padding: 0 6px;
        box-sizing: border-box;

        &:hover {
            cursor: pointer;
        }
    }
`;

export {
    WrapDropdown
}