//header
export const HEADER_HEIGHT = 80;

//table
export const MAIN_CONTENT_PADDING_TOP = 30;
export const TABLE_HEADER_HEIGHT = 30;
export const TABLE_HEADER_USER_LOGIN_HEIGHT = 21;

//time countup
export const URGENT = 'URGENT'
export const NORMAL = 'NORMAL'
export const WARNING = 'WARNING'
