import React from 'react'
import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export { Bound }