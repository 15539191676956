import { combineReducers } from "redux";

// common type
import * as types from './types'

import detailsAccount, { intitialState as initialStateDetailsAccount } from './detailsAccount'
import notes, { intitialState as initialStateNotes } from './notes'
import faces, { intitialState as initialStateFaces } from './faces'
import eKYCs, { intitialState as initialStateEKYCs } from './eKYCs'
import loginHistories, { intitialState as initialStateLoginHistories } from './loginHistories'
import transactionHistories, { initialState as initialStateTransactionHistories } from './transactionHistories'

const initalState = {
    detailsAccount: initialStateDetailsAccount,
    notes: initialStateNotes,
    faces: initialStateFaces,
    eKYCs: initialStateEKYCs,
    loginHistories: initialStateLoginHistories,
    transactionHistories: initialStateTransactionHistories
}

const detailsUser = combineReducers({
    detailsAccount,
    notes,
    faces,
    eKYCs,
    loginHistories,
    transactionHistories
})

const rootDetailsUser = (state, action) => {
    if(action?.type === types.CLEAR_DETAILS_USER) {
        state = undefined
    }

    return detailsUser(state, action)
}

export default rootDetailsUser