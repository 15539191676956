import * as types from './types'

export const initialState = {
    list: [],
    total: 0,
    condFilters: {},
    condRequestFilters: {}
}

const listTransactions = (state=initialState, action) => {
    switch(action?.type) {
        case types.GET_LIST_TRANSACTIONS:
            return {
                ...state,
                ...action.payload
            }
        case types.CLEAR_LIST_TRANSACTION:
            return initialState;
        default:
            return state;
    }
}

export default listTransactions