import moment from  'moment'

export const verifyParamsActionGetListTrans = (params) => {

    const { PageNumber, PageSize, FacepayNumber, Shop, Amount, Time, User, Status } = params

    if (!PageNumber) throw Error('PageNumber is obligated')
    if (typeof (PageNumber) !== 'number') throw Error('PageNumber must be a number')
    if (!PageSize) throw Error('PageSize is obligated')
    if (typeof (PageSize) !== 'number') throw Error('PageSize must be a number')

    const validSortValue = [-1, 0 , 1]
    const keyValidInCompare = ["GREATER", "LESS", "GREATER_OR_EQUAL", "LESS_OR_EQUAL", "NOT_EQUAL", "EQUAL"]

    if(FacepayNumber) {
        const { Sort, No } = FacepayNumber

        if(Sort && !validSortValue.includes(Sort))
            throw Error(`FacepayNumber.Sort must be one value in {${validSortValue.join(', ')}}`)
        
        if(No && typeof(No) !== 'string')
            throw Error('FacepayNumber.No must be a string')
    }

    if(Shop) {
        const { Sort, ShopName } = Shop

        if(Sort && !validSortValue.includes(Sort))
            throw Error(`Shop.Sort must be one value in {${validSortValue.join(', ')}}`)
        
        if(ShopName && typeof(ShopName) !== 'string')
            throw Error('Shop.ShopName must be a string')
    }

    if(Amount){
        const { Sort, Comparers } = Amount

        if(Sort && !validSortValue.includes(Sort)) {
            throw Error(`Amount.Sort must be one value in {${validSortValue.join(', ')}} but ${Sort}`)
        }

        if(Comparers) {
            if(!Array.isArray(Comparers)) {
                throw Error(`Amount.Comparers must be an array`)
            }

            Comparers.forEach(item => {
                if(!item || typeof(item) !== "object") {
                    throw Error('item in list Amount.Comparers must be an object')
                }
                const { Operator, Value } = item
                if(!keyValidInCompare.includes(Operator)) {
                    throw Error(`Amount.Comparers[item].Operator must be one value in {${keyValidInCompare.join(", ")}}`)
                }

                if(Value !== 0 && typeof(Value) !== "number"){
                    throw Error(`Amount.Comparers[item].Value must be a number but ${typeof(Value)}`)
                }
            })
        }
    }

    if(Time) {
        const { Sort, FromDate, ToDate } = Time

        if(Sort && !validSortValue.includes(Sort))
            throw Error(`Time.Sort must be one value in {-1, 0, 1}`)
        
        if(FromDate && typeof(FromDate) !== 'string') {
            throw Error(`Time.FromDate must be a string but ${typeof(FromDate)}`)
        }

        if(FromDate && !moment(FromDate, 'YYYY/MM/DD', true).isValid()) {
            throw Error(`Time.FromDate must format YYYY/MM/DD but ${typeof(FromDate)}`)
        }

        if(ToDate && typeof(ToDate) !== 'string')
            throw Error(`Time.ToDate must be a string but ${typeof(ToDate)}`)

        if(ToDate && !moment(ToDate, 'YYYY/MM/DD', true).isValid()) {
            throw Error(`Time.ToDate must format YYYY/MM/DD but ${typeof(ToDate)}`)
        }
    }

    if(User) {
        const { Name, Username, PhoneNumber } = User
        
        if(Name && typeof(Name) !== 'string')
            throw Error('User.Name must be a string')

        if(Username && typeof(Username) !== 'string')
            throw Error('User.Username must be a string')

        if(PhoneNumber && typeof(PhoneNumber) !== 'string')
            throw Error('User.PhoneNumber must be a string')
    }

    const sortShop = !!Shop && Shop?.Sort
    const sortAmount = !!Amount && Amount?.Sort
    const sortTime = !!Time && Time?.Sort
    const lstSort = [sortShop, sortAmount, sortTime]
    // sort have value
    // const isLstSortHaveVal = lstSort.every(item => item && item !== undefined)
    // if (isLstSortHaveVal === flase) throw Error('lstSort is obligated')

    // check valid sort
    // const lstSortEnable = lstSort.filter(item => item !== 0)
    // if (lstSortEnable.length > 1)throw Error('Only one element in lstSort has a value')

    // check value locked is LOCKED or NORMAL
    if (Status) {
        const lockStatus = Status.Locked
        if (lockStatus) {
            const arrStatus = ['LOCKED', 'NORMAL']
            const valStatus = arrStatus.includes(lockStatus)
            if (valStatus === false) throw Error("valStatus must be 'LOCKED' or 'NORMAL'")
        }
    }

}