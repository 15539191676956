import styled from 'styled-components'

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .animation-container{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    img{
        display:flex;
        width:200px;
        height:200px;
        margin-bottom:30px;
    }
    font-family: SVN-Gilroy;
    font-style: normal;
    color: #1C1C1C;
    h1{
        font-weight: bold;
        font-size: 24px;
        line-height: 25px;
        display: flex;
    }
    p{
        width: 370px;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        text-align: center;
        display: flex;
    }
`

export { Bound }