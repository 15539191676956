import { css } from "styled-components";
import * as stylesConstaints from '../../../../assets/constants/ui'

export const TABLE_HEADER_STYLED = css`
    grid-template-columns: 30px 190px 200px 180px 1fr 240px 70px;
    grid-gap: 10px;
    width: 100%;
    height: ${stylesConstaints.TABLE_HEADER_USER_LOGIN_HEIGHT}px;
    margin-bottom: 10px;
`

export const TABLE_HEADER_TITILE = [
    {
        label: '',
    },
    {
        label: 'table.header.store_code',
    },
    {
        label: 'table.header.store_name',
    },
    {
        label: 'table.header.store_area',
    },
    {
        label: 'table.header.address',
    },
    {
        label: 'table.header.device'
    },
    {
        label: '',
    },
]
