import styled, { css } from 'styled-components'

const Bound = styled.div`
    width: 510px;
    height: 173px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 6px;
    position: absolute;
    /* top: 3px;
    right: 82px; */
    bottom: calc(100% + 9px);
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);
    .title-popup{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 170%;
        color: #15314C;
        padding: 30px 0 0 25px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 39px 0 0 0;
        padding:33px 0 28px 25px;
    }
    .icon-arrow{
        position: absolute;
        bottom:-12px;
        left: 29px;
    }
`

const styleButtonYes = css`
    width: 210px;
    height: 48px;
    background: #FA8C8C;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color:#ffffff;
    outline: none;
    border: none;
    
`

const styleButtonNo = css`
    width: 210px;
    height: 48px;
    background: #FFFFFF;
    border: 0.5px solid #184DA2;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #184DA2;
    outline: none;
    margin-right: 35px;

`
export { Bound, styleButtonYes, styleButtonNo }