import * as types from './types'


import { clearDetailsAccount } from './detailsAccount/action'
import { clearEKYCs } from './eKYCs/action'
import { clearFaces } from './faces/action'
import { clearNotes } from './notes/action'

export const clearDetailsUser = () => ({
    type: types.CLEAR_DETAILS_USER
})

export const clearAllDetailsAboutUser = () => async dispatch => {
    dispatch(clearDetailsAccount())
    dispatch(clearFaces())
    dispatch(clearNotes())
    dispatch(clearEKYCs())
}