import React from 'react'
import Lottie from 'react-lottie'
import * as styles from './index.style'

//img
import IcEmptyComponent from '../../../assets/images/components/IcEmptyComponent'
//json
// import * as webBuilding from '../../../assets/json/web_building.json'
import * as webDevelopment from '../../../assets/json/web-development.json'

const EmptyControl = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: webDevelopment.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    return (
        <styles.Bound>
            {/* <FadeIn> */}
                <div className='animation-container'>
                    <Lottie options={defaultOptions} height={500} width={500} />  
                    <h1>Trang này đội thiết kế đang xây dựng!  👋</h1>
                </div>
            {/* </FadeIn> */}
        </styles.Bound>
    )
}

export default React.memo(EmptyControl)
