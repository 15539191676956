import styled from "styled-components";

export const Bound = styled.div`
  font-family: SVN-Gilroy;
  font-style: normal;
  position: absolute;
  top: -10px;
  left: -340%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 6px 6px 0px 0px;
  width: 510px;
  height: 326x;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .container {
    padding: 30px 20px 25px 25px;
    .header{
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #15314c;
      }
      .ic_close {
        padding: 5px;
        cursor: pointer;
      }
    }

    .content {
      font-weight: normal;
      font-size: 18px;
      line-height: 170%;
      color: #15314c;
      margin: 20px 0 0 0;
    }

    input {
      width: 460px;
      height: 48px;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #c6d5eb;
      border-radius: 3px;
      margin: 20px 0 0 0;
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #1C1C1C;
      &::placeholder {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: rgba(28, 28, 28, 0.2);
        border: none;
      }
    }
  }

  .block__btn {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &--yes {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffffff;
      color: #184da2;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 0 0 0 6px;
      &.lock{
        background: #F4F4F4;
        color: rgba(24, 77, 162, 0.3);
        cursor: unset;
      }
    }
    
    &--no {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #184da2;
      cursor: pointer;
      border-radius: 0 0 6px 0px;
      p {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .triangle-right {
    position: absolute;
    top: 12px;
    right: -13px;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 25px solid #ffffff;
    border-bottom: 15px solid transparent;
  }
`;

export const Dropdown = styled.div`
  width: 460px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #c6d5eb;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 20px 0 0 0;
  padding: 12px 20px 12px 10px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  .choose-content{
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    color: #1C1C1C;
  }
  .list {
    width: 307px;
    height: max-content;
    position: absolute;
    top: 50px;
    right: 0px;
    background: #FFFFFF;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    .item-reason{
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;                
      background-color: #FFFFFF;
      border-bottom: 1px solid #F8F8F8;
      box-sizing: border-box;                
      padding: 0 10px 0 18px;
      font-family: 'UTM Avo';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #222222;
      :last-child{
        border-bottom: none;
      }
      &:hover{
        background-color: #f1f5fa;
      }
      &.active{
        color: #212121;
      }  
    }
  }
`;
