import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import * as styles from "./index.style";

//component
import HeaderComponent from "../../Control/HeaderComponent";
import HeaderInformationBoard from "../../Control/HeaderComponent/HeaderInfomationBoard";
import UserCensorship from "./UserCensorship";
import Chains from "./Chains"
import TransactionDetail from "./TransactionDetail"
//constant
import * as pathNames from '../../../assets/constants/pathName'
import * as stylesConstants from '../../../assets/constants/ui'
import * as keys from '../../../assets/constants/keys'

const InformationBoard = (props) => {
  const { tabView } = props

  return (
    <styles.Bound>
      <HeaderComponent
        height={stylesConstants.HEADER_HEIGHT - 1}
        backgroundColor="rgba(147, 191, 242, 0.1)"
        borderBottom="1px solid #C6D5EB"
        position="absolute"
        zIndex={5}
        child={<HeaderInformationBoard />}
      />
      <Switch>
        <Route path={`/${pathNames.MAIN}/${pathNames.HOME}`}></Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.USER_CENSORSHIP}`}>
          <UserCensorship tabView={tabView}/>
        </Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.USERS}`}>
          <UserCensorship tabView={tabView}/>
        </Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.TRANSACTIONS}`}>
          <TransactionDetail />
        </Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.CHAINS}`}>
          <Chains tabView={keys.CHAINS}/>
        </Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.MPOS}`}></Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.VERSION_RELEASED}`}></Route>
        <Route path={`/${pathNames.MAIN}/${pathNames.SETTING}`}></Route>

        {/* <Redirect to={`${path}/${pathNames.USER_CENSORSHIP}`} /> */}
      </Switch>
    </styles.Bound>
  );
};

export default React.memo(InformationBoard);
