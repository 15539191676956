import React from 'react'

const IcServer = ({ colorState }) => <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
  <circle opacity="0.1" cx="15.9999" cy={16} r={16} fill={colorState} />
  <path d="M21.5999 9H10.3999C9.62674 9 8.99994 9.6268 8.99994 10.4V13.2C8.99994 13.9732 9.62674 14.6 10.3999 14.6H21.5999C22.3731 14.6 22.9999 13.9732 22.9999 13.2V10.4C22.9999 9.6268 22.3731 9 21.5999 9Z" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M21.5999 17.4H10.3999C9.62674 17.4 8.99994 18.0268 8.99994 18.8V21.6C8.99994 22.3732 9.62674 23 10.3999 23H21.5999C22.3731 23 22.9999 22.3732 22.9999 21.6V18.8C22.9999 18.0268 22.3731 17.4 21.5999 17.4Z" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M11.7999 11.8H11.8069" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M11.7999 20.2H11.8069" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
</g>
<defs>
  <clipPath id="clip0">
    <rect width={32} height={32} fill="white" />
  </clipPath>
</defs>
</svg>

export default IcServer
