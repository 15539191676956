import * as types from './types'

const initialState = {
    list: [],
    total: 0,
    condFilters: {
        PageNumber: 1,
        PageSize: 8,
    }
}

const listInfoUsers = (state=initialState, action) => {
    switch(action.type){
        case types.GET_LIST_INFO_USERS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
}

export default listInfoUsers