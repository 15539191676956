import React from 'react'

const IcStoreOn = (props) => <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.1" cx={16} cy={16} r={16} fill="url(#paint0_linear)" />
    <path d="M10 13.901L16.3 9.00098L22.6 13.901V21.601C22.6 21.9723 22.4525 22.3284 22.1899 22.5909C21.9274 22.8535 21.5713 23.001 21.2 23.001H11.4C11.0287 23.001 10.6726 22.8535 10.4101 22.5909C10.1475 22.3284 10 21.9723 10 21.601V13.901Z" stroke="url(#paint1_linear)" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <defs>
        <linearGradient id="paint0_linear" x1={16} y1={48} x2={48} y2={16} gradientUnits="userSpaceOnUse">
            <stop stopColor="#00CFFF" />
            <stop offset={1} stopColor="#0065FF" />
            <stop offset={1} stopColor="#005BEC" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="16.3" y1="30.001" x2="30.2227" y2="17.4706" gradientUnits="userSpaceOnUse">
            <stop stopColor="#00CFFF" />
            <stop offset={1} stopColor="#0065FF" />
            <stop offset={1} stopColor="#005BEC" />
        </linearGradient>
    </defs>
</svg>

export default IcStoreOn
