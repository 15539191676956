import styled from 'styled-components'

const AcceptFace = styled.div`
    display: flex;
    align-items: center;
    margin-right: 38px;
    & > *:last-child {
        margin-left: 9px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #00B887;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 15px;
    }
`;

const RejectFace = styled.div`
    display: flex;
    align-items: center;
    margin-right: 38px;
    & > *:last-child {
        margin-left: 9px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #FA8C8C;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
    }
`;

export {
    AcceptFace,
    RejectFace
}