import styled from "styled-components";

const Bound = styled.div`
    display: flex;
    flex: 1;
    height: 100%;
    overflow: auto;
    padding: 30px 27px 0 25px;
`;

export { Bound };
