import React, { useRef, useState, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next'
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from 'react-autosize-textarea';

// styles
import * as styles from "./index.style";

// images
import IcSendNote from "../../../../assets/images/components/Ic_send_note";

// helper
import { convertDateTime } from "../../../../assets/helper/tools";

// action
import { getNotesByUser, addNote } from "../../../../redux/userCensorship/detailsUser/notes/action";

// constant
import { DANGER, WARNING, INFO } from "../../../../assets/constants/noteTypes";

// component
import NoteItem from "./NoteItem";
import IcSentNoteBlur from "../../../../assets/images/components/IcSentNoteBlur";

const dataPriority = [
  {
    key: DANGER,
    type: "#Cao",
    background: "#FA8C8C",
  },
  {
    key: WARNING,
    type: "#Bình thường",
    background: "#FAD58C",
  },
  {
    key: INFO,
    type: "#Thấp",
    background: "#93BFF2",
  },

];


const AdminNote = ({ handleGetAllNote, handleAddNote, listNotes }) => {
  const { t } = useTranslation('common')
  const valueRef = useRef();
  const addNoteRef = useRef(handleAddNote)
  const isClickOutsiteRef = useRef(false);
  const listMessage = useRef();
  const dispatch = useDispatch()

  const { detailsAccount } = useSelector(state => ({
    detailsAccount: state.userCensorship.detailsUser.detailsAccount
  }))

  const [showBlockPriority, setShowBlockPriority] = useState(false);
  const [priority, setPriority] = useState(dataPriority[1]);
  const [value, setValue] = useState("")

  const _handleClickOutSite = useCallback(() => {
    if (isClickOutsiteRef.current === false)
      setTimeout(() => {
        setShowBlockPriority(false);
      }, 0);
  }, []);

  useEffect(() => {
    if (showBlockPriority)
      document.addEventListener("click", _handleClickOutSite, false);
    return () =>
      document.removeEventListener("click", _handleClickOutSite, false);
  }, [_handleClickOutSite, showBlockPriority]);

  const _handleOnChange = useCallback(
    (e) => {
      const { value } = e.target
      // const val = value.trimStart().replace(/\s+/g, " ")
      setValue(value)
    },
    [value, setValue],
  )

  const _onSubmit = useCallback((e) => {
    e.preventDefault();
    const { value } = valueRef.current;
    if (!value) return;
    // addNoteRef.current(priority.key, value.trim().replace(/\s+/g, " "))
    addNoteRef.current(priority.key, value)
    // valueRef.current.value = "";
    setValue("")
    scrollToBottom();
  }, [priority]);

  const _handlePress = useCallback(
    (e) => {

      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault()
        _onSubmit(e)

        return;
      }
    },
    [_onSubmit],
  )

  useEffect(() => {
    const textarea = valueRef.current;
    textarea.addEventListener('keypress', _handlePress)
    return () => {
      textarea.removeEventListener('keypress', _handlePress)
    }
  }, [valueRef, _handlePress])

  const scrollToBottom = () => {
    listMessage.current.scrollTo(
      listMessage.current.scrollHeight - 80,
      listMessage.current.scrollHeight
    );
  };

  useEffect(() => {
    scrollToBottom();
  }, [listNotes]);

  useEffect(() => {
    addNoteRef.current = handleAddNote
  }, [handleAddNote])

  useEffect(() => {
    handleGetAllNote()
    setValue("")
    if (valueRef.current)
      valueRef.current.value = ''
  }, [handleGetAllNote])

  const _renderDataListNote = (data) => {
    if (!Array.isArray(data) || !data || data.length === 0) return;

    return data.map((item, index) => {
      const line = dataPriority.find((a) => a.key === item.Type);
      // const type = dataPriority.find((a) => a.type === item.Type);
      return (
        <NoteItem key={index} line={line} data={item} />
      );
    });
  };


  return (
    <styles.Bound>
      <div className="list__note" ref={listMessage}>
        {_renderDataListNote(listNotes)}
      </div>

      <div className="send__note">
        <div className="priority">
          <div
            className="priority__choice"
            style={{ backgroundColor: priority.background }}
            onClick={() => setShowBlockPriority(!showBlockPriority)}
          >
            <p>#</p>
          </div>

          {showBlockPriority && (
            <div
              className="priority__block"
              onMouseEnter={() => (isClickOutsiteRef.current = true)}
              onMouseLeave={() => (isClickOutsiteRef.current = false)}
            >
              <div className='title-priority-wrapper'>
                <p className='title-priority'>Chọn mức độ</p>
              </div>
              {dataPriority.map((item, index) => {
                return (
                  <div
                    className="item-wrapper"
                    key={index}
                    style={{ backgroundColor: item.background }}
                    onClick={() => {
                      setPriority(item);
                      setShowBlockPriority(false);
                    }}
                  >
                    {item.type}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <form className="form" onSubmit={_onSubmit}>
          {/* <input
            className="input"
            type="text"
            placeholder={t('input.placeholder.note')}
            ref={valueRef}
          /> */}
          <div className="input-wrap">
            <TextareaAutosize
              className="input"
              placeholder={t('input.placeholder.note')}
              style={{ maxHeight: 100 }}
              value={value}
              onChange={_handleOnChange}
              ref={valueRef}
            />
          </div>
          {
            value !== "" ?
              <button className="btn">
                <IcSendNote />
              </button>
              :
              <button className="btn">
                <IcSentNoteBlur />
              </button>
          }
          

        </form>
      </div>
    </styles.Bound>
  );
};

AdminNote.propTypes = {
  // dataListNote: PropTypes.array,
};

export default React.memo(AdminNote);
