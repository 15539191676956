import React from 'react'

const Calendar = (props) => {
    return (
        <svg {...props} width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 3.16699H3.33333C2.59695 3.16699 2 3.76395 2 4.50033V13.8337C2 14.57 2.59695 15.167 3.33333 15.167H12.6667C13.403 15.167 14 14.57 14 13.8337V4.50033C14 3.76395 13.403 3.16699 12.6667 3.16699Z" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6667 1.83301V4.49967" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.33325 1.83301V4.49967" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 7.16699H14" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Calendar
