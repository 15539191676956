import styled from 'styled-components'
const Bound = styled.div`
    position: fixed;
    top: ${props => props.pos.top || 0}px;
    left: ${props => props.pos.left || 0}px;
    width: 1330px;
    height: 636px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #C6D5EB;
    z-index: 9999;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);

`
export { Bound }