import * as types from "./types";

const intitialState = {
    primaryUser: {},
    primaryEKYCUser: {},
    secondaryUser: {},
    secondaryEKYCUser: {},
};

const compareEKYCs = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_COMPARE_INFOS_EKYCS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
};

export default compareEKYCs;
