import logo_abbank from "../assets/images/bank/logo_abbank.svg";
import logo_FE from "../assets/images/bank/logo_FE.svg";
import logo_lienviet from "../assets/images/bank/logo_lienviet.svg";
import logo_msb from "../assets/images/bank/logo_msb.svg";
import logo_OCB from "../assets/images/bank/logo_OCB.svg";
import logo_sacombank from "../assets/images/bank/logo_sacombank.svg";
import logo_tpbank from "../assets/images/bank/logo_tpbank.svg";
import logo_vietcapital from "../assets/images/bank/logo_vietcapital.svg";
import logo_vietcredit from "../assets/images/bank/logo_vietcredit.svg";
import logo_vpbank from "../assets/images/bank/logo_vpbank.svg";
import logo_vietinbank from "../assets/images/bank/logo_vietinbank.svg"
import logo_shb from "../assets/images/bank/logo_shb.svg"
import logo_hdbank from "../assets/images/bank/logo_hdbank.svg"
import logo_oceanbank from "../assets/images/bank/logo_oceanbank.svg"
import logo_seabank from "../assets/images/bank/logo_seabank.svg"
import logo_vietcombank from "../assets/images/bank/logo_vietcombank.svg"
import logo_agribank from "../assets/images/bank/logo_agribank.svg"
import logo_techcombank from "../assets/images/bank/logo_techcombank.svg"
import logo_vib from "../assets/images/bank/logo_vib.svg"
import logo_acb from "../assets/images/bank/logo_acb.svg"
import logo_namabank from "../assets/images/bank/logo_namabank.svg"
import logo_shinhanbank from "../assets/images/bank/logo_shinhanbank.svg"
import logo_publicbank from "../assets/images/bank/logo_publicbank.svg"
import logo_bidv from "../assets/images/bank/logo_bidv.svg"
import logo_vrb from "../assets/images/bank/logo_vrb.svg"
import logo_cimb from "../assets/images/bank/logo_cimb.svg"
import logo_pgbank from "../assets/images/bank/logo_pgbank.svg"
import logo_exmimbank from "../assets/images/bank/logo_exmimbank.svg"
import logo_hongleongbank from "../assets/images/bank/logo_hongleongbank.svg"
import logo_saigonbank from "../assets/images/bank/logo_saigonbank.svg"
import logo_dongabank from "../assets/images/bank/logo_dongabank.svg"
import logo_kienlongbank from "../assets/images/bank/logo_kienlongbank.svg"
import logo_pvcombank from "../assets/images/bank/logo_pvcombank.svg"
import logo_mbbank from "../assets/images/bank/logo_mbbank.svg"
import logo_baovietbank from "../assets/images/bank/logo_baovietbank.svg"
import logo_gpbank from "../assets/images/bank/logo_gpbank.svg"
import logo_coopbank from "../assets/images/bank/logo_coopbank.svg"
import logo_idovinabank from "../assets/images/bank/logo_idovinabank.svg"
import logo_ncb from "../assets/images/bank/logo_ncb.svg"
import logo_wooribank from "../assets/images/bank/logo_wooribank.svg"
import logo_vietabank from "../assets/images/bank/logo_vietabank.svg"
import logo_scbbank from "../assets/images/bank/logo_scbbank.svg"
import logo_bacabank from "../assets/images/bank/logo_bacabank.svg"
import logo_vietbank from "../assets/images/bank/logo_vietbank.svg"
import logo_uob from "../assets/images/bank/logo_uob.svg"



export const dataBank = [
    {
        BankCode: "CTG",
        BankImage: logo_vietinbank,
        BankShortName: "VietinBank",
    },
    {
        BankCode: "OCB",
        BankImage: logo_OCB,
        BankShortName: "OCB",
    },
    {
        BankCode: "STB",
        BankImage: logo_sacombank,
        BankShortName: "Sacombank",
    },
    {
        BankCode: "ABB",
        BankImage: logo_abbank,
        BankShortName: "ABBANK",
    },
    {
        BankCode: "TPB",
        BankImage: logo_tpbank,
        BankShortName: "TPBank",
    },
    {
        BankCode: "VPB",
        BankImage: logo_vpbank,
        BankShortName: "VPBank",
    },
    {
        BankCode: "LPB",
        BankImage: logo_lienviet,
        BankShortName: "LienVietPostBank",
    },
    {
        BankCode: "VCCB",
        BankImage: logo_vietcapital,
        BankShortName: "VietCapitalBank",
    },
    {
        BankCode: "MSB",
        BankImage: logo_msb,
        BankShortName: "MSB",
    },
    {
        BankCode: "SHB",
        BankImage: logo_shb,
        BankShortName: "SHB",
    },
    {
        BankCode: "HDB",
        BankImage: logo_hdbank,
        BankShortName: "HDB",
    },
    {
        BankCode: "OJB",
        BankImage: logo_oceanbank,
        BankShortName: "OJB",
    },
    {
        BankCode: "SEAB",
        BankImage: logo_seabank,
        BankShortName: "SEAB",
    },
    {
        BankCode: "VCB",
        BankImage: logo_vietcombank,
        BankShortName: "VCB",
    },
    {
        BankCode: "VARB",
        BankImage: logo_agribank,
        BankShortName: "VARB",
    },
    {
        BankCode: "TCB",
        BankImage: logo_techcombank,
        BankShortName: "TCB",
    },
    {
        BankCode: "VIB",
        BankImage: logo_vib,
        BankShortName: "VIB",
    },
    {
        BankCode: "ACB",
        BankImage: logo_acb,
        BankShortName: "ACB",
    },
    {
        BankCode: "NAB",
        BankImage: logo_namabank,
        BankShortName: "NAB",
    },
    {
        BankCode: "SVB",
        BankImage: logo_shinhanbank,
        BankShortName: "SVB",
    },
    {
        BankCode: "PBVN",
        BankImage: logo_publicbank,
        BankShortName: "PBVN",
    },
    {
        BankCode: "BIDV",
        BankImage: logo_bidv,
        BankShortName: "BIDV",
    },
    {
        BankCode: "VRB",
        BankImage: logo_vrb,
        BankShortName: "VRB",
    },
    {
        BankCode: "CIM",
        BankImage: logo_cimb,
        BankShortName: "CIM",
    },
    {
        BankCode: "PGB",
        BankImage: logo_pgbank,
        BankShortName: "PGB",
    },
    {
        BankCode: "EIB",
        BankImage: logo_exmimbank,
        BankShortName: "EIB",
    },
    {
        BankCode: "HOL",
        BankImage: logo_hongleongbank,
        BankShortName: "HOL",
    },
    {
        BankCode: "SGB",
        BankImage: logo_saigonbank,
        BankShortName: "SGB",
    },
    {
        BankCode: "DAB",
        BankImage: logo_dongabank,
        BankShortName: "DAB",
    },
    {
        BankCode: "KLB",
        BankImage: logo_kienlongbank,
        BankShortName: "KLB",
    },
    {
        BankCode: "PVCB",
        BankImage: logo_pvcombank,
        BankShortName: "PVCB",
    },
    {
        BankCode: "MB",
        BankImage: logo_mbbank,
        BankShortName: "MB",
    },
    {
        BankCode: "BVB",
        BankImage: logo_baovietbank,
        BankShortName: "BVB",
    },
    {
        BankCode: "GPB",
        BankImage: logo_gpbank,
        BankShortName: "GPB",
    },    
    {
        BankCode: "COP",
        BankImage: logo_coopbank,
        BankShortName: "COP",
    },
    {
        BankCode: "IVB",
        BankImage: logo_idovinabank,
        BankShortName: "IVB",
    },
    {
        BankCode: "NVB",
        BankImage: logo_ncb,
        BankShortName: "NVB",
    },
    {
        BankCode: "WOO",
        BankImage: logo_wooribank,
        BankShortName: "WOO",
    },
    {
        BankCode: "VAB",
        BankImage: logo_vietabank,
        BankShortName: "VAB",
    },
    {
        BankCode: "SCB",
        BankImage: logo_scbbank,
        BankShortName: "SCB",
    },
    {
        BankCode: "NASB",
        BankImage: logo_bacabank,
        BankShortName: "NASB",
    },
    {
        BankCode: "VBB",
        BankImage: logo_vietbank,
        BankShortName: "VBB",
    },
    {
        BankCode: "UOB",
        BankImage: logo_uob,
        BankShortName: "UOB",
    },
    




    {
        BankCode: "VIETCREDIT",
        BankImage: logo_vietcredit,
        BankShortName: "VIETCREDIT",
    },
    {
        BankCode: "FECREDIT",
        BankImage: logo_FE,
        BankShortName: "FECREDIT",
    },
]