import styled from 'styled-components'
import { WrapFilter } from '../../../BoxFilterByText/index.styles';

const WrapBoxListItem = styled(WrapFilter)`
    z-index: 1;
    width: 175px;
    border-radius: 6px;

    .field {
        z-index: 1;

        &:hover, &.selected {
            background: #EDEDED;
        }

        &:hover {
            cursor: pointer;
        }

        div {
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 142%;
            color: #212121;
            margin-left: 6px;
        }
    }
`;

const DropdownItem = styled.div`
    height: 42px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 6px;
`;

export {
    WrapBoxListItem,
    DropdownItem
}