import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import * as styles from "./index.style";

//component
import TableComponent from "../../../Control/TableComponent";
import EmptyDataControl_v3 from '../../../Control/EmptyDataControl_v3'
//template
import * as templateHeader from '../../../Control/TableComponent/Template/usercensorship.template.header'
import * as templateContent from '../../../Control/TableComponent/Template/usercensorship.template.content'
//constant
import * as keys from '../../../../assets/constants/keys'
//action
import { getUserInfos } from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'
import { getPendingUsers } from '../../../../redux/userCensorship/listUsers/commonAction'

var isFirstTimeRender = true

const UserCensorship = (props) => {
    const dispatch = useDispatch()
    const { typeView } = props
    // let data=[]
    // const randomLength = Math.floor(Math.random() * 20)
    // for(let i = 0; i < randomLength; i++){
    //     data.push(mockData.user_censorship[Math.floor(Math.random() * mockData.user_censorship.length) + 1])
    // }

    const [data, setData] = useState([])
    const [type, setType] = useState(typeView)

    const { userCensorship } = useSelector(state => ({
        userCensorship: state.userCensorship
    }))

    useEffect(() => {
        dispatch(getPendingUsers({PageNo: 1, PageSize: 50, Type: keys.AVATAR}))
        dispatch(getPendingUsers({PageNo: 1, PageSize: 50, Type: keys.EKYC}))
    }, [])

    useEffect(() => {
        if(!typeView) return
        if(typeView !== keys.AVATAR && typeView !== keys.EKYC) return
        if(type !== typeView){
            setData([])
            setType(typeView)
        }
    }, [typeView])

    useEffect(() => {
        // if(!userCensorship.listUsers) return
        if(type === keys.AVATAR){
            if(userCensorship?.listUsers?.facesPending){
                setData(userCensorship.listUsers.facesPending.list)
            }
        }
        if(type === keys.EKYC){
            if(userCensorship?.listUsers?.eKYCsPending){
                setData(userCensorship.listUsers.eKYCsPending.list)
            }
        }

    }, [userCensorship.listUsers])

    useEffect(() => {
        if(!data) return
        if(data.length === 0) return
        if(isFirstTimeRender){
            isFirstTimeRender = false
            dispatch(getUserInfos({UserID: data[0].ID}))
        }
    }, [data])
    
    return (
        <styles.Bound>
            {
                data && data.length > 0 ?
                <TableComponent 
                    typeView = {typeView}
                    data = { data }
                    templateHeader = { templateHeader }
                    templateContent = { templateContent }
                />
                :
                <EmptyDataControl_v3 labelDes={'Không có tài khoản nào cần xử lý'}/>
            }
            
        </styles.Bound>
  );
};

export default React.memo(UserCensorship);
