import { css } from "styled-components";
import * as stylesConstaints from '../../../../assets/constants/ui'

export const TABLE_HEADER_STYLED = css`
    grid-template-columns: 45px 54px 254px 306px 1fr 298px;
    /* padding: 0 30px 0 58px;
    width: calc(100% - (30px + 58px)); */
    width: 100%;
    height: ${stylesConstaints.TABLE_HEADER_USER_LOGIN_HEIGHT}px;
    margin-bottom: 10px;
`

export const TABLE_HEADER_TITILE = [
    {
        label: '',
    },
    {
        label: '',
    },
    {
        label: 'table.header.time',
    },
    {
        label: 'table.header.login_type',
    },
    {
        label: 'table.header.location',
    },
    {
        label: 'table.header.status'
    },
]
