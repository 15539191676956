import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
//language json
import en_common from "./translations/en_common.json";
import vn_common from "./translations/vn_common.json";

// constant
import { FACE_PAY_BO_V2_LOGIN } from "./assets/constants/localStore";

const loginData = localStorage.getItem(FACE_PAY_BO_V2_LOGIN)
let initialStates = {}
if(loginData){
  const loginResults = JSON.parse(loginData)
  initialStates = {
    authReducer: {
      loggedIn: true,
      dataLogin: loginResults,
      error: "",
    }
  }
}

const store = configureStore(initialStates);

i18next
  .use(initReactI18next)
  .init({
  react: {
    useSuspense: false
  },
  interpolation: { escapeValue: false },
  fallbackLng: 'vn',
  resources: {
    vn: {
      common: vn_common
    },
    en: {
      common: en_common
    },
  },
}); 

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
