import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next'
import * as styles from "./index.style";


// components
import HeaderComponent from "../../../Control/HeaderComponent";
import HeaderMenuInformationBoard from "../../../Control/HeaderComponent/HeaderMenuInformationBoard";
import { headerUsercensorshipMenuInfomationBoard } from "../../../../assets/data/dataHeaderMenu";
import UserInformation from "./UserInformation";
import PictureCensorship from "./PicturesCensorship";
import EKYCCensorship from './eKYCCensorship'
import UserLoginHistory from "./UserLoginHistory";
import AdminNote from "../AdminNote";
import { HeaderDetailAccount } from "../../../Control/HeaderComponent/HeaderDetailAccount";
import HeaderNote from "../../../Control/HeaderComponent/HeaderNote";
import EmptydataControl_v2 from '../../../Control/EmptyDataControl_v2';
import EmptydataControl_v3 from '../../../Control/EmptyDataControl_v3';
import TransactionHistory from "./TransactionHistory";

// constant
import * as stylesConstants from "../../../../assets/constants/ui";

// data
import { dataListNote } from "../../../../assets/data/dataListNote";

//constant
import * as keys from '../../../../assets/constants/keys'

// action
import { addNote, getNotesByUser } from "../../../../redux/userCensorship/detailsUser/notes/action";
import { clearDetailsAccount } from "../../../../redux/userCensorship/detailsUser/detailsAccount/action"

let bound;
let userManageBar;

const UserCensorship = (props) => {
  const dispatch = useDispatch()

  const { tabView } = props

  const { userCensorship, listInfoUsers } = useSelector(state => ({
    userCensorship: state.userCensorship,
    listInfoUsers: state.listInfoUsers
  }))
  

  const [userManageSeleted, setUserManageSeleted] = useState(
    headerUsercensorshipMenuInfomationBoard[0].key
  );

  const [userSelected, setUserSelected] = useState({})
  const [isEmptyData, setIsEmptyData] = useState(true)

  useEffect(() => {
    caculateUserManageBarHeightContent();
    return () => {};
  }, []);

  useEffect(() => {
    refreshUI()
  }, [tabView, userCensorship, listInfoUsers])

  useEffect(() => {
    dispatch(clearDetailsAccount())
    refreshUI()
  }, [tabView])

  const refreshUI = () => {
    switch (tabView) {
      case keys.AVATAR:{
        if(!userCensorship?.listUsers?.facesPending) return
        if(userCensorship?.listUsers?.facesPending?.list?.length>0) setIsEmptyData(false) 
        else setIsEmptyData(true)
      }
        break;
      case keys.EKYC:{
        if(!userCensorship?.listUsers?.eKYCsPending) return
        if(userCensorship?.listUsers?.eKYCsPending?.list?.length>0) setIsEmptyData(false) 
        else setIsEmptyData(true)
      }
        break;
      case keys.USERS:{
        if(!listInfoUsers?.list) return
        if(listInfoUsers?.list?.length === 0) setIsEmptyData(true) 
        else {
          if(userCensorship?.detailsUser?.detailsAccount?.ID?.length > 0)
            setIsEmptyData(false)
          else 
            setIsEmptyData(true)
        }
      }
        break;
      default:
        break;
    }
    caculateUserManageBarHeightContent()
  }

  // useEffect(() => {
  //   if(!userCensorshipReducer.userCensorshipDetail) return
  //   if(userCensorshipReducer.userCensorshipDetail.id.length === 0) return
  //   setUserSelected(userCensorshipReducer.userCensorshipDetail)
  //   return () => {
        
  //   }
  // }, [userCensorshipReducer.userCensorshipDetail])

  const caculateUserManageBarHeightContent = () => {
    // bound = document.getElementById("user-censorship-id");
    let bound = document.getElementById('main-bound-id')
    userManageBar = document.getElementById("user-manage-id");
    if (!userManageBar || !bound) return;
    
    let vh = document.body.offsetHeight;
    if (!vh) return;
    let barHeight =
      bound.offsetHeight -
      (stylesConstants.HEADER_HEIGHT +
        stylesConstants.MAIN_CONTENT_PADDING_TOP) -
      15;
      
    userManageBar.style.height = `${barHeight}px`;
  };

  const _callBackClickNavLink = (data) => {
    setUserManageSeleted(data.key);
  };

  const _handleGetAllNote = useCallback(
    () => {
      const { ID } = userCensorship.detailsUser.detailsAccount
      if(!ID) return;
      dispatch(getNotesByUser({UserID: ID}))
    },
    [JSON.stringify(userCensorship.detailsUser.detailsAccount)],
  )

  const _handleAddNote = useCallback(
    (Type, Message) => {
      const { ID } = userCensorship.detailsUser.detailsAccount
      if(!ID) return;

      dispatch(addNote(
      {
        UserID: ID,
        Type,
        Message
      }, 
      () => _handleGetAllNote()
    ))
    },
    [JSON.stringify(userCensorship.detailsUser.detailsAccount), _handleGetAllNote],
  )
  
  const listNotes = useMemo(() => userCensorship.detailsUser.notes.list,  [JSON.stringify(userCensorship.detailsUser.notes.list)] ) 

  return (
    <styles.Bound id="user-censorship-id">
      {
        isEmptyData ?
        <EmptydataControl_v2 />
        :
        <div className="scroll">

          {/* USER INFORMATION BAR */}
          <div className="user-info">
            <HeaderComponent
              height={68}
              backgroundColor="rgba(198, 213, 235, 0.1)"
              position="absolute"
              zIndex={2}
              child={<HeaderDetailAccount isLocked = {userSelected? userSelected.isLocked:false}/>}
            />
            <UserInformation />
          </div>

          {/* ADMIN NOTE BAR */}
          <div className="user-note">
            <HeaderComponent
              height={68}
              backgroundColor="rgba(198, 213, 235, 0.1)"
              position="absolute"
              child={<HeaderNote lstClassification={userCensorship.detailsUser.notes.lstClassification} />}
            />
            <AdminNote 
              handleGetAllNote={_handleGetAllNote}
              handleAddNote={_handleAddNote}
              listNotes={listNotes}
            />
          </div>

          {/* CENSORSHIP MANAGE BAR */}
          <div className="user-manage" id="user-manage-id">
            <HeaderComponent
              height={69}
              backgroundColor="rgba(198, 213, 235, 0.1)"
              borderBottom="1px solid #C6D5EB"
              position="absolute"
              zIndex={3}
              child={
                <HeaderMenuInformationBoard
                  dataMenu={headerUsercensorshipMenuInfomationBoard}
                  callBackClickNavLink={_callBackClickNavLink}
                />
              }
            />
            <div className="empty-header" />
            {
              userManageSeleted === headerUsercensorshipMenuInfomationBoard[0].key ? (
                <PictureCensorship />
              ) : 
              userManageSeleted === headerUsercensorshipMenuInfomationBoard[1].key ? (
                <EKYCCensorship />
              // ) :
              // userManageSeleted === headerUsercensorshipMenuInfomationBoard[2].key ? (
              //   <EKYCCensorship />
              // ) : userManageSeleted === headerUsercensorshipMenuInfomationBoard[3].key ? (
              //   <UserLoginHistory />
              ) : userManageSeleted === headerUsercensorshipMenuInfomationBoard[2].key ? (
                <TransactionHistory />
              ) : <div style={{flex: 1}}><EmptydataControl_v3/></div>
            }
          </div>
        </div>
        
      }
    </styles.Bound>
  );
};

export default React.memo(UserCensorship);


