import React, { useState, useEffect, useRef } from 'react'

// component
import IcFacepay from '../../../assets/images/components/IcFacepay'

// styles
import { WrapToast } from './index.styles'


const Toast = ({ isShow }) => {
    // const tick = useRef(0)
    // const timer = useRef(0)
    const isFirstRender = useRef(true)

    // const [isFirstRender, setIsFirstRender] = useState(true);

    // useEffect(() => {
    //     if(!isShow) {
    //         clearInterval(timer.current)
    //         console.log('total time show:', tick.current)
    //         tick.current = 0
    //         return;
    //     }
    //     timer.current = setInterval(() => {
    //         tick.current++
    //     }, 1000);
    //     return () => {
    //         clearInterval(timer.current)
    //     }
    // }, [isShow]);

    useEffect(() => {
        isFirstRender.current = false
    }, []);

    const className = isShow ? 'show-toast' : isFirstRender.current ? '' : 'hidden-toast'

    return (
        <WrapToast className={className}>
            <IcFacepay className="icon" />
            <span>Khách hàng vừa đăng ký tài khoản, kiểm duyệt ngay nhé!</span>
        </WrapToast>
    )
}

export default Toast
