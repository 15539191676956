import React from 'react'

const IcStepDetailsOff = (props) => {
    return (
        <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.1" cx={16} cy={16} r={16} fill="#6F6F6F" />
            <path d="M19.1 9H10.7C10.5143 9 10.3363 9.07375 10.205 9.20503C10.0737 9.3363 10 9.51435 10 9.7V20.9C10 21.0857 10.0737 21.2637 10.205 21.395C10.3363 21.5263 10.5143 21.6 10.7 21.6H11.4V22.3C11.4 22.4857 11.4737 22.6637 11.605 22.795C11.7363 22.9263 11.9143 23 12.1 23C12.2857 23 12.4637 22.9263 12.595 22.795C12.7262 22.6637 12.8 22.4857 12.8 22.3V21.6H19.1C19.657 21.6 20.1911 21.3788 20.5849 20.9849C20.9787 20.5911 21.2 20.057 21.2 19.5V11.1C21.2 10.543 20.9787 10.0089 20.5849 9.61508C20.1911 9.22125 19.657 9 19.1 9ZM17 20.2H11.4V10.4H17V20.2ZM19.8 19.5C19.8 19.6857 19.7262 19.8637 19.595 19.995C19.4637 20.1263 19.2857 20.2 19.1 20.2H18.4V10.4H19.1C19.2857 10.4 19.4637 10.4737 19.595 10.605C19.7262 10.7363 19.8 10.9143 19.8 11.1V19.5Z" fill="#6F6F6F" />
        </svg>
    )
}

export default IcStepDetailsOff
