import React, { useCallback } from 'react'

// styles
import { DropdownItem, WrapBoxListItem } from './index.styles'

// components
import LessThanHaveBg from '../../../../../assets/images/components/LessThanHaveBg'
import GreaterThanHaveBg from '../../../../../assets/images/components/GreaterThanHaveBg'
import LessThanOrEqualHaveBg from '../../../../../assets/images/components/LessThanOrEqualHaveBg'
import EqualHaveBg from '../../../../../assets/images/components/EqualHaveBg'
import NotEqualHaveBg from '../../../../../assets/images/components/NotEqualHaveBg'
import GreaterThanOrEqualHaveBg from '../../../../../assets/images/components/GreaterThanOrEqualHaveBg'


export const lstTypesKeyFilterNum = {
    LESS_THAN: "LESS_THAN",
    LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
    GREATER_THAN: "GREATER_THAN",
    GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
    EQUAL: "EQUAL",
    NOT_EQUAL: "NOT_EQUAL",
}

export const listDropdownData = {
    [lstTypesKeyFilterNum.LESS_THAN]: {
        key: lstTypesKeyFilterNum.LESS_THAN,
        Icon: LessThanHaveBg,
        text: "Nhỏ hơn"
    },
    [lstTypesKeyFilterNum.GREATER_THAN]: {
        key: lstTypesKeyFilterNum.GREATER_THAN,
        Icon: GreaterThanHaveBg,
        text: "Lớn hơn"
    },
    [lstTypesKeyFilterNum.LESS_THAN_OR_EQUAL]: {
        key: lstTypesKeyFilterNum.LESS_THAN_OR_EQUAL,
        Icon: LessThanOrEqualHaveBg,
        text: "Nhỏ hơn hoặc bằng"
    },
    [lstTypesKeyFilterNum.GREATER_THAN_OR_EQUAL]: {
        key: lstTypesKeyFilterNum.GREATER_THAN_OR_EQUAL,
        Icon: GreaterThanOrEqualHaveBg,
        text: "Lớn hơn hoặc bằng"
    },
    [lstTypesKeyFilterNum.EQUAL]: {
        key: lstTypesKeyFilterNum.EQUAL,
        Icon: EqualHaveBg,
        text: "Bằng"
    },
    [lstTypesKeyFilterNum.NOT_EQUAL]: {
        key: lstTypesKeyFilterNum.NOT_EQUAL,
        Icon: NotEqualHaveBg,
        text: "Khác"
    },

}

const BoxListDropdownItem = ({ closePopup, selected, setSelected }) => {

    const _handleSelectItem = useCallback(
        (key) => {
            setSelected(key); 
            closePopup()
        },
        [setSelected, closePopup],
    )

    return (
        <WrapBoxListItem>
            {
                Object.values(listDropdownData).map(({ key, Icon, text }) => 
                    <div 
                        className={`field${selected === key? ' selected' : ''}`} 
                        key={key}
                        onClick={() => _handleSelectItem(key)}
                    >
                        <Icon />
                        <div>{text}</div>
                    </div>
                )
            }
        </WrapBoxListItem>
    )
}

export default BoxListDropdownItem
