import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'

// constant
import { FACE_PAY_BO_V2_LOGIN } from '../../../assets/constants/localStore';

// action
import { logoutAction } from '../../../redux/authenticates/action';

// component
import AlertPopup from '../AlertPopup';

const alertPopupExtendStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: 540px;
    max-width: unset;

    .timer {
        color: #051321;
        font-weight: bold;
        font-size: 16px;
        margin-right: 35px;
    }
`

const Error403 = ({ handleClose }) => {
    const history = useHistory()
    const dispatch = useDispatch();


    const second = useRef(10);
    const [secondComponent, setSecondComponent] = useState(<div className="timer">10s</div>);

    useEffect(() => {
        const dataLogin = localStorage.getItem(FACE_PAY_BO_V2_LOGIN);
        if (!dataLogin) {
            handleClose();
            dispatch(logoutAction(history, false));
            return;
        }

        const objDataLogin = JSON.parse(dataLogin);
        if (objDataLogin.isLogedOut) {
            handleClose();
            dispatch(logoutAction(history, false));
            return;
        }
        objDataLogin.isLogedOut = true;
        localStorage.setItem(FACE_PAY_BO_V2_LOGIN, JSON.stringify(objDataLogin));

        let time = null;
        const timeout = setInterval(() => {
            second.current = second.current - 1;
            setSecondComponent(<div className="timer">{second.current}s</div>);
            if (second.current === 0) {
                clearInterval(timeout);
                time = setTimeout(() => {
                    handleClose();
                    dispatch(logoutAction(history, false));
                }, 500)
            }
        }, 1000)

        return () => {
            clearInterval(timeout)
            clearTimeout(time)
        }
    }, [])

    return (
        <AlertPopup
            type="WARNING"
            extendStyles={alertPopupExtendStyles}
            btn={secondComponent}
            isAutoClose={false}
        >
            <div>Tài khoản đã đăng nhập từ thiết bị khác hoặc quá thời</div>
            <div>gian hoạt động, bạn vui lòng đăng nhập lại nhé !</div>
        </AlertPopup>
    );
}

Error403.propTypes = {
    handleClose: PropTypes.func.isRequired,
    autoClosePopup: PropTypes.any
}

export default Error403;
