import { combineReducers } from "redux";

import listTransactions from './listTransactions'
import transactionDetails from './transactionDetails'

const transactions = combineReducers({
    listTransactions,
    transactionDetails
})

export default transactions;
