import React, { useState, useReducer, useRef, useEffect } from 'react'

//style
import { WrapFilterStatus } from './index.styles'
//component
import TitleDropdownFilter from '../../../../../Control/TitleDropdownFilter'

const DropDownExcutesStatus = () => {

    const [activeFilter, setActiveFilter] = useState(false)
    const [isShowDropdown, setIsShowDropdown] = useState(false)

    const _handleOpenBox = () => {

    }
    return (
        <WrapFilterStatus >
            <TitleDropdownFilter onClick={_handleOpenBox} active={activeFilter} disabled={true}>
            Trạng thái xử lý
            </TitleDropdownFilter>
            {/* {
                isShowDropdown &&
                
            } */}
        </WrapFilterStatus>
    )
}

export default DropDownExcutesStatus
