import styled, { css } from 'styled-components'
const Bound = styled.div`
    width: 510px;
    height: 235px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: SVN-Gilroy;
    font-style: normal;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #15314C;
    position: absolute;
    top: 3px;
    right: 70px;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.1);
    .icon-arrow{
        position: absolute;
        top:16px;
        right: -12px;
    }
    .title-popup{        
        font-weight: bold;
        font-size: 20px;
        padding: 30px 0 0 25px;        
    }
    .content-popup{        
        font-weight: normal;
        font-size: 18px;
        padding: 20px 59px 0 25px;
    }
    .button-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 39px 0 0 0;
    }

`
const styleButtonYes = css`
    width: 254px;
    height: 50px;
    background: #F4F4F4;
    border-radius: 0px 0px 0px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color:rgba(24, 77, 162);
    border: none;
    outline: none;

`

const styleButtonNo = css`
    width: 254px;
    height: 50px;
    background: #184DA2;
    border-radius: 0px 0px 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    outline: none;

`
export { Bound, styleButtonYes, styleButtonNo }