import React, { useState, useCallback, useReducer, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//styles
import * as style from './index.style'
import { styleButtonAddStore } from './index.style'
//constant
import * as keys from '../../../../../../../assets/constants/keys'
//component
import InputText from '../../../../../../Control/InputText'
import ButtonControl from '../../../../../../Control/Button'
import DropdownArea from '../../../../../../Control/DropdownArea'
import IcCheck from '../../../../../../../assets/images/components/IcCheck'

// action
import { addShopToChain, getListShopsByChain } from '../../../../../../../redux/chains/chainInfos/stores/action'
import { getListChains } from '../../../../../../../redux/chains/listChains/action'

const ContentPopupAddStore = ({ handleClose }) => {

    const dispatch = useDispatch()

    const { stores, chainDetails } = useSelector(state => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        stores: state.chains.chainInfos.stores,
    }))

    const [state, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            Code: "",
            Name: "",
            Address: "",
            AreaID: "",
            ChainID: "",
            Lat: "",
            Lng: "",
        }
    )

    const _handleClickAddStore = useCallback(
        () => {
            dispatch(
                addShopToChain(
                    { ...state },
                    () => {
                        dispatch(
                            getListShopsByChain({
                                ChainID: state.ChainID, 
                                PageNo: stores.firstFilter.PageNo, 
                                PageSize: stores.firstFilter.PageSize
                            })
                        )
                        dispatch(getListChains({
                            PageNo: 1,
                            PageSize: 50,
                        }))
                        handleClose()
                    }
                )
            )
        },
        [JSON.stringify(state), handleClose, stores.firstFilter],
    )

    useEffect(() => {
        if (!chainDetails.details) return;
        const { ID } = chainDetails.details
        if (!ID) return;

        setState({ ChainID: ID })
    }, [JSON.stringify(chainDetails.details)])

    const isDisableAddStore = useMemo(() => {
        const { Code, Name, Address, AreaID } = state
        const isObligateField = [Code, Name, Address, AreaID]
        return isObligateField.some(item => !item)
    }, [JSON.stringify(state)])

    return (
        <style.Bound>
            <div className="row-input">
                <div className="wrapper-input">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập mã cửa hàng ..."
                        titleInput="Mã cửa hàng *"
                        maxLength={200}
                        keyValue={keys.STORECODE}
                        onChange={(_, value) => setState({ Code: value.trim() })}
                    />
                </div>
                <div className="wrapper-input">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên cửa hàng ..."
                        titleInput="Nhập tên cửa hàng *"
                        maxLength={200}
                        keyValue={keys.STORENAME}
                        onChange={(_, value) => setState({ Name: value.trim() })}
                    />
                </div>
            </div>
            <div className="drop-down">
                <DropdownArea
                    onChangeValue={(selected) => setState({ AreaID: selected.ID || "" })}
                />
            </div>
            <div className="row-input">
                <div className="wrapper-input">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập Latitude ..."
                        titleInput="Latitude"
                        keyValue={keys.LATITUDE}
                        onChange={(_, value) => setState({ Lat: value.trim() })}
                    />
                </div>
                <div className="wrapper-input">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập Longitude ..."
                        titleInput="Longitude"
                        keyValue={keys.LONGITUDE}
                        onChange={(_, value) => setState({ Lng: value.trim() })}
                    />
                </div>
            </div>
            <div className="wrapper-input">
                <InputText
                    isTitle={true}
                    disabled={false}
                    placeholder="Nhập địa chỉ ..."
                    titleInput="Địa chỉ *"
                    keyValue={keys.ADDRESS}
                    onChange={(_, value) => setState({ Address: value.trim() })}
                />
            </div>
            <div className="button-container">
                <ButtonControl
                    icon={<IcCheck />}
                    isDisable={isDisableAddStore}
                    handleClick={_handleClickAddStore}
                    extendStyles={styleButtonAddStore}
                    children="Thêm cửa hàng"
                />
            </div>
        </style.Bound>
    )
}

export default ContentPopupAddStore
