import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment';

// styles
import { RowFilter } from '../index.styles'

// component
import DropDownFilterTranCodes from './DropDownFilterTranCodes'
import DropDownFilterTimes from './DropDownFilterTimes'
import DropDownFilterUsers from './DropDownFilterUsers'
import DropDownFilterStores from './DropDownFilterStores'
import DropDownFilterMoneyPayments from './DropDownFilterMoneyPayments'

// constant
import { lstTypesKeyFilterNum } from '../../../../Control/BoxFilterByNum/DropdownSelectTypeFilterNum/BoxListDropdownItem';

// action
import { getListTransactions } from '../../../../../redux/transactions/listTransactions/action';

const mapSortTypes = new Map([[-1, "DECREASE"], [0, ""], [1, "INCREASE"]])
const mapSortTypesRevertKeyValue = (() => {
    const tmp = new Map();
    mapSortTypes.forEach((value, key) => {
        tmp.set(value, key)
    })
    return tmp
})()

const mapTypeFilterNumWithKeyCompare = {
    [lstTypesKeyFilterNum.LESS_THAN]: 'LESS',
    [lstTypesKeyFilterNum.LESS_THAN_OR_EQUAL]: 'LESS_OR_EQUAL',
    [lstTypesKeyFilterNum.GREATER_THAN]: 'GREATER',
    [lstTypesKeyFilterNum.GREATER_THAN_OR_EQUAL]: 'GREATER_OR_EQUAL',
    [lstTypesKeyFilterNum.EQUAL]: 'EQUAL',
    [lstTypesKeyFilterNum.NOT_EQUAL]: 'NOT_EQUAL',
}
const mapKeyCompareWithTypeFilterNum = Object.keys(mapTypeFilterNumWithKeyCompare)
    .reduce((targets, item) => ({
        ...targets,
        [mapTypeFilterNumWithKeyCompare[item]]: item
    }), {})

const Filters = () => {
    const dispatch = useDispatch()

    const { condFilters } = useSelector((state) => ({
        condFilters: state.transactions.listTransactions.condFilters
    }))

    const valuesFilterByTransCode = useMemo(() => ({
        sort: mapSortTypes.get(condFilters?.FacepayNumber?.Sort) || "",
        searchKey: condFilters?.FacepayNumber?.No || ""
    }), [JSON.stringify(condFilters.FacepayNumber)])

    const valuesFilterByTimes = useMemo(() => ({
        sort: mapSortTypes.get(condFilters?.Time?.Sort) || "",
        startDateTime: condFilters?.Time?.FromDate ? moment(condFilters?.Time?.FromDate, "YYYY/MM/DD").toDate() : undefined,
        endDateTime: condFilters?.Time?.ToDate ? moment(condFilters?.Time?.ToDate, "YYYY/MM/DD").toDate() : undefined
    }), [JSON.stringify(condFilters.Time)])

    const valuesFilterByUsers = useMemo(() => ({
        name: condFilters?.User?.Name || "",
        username: condFilters?.User?.Username || "",
        phoneNum: condFilters?.User?.PhoneNumber || ""
    }), [JSON.stringify(condFilters.User)])

    const valuesFilterByStores = useMemo(() => ({
        sort: mapSortTypes.get(condFilters?.Shop?.Sort) || "",
        searchKey: condFilters?.Shop?.ShopName || ""
    }), [JSON.stringify(condFilters.Shop)])

    const valuesFilterByMoneyPayments = useMemo(() => {
        
        const { Amount } = condFilters
        if(!Amount) return {}

        const condConfigs = {
            sort: mapSortTypes.get(condFilters?.Amount?.Sort) || "",
            firstCompares: {
                // type: lstTypesKeyFilterNum.GREATER_THAN,
                type: '',
                numVal: ''
            },
            secondCompares: {
                // type: lstTypesKeyFilterNum.LESS_THAN,
                type: '',
                numVal: ''
            }
        }

        const { firstCompares } = Amount
        if(firstCompares && typeof(firstCompares) === "object") {
            const keyCompare = firstCompares.Operator
            const type = mapKeyCompareWithTypeFilterNum[keyCompare]
            const numVal = firstCompares.Value
            condConfigs.firstCompares = {
                type,
                numVal
            }
        }

        const { secondCompares } = Amount
        if(secondCompares && typeof(secondCompares) === "object") {
            const keyCompare = secondCompares.Operator
            const type = mapKeyCompareWithTypeFilterNum[keyCompare]
            const numVal = secondCompares.Value
            condConfigs.secondCompares = {
                type,
                numVal
            }
        }
        // console.log(condConfigs)
        return condConfigs
    }, [JSON.stringify(condFilters.Amount)])

    const _handleChangeValuesFilterByTransCode = useCallback(
        (values) => {
            const { sort, searchKey } = values
            // console.log(values)
            if(sort) {
                const dataFilter = {
                    ...condFilters,
                    PageNumber: 1,
                    PageSize: 8,
                    FacepayNumber: {
                        Sort: mapSortTypesRevertKeyValue.get(sort),
                        No: searchKey?.trim()
                    },
                    Shop: {
                        ...condFilters.Shop,
                        Sort: 0
                    },
                    Amount: {
                        ...condFilters.Amount,
                        Sort: 0
                    },
                    Time: {
                        ...condFilters.Time,
                        Sort: 0
                    }
                }
                dispatch(getListTransactions(dataFilter))
                return;
            }

            const dataFilter = {
                ...condFilters,
                PageNumber: 1,
                PageSize: 8,
                FacepayNumber: {
                    Sort: mapSortTypesRevertKeyValue.get(sort),
                    No: searchKey?.trim()
                }
            }
            dispatch(getListTransactions(dataFilter))
        },
        [condFilters],
    )

    const _handleChangeValuesFilterByTimes = useCallback(
        (values) => {
            const { sort, startDateTime, endDateTime } = values
            // console.log(values)
            // console.log(moment(startDateTime).format('DD/MM/YYYY'))
            // console.log(moment(endDateTime).format('DD/MM/YYYY'))
            if(sort) {
                const dataFilter = {
                    ...condFilters,
                    PageNumber: 1,
                    PageSize: 8,
                    FacepayNumber: {
                        ...condFilters.FacepayNumber,
                        Sort: 0
                    },
                    Shop: {
                        ...condFilters.Shop,
                        Sort: 0
                    },
                    Amount: {
                        ...condFilters.Amount,
                        Sort: 0
                    },
                    Time: {
                        Sort: mapSortTypesRevertKeyValue.get(sort),
                        FromDate: moment(startDateTime).format("YYYY/MM/DD"),
                        ToDate: moment(endDateTime).format("YYYY/MM/DD")
                    }
                }
                dispatch(getListTransactions(dataFilter))
                return;
            }
            const dataFilter = {
                ...condFilters,
                PageNumber: 1,
                PageSize: 8,
                Time: {
                    Sort: mapSortTypesRevertKeyValue.get(sort),
                    FromDate: moment(startDateTime).format("YYYY/MM/DD"),
                    ToDate: moment(endDateTime).format("YYYY/MM/DD")
                }
            }

            dispatch(getListTransactions(dataFilter))
        },
        [condFilters],
    )
    
    const _handleChangeValuesFilterByUsers = useCallback(
        (values) => {
            const { name, phoneNum, username } = values
            // console.log(values)
            const dataFilter = {
                ...condFilters,
                PageNumber: 1,
                PageSize: 8,
                User: {
                    Name: name?.trim(),
                    Username: username?.trim(),
                    PhoneNumber: phoneNum?.trim()
                }
            }
            dispatch(getListTransactions(dataFilter))
        },
        [condFilters],
    )

    const _handleChangeValuesFilterByStores = useCallback(
        (values) => {
            const { sort, searchKey } = values
            // console.log(values)
            if(sort) {
                const dataFilter = {
                    ...condFilters,
                    PageNumber: 1,
                    PageSize: 8,
                    FacepayNumber: {
                        ...condFilters.FacepayNumber,
                        Sort: 0
                    },
                    Shop: {
                        Sort: mapSortTypesRevertKeyValue.get(sort),
                        ShopName: searchKey?.trim()
                    },
                    Amount: {
                        ...condFilters.Amount,
                        Sort: 0
                    },
                    Time: {
                        ...condFilters.Time,
                        Sort: 0
                    }
                }
                dispatch(getListTransactions(dataFilter))
                return;
            }

            const dataFilter = {
                ...condFilters,
                PageNumber: 1,
                PageSize: 8,
                Shop: {
                    Sort: mapSortTypesRevertKeyValue.get(sort),
                    ShopName: searchKey?.trim()
                }
            }
            dispatch(getListTransactions(dataFilter))
        },
        [condFilters],
    )

    const _handleChangeValuesFilterByMoneyPayments = useCallback(
        (values) => {
            const { sort, firstCompares, secondCompares } = values
            // console.log(values)

            if(sort) {
                const dataFilter = {
                    ...condFilters,
                    PageNumber: 1,
                    PageSize: 8,
                    FacepayNumber: {
                        ...condFilters.FacepayNumber,
                        Sort: 0
                    },
                    Shop: {
                        ...condFilters.Shop,
                        Sort: 0
                    },
                    Amount: {
                        Sort: mapSortTypesRevertKeyValue.get(sort),
                        firstCompares: typeof(firstCompares.numVal) === "number" ? {
                            Operator: mapTypeFilterNumWithKeyCompare[firstCompares.type],
                            Value: firstCompares.numVal
                        } : undefined,
                        secondCompares: typeof(secondCompares.numVal) === "number" ? {
                            Operator: mapTypeFilterNumWithKeyCompare[secondCompares.type],
                            Value: secondCompares.numVal
                        } : undefined
                    },
                    Time: {
                        ...condFilters.Time,
                        Sort: 0
                    }
                }
                dispatch(getListTransactions(dataFilter))
                return;
            }

            const dataFilter = {
                ...condFilters,
                PageNumber: 1,
                PageSize: 8,
                Amount: {
                    Sort: mapSortTypesRevertKeyValue.get(sort),
                    firstCompares: typeof(firstCompares.numVal) === "number" ? {
                        Operator: mapTypeFilterNumWithKeyCompare[firstCompares.type],
                        Value: firstCompares.numVal
                    }: undefined,
                    secondCompares: typeof(secondCompares.numVal) === "number" ? {
                        Operator: mapTypeFilterNumWithKeyCompare[secondCompares.type],
                        Value: secondCompares.numVal
                    }: undefined
                },
            }
            dispatch(getListTransactions(dataFilter))
        },
        [condFilters],
    )

    return (
        <RowFilter className="filter-controls" enableClick={false}>
            <div className="filter-transaction">
                <DropDownFilterTranCodes 
                    values={valuesFilterByTransCode}
                    onChangeValues={_handleChangeValuesFilterByTransCode}
                />
            </div>
            <div className="filter-time">
                <DropDownFilterTimes 
                    values={valuesFilterByTimes}
                    onChangeValues={_handleChangeValuesFilterByTimes}
                />
            </div>
            <div className="filter-user">
                <DropDownFilterUsers 
                    values={valuesFilterByUsers}
                    onChangeValues={_handleChangeValuesFilterByUsers}
                />
            </div>
            <div className="filter-store">
                <DropDownFilterStores 
                    values={valuesFilterByStores}
                    onChangeValues={_handleChangeValuesFilterByStores}
                />
            </div>
            <div className="filter-money-payment">
                <DropDownFilterMoneyPayments 
                    values={valuesFilterByMoneyPayments} 
                    onChangeValues={_handleChangeValuesFilterByMoneyPayments}
                />
            </div>
        </RowFilter>
    )
}

export default Filters
