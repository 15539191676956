import React, { useState, useEffect } from "react";

import * as styled from "./index.style";
import IcSearch from "../../../assets/images/components/Ic_Search";

const InputSearch = ({
    placeHolderSearch,
    marginTop,
    widthSearch,
    heightSearch,
    value,
    onChangeValue,
    extendStyles
}) => {
    return (
        <styled.InputSearch 
            marginTop={marginTop} 
            widthSearch={widthSearch} 
            heightSearch={heightSearch}
            extendStyles={extendStyles}
        >
            <IcSearch className="ic_search" />
            <input
                placeholder={placeHolderSearch}
                value={value}
                onChange={(e) => onChangeValue(e.target.value)}
            />
        </styled.InputSearch>
    );
};
export default InputSearch;
