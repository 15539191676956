import React, { Fragment } from "react";
import { css } from "styled-components";

//tool
import { convertDateTime } from "../../../../assets/helper/tools";
//image
import IcTimeGrey from "../../../../assets/images/components/Ic_TimeGrey";
import IcTimeRed from "../../../../assets/images/components/IcTimeRed";
import IcCheckBox from "../../../../assets/images/components/IcCheckBox";
import IcCheckGreen from "../../../../assets/images/components/Ic_CheckGreen";
//component
import CountupControl from '../../../Control/CountupControl'
//constant
import * as stylesConstants from '../../../../assets/constants/ui'
import * as censorship_status from '../../../../assets/constants/faceCensorShipStatus'
import { apiAvatarImage, apiAvatarImageMini } from "../../../../assets/constants/host";
import * as keys from '../../../../assets/constants/keys'
//action
import { getUserInfos } from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'
import { getListInfoUsers } from '../../../../redux/listInfoUsers/action'
import { dataBank } from "../../../../data/dataBank";

export const ISALLOWCLICK = true;
export const ISCONNECT = false;
export const KEY = keys.USERS
export const HEIGHT_ROW_ITEM_CONTAINER = '82px'

export const TABLE_CONTENT_STYLED = css`
  padding: 0 24px 0 58px;
  width: calc(100% - (24px + 58px));
`;

export const TABLE_ROW_ITEM_STYLED = css`
  grid-template-columns: 74px 228px 190px 1fr;
  cursor: pointer;
  height: 82px;
  
  .user-item-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-user {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      object-fit: cover;
      &.default{
        background-color: #959595;
      }
    }
  }
  .user-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    .p-bold {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 180px;
    }
  }
  .bank-linked {
    display: flex;
    .not-bank{
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      color: #959595;
      display: flex;
      align-items: center;
    }

  }
  .status-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    .avatar-status {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      box-sizing: border-box;
      width: 120px;
      height: 32px;
      border-radius: 35px;
      color: #ffffff;
      :first-child{
        margin-right: 10px;
      }
      &.accept {
        background: #00B887;
      }
      &.reject {
        background: #ffffff;
        border: 1px solid #98ACC5;
        color: #98ACC5;
      }
      span {        
        font-family: 'UTM Avo';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        letter-spacing: 0.05px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
    .ekyc-status{
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      box-sizing: border-box;
      border-radius: 35px;
      color: #ffffff;
      width: 82px;
      height: 32px;
      &.accept {
        background: #00B887;
      }
      &.reject {
        background: #ffffff;
        border: 1px solid #98ACC5;
        color: #98ACC5;
      }
      span {
        font-family: 'UTM Avo';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        letter-spacing: 0.05px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
    }
  }
`;

export const caculateTableHeightContent = () => {
  let vh = document.body.offsetHeight
  if (!vh) return
  return vh -
    (stylesConstants.HEADER_HEIGHT + stylesConstants.MAIN_CONTENT_PADDING_TOP + stylesConstants.TABLE_HEADER_HEIGHT + 10)
}

export const _actionOnClickItem = (item) => getUserInfos({ UserID: item.ID })

export const _onLoadMore = ({size, keyword, typeView}) => getListInfoUsers({PageNumber: 1, PageSize: size})

export const renderItem = ({item, funcTranslate, funcImageError, index}) => {
  if (!item) return;

  const _returnBankImage = (bankCode) => {
    const bank = dataBank.find((item) => item.BankCode === bankCode);
    if (bank) return bank.BankImage;
    else return "";
  };

  return (
    <React.Fragment>
      <div className="user-item-avatar">
        <img
          className="avatar-user"
          src={item && item.ID ? apiAvatarImageMini(item.ID) : ""}
          alt="avatar-user"
          id={`${KEY}-${index}`}
          onError={() => funcImageError(`${KEY}-${index}`)}
        />
      </div>
      <div className="user-item-info">
        <p className="p-bold">{item && item.FullName ? item.FullName : "-"}</p>
        <p className="p-thin">{item && item.PhoneNumber ? item.PhoneNumber : "-"}</p>
      </div>
      <div className="bank-linked">
        {
          item && item.BankCode ?
          <img
            className="logo-bank"
            src={_returnBankImage(
              item && item.BankCode ? item.BankCode : ""
            )}
            alt="logo-bank"
          />
          : <div className="not-bank">Chưa liên kết ngân hàng</div>
        }
      </div>
      <div className="status-container">
        <div className={item.AvatarStatus === censorship_status.ACCEPT ? "avatar-status accept" : "avatar-status reject"}>
          {item && item.AvatarStatus && item.AvatarStatus === censorship_status.ACCEPT ? (
            <Fragment>
              <IcCheckGreen />
              <span>{funcTranslate('table.status.avatar_status')}</span>
            </Fragment>
          ) : (
              <Fragment>
                <IcTimeGrey />
                <span>{funcTranslate('table.status.avatar_status')}</span>
              </Fragment>
            )}
        </div>
        <div className={item.EKYCStatus === censorship_status.ACCEPT ? "ekyc-status accept" : "ekyc-status reject"}>
          {item && item.EKYCStatus && item.EKYCStatus === censorship_status.ACCEPT ? (
            <Fragment>
              <IcCheckGreen />
              <span>{funcTranslate('table.status.ekyc_status')}</span>
            </Fragment>
          ) : (
              <Fragment>
                <IcTimeGrey />
                <span>{funcTranslate('table.status.ekyc_status')}</span>
              </Fragment>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};


