import Axios from "axios";

// helper
import mapErrors from "../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../assets/constants/codeResponse";

// api
import { apiGetTransactionDetails } from "./api";

export const getTransactionDetails = ({ PaymentID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        // LogID: '5fab9acb879c0814ccc1080b'
        PaymentID
    })

    try {
        const res = await Axios.post(apiGetTransactionDetails, dataRequest)

        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;

        if(isSuccess){
            dispatch({
                type: types.GET_TRANSACTION_DETAILS,
                payload: {
                    ...data
                }
            })
        }
        else {
            dispatch({
                type: types.GET_TRANSACTION_DETAILS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.GET_TRANSACTION_DETAILS_ERR))
    }
}

export const clearDetailsTransaction = () => ({
    type: types.CLEAR_TRANSACTION_DETAILS
})