import React from "react";
import { css } from "styled-components";
//component
import InputText from "../../../../../../Control/InputText";
import DropDownSingle from "../../../../../../Control/DropDownSingle";
//constant
import * as keys from '../../../../../../../assets/constants/keys'

export const INFORMATION_STYLED = css`
    display:grid;
    grid-template-rows: repeat(3, auto);
    grid-gap: 20px;
    .row-1, .row-2, .row-3{
        display:grid;
        grid-gap: 30px;
        width: 100%; 
        height: 73px;
        div{
            /* background-color: #EDEDED; */
        }
    }
    .row-1{
        grid-template-columns: 1fr 270px 140px;
    }
    .row-2{
        grid-template-columns: 1fr;
    }
    .row-3{
        grid-template-columns: 1fr 270px 240px;
    }
`;

const dataCategory = {
    0: {
        key: 0,
        value: "Chưa xác định"
    },
    1: {
        key: 1,
        value: "Nam"
    },
    2: {
        key: 2,
        value: "Nữ"
    },
    3: {
        key: 3,
        value: "Khác"
    }
}

export const renderItem = (item, isDisabled, onChangeInformation, stateIsInvalid) => {
    if (!item?.PassportInfo)  return;

    const _handleOnChange = (key, value) =>{
        onChangeInformation(key, value, keys.PASSPORT)
    }

    return (
        <React.Fragment>
            <div className='row-1'>
                <InputText
                    isTitle={true}
                    titleInput="Họ và tên"
                    defaultValue={item?.PassportInfo?.FullName}
                    disabled={isDisabled}
                    keyValue={keys.FULLNAME}
                    onChange={_handleOnChange}
                />
                <InputText
                    isTitle={true}
                    titleInput="Ngày sinh"
                    defaultValue={item?.PassportInfo?.Birthday}
                    disabled={isDisabled}
                    keyValue={keys.BIRTHDAY}
                    onChange={_handleOnChange}
                    isInvalid={stateIsInvalid.Birthday}
                />
                <DropDownSingle
                    // placeholder={dataCategory[0].value}
                    defaultValue={dataCategory[item?.PassportInfo?.Gender] || dataCategory[0]}
                    titleDropDown="Giới tính"
                    label="Giới tính"
                    data={dataCategory}
                    // genderUser={item?.PassportInfo?.Gender}
                    disabled={isDisabled}
                    isGender={true}
                    keyValue={keys.GENDER}
                    onChange={_handleOnChange}
                />
            </div>
            <div className='row-2'>
                <InputText
                    isTitle={true}
                    titleInput="Nơi sinh"
                    defaultValue={item?.PassportInfo?.PlaceOfBirth}
                    disabled={isDisabled}
                    keyValue={keys.PLACEOFBIRTH}
                    onChange={_handleOnChange}
                />
            </div>
            <div className='row-3'>
                <InputText
                    isTitle={true}
                    titleInput="Quốc tịch"
                    defaultValue={item?.PassportInfo?.Nationality}
                    disabled={isDisabled}
                    keyValue={keys.NATIONALITY}
                    onChange={_handleOnChange}
                />
                <InputText
                    isTitle={true}
                    titleInput="Ngày cấp"
                    defaultValue={item?.PassportInfo?.Valid}
                    disabled={isDisabled}
                    keyValue={keys.VALID_DATE}
                    onChange={_handleOnChange}
                    isInvalid={stateIsInvalid.IssueDate}
                />
                <InputText
                    isTitle={true}
                    titleInput="Ngày hết hạn"
                    defaultValue={item?.PassportInfo?.Expire}
                    disabled={isDisabled}
                    keyValue={keys.EXPIRE_DATE}
                    onChange={_handleOnChange}
                    isInvalid={stateIsInvalid.Expire}
                />
            </div>
        </React.Fragment>
    );
};
