import styled from 'styled-components'

const TIME_TRANSITION = 0.3

const WrapperCollapse = styled.div`
    width: 100%;
`;
const WrapperPanel = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eaeff4cc;
    border-radius: 6px;
    padding: 0 20px;
    box-sizing: border-box;

    .ic-expand {
        :hover {
            cursor: pointer;
        }

        transform: ${props => props.isExpand ? 'rotateZ(180deg)' : 'unset' };
        transition: transform ${TIME_TRANSITION}s ease-in-out;
    }
`;
const WrapperContent = styled.div`
    margin-top: ${props => props.marginTop ? `${props.marginTop}px`: 0};;
    /* max-height: ${props => props.isExpand && props.maxHeight ? `${props.maxHeight}px`: 0}; */
    max-height: ${props => props.isExpand ? `5000px`: 0};
    overflow: hidden;
    transition: max-height ${TIME_TRANSITION}s ease-in-out;
`;

export {
    WrapperCollapse,
    WrapperPanel,
    WrapperContent
}