import styled from 'styled-components'

const WrapVerifyFaceItem = styled.div`
    &.again-step {
        display: flex;
        flex-direction: column;            
        & > div {
            margin-left: 30px;
        }            
    }   
    .scan-face{
        width: 170px;
        height: 170px;
        box-sizing: border-box;
        border-radius: 3px;
        margin-top: 10px;
        &.success{
            border: 2px solid #09AC10;
        }
        &.failed{
            border: 2px solid #F45C5C;
        }
        .avatar-scan{
            width: 166px;
            height: 166px;
            object-fit: contain;
        }
    }  
`;

export {
    WrapVerifyFaceItem
}