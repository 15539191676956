import styled, { css } from "styled-components";

export const Bound = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    margin: 0 29px 0 58px;
    .wrap-menu {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .trans-list-menu {
            display: flex;
            align-items: center;
            & > div {
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 170%;
                letter-spacing: 0.05px;
                color: #184DA2;
                margin-left: 10px;
            }
        }
        .selected-line {
            width: 220px;
            height: 4px;
            background: #184DA2;
            position: absolute;
            bottom: 0;
            margin: 0;
            border: none;
        }
    }

`
