import * as types from './types'

const initialState = {

}

const notifies = (state = initialState, action) => {
    if(/.*_ERR/.test(action.type) || action.type === types.PUSH_NOTIFY){
        return {
            ...state,
            [action.type]: action
        }
    }
    if(action.type === types.POP_NOTIFY) {
        const copyState = {...state};
        // const firstItem = Object.values(copyState)[0]
        // if(!firstItem) return state;
        // delete copyState[firstItem.type]
        delete copyState[action.payload.typeItem]
        return copyState;
    }
    return state;
}

export default notifies