import React, { useState, useCallback, useReducer, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format';

// styles
import * as style from './index.style'
import { styleButtonAddChains } from './index.style'
import { Input } from '../../../../InputText/index.style';


//constant
import * as keys from '../../../../../../assets/constants/keys'
import { dataCategory } from '../../../../../../assets/data/dataChain'
import errorImgTypes from '../../../../../../assets/constants/errorImgTypes';

// helper
import fileToBase64 from '../../../../../../assets/helper/fileToBase64'
import isEmail from '../../../../../../assets/helper/isEmail';

//component
import InputText from '../../../../InputText'
import DropDownSingle from '../../../../DropDownSingle'
import ButtonControl from '../../../../Button'
import DropOrChooseLogo from '../../../../DropOrChooseLogo'
import IcCheck from '../../../../../../assets/images/components/IcCheck'

// action
import { addChain, getListChains } from '../../../../../../redux/chains/listChains/action'
import { pushNotify } from '../../../../../../redux/notifies/action';

const ContentPopupAddChains = ({ handleClose }) => {

    const dispatch = useDispatch()

    const { listChains } = useSelector((state) => ({
        listChains: state.chains.listChains
    }))

    const [imageShow, setImageShow] = useState("")
    // const [isValidEmail, setIsValidEmail] = useState(true)


    const [state, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            Name: "",
            Address: "",
            PhoneNumber: "",
            Category: "",
            Email: "",
            RepPosition: "",
            Logo: "",
            TaxNumber: "",
            RepName: ""
        }
    )

    // const { keyword } = useMemo(() => listChains.filter, [JSON.stringify(listChains)])

    const _handleClickAddChains = useCallback(() => {
        // if(!isValidEmail) return;
        dispatch(addChain(state, () => {
            dispatch(getListChains({ 
                PageNo: 1, 
                PageSize: 50, 
                // Filter: keyword 
            }))
            handleClose()
        }))
    }, [
        JSON.stringify(state),
        // isValidEmail,
        // keyword
    ])

    const _handleDropFiles = useCallback(
        async (files) => {
            // setError('')
            if (imageShow) URL.revokeObjectURL(imageShow);
            try {
                const url = URL.createObjectURL(files[0]);
                setImageShow(url);
                const imgBase64 = await fileToBase64(files[0])
                setState({ Logo: imgBase64.split(',')[1] })
            } catch (err) {
                setImageShow("");
                setState({ Logo: "" })
            }
        },
        [imageShow],
    )

    const _handleErrorGetImg = useCallback(
        (err) => {
            // setError(err)
            const strError = errorImgTypes[err]
            dispatch(pushNotify(strError))
            setImageShow("");
            setState({ Logo: "" })
        },
        [],
    )

    // useEffect(() => {
    //     const isValidEmail = !state.Email  || (state.Email && isEmail(state.Email))
    //     setIsValidEmail(isValidEmail)
    // }, [state.Email])

    // useEffect(() => {
    //     if(!error) return;
    //     const strError = errorImgTypes[error]
    //     if(!strError) return;
    //     dispatch(pushNotify(strError))
    // }, [error]);

    return (
        <style.Bound>
            <div className="content-top">
                <div className="import-logo">
                    <DropOrChooseLogo
                        file={imageShow}
                        handleDropFiles={_handleDropFiles}
                        handleReturnError={_handleErrorGetImg}
                    />
                </div>
                <div className="info-left">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên chuỗi ..."
                        titleInput="Nhập tên chuỗi *"
                        keyValue={keys.CHAINSNAME}
                        onChange={(_, value) => setState({ Name: value.trim() })}
                        maxLength="200"
                    />
                    <DropDownSingle
                        placeholder="Chọn loại chuỗi ..."
                        disabled={false}
                        titleDropDown="Loại chuỗi *"
                        label="Loại chuỗi"
                        widthListData={"300px"}
                        data={dataCategory}
                        keyValue={keys.CHAINSCATEGORY}
                        onChange={(_, value) => setState({ Category: value })}
                    />
                    <div className="input-field">
                        <div className="title-input">Mã số thuế (hoặc CMND của người đại diện) *</div>
                        <NumberFormat
                            // customInput= {Input}
                            className='input-number'
                            displayType={'input'}
                            placeholder="Nhập mã số thuế  ..."
                            onValueChange={objVal => setState({ TaxNumber: objVal.value })}
                            allowNegative={false}
                            allowLeadingZeros={true}
                            // decimalSeparator=""
                            isAllowed={values => {
                                const isValid = !values.value.includes('.')
                                return isValid
                            }}
                        />
                    </div>
                </div>
                <div className="info-right">
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên ..."
                        titleInput="Tên đại diện *"
                        keyValue={keys.REPNAME}
                        onChange={(_, value) => setState({ RepName: value.trim() })}
                    />
                    <InputText
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập chức vụ ..."
                        titleInput="Chức vụ người đại diện *"
                        keyValue={keys.REPPOSITION}
                        onChange={(_, value) => setState({ RepPosition: value.trim() })}
                    />
                    <InputText
                        // isInvalid={!isValidEmail}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập email ..."
                        titleInput="Email đại diện *"
                        keyValue={keys.EMAIL}
                        onChange={(_, value) => setState({ Email: value.trim() })}
                    />
                </div>
            </div>
            <div className="content-bottom">
                <InputText
                    isTitle={true}
                    disabled={false}
                    placeholder="Nhập địa chỉ ..."
                    titleInput="Trụ sở *"
                    keyValue={keys.OFFICEADDRESS}
                    onChange={(_, value) => setState({ Address: value.trim() })}
                />
                <div className="input-field">
                    <div className="title-input">Số điện thoại đại diện *</div>
                    <NumberFormat
                        className='input-number'
                        displayType={'input'}
                        type="tel"
                        placeholder="Nhập số điện thoại ..."
                        onValueChange={objVal => setState({ PhoneNumber: objVal.value })}
                        allowNegative={false}
                        allowLeadingZeros={true}
                        // decimalSeparator=""
                        isAllowed={values => {
                            const isValid = !values.value.includes('.')
                            return isValid
                        }}
                    />
                </div>
            </div>
            <div className="button-container">
                <ButtonControl
                    icon={<IcCheck />}
                    isDisable={Object.values(state).some(item => !item)}
                    handleClick={_handleClickAddChains}
                    extendStyles={styleButtonAddChains}
                    children="Thêm chuỗi"
                />
            </div>
        </style.Bound>
    )
}

export default ContentPopupAddChains
