import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// style
import { Wrapper } from './index.style'

// component
import PictureCensorshipItem from './PictureCensorshipItem'

//action
import { getAvatarHistoryByUser } from '../../../../../redux/userCensorship/detailsUser/faces/action'

// mock-data
import historyFaceCensorship from '../../../../../data/historyFaceCensorship'

const PictureCensorship = () => {

    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const { detailsAccount, faceCensorship  } = useSelector(state => ({
        detailsAccount: state.userCensorship.detailsUser.detailsAccount,
        faceCensorship: state.userCensorship.detailsUser.faces
    }))

    useEffect(() => {
        if(!detailsAccount || !detailsAccount.ID) return
        setData([])
        dispatch(getAvatarHistoryByUser({UserID: detailsAccount.ID}))
    }, [detailsAccount])

    useEffect(() => {
        if(!faceCensorship) return
        if(faceCensorship?.listHistory) setData(faceCensorship.listHistory)
    }, [faceCensorship])

    return (
        <Wrapper>
            {
                data.map((item, key) =>
                    <PictureCensorshipItem 
                        key={key}
                        defaultExpands={key===0}
                        data={item}
                    />
                )
            }
        </Wrapper>
    )
}

export default PictureCensorship
