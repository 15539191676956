import * as types from "./types";

export const initialState = {
    list: [],
    total: 0,
    condFilters: {},
    condRequestFilters: {}
};

const transactionHistories = (state = initialState, action={}) => {
    switch (action.type) {
        case types.GET_LIST_TRANS_HISTORY_BY_USER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case types.CLEAR_LIST_TRANS_HISTORY_BY_USER: {
            return initialState
        }
        default:
            return state;
    }
};

export default transactionHistories;
