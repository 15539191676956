import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 272px 1fr;
    width: 100%;
    height: 80px;
    .logo-facepay{
        background: #184DA2;
    }
    .admin{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background: rgba(234, 239, 244, 0.8);
        padding: 0 62px 0 0;
        .admin-info{
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .name{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.2px;
                color: #1C1C1C;
                text-align: right;
            }
            .role{
                font-family: 'SVN-Gilroy';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 170%;
                color: #959595;
            }
        }
        .admin-avatar{
            width: 44px;
            height: 44px;
            border-radius: 50%;
            object-fit: contain;
            border: 1.5px solid #ffffff;
            margin: 0 0 0 15px;
        }
    }


`
export {Bound}