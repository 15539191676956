import styled from 'styled-components'

const RowContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : "10px"};
    div{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        margin: 0       
    }
    .title{
        width: 64px;
        height: 24px;
        color:rgba(28, 28, 28, 0.5)
    }
    .content{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 600px;
        margin-left: 10px;
        color: #1C1C1C;
    }
    
`
export { RowContent }