const comparePageData = {
    userCompared: {
        "id": 1,
        "type": "cmnd",
        "img_avatar": "https://robohash.org/adoloresmagnam.jpg?size=174x174&set=set1",
        "timeCreate": "2019-12-09T04:17:17Z",
        "lastActive": "2019-06-13T04:53:41Z",
        "weeID": "337941681909163",
        "birthOfDate": "1986-02-14T01:50:40Z",
        "gender": 2,
        "phoneNumber": "7936777275",
        "status": 2,
        "frontImg": "http://dummyimage.com/540x345.jpg/ff4444/ffffff",
        "backImg": "http://dummyimage.com/540x345.jpg/ff4444/ffffff",
        "cardNumber": "14578666624",
        "imageCardFace": "https://robohash.org/expeditaenimassumenda.jpg?size=200x200&set=set1",
        "imageVerify": "https://robohash.org/corporismolestiaedelectus.jpg?size=200x200&set=set1",
        "fullName": "Kirbee Healy",
        "birthday": "18/12/2019",
        "nationality": "United States",
        "gender": 3,
        "dateOfIssue": "18/08/2019",
        "domicile": "Pittsburgh",
        "validDate": "07/03/2020",
        "address": "94 Gale Center",
        "Status": "",
        "createTime": "2020-04-21T00:39:04Z",
        "verifiedAt": "2020-05-17T18:13:58Z"
    },
    usersMatched: {
        "id": 2,
        "type": "cccd",
        "img_avatar": "https://robohash.org/adoloresmagnam.jpg?size=174x174&set=set1",
        "timeCreate": "2019-12-09T04:17:17Z",
        "lastActive": "2019-06-13T04:53:41Z",
        "weeID": "337941681909163",
        "birthOfDate": "1986-02-14T01:50:40Z",
        "gender": 2,
        "phoneNumber": "7936777275",
        "status": 2,
        "frontImg": "http://dummyimage.com/540x345.jpg/cc0000/ffffff",
        "backImg": "http://dummyimage.com/540x345.jpg/ff4444/ffffff",
        "cardNumber": "14578666640",
        "imageCardFace": "https://robohash.org/remeumet.jpg?size=200x200&set=set1",
        "imageVerify": "https://robohash.org/odioquaeratmolestiae.jpg?size=200x200&set=set1",
        "fullName": "Vannie Boolsen",
        "birthday": "29/03/2020",
        "nationality": "China",
        "gender": 1,
        "dateOfIssue": "29/08/2019",
        "domicile": "Xuri",
        "validDate": "20/01/2020",
        "address": "1 Grayhawk Trail",
        "Status": "Đã kiểm duyệt thông tin",
        "createTime": "2020-01-04T08:30:53Z",
        "verifiedAt": "2019-10-24T04:15:17Z"
    }
}

export default comparePageData