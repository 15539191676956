import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    /* overflow: hidden; */
    /* background-color: rgba(147, 191, 242, 0.1); */
    border-right: 1px solid #C6D5EB;
    position: relative;
    z-index: 1;
    /* overflow: hidden; */
`

export { Bound }