import React from "react";

const IcUser = ({ colorIcon }) => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9446 15.5V13.9444C13.9446 13.1193 13.6168 12.3279 13.0333 11.7445C12.4499 11.161 11.6586 10.8333 10.8334 10.8333H4.61114C3.78602 10.8333 2.99469 11.161 2.41123 11.7445C1.82778 12.3279 1.5 13.1193 1.5 13.9444V15.5"
      stroke={colorIcon ? colorIcon : "#184DA2"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.72229 7.72229C9.44053 7.72229 10.8334 6.32938 10.8334 4.61114C10.8334 2.89291 9.44053 1.5 7.72229 1.5C6.00405 1.5 4.61115 2.89291 4.61115 4.61114C4.61115 6.32938 6.00405 7.72229 7.72229 7.72229Z"
      stroke={colorIcon ? colorIcon : "#184DA2"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcUser;
