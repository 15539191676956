import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyColX6P0GbbAmuVxEVRWiDJdsHLuPzY_r8",
    authDomain: "facepay-287103.firebaseapp.com",
    databaseURL: "https://facepay-287103.firebaseio.com",
    projectId: "facepay-287103",
    storageBucket: "facepay-287103.appspot.com",
    messagingSenderId: "495952374661",
    appId: "1:495952374661:web:6790f55ec20d588a1532ec",
    measurementId: "G-Q3NCEZBV5B"
};

const configFirebase = async () => {
    
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app)

    try {
        if(!window.navigator.onLine)
            throw Error('network is offline')
        const res = await signInAnonymously(auth)
        // console.log(res)
        return app
    }
    catch(err) {
        console.log(err)
    }
    return false
}

export default configFirebase