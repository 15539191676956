import React from 'react'
import * as styles from './index.style'

import img_nodata from '../../../assets/images/img_nodata.svg'
import IcNoData from '../../../assets/images/components/IcNoData'

const EmptyDataControl_v3 = (props) => {
    const { labelDes } = props
    return (
        <styles.Bound>
            <div className='animation-container'>
                {/* <img src={img_nodata} alt='no data'/> */}
                <IcNoData className="ic-no-data" />
                <h1>Danh sách trống!</h1>
                <p>{labelDes}</p>
            </div>
        </styles.Bound>
    )
}

export default React.memo(EmptyDataControl_v3)
