import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
    .information-container{
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 770px 570px;
        grid-template-rows: 370px auto;
        grid-gap: 30px;
        padding: 0 60px 0 30px;
        .user-info, .user-note, .user-manage{
            width: 100%;
            height: 100%;
            position: relative;
            background-color: #fff;
            border-radius: 6px;
            border: 0.5px solid #C6D5EB;
            box-sizing: border-box;
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
        }
        .user-info{
            
        }
        .user-note{

        }
        .user-manage{
            grid-column: 1 / span 2;
        }
    }
`

export { Bound }