import React, { useState } from 'react'

// style
import { WrapperNoteItem } from './index.style'

// helper
import { convertDateTime } from '../../../../../assets/helper/tools'
import AvatarVerifyDefault from '../../../../../assets/images/components/AvatarVerifyDefault'

const NoteItem = ({ line, data }) => {

  const [isImgError, setIsImgError] = useState(true)

  return (
    <WrapperNoteItem>
      <div className="avatar-container">
        {
          isImgError
            ? <AvatarVerifyDefault className='avatar' />
            : <img
              src={data.Avatar}
              alt="avatar"
              className="avatar"
              onError={() => setIsImgError(true)}
            />
        }
      </div>
      <div className='message-container'>
        <div className="info__user">
          <p className="name">{data.AdminName}&nbsp;</p>
            &bull;
            <p className="time">
            &nbsp;
              {convertDateTime(data.CreateTime, "DD/MM/YYYY HH:mm")}
          </p>
        </div>
        <div className="message-wrapper" >
            <span
              className="line"
              style={{
                backgroundColor: line.background,
              }}
            >
              {line.type}
            </span>
            {data.Message}
        </div>
      </div>
    </WrapperNoteItem>
  )
}

export default NoteItem
