import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import * as styles from "./index.style";

//component
import TableComponent from "../../../Control/TableComponent";
import EmptyDataControl_v3 from '../../../Control/EmptyDataControl_v3'
//template
import * as templateContent from '../../../Control/TableComponent/Template/chains.template.content'
//action

import * as userAction from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'
import { getListChains, resetListChain } from "../../../../redux/chains/listChains/action";
//constant
import * as keys from '../../../../assets/constants/keys'


var isFirstTimeRender = true
const Chains = (props) => {
    
    const dispatch = useDispatch()

    const listChains = useSelector(state => state.chains.listChains)

    const [data, setData] = useState([])
    
    useEffect(() => {
        dispatch(getListChains({ PageNo: 1, PageSize: 50 }))
    }, [])

    useEffect(() => {
        
        return () => {
            dispatch(resetListChain())
        }
    }, [])

    useEffect(() => {
        if(listChains?.list){
            setData(listChains.list)
        }
    }, [listChains.list])

    useEffect(() => {
        if(!data) return
        if(data.length === 0) return
        if(isFirstTimeRender){
            isFirstTimeRender = false;
            // dispatch(userAction.getUserInfos({UserID: data[0].ID}))
        }
    }, [data])
    
    return (
        <styles.Bound>
            {
                data && data.length > 0 ?
                <TableComponent 
                    typeView = { keys.CHAINS }
                    data = { data }
                    templateContent = { templateContent }
                />
                :
                <EmptyDataControl_v3 labelDes={'Không có chuỗi nào được tạo'}/>
            }
        </styles.Bound>
  );
};

export default React.memo(Chains);
