import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";

import * as styles from "./index.style";

// custom hook
import { useOnClickOutside } from "../../../../hook/useClickOutside.hook";

// images
import IcUnlock from "../../../../assets/images/components/Ic_unlock";
import IcWarningRed from "../../../../assets/images/components/IcWarningRed";
import IcLockRed from "../../../../assets/images/components/Ic_lock_red";
import IcWarningGreen from "../../../../assets/images/components/IcWarningGreen";

// components
import PopupLockDetailAccount from "./PopupLock";
import PopupUnLockDetailAccount from "./PopupUnLock";
import PopupOpenDetailAccount from "./PopupOpen";
import PopupInfoDetailAccount from "./PopupInfo";
import { Fragment } from "react";

const STATUS_LOCK = "STATUS_LOCK";
const STATUS_NORMAL = "STATUS_NORMAL";
const STATUS_FILL_NOTE_UNLOCK = "STATUS_FILL_NOTE_UNLOCK";

export const HeaderDetailAccount = ({ isLocked = false }) => {
  const { t } = useTranslation('common')
  const clickOutSitePopupUnLock = useRef();
  const clickOutSitePopupLock = useRef();

  const { detailsAccount } = useSelector(state => ({
    detailsAccount: state.userCensorship.detailsUser.detailsAccount
  }))

  // const [status, setStatus] = useState(isLocked?STATUS_LOCK:STATUS_NORMAL);
  const [status, setStatus] = useState(STATUS_NORMAL);
  const [showPopupUnLock, setShowPopupUnLock] = useState(false);
  const [showPopupLock, setShowPopupLock] = useState(false);

  useEffect(() => {
    // if(isLocked) setStatus(STATUS_LOCK)
    if (detailsAccount.IsLock) setStatus(STATUS_LOCK)
    else setStatus(STATUS_NORMAL)
    return () => {
     
    }
    // }, [isLocked])
  }, [detailsAccount.IsLock])

  useOnClickOutside(clickOutSitePopupUnLock, () => {
    setShowPopupUnLock(false);
  });
  useOnClickOutside(clickOutSitePopupLock, () => {
    setShowPopupLock(false);
  });

  return (
    <styles.Bound>
      <p className="title">{t('header.user_detail')}</p>
      {status === STATUS_NORMAL && (
        <div className="status_normal" ref={clickOutSitePopupLock}>
          <div className="tooltip-wrap">
            <IcWarningGreen />
            <div className="tooltip-content">
              <PopupInfoDetailAccount />
            </div>
          </div>
          <Fragment>
            <div className="btn">
              <IcUnlock />
              <p className="text">{t('header.status_normal')}</p>
            </div>
            <div className="wrapper-lock">
              <div className="btn-lock" onClick={() => setShowPopupLock(true)}>
                <p className="text">{t('header.status_locked_account')}</p>
              </div>
            </div>
          </Fragment>
          {showPopupLock && (
            <PopupLockDetailAccount
              callbackClosePopup={(isLock) => {
                if (isLock === 1) {
                  setStatus(
                    status === STATUS_LOCK ? STATUS_NORMAL : STATUS_LOCK
                  );
                }
                setShowPopupLock(false);
              }}
            />
          )}
        </div>
      )}

      {status === STATUS_LOCK && (
        <div className="status_lock" ref={clickOutSitePopupUnLock}>
          <div className="tooltip-wrap">
            <IcWarningRed />
            <div className="tooltip-content">
              <PopupUnLockDetailAccount
                callbackClosePopup={(isUnLock) => {
                  if (isUnLock === 1) {
                    setStatus(
                      status === STATUS_LOCK ? STATUS_NORMAL : STATUS_LOCK
                    );
                  }
                  setShowPopupUnLock(false);
                }}
                unlock={() => setStatus(STATUS_FILL_NOTE_UNLOCK)}
              />
            </div>
          </div>
          <Fragment>
            <div className="btn" >
              <IcLockRed />
              <p className="text">{t('header.status_locked')}</p>
            </div>
            <div className="wrapper-unlock">
              <div className="btn-unlock" onClick={() => setShowPopupUnLock(true)}>
                <p className="text">{t('header.status_unlocked_account')}</p>
              </div>
            </div>
            {showPopupUnLock && (
              <PopupOpenDetailAccount
                callbackClosePopup={() => {
                  setStatus(STATUS_LOCK)
                  setShowPopupUnLock(false)
                }}
              />
            )}
          </Fragment>
        </div>
      )}
    </styles.Bound>
  );
};
