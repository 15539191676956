import React from "react";

const IcArrowDownBlack = (props) => (
  <svg
    {...props}
    width={10}
    height={5}
    viewBox="0 0 10 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.90735e-06 4.37114e-07L5 5L10 0" fill="#1C1C1C" />
  </svg>
);

export default IcArrowDownBlack;
