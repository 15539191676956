import React, { Fragment } from "react";
import { css } from "styled-components";

//tool
import { convertDateTime } from "../../../../assets/helper/tools";
//image
import IcTimeGrey from "../../../../assets/images/components/Ic_TimeGrey";
import IcTimeRed from "../../../../assets/images/components/IcTimeRed";
//component
import CountupControl from '../../../Control/CountupControl'
//constant
import * as stylesConstants from '../../../../assets/constants/ui'
import * as censorship_status from '../../../../assets/constants/faceCensorShipStatus'
import * as keys from '../../../../assets/constants/keys'
import { apiAvatarImageMini } from "../../../../assets/constants/host";
//action
import { clearDetailsAccount, getUserInfos } from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'
import { getPendingUsers } from '../../../../redux/userCensorship/listUsers/commonAction'

export const ISALLOWCLICK = true;
export const ISCONNECT = false;
export const KEY = keys.USERCENSOSHIP
export const HEIGHT_ROW_ITEM_CONTAINER = '82px'

export const TABLE_CONTENT_STYLED = css`
  padding: 0 24px 0 58px;
  width: calc(100% - (24px + 58px));
`;

export const TABLE_ROW_ITEM_STYLED = css`
  grid-template-columns: 110px 44px 212px 174px 1fr;
  cursor: pointer;
  height: 82px;
  .time-wait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .user-item-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-user {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      object-fit: cover;
      &.default{
        background-color: #959595;
      }
    }
  }
  .user-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    .p-bold {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      width: 180px;
    }
  }
  .time-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: 1px solid #98acc5;
    box-sizing: border-box;
    border-radius: 35px;
    color: #98acc5;
    &.pending {
      width: 118px;
      height: 32px;
    }
    &.re_pending {
      width: 138px;
      height: 32px;
      border: 1px solid #fa8c8c;
      color: #fa8c8c;
    }
    span {
      margin-left: 10px;
    }
  }
`;

export const caculateTableHeightContent = () => {
  let vh = document.body.offsetHeight
  if (!vh) return
  let mainBound = document.getElementById('main-bound-id')
    if(mainBound){
        return mainBound.offsetHeight -
            (stylesConstants.HEADER_HEIGHT + stylesConstants.MAIN_CONTENT_PADDING_TOP + stylesConstants.TABLE_HEADER_HEIGHT + 10)
    }
    else
        return vh -
            (stylesConstants.HEADER_HEIGHT + stylesConstants.MAIN_CONTENT_PADDING_TOP + stylesConstants.TABLE_HEADER_HEIGHT + 10)
  // return vh -
  //   (stylesConstants.HEADER_HEIGHT + stylesConstants.MAIN_CONTENT_PADDING_TOP + stylesConstants.TABLE_HEADER_HEIGHT + 10)
}

export const _actionOnClickItem = (item) => getUserInfos({ UserID: item.ID })
export const _actionOnClickClearItem = (item) => clearDetailsAccount()
export const _onLoadMore = ({ size, keyword, typeView }) => getPendingUsers({ PageNo: 1, PageSize: size, Type: typeView })

export const renderItem = ({ item, funcTranslate, funcImageError, index, typeView }) => {
  if (!item) return;
  let status
  switch (typeView) {
    case keys.AVATAR:
      status = item.AvatarStatus
      break;
    case keys.EKYC:
      status = item.EKYCStatus
      break;
    default:
      break;
  }
  return (
    <React.Fragment>
      <div className="time-wait">
        <CountupControl timeStart={status?.UpdateTime || '00:00'} />
      </div>
      <div className="user-item-avatar" id={`${KEY}-${index}`}>
        <img
          className="avatar-user"
          src={item && item.ID ? apiAvatarImageMini(item.ID) : ""}
          alt="avatar-user"
          // id={`${KEY}-${index}`}
          onError={() => funcImageError(`${KEY}-${index}`)}
        />
      </div>
      <div className="user-item-info">
        <p className="p-bold" data-toogle="tooltip" title={item?.FullName || ""}>{item && item.FullName ? item.FullName : "-"}</p>
        <p className="p-thin">{item && item.PhoneNumber ? item.PhoneNumber : "-"}</p>
      </div>
      <div className="time-submit">
        <p className="p-bold">
          {convertDateTime(status?.UpdateTime, "DD [Tháng] MM, YYYY")}
        </p>
        <p className="p-thin">
          {convertDateTime(status?.UpdateTime, "HH:mm:ss")}
        </p>
      </div>
      <div className={status?.Status === censorship_status.PENDING ? "status pending" : "status re_pending"}>
        {status?.Status === censorship_status.PENDING ? (
          <Fragment>
            <IcTimeGrey />
            <span>{funcTranslate('table.content.pending')}</span>
          </Fragment>
        ) : (
            <Fragment>
              <IcTimeRed />
              <span>{funcTranslate('table.content.re_pending')}</span>
            </Fragment>
          )}
      </div>
    </React.Fragment>
  );
};


