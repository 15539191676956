import Axios from 'axios'

// host
import { host } from '../../../../assets/constants/host'

// helper
import mapErrors from '../../../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../../../assets/constants/codeResponse'
import * as noteTypes from '../../../../assets/constants/noteTypes'

const apiAddNote = `${host}/backoffice/add-note`
const apigetNotesByUser = `${host}/backoffice/get-note-by-user`

export const addNote = ({ UserID, Message, Type }, callbackSuccess) => async dispatch => {
    if(!Object.values(noteTypes).includes(Type)) 
        throw Error('Type is not valid')
    const dataRequest = JSON.stringify({
        UserID,
        Message,
        Type
    })

    try {
        const res = await Axios.post(apiAddNote, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess) {
            dispatch({
                type: types.ADD_NOTE,
                payload: {
                    note: {
                        Message,
                        Type
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ADD_NOTE_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.ADD_NOTE_ERR))
    }
}

export const getNotesByUser = ({ UserID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        UserID
    })

    try {
        const res = await Axios.post(apigetNotesByUser, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const list = data.Notes || []
            const initialClassification = {
                [noteTypes.DANGER]: 0,
                [noteTypes.WARNING]: 0,
                [noteTypes.INFO]: 0
              }
            const lstClassification = list.reduce((lst, curr) => {
                return {...lst, [curr.Type]: lst[curr.Type] + 1}
            }, initialClassification)
            dispatch({
                type: types.GET_NOTES_BY_USER,
                payload: {
                    list: list.reverse(),
                    lstClassification
                }
            })
        }
        else {
            dispatch({
                type: types.GET_NOTES_BY_USER_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_NOTES_BY_USER_ERR))
    }
}

export const clearNotes = () => ({
    type: types.CLEAR_NOTES
})