import React, { useRef, useState, useEffect, useCallback } from 'react'
import * as style from './index.style'
//component
import IcArrowDownBlack from '../../../assets/images/components/Ic_arrow_down_black'
import IcSelected from '../../../assets/images/components/IcSelected'
import IcUnSelected from '../../../assets/images/components/IcUnSelected'

const DropDownSingle = ({
    placeholder,
    titleDropDown,
    data,
    disabled = true,
    onChange,
    keyValue,
    widthListData,
    label,
    isGender,
    defaultValue
}) => {

    const isClickOutsiteRef = useRef(false);

    const [isShow, setIsShow] = useState(false)
    const [isDisabled, setIsDisabled] = useState(disabled)
    const [selected, setSelected] = useState(defaultValue || {})

    const _handleClickOutSite = useCallback(() => {
        if (isClickOutsiteRef.current === false)
            setTimeout(() => {
                setIsShow(false);
            }, 0);
    }, []);

    useEffect(() => {
        if(!defaultValue) return;
        setSelected(defaultValue)
    }, [JSON.stringify(defaultValue)])

    useEffect(() => {
        if (isShow)
            document.addEventListener("click", _handleClickOutSite, false);
        return () =>
            document.removeEventListener("click", _handleClickOutSite, false);
    }, [_handleClickOutSite, isShow]);

    useEffect(() => {
        setIsDisabled(disabled)
    }, [disabled])

    const renderListData = () => {
        return Object.values(data).map((item, index) => {
            return (
                <div
                    key={index}
                    className="selected"
                    onClick={() => onChangeValue(item)}
                >
                    <div className="icon-wrapper">
                        {
                            selected.key === item.key ?
                                <IcSelected />
                                :
                                <IcUnSelected />
                        }
                    </div>
                    <div className="area-name">{item.value}</div>
                </div>
            )
        })
    }

    const onChangeValue = (item) => {
        setIsShow(false)
        onChange(keyValue, item.key)
        setSelected(item)
    }

    return (
        <style.Bound widthListData={widthListData}>
            <p className="title">{titleDropDown}</p>
            <div
                className="drop-down"
                style={{ backgroundColor: disabled ? '#EDEDED' : 'unset' }}
                onClick={
                    isDisabled ?
                        null
                        :
                        () => setIsShow(!isShow)
                }
            >
                <div className="value">
                    {selected.value ?
                        <p>{selected.value}</p>
                        :
                        <p className={isGender ? "value" : "placeholder"}>{placeholder}</p>
                    }
                </div>
                <IcArrowDownBlack />
                {
                    isShow &&
                    <div
                        className="list-data"
                        onMouseEnter={() => (isClickOutsiteRef.current = true)}
                        onMouseLeave={() => (isClickOutsiteRef.current = false)}
                    >
                        <div className="label">
                            {label}
                        </div>
                        {renderListData()}
                    </div>
                }
            </div>
        </style.Bound>
    )
}

export default React.memo(DropDownSingle)
