import styled from 'styled-components'

const WIDTH_POPUP = 1330

const Bound = styled.div`
    position: fixed;
    /* top: 70px; */
    /* right: 0px; */
    top: ${props => props.pos.top}px;
    right: ${props => window.innerWidth - props.pos.right}px;
    width: ${WIDTH_POPUP}px;
    height: 636px;
    background: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #C6D5EB;
    z-index: 9999;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);

`
export { Bound }