import styled from "styled-components";

export const Bound = styled.div`
  width: 100%;
  height: 100%;
  font-family: SVN-Gilroy;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 62px 0 0;
  box-sizing: border-box;

  .search {
    margin-right: 30px;
    cursor: pointer;
  }

  .noti {
    margin-right: 30px;
    cursor: pointer;
    position: relative;

    span {
      position: absolute;
      top: -2px;
      right: -2px;
      width: 6px;
      height: 6px;
      background: #fa8c8c;
      border: 1.5px solid #f7f8fc;
      border-radius: 50%;
    }
  }

  .line {
    height: 32px;
    width: 1px;
    margin-right: 80px;
    background: #dfe0eb;
  }

  .admin {
    display: flex;
    align-items: center;
    justify-content: center;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .name {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #1c1c1c;
      }

      .role {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 170%;
        color: #959595;
      }
    }
    &__avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      object-fit: contain;
      border: 1.5px solid #ffffff;
      margin: 0 0 0 15px;
    }
  }
`;
