import React from 'react'

// style
import { WrapperSimpleDetails } from './index.style'

// component
import StatusFace from './StatusFace'

// helper
import { convertDateTime } from '../../../../../../../assets/helper/tools'

const SimpleItemDetails = ({ data }) => {
    return (
        <WrapperSimpleDetails>
            <div className="time-submit">Hình được gửi vào lúc: { convertDateTime(data.CreateTime, 'DD/MM/YYYY') }</div>
            <StatusFace status={data&&data.Status} />
            {/* <StatusFace status="ACCEPTED" /> */}
        </WrapperSimpleDetails>
    )
}

export default SimpleItemDetails
