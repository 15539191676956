import React, { useEffect, useState } from 'react'
import { Route, Switch } from "react-router-dom";
import { useDispatch } from 'react-redux'
import * as styles from './index.style'

//component
import UserCensorship from './UserCensorship'
import Users from './Users'
import Chains from './Chains'
import UserList from './UserList';
import Transaction from './Transaction';
    //Header
import HeaderComponent from "../../Control/HeaderComponent";
import HeaderMenu from "../../Control/HeaderComponent/HeaderMenuMainBoard";
import UsersMainBoardHeader from '../../Control/HeaderComponent/UsersMainBoardHeader'
import ChainsMainBoardHeader from '../../Control/HeaderComponent/ChainsMainBoardHeader'
import TransactionBoardHeader from './TransactionBoardHeader';

//data
import { headerMenuMainBoard } from "../../../assets/data/dataHeaderMenu";
//constant
import * as stylesConstants from '../../../assets/constants/ui'
import * as pathNames from '../../../assets/constants/pathName'
import * as keys from '../../../assets/constants/keys'
//action
import * as userCensorshipActions from '../../../redux/userCensorship/listUsers/commonAction'

const MainBoard = (props) => {
    const { changeTabView } = props
    const dispatch = useDispatch()
    const [typeView, setTypeView] = useState(keys.AVATAR)

    useEffect(() => {
        // _changeTabViewCensorship(keys.AVATAR)
        return () => {
            
        }
    }, [])

    const _changeTabView = (data) => {
        if(!data) return
        setTypeView(data.key)
        changeTabView(data.key)
        switch (data.key) {
            case keys.AVATAR:
            case keys.EKYC:
                dispatch(userCensorshipActions.getPendingUsers({PageNo: 1, PageSize: 50, Type: data.key}))
                break;
            case keys.CHAINS:
                // get list chains

                break;
            default:
                break;
        }
        
    }

    return (
        <styles.Bound>
            <HeaderComponent 
                height = {stylesConstants.HEADER_HEIGHT - 1}
                backgroundColor = 'rgba(147, 191, 242, 0.1)'
                borderBottom = '1px solid #C6D5EB'
                position = 'absolute'
                child = {
                    <Switch>
                        <Route path={`/${pathNames.MAIN}/${pathNames.HOME}`}>
                            
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.USER_CENSORSHIP}`}>
                            <HeaderMenu
                                dataMenu={headerMenuMainBoard}
                                callBackClickNavLink={_changeTabView}
                            />
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.USERS}`}>
                            <UsersMainBoardHeader 
                                callBackClickNavLink={_changeTabView}
                            />
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.TRANSACTIONS}`}>
                            <TransactionBoardHeader />
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.CHAINS}`}>
                            <ChainsMainBoardHeader
                                callBackClickNavLink={_changeTabView}
                            />
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.MPOS}`}>
                            
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.VERSION_RELEASED}`}>
                            
                        </Route>
                        <Route path={`${pathNames.MAIN}/${pathNames.SYSTEM_LOG}`}>
                        
                        </Route>
                        <Route path={`/${pathNames.MAIN}/${pathNames.SETTING}`}>
                            
                        </Route>
                    </Switch>
                    
                }
            />
            <Switch>
                <Route path={`/${pathNames.MAIN}/${pathNames.HOME}`}>
                    
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.USER_CENSORSHIP}`}>
                    <UserCensorship typeView={typeView}/>
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.USERS}`}>
                    {/* <Users typeView={typeView}/> */}
                    <UserList />
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.TRANSACTIONS}`}>
                    <Transaction />
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.CHAINS}`}>
                    <Chains typeView={typeView}/>
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.MPOS}`}>
                    
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.VERSION_RELEASED}`}>
                    
                </Route>
                <Route path={`${pathNames.MAIN}/${pathNames.SYSTEM_LOG}`}>
                        
                </Route>
                <Route path={`/${pathNames.MAIN}/${pathNames.SETTING}`}>
                    
                </Route>

                {/* <Redirect to={`${path}/${pathNames.USER_CENSORSHIP}`} /> */}
            </Switch>
        </styles.Bound>
    )
}

export default React.memo(MainBoard)
