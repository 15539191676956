import styled from 'styled-components'

const RowFilter = styled.div`
    height: auto;
    display: grid;
    /* grid-template-columns: 395px 1fr ; */
    grid-template-columns: 1fr 235px;
    margin-top: 15px;
    z-index: 4;
    position: relative;
    & > * {
        width: calc(100% - 20px);
    }

    .filter-users {
        padding-left: 8px;
    }
`;
export { RowFilter }