import React, { useCallback } from 'react'

// style
import { Title, WrapperListCondidtion } from './index.style'

// component
// import CheckBoxField from './CheckBoxField'
import CheckBoxField from '../../../../../../../Control/CheckBoxField'

// constant
import { REJECT, ACCEPT } from '../../../../../../../../assets/constants/faceCensorShipStatus'

const ListConditionCheckPass = ({ stateCondition, dispatchCondition }) => {

    const _handleCheck = useCallback((dataItem) => {
        const { listChecked } = stateCondition;

        if(listChecked.includes(dataItem.ID)){
            dispatchCondition({
                listChecked: listChecked.filter(item => item !== dataItem.ID)
            })
            return
        }
        dispatchCondition({
            listChecked: [...listChecked, dataItem.ID]
        })
    }, [stateCondition.listChecked, dispatchCondition])

    return (
        <WrapperListCondidtion>
            {/* <Title>Hình ảnh {stateCondition.status === REJECT?"chưa":"phải"} đáp ứng các điều kiện sau:</Title> */}
            <Title>
            {
                stateCondition.status === REJECT ? 
                "Lý do từ chối:"
                :
                stateCondition.status === ACCEPT ?
                "Hình ảnh đáp ứng các điều kiện sau:"
                :
                "Hình ảnh phải đáp ứng các điều kiện sau:"
            }
            </Title>
            {
                Object.values(stateCondition.listCondition).map((item, key) => 
                    <CheckBoxField 
                        isDisabled={stateCondition.isDisableCheck}
                        key={key} 
                        data={stateCondition.status === REJECT ? item.RejectedReason : item.Reason} 
                        isChecked={stateCondition.listChecked.includes(item.ID)}
                        onClick={() => _handleCheck(item)}
                    />
                )
            }
            {
                stateCondition.status === REJECT &&
                !!stateCondition.optinalReason &&
                // <CheckBoxField
                //     isDisabled={true}
                //     data={stateCondition.optinalReason}
                //     isChecked={true}
                // />
                <div className="opt-reason">Từ chối vì lý do khác: {stateCondition.optinalReason}</div>
            }
        </WrapperListCondidtion>
    )
}

export default ListConditionCheckPass
