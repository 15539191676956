import Axios from "axios";

// host
import { host } from "../../../../assets/constants/host";

// helper
import mapErrors from "../../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../../assets/constants/codeResponse";
import * as noteTypes from '../../../../assets/constants/noteTypes'

const apiAddNoteToChain = `${host}/backoffice/add-chain-note`
const apiGetNotesByChain = `${host}/backoffice/get-note-by-chain`

export const addNoteToChain = ({ ChainID, Message, Type }, callbackSuccess) => async dispatch => {
    const dataRequest = JSON.stringify({
        ChainID, 
        Message, 
        Type 
    })

    try {
        const res = await Axios.post(apiAddNoteToChain, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess) {
            dispatch({
                type: types.ADD_NOTE_TO_CHAIN,
                payload: {
                    note: {
                        Message,
                        Type
                    }
                }
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ADD_NOTE_TO_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.ADD_NOTE_TO_CHAIN_ERR))
    }
}

export const getListNotesByChain = ({ ChainID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        ChainID
    })

    try {
        const res = await Axios.post(apiGetNotesByChain, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const list = data.Notes || []
            const initialClassification = {
                [noteTypes.DANGER]: 0,
                [noteTypes.WARNING]: 0,
                [noteTypes.INFO]: 0
              }
            const lstClassification = list.reduce((lst, curr) => {
                return {...lst, [curr.Type]: lst[curr.Type] + 1}
            }, initialClassification)
            dispatch({
                type: types.GET_NOTES_BY_CHAIN,
                payload: {
                    list: list.reverse(),
                    lstClassification
                }
            })
        }
        else {
            dispatch({
                type: types.GET_NOTES_BY_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_NOTES_BY_CHAIN_ERR))
    }
}

export const clearNotesByChain = () => ({
    type: types.CLEAR_NOTES_BY_CHAIN
})