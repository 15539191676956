import styled from 'styled-components'

const TIME_ANIMATION = 0.3

const Button = styled.div`
    border: ${props => props.border};
    box-sizing: border-box;
    border-radius: 3px;
    background: ${props => props.bg};
    width: ${props => {
        switch(true){
            case props.isDecrease:
                return '70px'
            case props.isIncrease:
                return '250px'
            default:
                return '204px'
        }
    }};
    transition: width ${TIME_ANIMATION}s linear;
    height: 50px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: bold;
    line-height: 15px;
    letter-spacing: ${props => props.isDecrease? 'normal' : '0.05em'};
    text-transform: uppercase;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    position: relative;

    & > * {
        flex-shrink: 0;
    }

    &:hover {
        cursor: pointer;
    }

    /* &:last-child {
        margin-left: 22px;
    } */

    & > *:first-child {
        transition: margin ${TIME_ANIMATION}s linear;
        margin: ${props => props.isDecrease ? "0 20px" : "0 40px 0 0"};
    }

    & > span {
        transition: font-size ${TIME_ANIMATION}s linear;
        font-size: ${props => props.isDecrease ? 0 : "14px"};
    }
`;

const WrapperFooter = styled.div`
    display: flex;

    .approved-infos {
        font-family: 'UTM Avo';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #959595;
    }

    .wrapper-btn-reject {
        position: relative;
        /* margin-left: 22px; */
    }

    & > ${Button} {
        margin-left: 22px;
    }
`;

export {
    WrapperFooter,
    Button
}