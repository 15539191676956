import React from "react";

import * as styles from "./index.style";

// images
import IcSearchInfo from "../../../../assets/images/components/Ic_search_info";
import IcNoti from "../../../../assets/images/components/Ic_noti";

const HeaderInformationBoard = () => {
  return (
    <styles.Bound>
      {/* <div className="search">
        <IcSearchInfo />
      </div>

      <div className="noti">
        <IcNoti />
        <span/>
      </div>

      <span className="line" /> */}

      <div className="admin">
        <div className="admin__info">
          <p className="name">Admin</p>
          <p className="role">Administrator</p>
        </div>
        <img
          className="admin__avatar"
          src={"https://picsum.photos/200"}
          alt="avatar"
        />
      </div>
    </styles.Bound>
  );
};

export default React.memo(HeaderInformationBoard);
