import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: auto;

`
export {Bound}