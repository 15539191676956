import React, { Component } from "react";
import * as style from "./index.style";

const RowContent = ({ title, content, marginBottom }) => {
  return (
    <style.RowContent marginBottom={marginBottom}>
      <div className="title">{title}</div>
      <div
        className="content"
        data-toogle="tooltip"
        title={content ? content : null}
      >
        {content ? content : "-"}
      </div>
    </style.RowContent>
  );
};

export default React.memo(RowContent);
