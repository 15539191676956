import * as types from './types'

export const popNotify = (typeItem) => dispatch => {
    dispatch({
        type: types.POP_NOTIFY,
        payload: {
            typeItem
        }
    })
}

export const pushNotify = (strError) => dispatch => {
    dispatch({
        type: types.PUSH_NOTIFY,
        payload: {
            strError
        }
    })
}