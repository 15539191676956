import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
//component
import IcEdit from "../../../../assets/images/components/Ic_Edit";
import PopupEditChains from '../../../Control/HeaderComponent/ChainsMainBoardHeader/PopupEditChains'

import * as styles from "./index.style";

export const HeaderDetailChain = React.memo(() => {
    const btnEditRef = useRef(null);

    const [isOpenPopup, setIsOpenPopup] = useState(false)

    const { t } = useTranslation('common')

    useEffect(() => {

        return () => {

        }
    }, [])

    const _handlePopupEditChains = useCallback(() => {
        setIsOpenPopup(!isOpenPopup)
    }, [isOpenPopup])

    return (
        <styles.Bound>
            <p className="title">{t('header.chain_detail')}</p>
            <div className="block-edit">
                <div
                    className="button-edit"
                    ref={btnEditRef}
                    onClick={() => _handlePopupEditChains()}
                >
                    <IcEdit className="icon-edit" />
                    <div className="tooltiptext">
                        <p id="text">Chỉnh sửa</p>
                    </div>
                </div>
                {
                    isOpenPopup && 
                        <PopupEditChains 
                            btnRef={btnEditRef} 
                            handleClose={_handlePopupEditChains} 
                        />
                }
            </div>
        </styles.Bound>
    );
});
