import React, { useRef, useState, useEffect } from 'react'
import { getDatabase, ref, onChildChanged, off } from "firebase/database";

// component
import Toast from './Toast'

// styles
import { WrapNotifyNewUser } from './index.styles'

// helper
import configFirebase from '../../assets/helper/configFirebase'

// constant
import { refNotifyNewUser } from '../../assets/constants/host';

const TIME_SHOW = process.env.NODE_ENV === 'development' ? 5_000 : 20_000 // 20 secs

const NotifyNewUser = () => {
    const timer = useRef(null)
    const isAllowNotification = useRef(false)
    const isFocusWindow = useRef(false)
    const [count, setCount] = useState(0);
    const [isShow, setIsShow] = useState(false);

    const _checkActiveTab = () => {
        const keys = ["hidden","webkitHidden","mozHidden","msHidden"]
        const stateKey = keys.find(key => key in document)
        return !document[stateKey];
    }

    const _getNotificationPermission = () => {
        if (!("Notification" in window)) {
            console.error("This browser does not support desktop notification");
            isAllowNotification.current = false
            return;
        }

        else if (Notification.permission === "granted") {
            isAllowNotification.current = true
            return;
        }

        else if (Notification.permission !== "denied") {
            Notification.requestPermission()
            .then(function (permission) {
                if (permission === "granted") {
                    isAllowNotification.current = true
                    return;
                }
                isAllowNotification.current = false
            });
            return;
        }
        // user not allow notification 
        console.log('User not allow notification')
        isAllowNotification.current = false
    }

    useEffect(_getNotificationPermission, []);

    const _notify = () => {
        const title = "Kiểm duyệt tài khoản"
        const options = {
            icon: "favicon.ico",
            body: "Khách hàng vừa đăng ký tài khoản, kiểm duyệt ngay nhé!"
        }
        const notification = new Notification(title, options)
        notification.onclick = function() {
            window.focus()
            this.close()
        }
        // notification.onerror = function() {
        //     console.log('error')
        // }
        // notification.onshow = function() {
        //     console.log('show')
        // }
    }

    useEffect(() => {
        let countRef
        const getConfig = async () => {
            const app = await configFirebase()
            const database = getDatabase(app);
            countRef = ref(database, refNotifyNewUser);
            onChildChanged(countRef, (data) => {
                if(data.key === 'Count'){
                    // console.log(data.val());
                    setCount(pre => pre + 1)
                    setIsShow(true);
                    // console.log('_checkActiveTab()', _checkActiveTab())
                    // console.log('isFocusWindow.current', isFocusWindow.current)
                    (!_checkActiveTab() || !(isFocusWindow.current || document.hasFocus())) && isAllowNotification.current && _notify()
                } else {
                    console.log('child is not count key')
                }
            });
        }
        getConfig()
        return () => {
            countRef && off(countRef)
        }
    }, []);

    useEffect(() => {
        if(!isShow) return 
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            setIsShow(false)
        }, TIME_SHOW)

        return () => {
            clearTimeout(timer.current)
        }
    }, [isShow, count]);
    
    useEffect(() => {
        // Active
        window.addEventListener('focus', () => {
            // console.log('focus')
            isFocusWindow.current = true
        });

        // Inactive
        window.addEventListener('blur', () => {
            // console.log('blur')
            isFocusWindow.current = false
        });

        window.addEventListener('mousemove', () => {
            // console.log('mousemove')
            !isFocusWindow.current && (isFocusWindow.current = true)
        });
        // window.addEventListener('mouseleave', () => {
        //     console.log('mouseleave')
        // });

        // document.addEventListener('visibilitychange',() => {
        //     console.log('hidden', document.hidden)
        // })
        window.focus()
    }, []);

    return (
        <WrapNotifyNewUser>
            <Toast 
                isShow={isShow} 
            />
        </WrapNotifyNewUser>
    )
}

export default NotifyNewUser
