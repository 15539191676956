import React from "react";

const IcKhuonMatOn = (props) => (
  <svg
    {...props}
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx={16} cy={16} r={16} fill="url(#paint0_linear)" />
    <path
      d="M22.4446 22.5V20.9444C22.4446 20.1193 22.1168 19.3279 21.5333 18.7445C20.9499 18.161 20.1586 17.8333 19.3334 17.8333H13.1111C12.286 17.8333 11.4947 18.161 10.9112 18.7445C10.3278 19.3279 10 20.1193 10 20.9444V22.5"
      stroke="url(#paint1_linear)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2223 14.7223C17.9405 14.7223 19.3334 13.3294 19.3334 11.6111C19.3334 9.89291 17.9405 8.5 16.2223 8.5C14.5041 8.5 13.1111 9.89291 13.1111 11.6111C13.1111 13.3294 14.5041 14.7223 16.2223 14.7223Z"
      stroke="url(#paint2_linear)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1={16}
        y1={48}
        x2={48}
        y2={16}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CFFF" />
        <stop offset={1} stopColor="#0065FF" />
        <stop offset={1} stopColor="#005BEC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="16.2223"
        y1="24.8333"
        x2="19.2908"
        y2="16.6506"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CFFF" />
        <stop offset={1} stopColor="#0065FF" />
        <stop offset={1} stopColor="#005BEC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="16.2223"
        y1="17.8334"
        x2="22.4446"
        y2="11.6111"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00CFFF" />
        <stop offset={1} stopColor="#0065FF" />
        <stop offset={1} stopColor="#005BEC" />
      </linearGradient>
    </defs>
  </svg>
);

export default IcKhuonMatOn;
