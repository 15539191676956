import * as types from "./types";

const intitialState = {
  list: [],
  filter: {
    keyword: ''
  },
  total: 0,
  isLoading: false
};

const listChains = (state = intitialState, action) => {
  switch (action.type) {
    case types.GET_LIST_CHAINS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case types.ADD_CHAIN_START: {
      return {
        ...state,
        isLoading: true
      }
    }
    case types.ADD_CHAIN_FINISH: {
      return {
        ...state,
        isLoading: false
      }
    }
    case types.RESET_LIST_CHAIN: {
      return intitialState      
    }
    default:
      return state;
  }
};

export default listChains;
