import React from 'react'

const IcDelete = (props) => {
    return (
        <svg {...props} width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.87214 3.05859C8.03675 2.91824 8.28966 2.81836 8.5804 2.81836H12.4188C12.7095 2.81836 12.9624 2.91824 13.127 3.05859C13.2868 3.19478 13.338 3.34207 13.338 3.45472V4.09109H7.66121V3.45472C7.66121 3.34207 7.71241 3.19478 7.87214 3.05859ZM15.338 3.45472V4.09109H17.2167C17.2295 4.09109 17.2422 4.09132 17.2548 4.0918H19.136C19.6883 4.0918 20.136 4.53951 20.136 5.0918C20.136 5.64408 19.6883 6.0918 19.136 6.0918H18.2167V16.5456C18.2167 17.301 17.8635 17.9861 17.3034 18.4637C16.7482 18.9371 16.0248 19.182 15.2976 19.182H5.70162C4.97435 19.182 4.25096 18.9371 3.69573 18.4637C3.13563 17.9861 2.78243 17.301 2.78243 16.5456V6.0918H1.86328C1.311 6.0918 0.863281 5.64408 0.863281 5.0918C0.863281 4.53951 1.311 4.0918 1.86328 4.0918H3.74437C3.757 4.09132 3.76968 4.09109 3.78243 4.09109H5.66121V3.45472C5.66121 2.69939 6.01441 2.01425 6.57452 1.53669C7.12974 1.06329 7.85314 0.818359 8.5804 0.818359H12.4188C13.146 0.818359 13.8694 1.06329 14.4247 1.53669C14.9848 2.01425 15.338 2.69939 15.338 3.45472ZM4.78243 6.0918V16.5456C4.78243 16.6583 4.83362 16.8056 4.99335 16.9418C5.15797 17.0821 5.41088 17.182 5.70162 17.182H15.2976C15.5883 17.182 15.8412 17.0821 16.0058 16.9418C16.1656 16.8056 16.2167 16.6583 16.2167 16.5456V6.0918H4.78243Z" fill="#184DA2" />
        </svg>
    )
}

export default IcDelete
