import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

// rootReducer
import rootReducer from "./root.reducer";

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const middleware = process.env.NODE_ENV !== 'production' ?
  [require('redux-immutable-state-invariant').default(), thunk] :
  [thunk];

export const configureStore = (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV === "development"
      ? composeEnhancers(applyMiddleware(...middleware))
      : applyMiddleware(...middleware)
  );
};
