import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";

//constant
import * as keys from '../../../../assets/constants/keys'

// style
import * as styles from "./index.style";
import UserCircleBlue from "../../../../assets/images/components/UserCircleBlue";

const UsersMainBoardHeader = (props) => {
    const { callBackClickNavLink } = props
    const { t } = useTranslation('common')
    const dispatch = useDispatch()

    useEffect(() => {
        callBackClickNavLink({key: keys.USERS})
    }, [])

    return (
        <styles.Bound>
            <div className="wrap-menu">
                <div className="trans-list-menu">
                    <UserCircleBlue />
                    <div>Danh sách người dùng</div>
                </div>
                <hr className="selected-line" />
            </div>
        </styles.Bound>
    );
};

UsersMainBoardHeader.propTypes = {

};

export default React.memo(UsersMainBoardHeader);
