import * as types from "./types";

const intitialState = {
    list: [],
    totals: 0,
    conditionFilter: null,
};

const eKYCsPending = (state = intitialState, action) => {
    switch (action.type) {
        case types.GET_LIST_EKYC_PENDING_USERS: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
};

export default eKYCsPending;
