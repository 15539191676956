import Axios from 'axios'

// host
import { host } from '../../../../assets/constants/host'
import { apiGetReasons } from '../faces/action'

// helper
import mapErrors from '../../../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../../../assets/constants/codeResponse'
import { EKYC } from '../../../../assets/constants/keys'

const apiGetEKYCByUser = `${host}/backoffice/ekyc/get-by-user`
const apiCheckDuplicateNumber = `${host}/backoffice/ekyc/check-duplicate`
const apiVerifyEKYCs = `${host}/backoffice/verify-identity-card`
const apiEditEKYCInfos = `${host}/backoffice/ekyc/edit-info`

export const getEKYCByUser = ({ UserID }, callbackSuccess, callbackError) => async dispatch => {
    const dataRequest = JSON.stringify({
        UserID
    })

    try {
        const res = await Axios.post(apiGetEKYCByUser, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;

        if(isSuccess){
            dispatch({
                type: types.GET_EKYC_BY_USER,
                payload: {
                    listEKYC: data.Data || []
                }
            })

            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.GET_EKYC_BY_USER_ERR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_EKYC_BY_USER_ERR))
        callbackError && callbackError()
    }
}

export const getReasonsEKYC = () => async dispatch => {
    const dataRequest = JSON.stringify({
        Type: EKYC
    })

    try {
        const res = await Axios.post(apiGetReasons, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const listReasons = data.Reasons || []
            dispatch({
                type: types.GET_REASON_EKYC,
                payload: {
                    listReasons: listReasons.reduce((obj, curr) => ({...obj, [curr.ID]: curr}), {})
                }
            })
        }
        else{
            // console.dir(data)
            dispatch({
                type: types.GET_REASON_EKYC_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_REASON_EKYC_ERR))
    }
}

export const checkDuplicateNumber = ({ Number, UserID }, idCurrData) => async dispatch => {
    const dataRequest = JSON.stringify({
        Number,
        UserID
    })

    try {
        const res = await Axios.post(apiCheckDuplicateNumber, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess) {
            dispatch({
                type: types.CHECK_DUPLICATE_NUMBER,
                payload: {
                    duplicatesNumber: data.Data || [],
                    idCurrData 
                }
            })
        }
        else {
            // console.dir(data)
            dispatch({
                type: types.CHECK_DUPLICATE_NUMBER_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.CHECK_DUPLICATE_NUMBER_ERR))
    }
}

export const verifyEKYCs = (
    { 
        UserID, 
        ID, 
        Status, 
        OptionalReason, 
        Reasons 
    }, 
    callbackSuccess, 
    callbackError
) => async (dispatch, getState) => {

    const store = getState()
    const { isLoadingVerifyEkycs } = store.userCensorship.detailsUser.eKYCs
    if(isLoadingVerifyEkycs) return;

    dispatch({ type: types.VERIFY_EKYCS_START })

    // if(process.env.NODE_ENV === 'development'){
    //     dispatch({
    //         type: types.VERIFY_EKYCS_ERR,
    //         payload: {
    //             Code: 4
    //         }
    //     })
    //     return
    // }

    const dataRequest = JSON.stringify({ 
        UserID, 
        ID, 
        Status, 
        OptionalReason, 
        Reasons 
    })

    try {
        const res = await Axios.post(apiVerifyEKYCs, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            callbackSuccess && callbackSuccess()
            dispatch({
                type: types.VERIFY_EKYCS
            })
        }
        else {
            callbackError && callbackError()
            dispatch({
                type: types.VERIFY_EKYCS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        callbackError && callbackError()
        dispatch(mapErrors(err, types.VERIFY_EKYCS_ERR))
    }

    dispatch({ type: types.VERIFY_EKYCS_END })

}

export const editEKYCInfos = (
    {
        ID,
        EKYCType,
        Nationality,
        // Avatar,
        CardNumber,
        Name,
        Birthday,
        Sex,
        HomeTown,
        IssueDate,
        IssuedPlace,
        Expire,
        Address,
        PlaceOfBirth
    },
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
    const store = getState()
    const { isLoadingEditEkycs } = store.userCensorship.detailsUser.eKYCs
    if(isLoadingEditEkycs) return;

    dispatch({ type: types.EDIT_EKYC_INFOS_START })

    const dataRequest = JSON.stringify({
        ID,
        EKYCType,
        Nationality,
        // Avatar,
        CardNumber,
        Name,
        Birthday,
        Sex,
        HomeTown,
        IssueDate,
        IssuedPlace,
        Expire,
        Address,
        PlaceOfBirth
    })

    try {
        const res = await Axios.post(apiEditEKYCInfos, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            callbackSuccess && callbackSuccess()
            dispatch({
                type: types.EDIT_EKYC_INFOS
            })
        }
        else {
            callbackError && callbackError()
            dispatch({
                type: types.EDIT_EKYC_INFOS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        callbackError && callbackError()
        dispatch(mapErrors(err, types.EDIT_EKYC_INFOS_ERR))
    }
    dispatch({ type: types.EDIT_EKYC_INFOS_END })
}

export const clearEKYCs = () => ({
    type: types.CLEAR_EKYCS
})