import { combineReducers } from "redux";

import listUsers from './listUsers'
import detailsUser from './detailsUser'

const userCensorship = combineReducers({
    listUsers,
    detailsUser
})

export default userCensorship