import styled from 'styled-components'

const Wrapper = styled.div`
    height: 100%;
    max-width: 100%;
    overflow-y: auto;
    padding: 30px;
    box-sizing: border-box;

    & > *:not(:first-child) {
        margin-top: 30px;
    }
`;

export {
    Wrapper
}