import React from 'react'

const IcSentNoteBlur = (props) => {
    return (
        <svg {...props} width={34} height={34} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.7767 7.64465L2.74861 14.6104C1.42866 15.0686 0.730064 16.51 1.18818 17.83C1.42178 18.503 1.92835 19.0463 2.58345 19.3265L9.9613 22.4874L24.6949 7.75376C24.0953 7.46436 23.4052 7.42509 22.7767 7.64465Z" fill="#C6D5EB" />
            <path d="M11.1538 23.6799L14.3159 31.059C14.8702 32.345 16.3621 32.9383 17.6482 32.384C17.9394 32.2585 18.2045 32.0795 18.4297 31.8562C18.7006 31.5853 18.9069 31.2568 19.0331 30.895L25.9965 10.8646C26.216 10.236 26.1768 9.54594 25.8874 8.94636L11.1538 23.6799Z" fill="#C6D5EB" />
        </svg>
    )
}

export default IcSentNoteBlur
