import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import * as styles from "./index.style";

//component
import TableComponent from "../../../Control/TableComponent";
//template
import * as templateHeader from '../../../Control/TableComponent/Template/users.template.header'
import * as templateContent from '../../../Control/TableComponent/Template/users.template.content'
//action
import { getListInfoUsers } from '../../../../redux/listInfoUsers/action'
import * as userAction from '../../../../redux/userCensorship/detailsUser/detailsAccount/action'


var isFirstTimeRender = true
const Users = (props) => {
    
    const dispatch = useDispatch()

    const [data, setData] = useState([])

    const { listInfoUsers } = useSelector(state => ({
        listInfoUsers: state.listInfoUsers
    }))

    useEffect(() => {
        dispatch(getListInfoUsers({PageNumber: 1, PageSize: 8}))
    }, [])

    useEffect(() => {
        if(listInfoUsers?.list){
            setData(listInfoUsers.list)
        }
    }, [listInfoUsers.list])

    useEffect(() => {
        if(!data) return
        if(data.length === 0) return
        if(isFirstTimeRender){
            isFirstTimeRender = false;
            dispatch(userAction.getUserInfos({UserID: data[0].ID}))
        }
    }, [data])
    
    return (
        <styles.Bound>
            <TableComponent 
                data = { data }
                templateHeader = { templateHeader }
                templateContent = { templateContent }
            />
        </styles.Bound>
  );
};

export default React.memo(Users);
