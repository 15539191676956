import Axios from "axios";

// host
// import { host } from "../../../assets/constants/host";
import { host } from "../../../../assets/constants/host";

// helper
import mapErrors from "../../../../assets/helper/mapErrors";

// constant
import * as types from "./types";
import { CODE_SUCCESS } from "../../../../assets/constants/codeResponse";

const apiGetAreasByChains = `${host}/backoffice/get-area`
const apiAddAreaToChain = `${host}/backoffice/add-area`

export const getAreasByChain = ({ ChainID, Filter }) => async dispatch => {
    const dataRequest = JSON.stringify({
        ChainID,
        Filter
    })

    try {
        const res = await Axios.post(apiGetAreasByChains, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.GET_AREAS_BY_CHAIN,
                payload: {
                    list: data.Areas || [] 
                }
            })
        }
        else {
            dispatch({
                type: types.GET_AREAS_BY_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err) {
        dispatch(mapErrors(err, types.GET_AREAS_BY_CHAIN_ERR))
    }
}

export const addAreaToChain = ({ ChainID, Name }, callbackSuccess) => async (dispatch, getState) => {
    const store = getState()
    const { isLoadingAddArea } = store.chains.chainInfos.areas
    if(isLoadingAddArea) return;

    dispatch({ type: types.ADD_AREA_TO_CHAIN_START })
    const dataRequest = JSON.stringify({
        ChainID,
        Name
    })

    try {
        const res = await Axios.post(apiAddAreaToChain, dataRequest);
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.ADD_AREA_TO_CHAIN
            })
            callbackSuccess && callbackSuccess()
        }
        else {
            dispatch({
                type: types.ADD_AREA_TO_CHAIN_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        dispatch(mapErrors(err, types.ADD_AREA_TO_CHAIN_ERR))
    }

    dispatch({ type: types.ADD_AREA_TO_CHAIN_END })
}