import React, { Component } from 'react'
import { useSelector } from 'react-redux'

// styles
import * as style from './index.style'

// component
import TitleColumn from './TitleColumn'
import SimpleInfos from './SimpleInfos'
import AdvancedInfos from './AdvancedInfos'

// mock-data
import comparePageData from '../../../data/comparePageData'
import UserInformation from '../../Main/InformationBoard/UserCensorship/UserInformation'
import { converTypeOfPaper } from '../../../assets/helper/tools'
import IcCheckGreen from '../../../assets/images/components/Ic_CheckGreen'

const MainComparePage = () => {

    const { primaryUser, primaryEKYCUser, secondaryUser, secondaryEKYCUser } = useSelector(
        state => state.compareEKYCs
    )
    // console.log(primaryEKYCUser);

    return (
        <style.Bound>
            <div className="row simple-infos">
                <div></div>
                <SimpleInfos infos={primaryUser} />
                <SimpleInfos infos={secondaryUser} paddingLeft="30px" />
            </div>
            <div className="row simple-infos type-data">
                <div className="title">Loại giấy tờ</div>
                <div className="type-paper">
                    {converTypeOfPaper(primaryEKYCUser.EKYCType).fullcardType}
                </div>
                <div className="type-paper right">
                    {converTypeOfPaper(secondaryEKYCUser.EKYCType).fullcardType}
                    <div className="button-censorship">
                        <IcCheckGreen />
                        <p className="info-censorship">Đã kiểm duyệt thông tin</p>
                    </div>
                </div>
            </div>
            <div className="row advanced-infos">
                <TitleColumn />
                <AdvancedInfos infos={primaryEKYCUser} />
                <AdvancedInfos infos={secondaryEKYCUser} paddingLeft="30px" />
            </div>
        </style.Bound>
    )
}

export default React.memo(MainComparePage)
