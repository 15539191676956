import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-rows: 319px 1fr;
    overflow: hidden;
    box-sizing: border-box;
    min-height: calc(100vh - 80px);
    min-width: 2560px;
    .simple-infos,
    .advanced-infos {
        display: grid; 
        grid-template-columns: 272px repeat(2, minmax(auto, 1fr));
        & > *:not(:last-child){
            border-right: 1px solid #C6D5EB;
        }
    }
    .simple-infos {
        background: #E5E5E5;
        padding-right: 5px;
        border-top: 1px solid #C6D5EB;

        &.type-data {
            height: 53px;
            background: #184DA2;
            color: #FFFFFF;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            font-family: 'SVN-Gilroy';
            font-style: normal;
            line-height: 170%;
            & > * {
                display: flex;
                align-items: center;
            }
            & > .title {
                padding-left: 30px;
            }
            & > .type-paper {
                font-size: 14px;
                font-weight: normal;
                padding: 0 70px 0 58px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.right{
                    padding: 0 70px 0 30px;
                }
                .button-censorship{
                    width: 210px;
                    height: 32px;
                    background-color: #00B887;
                    border-radius: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .info-censorship{
                        font-family: 'UTM Avo';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 170%;
                        letter-spacing: 0.05px;
                        color: #ffffff;
                        padding-left: 10px;
                    }
                }
            }
        }
    }
    .advanced-infos {
        max-height: calc(100vh - 319px - 80px);
        overflow-x: hidden;
        overflow-y: auto;

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background: #C6D5EB;
        }
    }
   
`
export {Bound}