import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns: 174px 1fr;
    grid-template-rows: 174px 1fr;
    grid-gap: 20px 30px;
    height: calc(370px - 68px);
    padding: 28px 30px 22px 30px;
    box-sizing: border-box;
    .logo-chain{
        width: 174px;
        height: 174px;
        background: #F4F4F4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar-chain{
            object-fit: contain;
            width: 174px;
            height: 174px;
            border-radius: 10px;
        }
    }
    .info-right-chain{
        background: #FFFFFF;        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        p {
            font-family: "SVN-Gilroy";
            font-style: normal;
            margin: 0;            
        }
        .name-retail {
            font-family: "SVN-Gilroy";
            font-weight: bold;
            font-size: 30px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #15314c;
            width: 475px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        .name-category {
            font-family: "SVN-Gilroy";
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: rgba(21, 49, 76, 0.5);
            margin: 0 0 15px 0;
        }
        .phone-number {
            font-family: "UTM Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #15314c;
            margin: 0 0 12px 0;
        }
    
    }
    .info-bottom-chain{
        width: 100%;
        grid-column: 1/ span 2;
    }
`

export { Bound }