import styled from 'styled-components'
import * as keys from '../../../../assets/constants/keys'

const Bound = styled.div`
    width: 100%;
    overflow-x: hidden;
    overflow-y: overlay;
    height: ${props => props.height? `${props.height}px`:'100%'};
    ${props => props.styled}
    ::-webkit-scrollbar {
        width: 6px;
        background-color:transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #E2E2E2;
        background-clip: padding-box;
        border-radius: 20px;
    }
    .table-scroll{
        padding: ${props => props.type === keys.CHAINS ? '30px 0' : '0 0 30px 0'};
    }
`

const RowItem = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: ${props => props.height};
    margin-bottom: 15px;
    transition: opacity 500ms, height 500ms, padding 500ms;
    .dot{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #2D98F0;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, calc(-50% - 7.5px));
        transition: opacity 250ms;
        z-index:0;
    }
    .dot-selected{
        opacity: 1;
    }
    .line-connect-container{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 18px;
        .circle{
            width: 17px;
            height: 17px;
            border-radius: 50%;
            border: 1px solid #2D98F0;
            background-color: #fff;
            position: absolute;
            top: 25%;
            left: 0;
            z-index:1;
        }
        .line{
            width: 1px;
            height: calc(100% + 15px);
            background-color: #2D98F0;
            position: absolute;
            top: 0;
            left: 9px;
            z-index:0;
        }
    }
    :first-child{
        .line-connect-container{
            .line{
                top: calc(25% + 9px);
            }
        }
    }
    :last-child{
        .line-connect-container{
            .line{
                height: calc(25% + 9px);
            }
        }
    }
    .item-content{
        display: grid;
        align-items: center;
        width: ${props => props.type === keys.CHAINS ? 'calc(100% -  40px)' : '100%'};
        height: 100%;
        background-color: #fff;
        border-radius: 6px;
        align-content:center;
        position: absolute;
        top: 0;
        right: 0;
        transition: width 250ms;
        z-index:1;
        overflow:hidden;
        cursor: pointer;
        div{
            /* height: 100%; */
        }
        p{
            display: flex;
            font-family: SVN-Gilroy;
            font-style: normal;
            line-height: 170%;
        }
        p.p-bold{
            font-weight: 600;
            font-size: 14px;
            color: #051321;
        }
        p.p-thin{
            font-weight: normal;
            font-size: 12px;
            color: #959595;
        }
        p.p-normal{
            font-weight: normal;
            font-size: 14px;
            color: #051321;
        }
        p.p-thin-blue{
            font-weight: normal;
            font-size: 12px;
            color: #2D98F0;
        }
        ${props => props.styled}
    }
    .selected{
        /* left: 15px; */
        width: calc(100% - ${props => props.type === keys.CHAINS ? 60 : 20}px - 4px);
        height: calc(${props => props.height} - ${props => props.type === keys.CHAINS ? 40 : 0}px - 4px);
        border: 2px solid #2D98F0;
    }
    
`

export { Bound, RowItem }