import React from "react";

const IcEKYCOff = (props) => (
  <svg
    {...props}
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.1" cx={16} cy={16} r={16} fill="#959595" />
    <path
      d="M12.8889 9H10.5556C10.143 9 9.74733 9.16389 9.45561 9.45561C9.16389 9.74733 9 10.143 9 10.5556V12.8889M23 12.8889V10.5556C23 10.143 22.8361 9.74733 22.5444 9.45561C22.2527 9.16389 21.857 9 21.4444 9H19.1111M19.1111 23H21.4444C21.857 23 22.2527 22.8361 22.5444 22.5444C22.8361 22.2527 23 21.857 23 21.4444V19.1111M9 19.1111V21.4444C9 21.857 9.16389 22.2527 9.45561 22.5444C9.74733 22.8361 10.143 23 10.5556 23H12.8889"
      stroke="#959595"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IcEKYCOff;
