import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as styles from './index.style'

//image
import IcClockRed from "../../../assets/images/components/IcClockRed";
import IcClockGreen from "../../../assets/images/components/IcClockGreen";
import IcClockYellow from "../../../assets/images/components/IcClockYellow";
//constant
import * as keys from '../../../assets/constants/ui'



const CountupControl = ({timeStart = moment()}) => {
    const [status, setStatus] = useState(keys.NORMAL)
    const [timeWait, setTimeWait] = useState('-')
    let timer;
    useEffect(() => {
        startCountUp()
        return () => {
            clearInterval(timer)
            timer = null;
        }
    }, [])
    
    const getTimeWait = () => {
        const timeNow = moment();
        const timeDiff = timeNow.diff(timeStart);
        const timeDay = moment.duration(timeDiff);
        const timeWaitFormat = Math.floor(timeDay.asHours()) + moment.utc(timeDiff).format(":mm");
        const hours = timeWaitFormat.split(":")[0]
        if(hours < 10)
            setTimeWait(`0${timeWaitFormat}`)
        else
            setTimeWait(timeWaitFormat)
        if(hours >= 12){
            if(status !== keys.URGENT) setStatus(keys.URGENT)
        }
        else if(hours >= 6){
            if(status !== keys.WARNING) setStatus(keys.WARNING)
        }
    };

    const startCountUp = () => {
        timer = setInterval(() => {
            getTimeWait()
        }, 1000);
    }

    return (
        <styles.Bound>
            {
                status === keys.NORMAL?
                <IcClockGreen />
                :
                status === keys.WARNING?
                <IcClockYellow />
                :
                <IcClockRed />
            }
            <p className="p-bold">
                {/* {timeWait ? convertTimeWait(timeWait) : "-"} */}
                {timeWait}
            </p>
        </styles.Bound>
    )
}

CountupControl.propTypes = {

}

export default React.memo(CountupControl)

