import styled from 'styled-components'

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    .animation-container{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    img{
        display:flex;
        width:200px;
        height:200px;
        margin-bottom:30px;
    }
    h1{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 170%;
        color: #1C1C1C;
    }
`

export { Bound }