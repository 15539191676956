import { combineReducers } from "redux";

import listChains from './listChains'
import chainInfos from './chainInfos'

const chainReducer = combineReducers({
    listChains,
    chainInfos
})

export default chainReducer;
