import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    .title-input{
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 128%;
        color: #959595;
        margin: 0 0 10px 10px;
    }
    




`

const Input = styled.input`
    &.value{
        width: 100%;
        height: ${props => props.heightInput ? props.heightInput : "48px"};
        border: 1px solid ${props => props.isInvalid? "#FA8C8C": "#C6D5EB" };
        box-sizing: border-box;
        border-radius: 3px;    
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #000000;
        /* background: #EDEDED; */
        ::placeholder{
            color: rgba(28, 28, 28, 0.5);
        }
        &.cardnumber{
            text-align: center;
            justify-content: center;
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: 0.18em;
            color: #000000;
            ::placeholder{
                color: #000000;
            }
        }
    }

`

export { Bound, Input }