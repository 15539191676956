import styled from 'styled-components'

import { WrapVerticalScroll } from '../TitleColumn/index.style';


const WrapperAdvancedInfos = styled(WrapVerticalScroll)`
    width: 100%;
    height: 100%;    
    box-sizing: border-box;
   .image-type-paper{
        display: flex;
        align-items: center;
        padding-left: ${props => props.paddingLeft ? props.paddingLeft : "58px"};
        .front-side, .back-side {
            display: flex;
            flex-direction: column;
            margin: 0 58px 0 0;
            .info{
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 170%;
                color: #1C1C1C;
                margin: 0 0 22px 0;
            }
            .image-paper{
                width: 271px;
                height: 173px;
                border-radius: 6px;
            }
        }
   }
   .card-number{
        background: #FA8C8C;
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.18em;
        color: #000000;
        padding-left: ${props => props.paddingLeft ? props.paddingLeft : "58px"};        
   }
   .avatar-container{
        display: flex;
        align-items: center;
        padding-left: ${props => props.paddingLeft ? props.paddingLeft : "58px"};
        .avatar-info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            :first-child{
                margin: 0 98px 0 0;
            }
            img{
                width: 100px;
                height: 100px;
                border-radius: 50%;            
                box-sizing: border-box;
                object-fit: cover;
            }
            .card-face{
                display: flex;
                width: 94px;
                height: 94px;
                border-radius: 50%;
                border: 3px solid #2D98F0;
                justify-content: center;
                align-items: center;
                &.error{
                    border: 3px solid #FA8C8C;
                }
                img{
                    display: flex;
                    width: 88px;
                    height: 88px;
                    object-fit: cover;
                }
            }            
        }
        .info-image{
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 128%;
            color: #959595;
            margin: 10px 0 0 0;
        }
    }
    .info-user-verify{
        font-family: SVN-Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #1C1C1C;
        padding-left: ${props => props.paddingLeft ? props.paddingLeft : "58px"};
        &.status{
            color: #00B887;
        }
        &.name{
            color: #2D98F0;
        }
    }
   
`;

export { WrapperAdvancedInfos }