import React, { useCallback, useState, useEffect } from 'react'

// style
// import { Title, WrapperListCondidtion } from './index.style'
import { WrapperListCondidtion/* , Title */ } from '../../../../PicturesCensorship/PictureCensorshipItem/AdvancedItemDetails/ListConditionCheckPass/index.style'

// component
import CheckBoxField from '../../../../../../../Control/CheckBoxField'

// constant
// import { REJECT } from '../../../../../../../../assets/constants/faceCensorShipStatus'
import { REJECT } from '../../../../../../../../assets/constants/faceCensorShipStatus'

const ListConditionEKYC = ({ data, stateCondition, dispatchCondition }) => {

    const [isHaveDuplicateIdCard, setIsHaveDuplicateIdCard] = useState(false)

    const _handleCheck = useCallback((dataItem) => {
        const { listChecked } = stateCondition;

        if (listChecked.includes(dataItem.ID)) {
            dispatchCondition({
                listChecked: listChecked.filter(item => item !== dataItem.ID)
            })
            return
        }
        dispatchCondition({
            listChecked: [...listChecked, dataItem.ID]
        })
    }, [stateCondition.listChecked, dispatchCondition])

    useEffect(() => {
        const { 
            Type, 
            BackInfo
        } = data
        if(Type !== 'CMND') {
            const isHaveDuplicateIdCard = !!data.duplicatesNumber && !!data.duplicatesNumber.length;
            setIsHaveDuplicateIdCard(isHaveDuplicateIdCard)
            return
        }
        
        const isHaveDuplicateIdCard = !!data.duplicatesNumber && !!data.duplicatesNumber.length
        if(isHaveDuplicateIdCard){
            const {
                IssuedPlace,
                Valid
            } = BackInfo || {}
            const { 
                EKYCType, 
                IssueDate: IssueDateDuplicate, 
                IssuedPlace: IssuedPlaceDuplicate
            } = data.duplicatesNumber[0]

            if(EKYCType === Type) {
                setIsHaveDuplicateIdCard(
                    IssuedPlace === IssuedPlaceDuplicate && 
                    Valid === IssueDateDuplicate
                )
                return;
            }
        }
        setIsHaveDuplicateIdCard(false)
    }, [data])

    return (
        <WrapperListCondidtion>
            {/* <Title>Hình ảnh phải đáp ứng các điều kiện sau:</Title> */}
            {
                Object.values(stateCondition.listCondition).map((item, key) =>
                    <CheckBoxField
                        isDisabled={stateCondition.isDisableCheck || (item.CheckDuplicate && isHaveDuplicateIdCard)}
                        key={key}
                        data={stateCondition.status === REJECT ? item.RejectedReason : item.Reason}
                        isChecked={stateCondition.listChecked.includes(item.ID)}
                        onClick={() => _handleCheck(item)}
                    />
                )
            }
            {
                stateCondition.status === REJECT &&
                !!stateCondition.optinalReason &&
                // <CheckBoxField
                //     isDisabled={true}
                //     data={stateCondition.optinalReason}
                //     isChecked={true}
                // />
                <div className="opt-reason">Từ chối vì lý do khác: {stateCondition.optinalReason}</div>
            }
        </WrapperListCondidtion>
    )
}

export default ListConditionEKYC
