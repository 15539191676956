import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'

// styles
import { WrapDatePicker } from './index.styles'
import 'react-datepicker/dist/react-datepicker.css'

// component
import Calendar from '../../../../assets/images/components/Calendar'

// hooks
import useDebounce from '../../../../hook/useDebouce.hook'


const DateRangePicker = ({
    startDateTime,
    endDateTime,
    onChangeStartDateTime,
    onChangeEndDateTime,
    ...props
}) => {
    // const DEFAULT_START_DATE = useMemo(() => moment().subtract(7, 'days').startOf('days').toDate(), [])
    // const DEFAULT_END_DATE = useMemo(() => moment().endOf('days').toDate(), [])
    const MAX_DATE = useMemo(() => moment().endOf('days').toDate(), [])
    const refStartDateTime = useRef(startDateTime)
    const refEndDateTime = useRef(endDateTime)

    const [startDate, setStartDate] = useState(startDateTime);
    const [endDate, setEndDate] = useState(endDateTime);

    // const debounceStartDate = useDebounce(startDate, 500)
    // const debounceEndDate = useDebounce(endDate, 500)

    const _handleChangeStartDateTime = useCallback(
        (date) => {
            setStartDate(date)
            // console.log(moment(date).format('DD/MM/YYYY'))
            // date && onChangeStartDateTime(date)
        },
        [setStartDate],
    )

    const _handleChangeRawStartDateTime = (e) => {
        if (moment(e.target.value, 'DD/MM/YYYY', true).isValid()) {
            const startDate = moment(e.target.value, 'DD/MM/YYYY')
            const endDateTmp = moment(endDate, 'DD/MM/YYYY')
            const diff = startDate.diff(endDateTmp)
            if (diff > 0) return;

            setStartDate(moment(e.target.value, 'DD/MM/YYYY').toDate())
        }
    }

    const _handleChangeEndDateTime = useCallback(
        (date) => {
            setEndDate(date)
            // date && onChangeEndDateTime(date)
        },
        [setEndDate],
    )

    const _handleChangeRawEndDateTime = (e) => {
        if (moment(e.target.value, 'DD/MM/YYYY', true).isValid()) {
            const endDate = moment(e.target.value, 'DD/MM/YYYY')
            const diff = endDate.diff(MAX_DATE)
            if (diff > 0) return;
            const startDateTmp = moment(startDate, 'DD/MM/YYYY')
            const diffStartDate = startDateTmp.diff(endDate)
            if (diffStartDate > 0) return;

            setEndDate(moment(e.target.value, 'DD/MM/YYYY').toDate())
        }
    }

    useEffect(() => {
        refStartDateTime.current = startDateTime
    }, [startDateTime])

    useEffect(() => {
        refEndDateTime.current = endDateTime
    }, [endDateTime])

    useEffect(() => {
        if (refStartDateTime.current === startDate) return;
        onChangeStartDateTime(startDate)
    }, [startDate, onChangeStartDateTime])

    useEffect(() => {
        if (refEndDateTime.current === endDate) return;
        onChangeEndDateTime(endDate)
    }, [endDate, onChangeEndDateTime])

    return (
        <WrapDatePicker>
            <Calendar className="calendar" />
            <DatePicker
                selected={startDate}
                onChange={_handleChangeStartDateTime}
                onChangeRaw={_handleChangeRawStartDateTime}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                className="input-date first-input"
                {...props}
            />
            -
            <DatePicker
                selected={endDate}
                onChange={_handleChangeEndDateTime}
                onChangeRaw={_handleChangeRawEndDateTime}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={MAX_DATE}
                className="input-date"
                {...props}
            />
        </WrapDatePicker>
    )
}

export default DateRangePicker
