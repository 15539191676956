import React from 'react'

const IcDollar = ({ colorState }) => <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
  <circle opacity="0.1" cx="15.9999" cy={16} r={16} fill={colorState} />
  <path d="M15.8181 9V23" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M18.9999 11.5455H14.2272C13.6365 11.5455 13.07 11.7801 12.6523 12.1978C12.2346 12.6155 11.9999 13.182 11.9999 13.7727C11.9999 14.3635 12.2346 14.93 12.6523 15.3477C13.07 15.7654 13.6365 16 14.2272 16H17.409C17.9997 16 18.5663 16.2347 18.9839 16.6524C19.4016 17.0701 19.6363 17.6366 19.6363 18.2273C19.6363 18.818 19.4016 19.3845 18.9839 19.8022C18.5663 20.2199 17.9997 20.4546 17.409 20.4546H11.9999" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
</g>
<defs>
  <clipPath id="clip0">
    <rect width={32} height={32} fill="white" />
  </clipPath>
</defs>
</svg>

export default IcDollar
