import styled from 'styled-components'

const WrapperNoteItem = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 20px 0 0 0px;
  box-sizing: border-box;
  /* &:first-child {
    margin: 30px 0 0 0px;
  } */
  .avatar-container{
    margin-top: 19px;
    .avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .message-container{
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    .info__user {
      width: fit-content;
      font-weight: normal;
      font-size: 10px;
      display: flex;
      align-items: center;
      color: #959595;
      .name {
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #1c1c1c;
      }
      .time {
        line-height: 2;
      }
    }   
    .message-wrapper{        
      font-family: 'UTM Avo';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #1C1C1C;  
      max-width: 457px;
      padding: 0 17px 0 0;
      word-break: break-word;
      .line{
        display: inline-block;
        width: fit-content;
        height: 24px;
        border-radius: 1px 20px 20px 1px;     
        font-family: 'UTM Avo';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 0 6px;
        margin: 0 10px 0 0; 
      }
    }
  } 

`;

export {
  WrapperNoteItem
}