import React from 'react'

// styles
import { WrapPopup } from './index.styles'
import { BgWrap } from '../../../../../Control/Popup/index.styles'

// component
import Popup from '../../../../../Control/Popup'
import ContentPopupAddStore from './ContentPopupAddStore'

const PopupAddStore = ({ handleClose }) => {
    return (
        <WrapPopup>
            <div className="bg"></div>
            <Popup
                title="thêm cửa hàng mới"
                handleClose={handleClose}
            >
                <ContentPopupAddStore handleClose={handleClose} />
            </Popup>
        </WrapPopup>
    )
}

export default PopupAddStore
