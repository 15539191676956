import React from 'react'

const IcPaymentHistoryOff = (props) => <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx={16} cy={16} r={16} fill="#959595" />
<g clipPath="url(#clip0)">
  <path d="M16 8.66666V23.3333" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M19.3333 11.3333H14.3333C13.7145 11.3333 13.121 11.5792 12.6834 12.0168C12.2458 12.4543 12 13.0478 12 13.6667C12 14.2855 12.2458 14.879 12.6834 15.3166C13.121 15.7542 13.7145 16 14.3333 16H17.6667C18.2855 16 18.879 16.2458 19.3166 16.6834C19.7542 17.121 20 17.7145 20 18.3333C20 18.9522 19.7542 19.5457 19.3166 19.9833C18.879 20.4208 18.2855 20.6667 17.6667 20.6667H12" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
</g>
<defs>
  <clipPath id="clip0">
    <rect width={16} height={16} fill="white" transform="translate(8 8)" />
  </clipPath>
</defs>
</svg>

export default IcPaymentHistoryOff
