import React from 'react'

const IcLogOut = (props) => <svg {...props} width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
  <circle opacity="0.1" cx="16.0001" cy={16} r={16} fill="#C6D5EB" />
  <path d="M13.6668 23H10.5557C10.1431 23 9.74746 22.8361 9.45573 22.5444C9.16401 22.2527 9.00012 21.857 9.00012 21.4444V10.5556C9.00012 10.143 9.16401 9.74733 9.45573 9.45561C9.74746 9.16389 10.1431 9 10.5557 9H13.6668" stroke="#C6D5EB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M19.1112 19.8889L23.0001 16L19.1112 12.1111" stroke="#C6D5EB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M23.0001 16H13.6667" stroke="#C6D5EB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
</g>
<defs>
  <clipPath id="clip0">
    <rect width={32} height={32} fill="white" transform="translate(0.00012207)" />
  </clipPath>
</defs>
</svg>

export default IcLogOut
