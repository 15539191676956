import React from 'react'

const IcRejectRed = (props) => {
    return (
        <svg {...props} width={15} height={16} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M7.5 0.5C3.36433 0.5 0 3.86433 0 8C0 12.1357 3.36433 15.5 7.5 15.5C11.6357 15.5 15 12.1357 15 8C15 3.86433 11.6357 0.5 7.5 0.5Z" fill="#FA8C8C" />
                <path d="M7.51214 10.0171C7.21811 10.0171 6.97244 9.79318 6.94519 9.50042L6.5 4.71692V3.5C6.5 2.94772 6.94772 2.5 7.5 2.5C8.05228 2.5 8.5 2.94772 8.5 3.5V4.71692L8.07934 9.49757C8.05347 9.79156 7.80726 10.0171 7.51214 10.0171ZM7.27093 12.5C6.88478 12.5 6.57175 12.187 6.57175 11.8008C6.57175 11.4147 6.88478 11.1016 7.27093 11.1016H7.72907C8.11522 11.1016 8.42825 11.4147 8.42825 11.8008C8.42825 12.187 8.11522 12.5 7.72907 12.5H7.27093Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width={15} height={15} fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default IcRejectRed
