import styled, { css } from 'styled-components'

const WrapFilter = styled.div`
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    background: #F4F4F4;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    .field {
        height: 42px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 6px;
    }
`;


const WrapFilterByText = styled(WrapFilter)`
    .field {
        &.search-field {
            background: #FFFFFF;
            border-radius: 0 0 6px 6px;
        }
    }
`;

const extendStylesCheckbox = css`
    .text-condition {
        font-size: 12px;
        color: #212121;
    }
`;

const extendStylesInput = css`
    border: 1px solid #F4F4F4;
    input {
        font-size: 12px;
        ::placeholder {
            font-size: 12px;
        }
    }
`;

export {
    WrapFilter,
    WrapFilterByText,
    extendStylesCheckbox,
    extendStylesInput
}