import React, { Fragment } from 'react'

// styles
import { WrapTransDetailsFlow, Step, extendStylesStep1, extendStylesStep2, extendStylesStep3, extendStylesStep5, extendStylesStep6, extendStylesStep6_1, extendStylesStep6_1_2, extendStylesStep6_2, extendStylesStep4 } from './index.styles'

// component
import IcAgainBgGreen from '../../../../../assets/images/components/IcAgainBgGreen'
import IcAgainBgRed from '../../../../../assets/images/components/IcAgainBgRed'
import VerifyFace from './VerifyFace'

//constant
import * as transactionOrders from '../../../../../assets/constants/transactionStepOrder'
//tools 
import { convertDateTime } from '../../../../../assets/helper/tools'


const TransactionDetailsFlow = ({ data }) => {

    const mapTransactionStep = () => {
        if (!data?.StepOrder || !data?.Steps) return
        return data.StepOrder.map((item, i) => {
            let isHideLine = (i === data.StepOrder.length - 1) ? true : false
            switch (item) {
                case transactionOrders.DISPLAY_REQUEST: {
                    return (
                        <Step key={i} step={i + 1} paddingLeft={40} isHiddenLine={isHideLine} extendStyles={extendStylesStep1}>
                            <div className="title">Hiển thị yêu cầu thanh toán</div>
                            <div className="time-wrap">
                                {data?.Steps?.DisplayRequest?.CreateTime ? convertDateTime(data?.Steps?.DisplayRequest.CreateTime, "DD/MM/YYYY") : '-'}
                                <span className="time">
                                    {data?.Steps?.DisplayRequest?.CreateTime ? convertDateTime(data?.Steps?.DisplayRequest.CreateTime, "HH:mm:ss") : ''}
                                </span>
                            </div>
                        </Step>
                    )
                }
                case transactionOrders.ACCEPT_REQUEST: {
                    return (
                        <Step key={i} step={i + 1} paddingLeft={40} isHiddenLine={isHideLine} extendStyles={extendStylesStep2}>
                            <div className="title">Người dùng xác nhận thanh toán</div>
                            <div className="time-wrap">
                                {data?.Steps?.AcceptRequest?.CreateTime ? convertDateTime(data?.Steps?.AcceptRequest.CreateTime, "DD/MM/YYYY") : '-'}
                                <span className="time">
                                    {data?.Steps?.AcceptRequest?.CreateTime ? convertDateTime(data?.Steps?.AcceptRequest.CreateTime, "HH:mm:ss") : ''}
                                </span>
                            </div>
                        </Step>
                    )
                }
                case transactionOrders.VERIFY_FACE: {
                    return (
                        <VerifyFace 
                            key={i}
                            data={data}
                            index={i}
                            isHiddenLine={isHideLine}
                            extendStyles={extendStylesStep3}
                        />
                    )
                }
                case transactionOrders.VERIFY_PIN: {
                    return (
                        <Step key={i} step={i + 1} paddingLeft={40} isHiddenLine={isHideLine} extendStyles={extendStylesStep4}>
                            {
                                data?.Steps?.VerifyPin?.CheckedPins?.map((pinResult, j) => {
                                    return (
                                        <div key={j} className={j !== 0 ? "again-step" : ""}>
                                            {
                                                j > 0 ?
                                                    pinResult?.ErrorCode === 0 ?
                                                        <IcAgainBgGreen /> : <IcAgainBgRed />
                                                    :
                                                    undefined
                                            }
                                            <div>
                                                <div className={`detail-item ${pinResult?.ErrorCode === 0 ? 'success' : 'failed'}`}>
                                                    {pinResult?.ErrorCode === 0 ? "Thành công" : "Thất bại"}
                                                </div>
                                                <div className="title">Nhập mã PIN</div>
                                                <div className="time-wrap">
                                                    {pinResult?.CreateTime ? convertDateTime(pinResult.CreateTime, "DD/MM/YYYY") : '-'}
                                                    <span className="time">
                                                        {pinResult?.CreateTime ? convertDateTime(pinResult.CreateTime, "HH:mm:ss") : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Step>
                    )
                }
                case transactionOrders.SELECT_AND_PAID: {
                    return data.Steps?.SelectAndPaid?.map((selectAndPaidItem, j) => {
                        let isFailed = true
                        let isHiddenLine = (j === data.Steps?.SelectAndPaid?.length - 1) ? true : false
                        let firstStep = i + (j * 2 + 1)
                        let secondStep = firstStep + 1

                        for (let l = 0; l < selectAndPaidItem?.Paid?.Result?.length; l++) {
                            if (selectAndPaidItem?.Paid?.Result[l].ErrorCode === 0) {
                                isFailed = false
                                break;
                            }
                        }
                        return (
                            <Fragment key={j}>
                                <Step step={firstStep} paddingLeft={40} isHiddenLine={selectAndPaidItem?.Paid ? false : true} extendStyles={extendStylesStep5}>
                                    <div className="card-type-wrap">
                                        <div className='.detail-item'>
                                            {selectAndPaidItem?.SelectCard?.CardNumber || "-"}
                                        </div>
                                        <div className='title'>
                                            {`Thẻ thanh toán - ${selectAndPaidItem?.SelectCard?.SelectType === "AUTO" ? "Chọn tự động" : "Người dùng chọn"}`}
                                        </div>
                                        <div className="time-wrap">
                                            {selectAndPaidItem?.SelectCard?.CreateTime ? convertDateTime(selectAndPaidItem?.SelectCard.CreateTime, "DD/MM/YYYY") : '-'}
                                            <span className="time">
                                                {selectAndPaidItem?.SelectCard?.CreateTime ? convertDateTime(selectAndPaidItem?.SelectCard.CreateTime, "HH:mm:ss") : ''}
                                            </span>
                                        </div>
                                    </div>
                                </Step>
                                <Step step={secondStep} paddingLeft={40} extendStyles={extendStylesStep6} lineStyles={'dashed'} isHiddenLine={isHiddenLine} isFailed={isFailed}>
                                    {
                                        selectAndPaidItem?.Paid?.IsNapas ?
                                            //Render napas transaction
                                            <div key={j} className={j !== 0 ? "again-step" : ""}>
                                                <div className="napas-type-wrap">
                                                    <div className="time-wrap">
                                                        {selectAndPaidItem?.Paid?.CreateTime ? convertDateTime(selectAndPaidItem?.SelectCard.CreateTime, "DD/MM/YYYY") : '-'}
                                                        <span className="time">
                                                            {selectAndPaidItem?.Paid?.CreateTime ? convertDateTime(selectAndPaidItem?.SelectCard.CreateTime, "HH:mm:ss") : ''}
                                                        </span>
                                                    </div>
                                                    <div className="title">
                                                        {selectAndPaidItem?.Paid?.IsNapas === true ? "Thanh toán qua form (NAPAS)" : "Thanh toán trực tiếp"}
                                                    </div>
                                                    <div className="detail-item">
                                                        Merchant ID: {selectAndPaidItem?.Paid?.MerchantID}
                                                    </div>
                                                </div>
                                                {
                                                    selectAndPaidItem?.Paid?.Result?.map((resultItem, m) => {
                                                        return (
                                                            <Step key={m} step={i + 2 + ((m + 1) * 0.1)} paddingLeft={20}
                                                                extendStyles={m + 1 === selectAndPaidItem?.Paid?.Result?.length ? extendStylesStep6_1_2 : extendStylesStep6_1}
                                                                isFailed={resultItem?.ErrorCode === 0 ? false : true}
                                                                isHiddenLine={m + 1 === selectAndPaidItem?.Paid?.Result?.length ? true : false}
                                                            >
                                                                <div className="napas-type-wrap">
                                                                    <div className={`detail-item ${resultItem?.ErrorCode === 0 ? 'success' : 'failed'}`}>
                                                                        {/* {resultItem?.ErrorCode === 0 ? "Thành công" :
                                                                        resultItem?.NapasErrorCode === "" ? "Thất bại" : resultItem?.NapasErrorCode} */}
                                                                        {resultItem?.ErrorCode === 0 ? "Thành công" : "Thất bại"}
                                                                    </div>
                                                                    <div className="title">
                                                                        {
                                                                            resultItem?.NapasResultType === 'RESULT_FORM' ?
                                                                                "Kết quả form"
                                                                                :
                                                                                resultItem?.NapasResultType === 'RESULT_IPN' ?
                                                                                    "Kết quả IPN"
                                                                                    :
                                                                                    resultItem?.NapasResultType === 'RESULT_PING' &&
                                                                                    "Kết quả Polling"
                                                                        }
                                                                    </div>
                                                                    <div className="time-wrap">
                                                                        {resultItem?.CreateTime ? convertDateTime(resultItem.CreateTime, "DD/MM/YYYY") : '-'}
                                                                        <span className="time">
                                                                            {resultItem?.CreateTime ? convertDateTime(resultItem.CreateTime, "HH:mm:ss") : ''}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Step>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            //Render normal transaction
                                            <div key={j}>
                                                <div className="normal-type-wrap">
                                                    <div className='time-wrap'>
                                                        {selectAndPaidItem?.Paid?.CreateTime ? convertDateTime(selectAndPaidItem?.Paid.CreateTime, "DD/MM/YYYY") : '-'}
                                                        <span className="time">
                                                            {selectAndPaidItem?.Paid?.CreateTime ? convertDateTime(selectAndPaidItem?.Paid.CreateTime, "HH:mm:ss") : ''}
                                                        </span>
                                                    </div>
                                                    <div className="title">
                                                        Thanh toán trực tiếp
                                                </div>
                                                    <div className="detail-item">
                                                        Merchant ID: {selectAndPaidItem?.Paid?.MerchantID}
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Step>
                            </Fragment>
                        )
                    })
                }
            }
        })
    }
    return (
        <WrapTransDetailsFlow>
            {mapTransactionStep()}
        </WrapTransDetailsFlow>
    )
}

export default TransactionDetailsFlow
