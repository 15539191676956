import styled, { css } from "styled-components";

const Bound = styled.div(
  ({ styledProps, expand }) =>
    css`
      position: fixed;
      z-index: 100000;
      /* top: 0;
      right: 0;
      left: 0;
      bottom: 0; */
      left: 80px;
      bottom: 120px;
      background: rgba(28, 28, 28, 0.9);
      backdrop-filter: blur(4px);
      display: flex;
      justify-content: center;
      align-items: center;
      ${!expand &&
      css`
        display: none;
        overflow: none;
      `}
      .container {
        display: flex;
        flex-direction: column;
        width: 510px;
        max-height: 203px;
        height: max-content;
        background-color: #ffffff;
        /* box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.1); */
        border-radius: 3px 3px 0 3px;
        color: #15314C;
        position: relative;
        &-close {
          position: absolute;
          top: 20px;
          right: 20px;
          width: 12px;
          height: 12px;
          background-color: transparent;
          border: none;
          padding: 0;
          cursor: pointer;
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
      .title {
        margin-top: 30px;
        margin-left: 25px;
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #15314C;
      }
      .content {
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 170%;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-left: 25px;
        margin-bottom: 38px;
        color: #15314C;
      }
      .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        cursor: pointer;       
      }
      ${styledProps && styledProps}
    `

);
const styleButtonLeft = `
    width: 50%;    
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;    
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #184DA2;
    background-color: #F4F4F4;
    border-radius: 0px 0px 0px 3px;
    box-sizing: border-box;
    border: none;
`
const styleButtonRight = `    
    width: 50%;    
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "SVN-Gilroy";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;    
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #184DA2;
    border-radius: 0px 0px 3px 0px;
    box-sizing: border-box;
    border: none;
`
export { Bound, styleButtonLeft, styleButtonRight }
