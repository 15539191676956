import React from 'react'

const IcUserPlus = ({ colorState }) => <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
  <circle opacity="0.1" cx="15.9999" cy={16} r={16} fill={colorState} />
  <path d="M18.5454 21.4545V20.1818C18.5454 19.5067 18.2772 18.8593 17.7998 18.3819C17.3225 17.9045 16.675 17.6364 15.9999 17.6364H11.5454C10.8703 17.6364 10.2229 17.9045 9.74549 18.3819C9.26812 18.8593 8.99994 19.5067 8.99994 20.1818V21.4545" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M13.7727 15.0909C15.1785 15.0909 16.3181 13.9513 16.3181 12.5455C16.3181 11.1396 15.1785 10 13.7727 10C12.3669 10 11.2272 11.1396 11.2272 12.5455C11.2272 13.9513 12.3669 15.0909 13.7727 15.0909Z" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M21.0908 13.1818V16.9999" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  <path d="M22.9999 15.0909H19.1818" stroke={colorState} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
</g>
<defs>
  <clipPath id="clip0">
    <rect width={32} height={32} fill="white" />
  </clipPath>
</defs>
</svg>

export default IcUserPlus
