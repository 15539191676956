import styled from 'styled-components';


const DropArea = styled.div`
    box-sizing: border-box;
    border: 1.5px dashed ${props => props.isError? '#F03B3B' : '#C6D5EB'};
    /* border: 1.5px dashed #C6D5EB; */
    background: #FFFFFF;
    width: 272px;
    height: 272px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: SVN-Gilroy;

    

    .drag-logo {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 170%;
        letter-spacing: 0.05px;
        color: #1C1C1C;
        margin-top: 14px;
    }

    .choose-local {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 170%;
        color: #1C1C1C;
        margin-top: 8px;

        span {
            color: #1279DA;

            :hover {
                cursor: pointer;
            }
        }
    }

    .note {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 170%;
        color: #BABABA;
        margin-top: 10px;
        text-align: center;
    }
`;

const WrapImg = styled.div`
    box-sizing: border-box;
    width: 272px;
    height: 272px;
    background: #FFFFFF;
    position: relative;
    border: 1px solid #EDEDED;


    .delete-img {
        position: absolute;
        top: 10px;
        right: 10px; 
        :hover {
            cursor: pointer;
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

export { DropArea, WrapImg } 