import React, { Component } from "react";
import * as style from "./index.style";
//component
import IcSmartPhone from "../../../assets/images/components/Ic_SmartPhone";

const RowIconDevice = ({ data }) => {
  return (
    <style.RowIconDevice>     
      <div className="row-icon">
        <IcSmartPhone className="icon" />
        <span className="total">
          {data &&
          (data.TotalOnlineDevices || data.TotalOnlineDevices === 0) &&
          (data.TotalOfflineDevices || data.TotalOfflineDevices === 0)
            ? data.TotalOnlineDevices + data.TotalOfflineDevices
            : "0"}
        </span>
      </div>
      <div className="row-icon">
        <p className="icon-circle-online"></p>
        <span className="content-online">
          {`${
            data && data.TotalOnlineDevices ? data.TotalOnlineDevices : "0"
          }   Online`}
        </span>
      </div>
      <div className="row-icon">
        <p className="icon-circle-offline"></p>
        <span className="content-offline">
          {`${
            data && data.TotalOfflineDevices ? data.TotalOfflineDevices : "0"
          }   Offline`}
        </span>
      </div>
    </style.RowIconDevice>
  );
};

export default RowIconDevice;
