import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import * as styles from './index.style'

//data
import { user_censorship } from '../../../../../data/mockData'
//tools
import { convertDateTime } from '../../../../../assets/helper/tools'
import { apiAvatarImage } from '../../../../../assets/constants/host';
//image 
// import avatar_verify_default from '../../../../../assets/images/avatar_verify_default.svg'
import AvatarVerifyDefault from '../../../../../assets/images/components/AvatarVerifyDefault';


const checkDefaultUserInfos = (phoneNum, username, email, dateOfBirth, gender) => {
    const usernameIsDefault = username === phoneNum
    const emailIsDefault = email === `user${phoneNum}@wee.vn`
    const dateOfBirthIsDefault = convertDateTime(dateOfBirth, "DD/MM/YYYY") === "01/01/1990"
    const genderIsDefault = gender === 3
    return usernameIsDefault && emailIsDefault && dateOfBirthIsDefault && genderIsDefault
}

const UserInformation = ({ userCensorship = {
    ID: "",
    CreateTime: "",
    UpdateTime: "",
    FullName: "",
    PhoneNumber: "",
    WeeID: "",
    DOB: "",
    Gender: ""
} }) => {
    const { t } = useTranslation('common')
    const [data, setData] = useState(userCensorship)
    const [isErrorAvatar, setIsErrorAvatar] = useState(false)

    // const { userCensorshipReducer } = useSelector(state => ({
    //     userCensorshipReducer: state.userCensorshipReducer
    // }))

    const { detailsAccount } = useSelector(state => ({
        detailsAccount: state.userCensorship.detailsUser.detailsAccount
    }))

    // useEffect(() => {
    //     if (!userCensorshipReducer.userCensorshipDetail) return
    //     if (userCensorshipReducer.userCensorshipDetail.id.length === 0) return
    //     setData(userCensorshipReducer.userCensorshipDetail)
    //     return () => {

    //     }
    // }, [userCensorshipReducer.userCensorshipDetail])

    useEffect(() => {
        // if(!userCensorshipReducer.userCensorshipDetail) return
        // if(userCensorshipReducer.userCensorshipDetail.id.length === 0) return
        setData(detailsAccount)
        return () => {

        }
    }, [JSON.stringify(detailsAccount)])

    useEffect(() => {
        if (!detailsAccount?.ID) return;
        setIsErrorAvatar(false)
    }, [detailsAccount?.ID])

    const onImageErr = () => {
        // let image = document.getElementById(`user-information-id`)
        // if (image) image.src = <AvatarVerifyDefault />
        setIsErrorAvatar(true)
    }

    const avatarSrc = useMemo(() => data && data.ID ? apiAvatarImage(data.ID) : "", [JSON.stringify(data)])

    const isDefaultInfosUser = checkDefaultUserInfos(data.PhoneNumber, data.Username, data.Email, data.DOB, data.Gender)

    return (
        <styles.Bound isLocked={data.IsLock}>
            <div className="infomation-left">
                {
                    isErrorAvatar ?
                        <AvatarVerifyDefault
                            className="avatar-user"
                            id='user-information-id'
                        />
                        :
                        <img
                            className="avatar-user"
                            src={avatarSrc}
                            alt="avatar-user"
                            id={`user-information-id`}
                            onError={onImageErr}
                        />
                }
                <div className="infomation-avatar">
                    <p className="title-info">{t('user_detail.date_register')}</p>
                    <p className="content-info">
                        {data.CreateTime ?
                            convertDateTime(data.CreateTime, "DD/MM/YYYY")
                            : "-"
                        }
                        <span>
                            {data.CreateTime ?
                                convertDateTime(data.CreateTime, "HH:mm")
                                : ""
                            }
                        </span>
                    </p>
                </div>
                <div className="infomation-avatar">
                    <p className="title-info">{t('user_detail.last_active')}</p>
                    <p className="content-info">
                        {data.LastActivity ?
                            convertDateTime(data.LastActivity, "DD/MM/YYYY")
                            : "-"
                        }
                        <span>
                            {data.LastActivity ?
                                convertDateTime(data.LastActivity, "HH:mm")
                                : ""
                            }
                        </span>
                    </p>
                </div>
            </div>
            <div className="infomation-right">
                <div className="name-container">
                    <p
                        className="name-user"
                        data-toogle='tooltip'
                        title={data && data.FullName ? data.FullName : ''}
                    >
                        {data && data.FullName ? data.FullName : "-"}
                    </p>
                    <div className="phone-user-wrap">
                        <p
                            className="username"
                            data-toogle='tooltip'
                            title={!isDefaultInfosUser ? `@${data.Username}` : ''}
                        >
                            {!isDefaultInfosUser ? `@${data.Username}` : "-"}
                        </p>
                        <span>-</span>
                        <p className="phone-user">
                            {data && data.PhoneNumber ? data.PhoneNumber : "-"}
                        </p>
                    </div>
                </div>
                <div className="gender-container">
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.email')}</p>
                        <p
                            className="content-info email"
                            data-toogle='tooltip'
                            title={!isDefaultInfosUser ? data.Email : ''}
                        >
                            {!isDefaultInfosUser ? data.Email : "-"}
                        </p>
                    </div>
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.birthday')}</p>
                        <p className="content-info">
                            {!isDefaultInfosUser ? convertDateTime(data.DOB, "DD/MM/YYYY") : "-"}
                        </p>
                    </div>
                    <div className="infomation-avatar">
                        <p className="title-info right">{t('user_detail.gender')}</p>
                        <p className="content-info">
                            {isDefaultInfosUser 
                                ? '-'
                                : data.Gender === 0
                                    ? t('user_detail.gender_default')
                                    : data.Gender === 1
                                        ? t('user_detail.gender_male')
                                        : data.Gender === 2
                                            ? t('user_detail.gender_female')
                                            : data.Gender === 3
                                                ? t('user_detail.gender_other')
                                                : "-"
                            }
                        </p>
                    </div>
                </div>
            </div>
        </styles.Bound>
    )
}

export default React.memo(UserInformation)
