import React from 'react'
//style
import * as styles from './index.style'
//component
import DropDownFilterTransAccounts from './DropDownFilterTransAccounts'
import DropDownFilterTransCodes from './DropDownFilterTransCodes'
import DropDownFilterTransMoneys from './DropDownFilterTransMoneys'
import DropDownFilterTransTimes from './DropDownFilterTransTimes'
import DropDownFilterTransOrderCodes from './DropDownFilterTransOrderCodes'
import DropDownFilterTransStores from './DropDownFilterTransStores'

const HeaderTransHistory = () => {
    return (
        <styles.Bound>
            <div className="filter" />
            <div className="filter-wrap-order-code">
                <DropDownFilterTransOrderCodes />
            </div>
            <div className="filter-wrap">
                <DropDownFilterTransTimes />
            </div>
            <div className="filter-wrap">
                <DropDownFilterTransMoneys />
            </div>
            <div className="filter-wrap">
                <DropDownFilterTransAccounts />
            </div>
            <div className="filter-wrap">
                <DropDownFilterTransStores />
            </div>
            <div className="filter-wrap">
                <DropDownFilterTransCodes />
            </div>

        </styles.Bound>
    )
}

export default HeaderTransHistory
