import styled, { css } from 'styled-components'

const WrapUserDuplicateItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .avatar-diff{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: contain;
        /* border: 1.5px solid #ffffff; */
        border: 1px solid #FA8C8C;
        margin: 0 10px 0 0;
        box-sizing: border-box;
        background: gray;
        object-fit: cover;
    }
    .user-coincide-info{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .name{
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 170%;
            color: #051321;
            max-width: 20ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .info{
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 170%;
                color: #2D98F0;
                margin: 0 10px 0 0;
                max-width: 19ch;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
`;

const styleButtonCompare = css`
    background-color: #ffffff;
    border: 1px solid #2D98F0;
    box-sizing: border-box;
    border-radius: 22px;
    width: 118px;
    height: 32px;
    font-family: SVN-Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    color: #2D98F0;
    margin: 5px 0 0 0;
`

export {
    WrapUserDuplicateItem,
    styleButtonCompare
}