import React from 'react'

const IcSelected = (props) => {
    return (
        <svg {...props} width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width={15} height={15} rx="7.5" stroke="#2D98F0" />
            <rect x="2.5" y="2.5" width={11} height={11} rx="5.5" fill="#2D98F0" stroke="#2D98F0" />
        </svg>
    )
}

export default IcSelected
