import React from 'react'

const IcAgainBgGreen = (props) => {
    return (
        <svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={15} cy={15} r={15} fill="#09AC10" />
            <path d="M22.3334 9.66797V13.668H18.3334" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M7.66663 20.332V16.332H11.6666" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33996 13.0004C9.67807 12.0449 10.2527 11.1906 11.0103 10.5173C11.7678 9.844 12.6836 9.37355 13.6721 9.14988C14.6607 8.92621 15.6898 8.9566 16.6634 9.23823C17.637 9.51985 18.5235 10.0435 19.24 10.7604L22.3333 13.667M7.66663 16.3337L10.76 19.2404C11.4765 19.9572 12.3629 20.4809 13.3365 20.7625C14.3101 21.0441 15.3392 21.0745 16.3278 20.8509C17.3163 20.6272 18.2321 20.1568 18.9896 19.4834C19.7472 18.8101 20.3218 17.9559 20.66 17.0004" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcAgainBgGreen
