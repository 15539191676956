import React from 'react'

// image
import IcCheckBox from '../../../assets/images/components/IcCheckBox'
import IcCheckBoxOutlineBlank from '../../../assets/images/components/IcCheckBoxOutlineBlank'

// style
import { WrapperCheckBoxItem } from './index.style'

const CheckBoxField = ({
    isDisabled,
    data,
    isChecked,
    onClick,
    extendStyles,
    ...props
}) => {
    return (
        <WrapperCheckBoxItem onClick={!isDisabled ? onClick: undefined} extendStyles={extendStyles} {...props}>
            {   
                isChecked
                    ? <IcCheckBox className={isDisabled ? "check-box disabled" : "check-box"} />
                    : <IcCheckBoxOutlineBlank className={isDisabled ? "check-box disabled" : "check-box"} />
            }
            <div className="text-condition">{data}</div>
        </WrapperCheckBoxItem>
    )
}

export default CheckBoxField
