import Axios from 'axios'

// host
import { host } from '../../../../assets/constants/host'
import { apiGetReasons } from '../faces/action'

// helper
import mapErrors from '../../../../assets/helper/mapErrors'

// constant
import * as types from './types'
import { CODE_SUCCESS } from '../../../../assets/constants/codeResponse'
import { LOCK } from '../../../../assets/constants/keys'


const apiGetUserInfos = `${host}/backoffice/get-user-info`
const apiLockAccount = `${host}/backoffice/lock-account`
const apiUnlockAccount = `${host}/backoffice/unlock-account`

export const getUserInfos = ({ UserID }) => async dispatch => {
    const dataRequest = JSON.stringify({
        UserID
    })

    try {
        const res = await Axios.post(apiGetUserInfos, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess) {
            dispatch({
                type: types.GET_USER_INFOS,
                payload: {
                    ...data
                }
            })
        }
        else {
            // console.dir(data)
            dispatch({
                type: types.GET_USER_INFOS_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_USER_INFOS_ERR))
    }
}

export const getReasonsLock = () => async dispatch => {
    const dataRequest = JSON.stringify({
        Type: LOCK
    })

    try {
        const res = await Axios.post(apiGetReasons, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            const listReasons = data.Reasons || []
            dispatch({
                type: types.GET_LIST_REASON_LOCK,
                payload: {
                    listReasons
                }
            })
        }
        else{
            // console.dir(data)
            dispatch({
                type: types.GET_LIST_REASON_LOCK_ERR,
                payload: {
                    ...data
                }
            })
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(mapErrors(err, types.GET_LIST_REASON_LOCK_ERR))
    }
}

export const lockAccount = (
    { 
        UID, 
        LockedReason, 
        LockReasonID 
    }, 
    successCallback, 
    errorCallback
) => async (dispatch, getState) => {
    const store = getState()
    const { isLoadingLockAccount } = store.userCensorship.detailsUser.detailsAccount
    if(isLoadingLockAccount) return;

    dispatch({ type: types.LOCK_ACCOUNT_START })

    const dataRequest = JSON.stringify({
        UID, 
        LockedReason, 
        LockReasonID
    })

    try {
        const res = await Axios.post(apiLockAccount, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS
        if(isSuccess) {
            dispatch({
                type: types.LOCK_ACCOUNT,
            })
            successCallback && successCallback()
        }
        else {
            dispatch({
                type: types.LOCK_ACCOUNT_ERR,
                payload: {
                    ...data
                }
            })
            errorCallback && errorCallback()
        }
    }
    catch(err) {
        errorCallback && errorCallback()
        dispatch(mapErrors(err, types.LOCK_ACCOUNT_ERR))
    }
    dispatch({ type: types.LOCK_ACCOUNT_END })
}

export const unlockAccount = (
    { 
        UID, 
        UnlockReason 
    }, 
    successCallback, 
    errorCallback
) => async (dispatch, getState) => {
    const store = getState()
    const { isLoadingUnLockAccount } = store.userCensorship.detailsUser.detailsAccount
    if(isLoadingUnLockAccount) return;

    dispatch({ type: types.UNLOCK_ACCOUNT_START })

    const dataRequest = JSON.stringify({
        UID,
        UnlockReason
    })

    try {
        const res = await Axios.post(apiUnlockAccount, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === CODE_SUCCESS;
        if(isSuccess){
            dispatch({
                type: types.UNLOCK_ACCOUNT
            })
            successCallback && successCallback()
        }
        else{
            dispatch({
                type: types.UNLOCK_ACCOUNT_ERR,
                payload: {
                    ...data
                }
            })
            errorCallback && errorCallback()
        }
    }
    catch(err) {
        errorCallback && errorCallback()
        dispatch(mapErrors(err, types.UNLOCK_ACCOUNT_ERR))
    }
    dispatch({ type: types.UNLOCK_ACCOUNT_END })
}

export const clearDetailsAccount = () => ({
    type: types.CLEAR_DETAILS_ACCOUNT
})
