import * as types from "./types";

const intitialState = {
  loggedIn: false,
  dataLogin: null,
  error: "",
  isLoadingLogin: false,
  isLoadingLogout: false
};

const authReducer = (state = intitialState, action) => {
  switch (action.type) {
    case types.AUTH_LOGIN_START: {
      return {
        ...state,
        isLoadingLogin: true
      }
    }

    case types.AUTH_LOGIN_END: {
      return {
        ...state,
        isLoadingLogin: false
      }
    }

    case types.AUTH_LOGOUT_START: {
      return {
        ...state,
        isLoadingLogout: true
      }
    }

    case types.AUTH_LOGOUT_END: {
      return {
        ...state,
        isLoadingLogout: false
      }
    }

    case types.AUTH_LOGIN:
      return {
        ...state,
        loggedIn: true,
        error: "",
        dataLogin: action.data,
      };

    case types.AUTH_LOGIN_ERROR:
      return {
        ...state,
        loggedIn: false,
        dataLogin: null,
        error: action.error,
      };

    default:
      return state;
  }
};

export default authReducer;
