import React from 'react'

const IcCheckBoxOutlineBlank = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" stroke="url(#check-box-outline-blank)" />
            <defs>
                <linearGradient id="check-box-outline-blank" x1="8" y1="24" x2="24" y2="8" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00CFFF" />
                    <stop offset="1" stopColor="#0065FF" />
                    <stop offset="1" stopColor="#005BEC" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default IcCheckBoxOutlineBlank
