import styled from 'styled-components'

const Bound = styled.div`
    display:flex;
    flex-direction:column;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .animation-container{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`

export { Bound }