import styled, { css } from 'styled-components'

const WrapTransDetailsFlow = styled.div`
    padding: 28px 40px 0 45px;
    overflow: auto;
`;

const Step = styled.div`
    position: relative;
    border-left: 1px ${props => props.lineStyles || "solid"} #184da280;
    ${props => props.isHiddenLine ? 'border-left: none;' : ''}
    padding-left: ${props => props.paddingLeft + 15}px;
    
    &::before {
        content: '${props => props.step ?? ''}';
        position: absolute;
        top: 0;
        left: -16px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: ${props => props.isFailed ? "#F45C5C" : "#09AC10"};
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 170%;
        color: #FFFFFF;
    }

    .failed {
        color: #F45C5C;
        margin-bottom: 6px;
    }

    .success {
        color: #09AC10;
        margin-bottom: 6px;
    }

    ${props => props.extendStyles}
`;

const extendStylesStep1 = css`
    font-family:'SVN-Gilroy';
    font-style: normal;
    line-height: 170%;
    font-weight: normal;
    color: #1C1C1C;
    font-size: 14px;
    padding-bottom: 28px;    
    .title {
        font-weight: 600;
        margin-bottom: 6px
    }
    .detail-item {
        font-weight: normal;
        &.username{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;  
        }   
    }
    .time-wrap{
        font-weight: normal;
        .time{
            color: #6F6F6F;
            margin-left: 5px;
        }
    }      
`;

const extendStylesStep2 = css`
    ${extendStylesStep1}

    padding-bottom: 24px;
`;

const extendStylesStep3 = css`
    ${extendStylesStep1}
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;    
    .face-scan-container{
        display: grid;
        grid-template-columns: 1fr 30px 1fr;
        grid-column-gap: 30px;
        .scan-avatar-frist{
            display: flex;
        }
        .icon-retry{
            width: 30px;
            height: 30px;
        }
        .scan-avatar-retry{
            display: flex;
        }
    }
    .user-found-container{
        display: grid;
        grid-template-columns: 170px 1fr;
        grid-gap: 30px;        
        padding-top: 20px; 
        .user-found-wrap{
            display: flex;
            flex-direction: column;
            .list-user-wrap{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                .list-users{
                    width: fit-content;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;            
                    font-weight: normal;
                    background: #EDEDED;
                    border-radius: 50px;
                    margin: 15px 10px 0 0;
                    padding: 0 10px;
                    cursor: pointer;
                    &.choose{
                        background: #09AC10;
                        color: #FFFFFF;                    
                    }
                    .icon-check{
                        margin-right: 10px;
                    }
                }
            }
        }
        .avatar-user-wrap{
            display: flex;
            flex-direction: column;
            .avatar-user{                
                width: 170px;
                height: 170px;
                border: 1px solid #1C1C1C;
                box-sizing: border-box;
                border-radius: 3px;
                margin-top: 10px;               
                .avatar{
                    width: 168px;
                    height: 168px;
                    object-fit: contain;
                }
            }
        }

    }
`

const extendStylesStep4 = css`
    ${extendStylesStep1}
    padding-bottom: 30px;

    display: grid;
    grid-template-columns: 271px 331px auto;

    .again-step {
        display: flex;
        
        & > div {
            margin-left: 30px;
        }
    }    
`;

const extendStylesStep5 = css`
    ${extendStylesStep1}
    padding-bottom: 30px;
    .again-step {
        display: flex;
        
        & > div {
            margin-left: 30px;
        }
    }    
    .card-type-wrap{
        display: flex;
        flex-direction: column;
    }
`;

const extendStylesStep6 = css`
    ${extendStylesStep1}
    padding-bottom: 30px;

    .again-step {
        display: flex;
        
        & > div {
            margin-left: 30px;
        }
    }    
    .napas-type-wrap{
        display: flex;
        flex-direction: column;
    }

`;

const extendStylesStep6_1 = css`
    margin-top: 20px;
    padding-bottom: 20px;
    margin-left: 16px;
    .again-step {
        display: flex;
        
        & > div {
            margin-left: 30px;
        }
    }    
    .napas-type-wrap{
        display: flex;
        flex-direction: column;
        .napas-type{
            font-weight: bold;
            margin: 6px 0;
        }
    }
    
`;

const extendStylesStep6_1_2 = css`
    ${extendStylesStep6_1}
    margin-top: 0;
    
`;

const extendStylesStep6_2 = css`
    margin-left: 16px;
    .again-step {
        display: flex;
        
        & > div {
            margin-left: 30px;
        }
    }    
    .napas-type-wrap{
        display: flex;
        flex-direction: column;
        .napas-type{
            font-weight: bold;
            margin: 6px 0;
        }
    }
`;

export {
    WrapTransDetailsFlow,
    Step,
    extendStylesStep1,
    extendStylesStep2,
    extendStylesStep3,
    extendStylesStep4,
    extendStylesStep5,
    extendStylesStep6,
    extendStylesStep6_1,
    extendStylesStep6_1_2,
    extendStylesStep6_2
}