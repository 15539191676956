import styled from 'styled-components'

const WrapUserList = styled.div`
    margin-left: 58px;
    .list-user-container{       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .empty-control{
            margin-top: 290px;
        }
        .list-user{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .wrap-list{
                height: 776px;
            }
            .wrap-pagination{
                height: 40px;
                margin-top: 20px;
            }
        }        
    }
`;

const RowEmpty = styled.div`
    width: 740px;
    height: 82px;
    margin-top: 15px;
`;

export {
    WrapUserList,
    RowEmpty,    
}