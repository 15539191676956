import { css } from "styled-components";
import * as stylesConstaints from '../../../../assets/constants/ui'

export const TABLE_HEADER_STYLED = css`
    grid-template-columns: 76px 228px 190px 1fr;
    padding: 0 30px 0 58px;
    width: calc(100% - (30px + 58px));
    height: ${stylesConstaints.TABLE_HEADER_HEIGHT}px;
    margin-bottom: 10px;
    div{
        :first-child{
            margin-left: 15px;
            grid-column: 1 / span 3;
        }
        :last-child{
            
        }
    }
`

export const TABLE_HEADER_TITILE = [
    {
        label: 'table.header.user_information', 
        styled: css`
            margin-left: 20px;
        `
    },
    { label: 'table.header.status' }
]
