import styled from 'styled-components'

const Bound = styled.div`
    width: 1273px;
    height: 82px;
    background: rgba(244, 244, 244, 0.6);
    mix-blend-mode: normal;
    box-shadow: inset 0px -0.5px 0px #E2E2E2;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 210px 165px 148px 250px 337px 164px;
    font-family: 'SVN-Gilroy';
    font-style: normal;
    font-size: 14px;
    font-weight: normal;
    line-height: 170%;
    .order-code-wrap{
        display: flex;
        align-items: center;
        padding-left: 20px;
        /* .icon-dollar{
            padding: 0 18px 0 0;
        } */
        .order-code{
            color: #1C1C1C;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 180px;        
        }
    }
    .time-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .date{
            color: #051321;
            font-weight: 600;
            line-height: 38px;
        }
        .time{
            color: rgba(28,28,28,0.5);
        }
    }
    .money{
        color: #FA8C8C;
        font-weight: 600;
        line-height: 38px;
        display: flex;
        align-items: center;
    }
    .account-sent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .image-bank-code{
            margin-bottom: 8px;
            height: 30px;
        }
        .number-bank-code{
            color: #051321;
        }
    }
    .shop{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .image-shop{
            margin-bottom: 8px;
            height: 30px;
        }
        .name-shop{
            color: #051321;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 300px;      
        }
    }
    .trans-code-wrap{        
        display: flex;
        align-items: center;
        .trans-code{
            font-weight: 600;
            line-height: 38px;
            color: #051321;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            max-width: 140px;
        }
    }
`
export { Bound }