import React from 'react'
import * as styles from './index.style'

// component
import SimpleItemDetails from '../../PicturesCensorship/PictureCensorshipItem/SimpleItemDetails'
import Collapse from '../../../../../Control/Collapse'
import AdvancedItemDetails from './AdvancedItemDetails'


const EKYCCensorshipItem = ({
    data,
    defaultExpands=false
}) => {
    if(!data) return
    return (
        <styles.Bound>
            <Collapse
                panelComponent={
                    <SimpleItemDetails
                        data={data}
                    />
                }
                defaultExpands={defaultExpands}
                // maxHeight={1240}
                marginTop={0}
            >
                <AdvancedItemDetails data={data}/>
            </Collapse>
        </styles.Bound>
    )
}

export default React.memo(EKYCCensorshipItem)
