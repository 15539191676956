import React from "react";

const IcUnlock = (props) => (
  <svg
    {...props}
    width={13}
    height={14}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M10.3334 6.46667H2.86672C2.27761 6.46667 1.80005 6.94424 1.80005 7.53334V11.2667C1.80005 11.8558 2.27761 12.3333 2.86672 12.3333H10.3334C10.9225 12.3333 11.4001 11.8558 11.4001 11.2667V7.53334C11.4001 6.94424 10.9225 6.46667 10.3334 6.46667Z"
        stroke="#00B887"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.93335 6.46663V4.33329C3.93269 3.67199 4.17777 3.03402 4.62102 2.54325C5.06427 2.05248 5.67406 1.74391 6.33202 1.67745C6.98998 1.61099 7.64916 1.79138 8.1816 2.18359C8.71404 2.57581 9.08176 3.15188 9.21335 3.79996"
        stroke="#00B887"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="12.8"
          height="12.8"
          fill="white"
          transform="translate(0.199951 0.599976)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IcUnlock;
