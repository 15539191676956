import React, { useState, useCallback, useReducer, useEffect, useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//styles
import * as style from './index.style'
import { styleButtonDelete, styleButtonSave } from './index.style'
//constant
import * as keys from '../../../../../../../assets/constants/keys'
//component
import InputText from '../../../../../../Control/InputText'
import ButtonControl from '../../../../../../Control/Button'
import DropdownArea from '../../../../../../Control/DropdownArea'
import IcDelete from '../../../../../../../assets/images/components/IcDelete'
import IcSave from '../../../../../../../assets/images/components/IcSave'
import PopupConfirmDelete from '../PopupConfirmDelete'

// action
import { editShopFromChain, getListShopsByChain } from '../../../../../../../redux/chains/chainInfos/stores/action'
import { useRef } from 'react'
import { getListChains } from '../../../../../../../redux/chains/listChains/action'


const ContentPopupEditStore = ({ store, handleClose, setIsNeedConfirmClose }) => {

    const dispatch = useDispatch()
    const defaultValue = useRef()

    const { stores, chainDetails } = useSelector((state) => ({
        chainDetails: state.chains.chainInfos.chainDetails,
        stores: state.chains.chainInfos.stores,
    }))

    const [state, setState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            Code: "",
            Name: "",
            Lat: "",
            Lng: "",
            Address: "",
            AreaID: ""
        }
    )

    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false)

    const ChainID = useMemo(() => {
        if (!chainDetails.details) return null;
        return chainDetails.details.ID
    }, [JSON.stringify(chainDetails.details)])

    const _handleClickDelete = () => {
        setIsOpenConfirmDelete(true)
    }

    const _handleClickSave = useCallback(() => {
        if (!ChainID) return;
        if (!store) return;
        const { ID } = store;
        const { Code, Name, AreaID, Address, Lat, Lng } = state;
        dispatch(editShopFromChain({ ID, Code, Name, AreaID, Address, Lat, Lng }, () => {
            dispatch(getListShopsByChain({ 
                ChainID, 
                PageNo: stores.firstFilter.PageNo, 
                PageSize: stores.firstFilter.PageSize
            }))
            dispatch(getListChains({
                PageNo: 1,
                PageSize: 50,
            }))
            handleClose()
        }))
    }, [JSON.stringify(store), JSON.stringify(state), handleClose, ChainID, stores.firstFilter])

    useLayoutEffect(() => {
        if (!store) return;
        const { Code, Name, AreaID, ID, Address, Lat, Lng } = store;
        const state = {
            Code,
            Name,
            AreaID,
            Address,
            Lat,
            Lng
        }
        setState(state)
        defaultValue.current = state
    }, [JSON.stringify(store), setState])

    useEffect(() => {
        const mixStateToDefaultValue = { ...defaultValue.current, ...state }
        const isDiff = JSON.stringify(mixStateToDefaultValue) !== JSON.stringify(defaultValue.current)
        setIsNeedConfirmClose(isDiff)
    }, [defaultValue, JSON.stringify(state), setIsNeedConfirmClose])

    const isDisableEditStore = useMemo(() => {
        const { Code, Name, Address, AreaID } = state
        const isObligateField = [Code, Name, Address, AreaID]
        return isObligateField.some(item => !item)
    }, [JSON.stringify(state)])

    return (
        <style.Bounds>
            <div className="row-input">
                <div className="wrapper-input">
                    <InputText
                        defaultValue={state.Code}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập mã cửa hàng ..."
                        titleInput="Mã cửa hàng *"
                        maxLength={200}
                        keyValue={keys.STORECODE}
                        onChange={(_, value) => setState({ Code: value.trim() })}
                    />
                </div>
                <div className="wrapper-input">
                    <InputText
                        defaultValue={state.Name}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập tên cửa hàng ..."
                        titleInput="Nhập tên cửa hàng *"
                        maxLength={200}
                        keyValue={keys.STORENAME}
                        onChange={(_, value) => setState({ Name: value.trim() })}
                    />
                </div>
            </div>
            <div className="drop-down">
                <DropdownArea
                    defaultArea={{ ID: store?.AreaID, Name: store?.AreaName }}
                    onChangeValue={(selected) => setState({ AreaID: selected.ID || "" })}
                />
            </div>
            <div className="row-input">
                <div className="wrapper-input">
                    <InputText
                        defaultValue={state.Lat}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập Latitude ..."
                        titleInput="Latitude"
                        keyValue={keys.LATITUDE}
                        onChange={(_, value) => setState({ Lat: value.trim() })}
                    />
                </div>
                <div className="wrapper-input">
                    <InputText
                        defaultValue={state.Lng}
                        isTitle={true}
                        disabled={false}
                        placeholder="Nhập Longitude ..."
                        titleInput="Longitude"
                        keyValue={keys.LONGITUDE}
                        onChange={(_, value) => setState({ Lng: value.trim() })}
                    />
                </div>
            </div>
            <div className="wrapper-input">
                <InputText
                    defaultValue={state.Address}
                    isTitle={true}
                    disabled={false}
                    placeholder="Nhập địa chỉ ..."
                    titleInput="Địa chỉ *"
                    keyValue={keys.ADDRESS}
                    onChange={(_, value) => setState({ Address: value.trim() })}
                />
            </div>
            <div className="button-container">
                <div className="btn-del-wrap">
                    <ButtonControl
                        icon={<IcDelete />}
                        isDisable={Object.values(state).some(item => !item)}
                        handleClick={_handleClickDelete}
                        extendStyles={styleButtonDelete}
                        children="xóa bỏ"
                    />
                    {
                        isOpenConfirmDelete &&
                        <PopupConfirmDelete
                            chainID={ChainID}
                            store={store}
                            onClose={() => setIsOpenConfirmDelete(false)}
                            onClosePopupEdit={handleClose}
                        />
                    }
                </div>
                <div>
                    <ButtonControl
                        icon={<IcSave />}
                        isDisable={isDisableEditStore}
                        handleClick={_handleClickSave}
                        extendStyles={styleButtonSave}
                        children="lưu lại"
                    />
                </div>
            </div>

        </style.Bounds>
    )
}

export default ContentPopupEditStore
