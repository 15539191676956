import React, { useState, useCallback, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'

//constant
import * as keys from '../../../../assets/constants/keys'

// style
import * as styles from "./index.style";
import { styleButtonAddChain } from './index.style'
import InputSearch from "../../InputSearch";
import ButtonControl from "../../Button";
import PopupAddChains from "./PopupAddChains";
import IcAdd14x14 from "../../../../assets/images/components/IcAdd14x14";

//action
import { getListChains } from "../../../../redux/chains/listChains/action";

let timer;

const ChainsMainBoardHeader = (props) => {
    const { callBackClickNavLink } = props
    const { t } = useTranslation('common')
    const dispatch = useDispatch()

    const { listChains } = useSelector((state) => ({
        listChains: state.chains.listChains
    }))

    const btnRef = useRef(null)

    const [value, setValue] = useState("");
    const [isOpen, setIsOpen] = useState(false)


    useEffect(() => {
        if(listChains.filter.keyword === value.trim()) return;
        setValue(listChains.filter.keyword)
    }, [listChains.filter.keyword])

    useEffect(() => {
        callBackClickNavLink({ key: keys.CHAINS })
    }, [])

    const _handleOnChangeValue = (value) => {
        // Prevent special string
        // let regSpecialString = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/;
        // let checkString = regSpecialString.test(value);
        // if (checkString) return;

        // Prevent double space
        if (value.includes("  ")) return;

        // Prevent first string is space
        if (value[0] === " ") return;

        setValue(value);
        if(timer !== null){
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            dispatch(getListChains({ PageNo: 1, PageSize: 50, Filter: value.trim() }))
            timer= null
        }, 1000);
    };

    const _handleClickAddChains = useCallback(
        (e) => {
            setIsOpen(!isOpen)
        },
        [isOpen],
    )

    return (
        <styles.Bound>
            <div className="title">Danh sách chuỗi</div>
            <InputSearch
                placeHolderSearch="Tìm kiếm..."
                value={value}
                onChangeValue={_handleOnChangeValue}
            />
            <ButtonControl
                handleClick={_handleClickAddChains}
                extendStyles={styleButtonAddChain}
                children="Thêm chuỗi mới"
                icon = {<IcAdd14x14 />}
                ref={btnRef}
            />
            { 
                isOpen &&
                <PopupAddChains btnRef={btnRef} handleClose={_handleClickAddChains} />
            }
        </styles.Bound>
    );
};

ChainsMainBoardHeader.propTypes = {

};

export default React.memo(ChainsMainBoardHeader);
