import styled from 'styled-components'

const StyleRow = styled.div`
    position: relative;
    width: 740px;
    height: 82px;   
    margin-top: 15px;    
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    &.filter-controls {
        height: auto;
    }
    
    .dot {
        width: 16px;
        height: 16px;
        background: #2D98F0;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 0;

        .dot-selected {
            z-index: 1;
        }
    }

    .content-data {
        z-index: 1;
        background: ${props => props.enableClick ? '#FFFFFF' : 'none'};
        flex-basis: 100%;
        transition: flex-basis 250ms;
        box-sizing: border-box;
        border-radius: 6px;        
        display: grid;
        grid-template-columns: 80px 90px 240px 170px 1fr;
        grid-gap: 10px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        align-items: center;
        &.content-data-selected {
            flex-basis: calc(100% - 16px - 4px);
            border: 2px solid #2D98F0;            
        }
        .trading-code{
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            color: #6F6F6F;
            display: flex;
            align-items: center;
            padding-left: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        .date-time{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .date{
                font-weight: 600;
                font-size: 14px;
                line-height: 170%;
                color: #252733;
            }
            .time{
                font-weight: normal;
                font-size: 12px;
                line-height: 170%;
                color: #6F6F6F;
            }
        }
        .user{
            display: flex;
            align-items: center;
            .avatar-wrapper{
                width: 44px;
                height: 44px;
                border-radius: 50%;
                padding-right: 10px;
                .avatar-user{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    object-fit: cover;                    
                }  
                .avatar-default{
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                }              
            }
            .info-user{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                .name-user{
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 170%;
                    color: #051321;
                    margin-bottom: 13px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 180px;
                }
                .wrapper-user{
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 170%;
                    color: #6F6F6F;
                    display: flex;
                    align-items: center;
                    /* max-width: 180px; */
                    span{
                        color: #2D98F0;
                        padding-right: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        max-width: 100px;
                    }
                }
            }
        }
        .chain{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .logo-chain-wrapper{
                height: 30px;
                margin-bottom: 10px;
                .logo-chain{
                    height: 30px;
                }
                .logo-chain-default{
                    height: 30px;
                    width: 30px;
                }
            }
            .name-chain{
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                color: #051321;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                /* max-width: 160px; */
            }          
        }
        .money{
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            line-height: 170%;
            color: #FA8C8C;            
        }
    }
    
`;

export {
    StyleRow
}