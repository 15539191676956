import styled from "styled-components";

const ModalStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* height: 100%; */
    z-index: 98;

    ${props => props.extendStyles}
`;

export {
    ModalStyles
}