import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//style
import * as styles from './index.style'
//component
import ButtonExportExcel from '../../../../Control/ButtonExportExcel'
import HeaderTransHistory from './HeaderTransHistory'
import EmptyDataControl_v3 from '../../../../Control/EmptyDataControl_v3'
import Pagination from '../../../MainBoard/Transaction/Pagination'
import RowTransHistory from './RowTransHistory'

// action
import { getTransHistoriesByUser, clearListTransHistoriesByUser, exportExcelTransHistoryByUser } from '../../../../../redux/userCensorship/detailsUser/transactionHistories/action'

const TransactionHistory = () => {
    const dispatch = useDispatch()

    const [listTrans, setListTrans] = useState([])


    const { detailsAccount, transactionHistories } = useSelector(state => ({
        detailsAccount: state.userCensorship.detailsUser.detailsAccount,
        transactionHistories: state.userCensorship.detailsUser.transactionHistories,
    }))

    useEffect(() => {
        if (!detailsAccount?.ID) return;

        dispatch(getTransHistoriesByUser({
            ID: detailsAccount.ID,
            PageNumber: 1,
            PageSize: 8
        }))
    }, [JSON.stringify(detailsAccount)])

    useEffect(() => {
        setListTrans(transactionHistories.list)
    }, [transactionHistories])

    useEffect(() => {
        return () => {
            dispatch(clearListTransHistoriesByUser())
        }
    }, [])

    const onChangePreviousPage = () => {
        dispatch(getTransHistoriesByUser({
            ID: detailsAccount.ID,
            PageNumber: pageNumber - 1,
            PageSize: pageSize
        }))
    }

    const onChangeNextPage = () => {
        dispatch(getTransHistoriesByUser({
            ID: detailsAccount.ID,
            PageNumber: pageNumber + 1,
            PageSize: pageSize
        }))
    }

    const _handleExportExcel = useCallback(
        () => {
            if(!Object.keys(transactionHistories.condRequestFilters).length) return;
            dispatch(exportExcelTransHistoryByUser(transactionHistories.condRequestFilters))
            dispatch(getTransHistoriesByUser({
                ID: detailsAccount.ID,
                PageNumber: 1,
                PageSize: 8
            }))
        },
        [JSON.stringify(transactionHistories.condRequestFilters)],
    )

    const pageSize = useMemo(() => transactionHistories.condFilters.PageSize, [transactionHistories.condFilters.PageSize])
    const pageNumber = useMemo(() => transactionHistories.condFilters.PageNumber, [transactionHistories.condFilters.PageNumber])
    return (
        <styles.Bound>
            <div className='wrapper-container'>
                <div className='button-export'>
                    <ButtonExportExcel onClick={_handleExportExcel}/>
                </div>
                <HeaderTransHistory />
                <div className='list-trans-history-container'>
                    {
                        listTrans.length === 0 ?
                            <div className="empty-control" >
                                <EmptyDataControl_v3 labelDes={"Không có kết quả được tìm thấy!"} />
                            </div>
                            :
                            <div className='list-trans-history'>
                                <div className='wrap-list'>
                                    {
                                        listTrans.map((item, index) => (
                                            <div className='wrapper-row'>
                                                <div className='line-connect-container'>
                                                    <div className='circle' />
                                                    {
                                                        listTrans.length > 1 &&
                                                        <div className='line' />
                                                    }
                                                </div>
                                                <RowTransHistory
                                                    key={item.ID}
                                                    data={item}
                                                />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                    }
                    {
                        listTrans.length > 0 &&
                            <div className="wrap-pagination">
                                <Pagination
                                    total={transactionHistories.total}
                                    pageNumber={pageNumber}
                                    pageSize={pageSize}
                                    onChangePreviousPage={onChangePreviousPage}
                                    onChangeNextPage={onChangeNextPage}
                                />
                            </div>
                           
                    }
                </div>
            </div>

        </styles.Bound>
    )
}

export default TransactionHistory
